<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card max-width="1400px" width="100%" height="100%" class="pa-4 d-flex flex-column" elevation="0" color="#EAF1F5"
      v-if="this.dialog.type != 'details'">
      <GenericFilter :level="3" @onSearchAction="onSearch" :requiredFields="{
        masterSchool: true,
        school: true,
        year: true,
        progTrainPeriod: false,
        class: false,
        lesson: false,
      }" />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          }" :headers="headers" :items="thesis.content" :server-items-length="thesis.elements"
            :options.sync="options" :loading="tableLoader">
            <!-- <template v-slot:[`item.grade`]="{ item }">
              <span v-if="item.grade == '0'">Αποτυχία</span>
              <span v-if="item.grade == '1'">Επιτυχία</span>
              <span></span>
            </template> -->

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onViewPoreia(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.examDate`]="{ item }">
              <span>{{
              new Date(item.examDate).toLocaleDateString("el")
              }}</span>
            </template>

            <template v-slot:[`item.person`]="{ item }">
              <span>
                {{ item.person.firstName + " " + item.person.lastName }}
              </span>
            </template>

            <template v-slot:[`item.exam1`]="{ item }">
              <span>{{
              item.examinerPerson1.firstName +
              " " +
              item.examinerPerson1.lastName
              }}</span>
            </template>

            <template v-slot:[`item.exam2`]="{ item }">
              <span v-if="item.examinerPerson2 != null">{{
              item.examinerPerson2.firstName +
              " " +
              item.examinerPerson2.lastName
              }}</span>
            </template>

            <template v-slot:[`item.exam3`]="{ item }">
              <span v-if="item.examinerPerson3 != null">{{
              item.examinerPerson3.firstName +
              " " +
              item.examinerPerson3.lastName
              }}</span>
            </template>

            <template v-slot:[`item.exam4`]="{ item }">
              <span v-if="item.examinerPerson4 != null">{{
              item.examinerPerson4.firstName +
              " " +
              item.examinerPerson4.lastName
              }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn depressed class="ml-auto" color="primary " @click="openDialog(null, 'add')" :disabled="isDisabled">
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'add'" max-width="500px" scrollable>
      <AddDegree v-if="dialog.open && dialog.type == 'add'" @close="closeDialog" />
    </v-dialog>

    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'edit'" max-width="500px" scrollable>
      <EditDegree v-if="dialog.open && dialog.type == 'edit'" :degree="dialog.degree" @close="closeDialog" />
    </v-dialog>

    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'delete'" max-width="500px" scrollable>
      <DeleteDegree v-if="dialog.open && dialog.type == 'delete'" :degree="dialog.degree" @close="closeDialog" />
    </v-dialog>
    <ThesisDetails v-if="dialog.open && dialog.type == 'details'" :thesis="dialog.degree" @close="closeDialog" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddDegree from "../../components/DegreeModals/AddDegree.vue";
import EditDegree from "../../components/DegreeModals/EditDegree.vue";
import DeleteDegree from "../../components/DegreeModals/DeleteDegree.vue";
import ThesisDetails from "../../views/Thesis/ThesisDetails.vue";

export default {
  name: "Thesis",
  components: {
    GenericFilter,
    AddDegree,
    EditDegree,
    DeleteDegree,
    ThesisDetails,
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      thesis: [],
      headers: [
        {
          text: "Θέμα",
          value: "subject",
          sortable: false,
        },
        {
          text: "Σπουδαστής",
          value: "person",
          sortable: false,
        },

        {
          text: "Βαθμός",
          value: "grade",
          sortable: false,
        },
        { text: "Επίδοση", value: "gradeCharacterised", sortable: false },

        {
          text: "Ημερομηνία έναρξης",
          value: "examDate",
          sortable: false,
        },

        {
          text: "Επιβλέπων καθηγητής 1",
          value: "exam1",
          sortable: false,
        },

        {
          text: "Επιβλέπων καθηγητής 2",
          value: "exam2",
          sortable: false,
        },

        {
          text: "Εξεταστής 1",
          value: "exam3",
          sortable: false,
        },

        {
          text: "Εξεταστής 2",
          value: "exam4",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        degree: null,
        open: false,
        type: null,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        from.path !=
        "/class_exams/" + from.params?.class_exam_id + "/students"
      ) {
        vm.clearGeneric();
      }
    });
  },
  watch: {
    async options() {
      if (this.genericFilter.academicYearId != null) await this.fetchThesis();
    },
  },
  computed: {
    ...mapState({
      genericFilter: (state) => state.generic_filter,
      systemRole: (state) => state.auth.selectedSystemRole
    }),
    isDisabled() {
      if (
        this.genericFilter.facultyId != null &&
        this.genericFilter.schoolId != null
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentDegree"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.fetchThesis();
      }
    },
    clearGeneric() {
      this.$store.dispatch("setAllFilter", {
        facultyId: null,
        schoolId: null,
        academicYearId: null,
        semesterId: null,
        classId: null,
        courseId: null,
        dates: null,
        date: null,
        active: null,
        searchText: null,
        examYearId: null,
        progExamPeriodId: null,
        examClassId: null,
        examLessonId: null,
        examDate: null,
      });
    },
    async fetchThesis() {
      try {
        this.tableLoader = true;
        let url = this.systemRole == 'TEACHER_ROLE' ? `${process.env.VUE_APP_BASE_URL}/degree/list/user` : `${process.env.VUE_APP_BASE_URL}/degree/list`
        const res = await axios.get(
          url,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              schoolId: this.genericFilter.schoolId,
              yearId: this.genericFilter.academicYearId,
            },
          }
        );
        this.thesis = res.data;
        this.tableLoader = false;
      } catch (e) {
        this.tableLoader = false;
        this.$store.dispatch("errorHandler", e);
      }
    },
    onViewPoreia(degree) {
      //   this.setCurrentDegree(degree);

      //   console.log(`thesis/${degree.id}/poreia`);
      //   this.$router.push(`thesis/${degree.id}/poreia`);
      this.dialog = {
        degree,
        open: true,
        type: "details",
      };
    },

    openDialog(degree, type) {
      this.dialog = {
        degree,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        degree: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchThesis();
    },
  },
};
</script>

<style>

</style>