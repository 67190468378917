
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="6"
        :search="true"
        :key="query"
        :listActive="1"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: true,
          lesson: requiredLesson,
          date: true,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="absences"
            :options.sync="options"
            :server-items-length="absences.length"
            :loading="tableLoader"
          >
            <template v-slot:[`item.absHours`]="{ item }">
              <span v-for="(abs, i) in absenceList" :key="i">
                <span v-if="abs.personId == item.personId">
                  <v-text-field
                    v-model="absenceList[i].absHours"
                    dense
                    single-line
                    
                    v-if="edit"
                    :error-messages="errorMessages(i)"
                  >
                  </v-text-field>
                  <span v-else> {{ item.absHours }}</span>
                </span>
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }" v-if="!edit">
              <v-tooltip left v-if="item.absenceId != undefined">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>
              <v-tooltip left v-if="item.absenceId != undefined">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            :disabled="(!filtersSet || absences.length == 0) && dates != null"
            v-if="!edit"
            @click="edit = !edit"
          >
            Επεξεργασία
            <v-icon dense right>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            depressed
            color="error"
            class="mr-2"
            v-if="edit"
            @click="onClose"
          >
            Ακύρωση
          </v-btn>

          <v-btn
            depressed
            color="success"
            class="mr-2"
            v-if="edit"
            :loading="saveLoader"
            @click="onSave"
          >
            Αποθήκευση
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddAbsence
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        from="1"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditAbsence
        v-if="dialog.open && dialog.type == 'edit'"
        :absence="dialog.absence"
        @close="closeDialog"
        from="1"
        type="massive"
        @handler="handler"
        :maxAbsence="maxAbsences"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteAbsence
        v-if="dialog.open && dialog.type == 'delete'"
        :absence="dialog.absence"
        @close="closeDialog"
        :absences="absences"
        type="massive"
        @handler="handler"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddAbsence from "../../components/AbsencesModals/AddAbsence.vue";
import EditAbsence from "../../components/AbsencesModals/EditAbsence.vue";
import DeleteAbsence from "../../components/AbsencesModals/DeleteAbsence.vue";
import { required, maxLength, maxValue } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  components: {
    GenericFilter,
    AddAbsence,
    EditAbsence,
    DeleteAbsence,
  },

  async created() {},

  data() {
    return {
      tableLoader: false,
      edit: false,
      hasLesson: false,
      options: {
        itemsPerPage: -1,
        page: 1,
      },
      saveLoader: false,
      filtersSet: false,
      absences: [],
      absenceList: [],
      headers: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Ώρες",
          value: "absHours",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      maxAbsences: null,
      query: false,
      dialog: {
        absence: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
  },
  validations() {
    const self = this;
    return {
      absenceList: {
        $each: {
          absHours: {
            maxValue: maxValue(self.maxAbsences),
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      courseId: (state) => state.generic_filter.courseId,
      classId: (state) => state.generic_filter.classId,
      schoolId: (state) => state.generic_filter.schoolId,
      dates: (state) => state.generic_filter.dates,
      searchText: (state) => state.generic_filter.searchText,
      appId: (state) => state.auth.appId,
      schoolDetails: (state) => state.generic_filter.schoolDetails,
      academicYearId: (state) => state.generic_filter.academicYearId,
    }),
    requiredLesson() {
      if (this.schoolDetails != null) {
        if (this.schoolDetails.absence) {
          if (this.schoolDetails.absence.cd == "1") {
            return true;
          }
        }
      }
      return false;
    },
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentStudent"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        await this.fetchSchoolProt();
        await this.handler();
        await this.fetchMaxAbsences();
      }
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/daily/absence/list/function`,
          {
            params: {
              page: 0,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              progTrainPeriodId: this.progTrainPeriodId,
              classId: this.classId,
              absenceDateTo: this.dates,
              searchText: this.searchText != "" ? this.searchText : null,
              lessonId: this.courseId,
            },
          }
        );
        console.log(res);
        this.absences = res.data.map((d) => {
          if (d.absHours == undefined) {
            d.absHours = null;
          }
          return d;
        });

        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;

        this.absenceList = [];

        for (const student of this.absences) {
          if (student.absHours != null && student.absHours != undefined) {
            this.absenceList.push({
              personId: student.personId,
              absDate: student.absDate,
              attendLessonId:
                student.attendLessonId != undefined &&
                student.attendLessonId != null
                  ? student.attendLessonId
                  : null,
              isNew: false,
              absHours: student.absHours,
              absType: student.absType,
              notes: student.notes,
              progTrainPrdId: this.progTrainPeriodId,
            });
          } else {
            this.absenceList.push({
              personId: student.personId,
              absDate: this.dates,
              attendLessonId:
                student.attendLessonId != undefined &&
                student.attendLessonId != null
                  ? student.attendLessonId
                  : null,
              isNew: true,
              absHours: null,
              absType: 0,
              notes: null,
              progTrainPrdId: this.progTrainPeriodId,
            });
          }
        }
        this.tableLoader = false;
      } catch (e) {
        this.absences = [];
        this.$store.dispatch("errorHandler", e);

        console.log(e);
        this.tableLoader = false;
      }
    },
    async onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.saveLoader = true;
      let content = JSON.parse(
        JSON.stringify(
          this.absenceList.filter((el) => {
            if (el.absHours == null || el.absHours == "") {
              null;
            } else {
              return el;
            }
          })
        )
      );

      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/daily/absence`,
          content
        );
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Η καταχώρηση απουσιών ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
        this.saveLoader = false;
        this.edit = false;
        this.handler();
      } catch (e) {
        this.saveLoader = false;
        this.edit = false;
        this.$store.dispatch("errorHandler", e);
        throw e;
      }
    },
    async fetchSchoolProt() {
      try {
        if (this.schoolId != null) {
          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/combine/${this.schoolId}`
          );
          this.hasLesson = res.data.school.absence.cd == 1 ? true : false;
        }
      } catch (e) {
        throw e;
      }
    },
    async fetchMaxAbsences() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/parameter`,
          {
            params: {
              param: 200.001,
              masterSchoolId: this.academicYearId,
              schoolId: this.schoolId,
              kind: this.appId,
            },
          }
        );
        this.maxAbsences = res.data;
        console.log(this.maxAbsences);
      } catch (e) {
        console.log(e);
      }
    },
    openDialog(absence, type) {
      this.dialog = {
        absence,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        absence: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.handler();
    },
    onClose() {
      for (let [i, abs] of this.absenceList.entries()) {
        if (abs.isNew) {
          this.absenceList[i] = {
            personId: abs.personId,
            absDate: abs.absDate,
            attendLessonId:
              this.courseId != undefined && this.courseId != null
                ? this.courseId
                : null,
            isNew: true,
            absHours: null,
            absType: 0,
            notes: null,
          };
        }

        this.edit = false;
      }
    },
    errorMessages(i) {
      if (
        this.absenceList[i].absHours != undefined &&
        this.absenceList[i].absHours != null
      ) {
        console.log(this.$v.absenceList.$each[i].absHours.$error);
        const errors = [];
        if (!this.$v.absenceList.$each[i].absHours.$dirty) return errors;
        if (this.$v.absenceList.$each[i].absHours.$error)
          errors.push(
            "Έχετε ξεπεράσει τον επιτρεπόμενο αριθμό απουσιών "
          );
        console.log(errors);
        return errors;
      } else return;
    },
  },
};
</script>

<style scoped lang="scss">
</style>