<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία τύπου σχολείου
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-textarea
          dense
          outlined
          v-model="description"
          label="Περιγραφή"
          hide-details="auto"
          :error-messages="descriptionErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Περιγραφή
          </template>
        </v-textarea>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      loader: false,
      description: "",
    };
  },

  validations: {
    description: {
      required,
    },
  },

  computed: {
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    ...mapState({
      appId:(state)=>state.auth.appId
    })
  },

  methods: {
    ...mapActions(["addSchoolType"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        await this.addSchoolType({
          description: this.description,
        });
        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>