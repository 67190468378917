<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      <span>Εκτύπωση {{ this.report.description }}</span>
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="
          () => {
            this.$v.$reset();
            $emit('close');
          }
        "
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-card>
        <v-form @submit.prevent="onSubmit" class="pa-4" id="form">
          <v-row>
            <v-radio-group v-model="outputType" row>
              <v-radio
                v-for="n in outputTypeList"
                :key="n.id"
                :label="n.desc"
                :value="n.id"
              ></v-radio>
            </v-radio-group>
          </v-row>
          <v-row v-for="(par, i) in report.parameters" :key="i">
            <!-- Text Field -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="content[i].value"
                  dense
                  outlined
                  v-if="par.type == '1'"
                  :label="par.label"
                  type="text"
                  v-bind="attrs"
                  hide-details="auto"
                  :error-messages="errorMessages(par, i)"
                  v-on="on"
                  class="dynamicField"
                >
                </v-text-field>
              </template>
              <span>{{ par.label }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="content[i].value"
                  dense
                  outlined
                  v-if="par.type == '2'"
                  :label="par.label"
                  hide-details="auto"
                  v-bind="attrs"
                  :error-messages="errorMessages(par, i)"
                  v-on="on"
                  class="dynamicField"
                >
                </v-text-field>
              </template>
              <span>{{ par.label }}</span>
            </v-tooltip>

            <v-menu
              ref="menu"
              v-model="content[i].menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-x
              left
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-if="par.type == '3'"
                  dense
                  outlined
                  v-model="content[i].value"
                  :label="par.label"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details="auto"
                  :error-messages="errorMessages(par, i)"
                  v-bind="attrs"
                  v-on="on"
                  class="dynamicField"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="content[i].value"
                scrollable
                :error-messages="errorMessages(par, i)"
              >
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  text
                  color="primary"
                  @click="
                    content[i].menu = false;
                    content[i].value = null;
                  "
                >
                  Ακύρωση
                </v-btn>
                <v-btn
                  dense
                  text
                  color="primary"
                  @click="content[i].menu = false"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

            <v-checkbox
              v-model="content[i].value"
              :label="par.label"
              v-if="par.type == '4'"
              :error-messages="errorMessages(par, i)"
            >
            </v-checkbox>

            <v-select
              v-model="content[i].value"
              :label="par.label"
              v-if="par.type == '6'"
              dense
              item-text="description"
              clearable
              item-value="cd"
              :error-messages="errorMessages(par, i)"
              hide-details="auto"
              :items="fetchDataList(par.lovId.cd, i, par)"
              @change="onChangeDataList($event, par)"
              outlined
              class="dynamicField"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>
          </v-row>
          <div class="d-flex flex-grow-1 align-center justify-center mt-8">
            <v-btn
              depressed
              color="success"
              class="mr-2"
              :loading="loader"
              @click="onSubmit"
            >
              Έκτυπωση
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-card-text>
    <Loader v-if="showLoader" />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Loader from "../Loader/Loader.vue";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
export default {
  name: "PrintParameterModal",
  props: ["report"],
  components: {
    Loader,
  },
  created() {
    for (let i = 0; i < this.report.parameters.length; i++) {
      // check if selectList
      if (this.report.parameters[i].type == "6") {
        // check parent exist
        if (
          this.report.parameters[i].parentRef == undefined ||
          this.report.parameters[i].parentRef == null ||
          this.report.parameters[i].parentRef == ""
        ) {
          this.checkList(this.report.parameters[i].lovId);
        }
      }

      this.content.push({
        value: this.report.parameters[i].type == "4" ? false : null, //assign false if input is checkbox
        label: this.report.parameters[i].label,
        type: this.report.parameters[i].type,
        menu: this.report.parameters[i].type == "3" ? false : null, //assign value only if input is date
        key: this.report.parameters[i].key,
      });
    }
  },
  data() {
    return {
      showLoader: false,
      content: [],
      dataList: [],
      loader: false,
      outputType: 1,
      outputTypeList: [
        {
          id: 1,
          desc: "pdf",
        },
        {
          id: 2,
          desc: "csv",
        },
        {
          id: 3,
          desc: "xlsx",
        },
      ],
    };
  },
  validations() {
    return {
      content: {
        $each: {
          value: {
            required,
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      staticView: (state) => state.auth.userInfo.staticView,
      appId: (state) => state.auth.appId,
    }),
  },
  methods: {
    fetchDataList(groupId, i, par) {
      let grp = this.dataList.filter((m) => {
        if (m.lovGroupId == groupId) {
          return m;
        }
      });
      if (
        this.content[i].value == undefined ||
        this.content[i].value == null ||
        this.content[i].value == ""
      ) {
        if (grp.length == 1) {
          this.content[i].value = grp[0].cd;
          this.onChangeDataList(grp[0].cd, par);
        }
      }
      return grp;
      // return this.dataList.filter((m) => {
      //   if (m.lovGroupId == groupId) {
      //     return m;
      //   }
      // });
    },
    onChangeDataList(event, item) {
      let fields = this.report.parameters.filter((element) => {
        if (Object.prototype.hasOwnProperty.call(element, "parentRef")) {
          if (element.parentRef.id == item.id) {
            return element;
          }
        }
      });

      fields.forEach((el) => {
        if (Object.prototype.hasOwnProperty.call(el, "lovId")) {
          this.dataList = this.dataList.filter((e) => {
            if (e.lovGroupId != Number(el.lovId.cd)) {
              return e;
            }
          });
          this.fetchDataDynamic(el.lovId.cd, event);
        }
      });
    },

    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        role: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );

        res.data.forEach((m) => {
          this.dataList.push(m);
        });
      } catch (e) {}
    },

    checkList(lovId) {
      // search on static list
      let existStatic = this.staticView.filter((l) => {
        if (l.lov_grp_id == lovId.id) {
          return l;
        }
      });

      // if exist push on data list
      if (existStatic.length > 0) {
        this.dataList.push(existStatic[0]);
      } else {
        this.fetchDataDynamic(lovId.cd);
      }
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loader = true;
      let jasperPrintParams = this.content.map((c) => {
        if (c.type == "6") {
          return { key: c.key, value: Number(c.value) };
        }
        return { key: c.key, value: c.value };
      });
      let body = {
        id: this.report.id,
        outputName: this.report.description,
        jasperPrintParams,
      };
      try {
        let m = moment();
        var ts = moment(m.toISOString()).valueOf();
        m = moment(ts);
        console.log(ts);
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/jasper/download/${this.outputType}`,
          body,
          {
            responseType: "blob",
          }
        );

        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: `${
              this.outputType == 1
                ? "application/pdf"
                : this.outputType == 2
                ? "text/csv"
                : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }`,
          })
        );
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        let out =
          this.outputType == 1
            ? ".pdf"
            : this.outputType == 2
            ? ".csv"
            : ".xlsx";
        fileLink.setAttribute("download", body.outputName +'_' + ts + out);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (e) {
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
          color: "error",
        });
        throw e;
      }
      this.loader = false;
    },
    errorMessages(item, i) {
      const errors = [];
      if (!this.$v.content.$each[i].value.$dirty) return errors;
      if (!this.$v.content.$each[i].value.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
};
</script>


<style scoped>
.dynamicField {
  margin: 10px;
}
</style>