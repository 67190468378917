
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="examResults.content"
            :server-items-length="examResults.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.examDate`]="{ item }">
              <span>{{
                new Date(item.examDate).toLocaleDateString("el")
              }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  created() {
    this.breadcrumb_items.push(
      {
        text: `Σχολές (${this.currentFaculty.description})`,
        disabled: false,
        link: true,
        exact: true,
        to: "/faculties",
      },
      {
        text: `Σχολεία (${this.currentSchool.description})`,
        disabled: false,
        link: true,
        exact: true,
        to: `/faculties/${this.currentFaculty.id}/schools`,
      },
      {
        text: `Διδακτικές περίοδοι (${this.currentSemester.description})`,
        disabled: false,
        link: true,
        exact: true,
        to: `/faculties/${this.currentFaculty.id}/schools/${this.currentSchool.id}/semesters`,
      },
      {
        text: `Μαθήματα (${this.currentCourse.description})`,
        disabled: false,
        link: true,
        exact: true,
        to: `/faculties/${this.currentFaculty.id}/schools/${this.currentSchool.id}/semesters/${this.currentSemester.id}/courses`,
      },

      {
        text: `Αποτελέσματα εξετάσεων`,
        disabled: true,
      }
    );
  },
  data() {
    return {
      tableLoader: false,
      search: "",
      breadcrumb_items: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Ημερομηνία εξέτασης",
          value: "examDate",
          sortable: false,
        },
        {
          text: "Αποτέλεσμα",
          value: "res.description",
          sortable: false,
        },
        {
          text: "Βαθμός",
          value: "grade",
          sortable: false,
        },
        {
          text: "Συνολικός βαθμός",
          value: "totGrade",
          sortable: false,
        },
      ],

      dialog: {
        course: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);
          await this.getExamResults({
            lessonId: this.currentCourse.id,
            page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
            limit:
              this.options.itemsPerPage == -1
                ? null
                : this.options.itemsPerPage,
          });
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      currentSemester: (state) => state.semesters.currentSemester,
      currentCourse: (state) => state.courses.currentCourse,
      courses: (state) => state.courses.courses,
      pageChanged: (state) => state.base.pageChanged,
      examResults: (state) => state.exam_results.examResults,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentCourse"]),
    ...mapActions(["getExamResults"]),
  },
};
</script>

<style scoped lang="scss">
</style>