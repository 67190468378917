import axios from "axios"

export default {
    state: {
        roles: [],
        privileges: [],
        filters: {},

        allRoles: []
    },

    mutations: {
        getRoles(state, roles) {
            state.roles = roles
        },
        getAllRoles(state, allRoles) {
            state.allRoles = allRoles
        },

        getPrivileges(state, privileges) {
            state.privileges = privileges
        },
    },

    actions: {

        async getRoles({ commit, dispatch }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/role/list/view`,
                    {
                        params: {
                            page: filters.page,
                            limit: filters.limit,

                        }
                    })
                commit('getRoles', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async getAllRoles({ commit, dispatch }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/role/list/view`,
                    {
                        params: {
                            page: 0,

                        }
                    })
                commit('getAllRoles', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async getPrivileges({ commit, dispatch }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/privilege/list`)
                commit('getPrivileges', res.data)
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addRole({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/role`, payload)
                await dispatch('getRoles',
                    {
                        page: rootState.base.filters.page,
                        limit: rootState.base.filters.itemsPerPage == -1 ? state.roles.elements : rootState.base.filters.itemsPerPage,

                    }
                )
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία ρόλου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editRole({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/role/${payload.id}`, payload.content)
                await dispatch('getRoles',
                    {
                        page: rootState.base.filters.page,
                        limit: rootState.base.filters.itemsPerPage == -1 ? state.roles.elements : rootState.base.filters.itemsPerPage,

                    }
                )
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία ρόλου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteRole({ commit, dispatch, rootState, state }, id) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/role/${id}`)
                if (state.roles.limit == 1 && state.roles.page != 1) {
                    commit('changePage', true)
                } else {
                    await dispatch('getRoles',
                        {
                            page: rootState.base.filters.page,
                            limit: rootState.base.filters.itemsPerPage == -1 ? state.roles.elements : rootState.base.filters.itemsPerPage,

                        }
                    )
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή ρόλου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        }

    }
}