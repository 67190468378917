<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="300"
        color="grey"
    >
      <v-card
          color="grey"
      >
        <div class="text-center">
        <v-card-text class="pt-2">
          <b>Παρακαλώ περιμένετε να ολοκληρωθεί η διαδικασία.</b>
        </v-card-text>
        </div>
        <div class="text-center">
        <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="primary"
            class="mb-5"
        ></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    dialog (val) {
      return val
    },
  },
}
</script>