import axios from "axios";
export default {
  state: {
    absences: [],
    absenceDateFrom: null,
  },

  mutations: {
    getAbsences(state, absences) {
      state.absences = absences;
    },
  },

  actions: {
    async getAbsences({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/daily/absence/list/view`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              absenceDateFrom: payload.absenceDateFrom,
              absenceTypeCd: payload.absenceTypeCd,
              lessonId: payload.lessonId,
              classId: payload.classId,
              progTrainPeriodId: payload.progTrainPeriodId,
              approved: payload.approved,
              searchText: payload.searchText
            },
          }
        );
        commit("getAbsences", res.data);
      } catch (e) {
        throw e;
      }
    },

    async addAbsence({ commit, dispatch }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/daily/absence`,
          payload
        );

        commit("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία απουσίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);
        throw e;
      }
    },

    async editAbsence({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/daily/absence/${payload.absenceId}`,
          payload.content
        );

        commit("toggleSnackbar", {
          open: true,
          text: "Η επεξεργασία απουσίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteAbsence({ commit, dispatch, rootState, state }, absenceId) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/daily/absence/${absenceId}`
        );
        commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφή απουσίας ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async approveAbsences({ commit, state, dispatch }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/daily/absence/mass/approve`,
          payload
        );
        if (state.absences.limit == 1 && state.absences.page != 1) {
          commit("changePage", true);
        }
        commit("toggleSnackbar", {
          open: true,
          text: `Ο έλεγχος ${payload.length > 1 ? "απουσιών" : "απουσίας"
            } ολοκληρώθηκε με επιτυχία`,
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
  },
};
