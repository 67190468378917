import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import axios from "axios";

Vue.use(Vuelidate);

Vue.config.productionTip = false;

if (store.getters.isLoggedIn) {
  console.log(store.state.auth.menuId);
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${store.state.auth.token}`;

  // axios.defaults.headers.common["Access-Control-Allow-Headers"] ="*";
  

}
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
