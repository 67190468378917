var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"component-wrapper d-flex align-center justify-center elevation-0",attrs:{"color":"transparent"}},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('GenericFilter',{key:_vm.query,attrs:{"level":6,"requiredFields":{
        masterSchool: true,
        school: true,
        year: true,
        progTrainPeriod: true,
        class: true,
        lesson: true,
      }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.getHeaders,"items":_vm.studentGrades.content,"options":_vm.options,"server-items-length":_vm.studentGrades.elements,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.entryDate`,fn:function({ item }){return [(item.entryDate != null)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.entryDate, "el"))+" ")]):_vm._e()]}},{key:`item.exmDate`,fn:function({ item }){return [(item.exmDate != null)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.exmDate, "el"))+" ")]):_vm._e()]}},{key:`item.flg_success`,fn:function({ item }){return [(item.flg_success != null)?_c('span',[_vm._v(" "+_vm._s(item.flg_success)+" ")]):_vm._e()]}},{key:`item.approveOral`,fn:function({ item }){return [(item.approveOral)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(!item.approveOral)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:`item.approveWritten`,fn:function({ item }){return [(item.approveWritten)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e(),(!item.approveWritten)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }