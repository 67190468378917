
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="1"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: false,
          year: false,
          progTrainPeriod: false,
          class: false,
          lesson: false,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="libraries.content"
            :options.sync="options"
            :server-items-length="libraries.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onLibraryView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
          <div class="custom-card-buttons">
            <v-btn
              depressed
              color="primary"
              class="mr-2"
              @click="openDialog(null, 'add')"
              :disabled="!filtersSet"
            >
              Δημιουργία
              <v-icon dense right>mdi-plus</v-icon>
            </v-btn>          
          </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddLibrary
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditLibrary
        v-if="dialog.open && dialog.type == 'edit'"
        :library="dialog.library"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteLibrary
        v-if="dialog.open && dialog.type == 'delete'"
        :library="dialog.library"
        @close="closeDialog"
        :libraries="libraries"
        @handler="handler"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddLibrary from "../../components/LibrariesModals/AddLibrary.vue";
import EditLibrary from "../../components/LibrariesModals/EditLibrary.vue";
import DeleteLibrary from "../../components/LibrariesModals/DeleteLibrary.vue";
import axios from "axios";
export default {
  components: {
    GenericFilter,
    AddLibrary,
    EditLibrary,
    DeleteLibrary,
  },

  async created() {
    try {
      if (this.masterSchoolId != null) await this.handler();
    } catch (e) {
      throw e;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path != `/libraries/${from.params?.library_id}/books`) {
        vm.clearGeneric();
      }
    });
  },
  data() {
    return {
      libraries: [],
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filtersSet: false,
      headers: [
        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        library: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet && this.masterSchoolId != null) await this.handler();
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      masterSchoolId: (state) => state.generic_filter.facultyId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentLibrary"]),

    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    clearGeneric() {
      this.$store.dispatch("setAllFilter", {
        facultyId: null,
        schoolId: null,
        academicYearId: null,
        semesterId: null,
        classId: null,
        courseId: null,
        dates: null,
        date: null,
        active: null,
        searchText: null,
        examYearId: null,
        progExamPeriodId: null,
        examClassId: null,
        examLessonId: null,
        examDate: null,
      });
    },

    onLibraryView(library) {
      this.setCurrentLibrary(library);
      this.$router.push(`/libraries/${library.id}/books`);
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/library/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              masterSchoolId: this.masterSchoolId,
            },
          }
        );

        this.libraries = res.data;

        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.libraries = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(library, type) {
      this.dialog = {
        library,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        library: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>