<template>
  <div class="mt-4 d-flex flex-column">
    <v-card class="mb-4">
      <v-card-title class="greyish--text">
        Πορεία πτυχιακής εργασίας
      </v-card-title>
      <v-card-text>
        <v-data-table
          no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
          loading-text="Φόρτωση..."
          :footer-props="{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :headers="poreiaHeaders"
          :items="poreiaPtyxiakhsErgasias.content"
          :server-items-length="poreiaPtyxiakhsErgasias.elements"
          :options.sync="poreiaOptions"
          :loading="poreiaTableLoader"
        >
          <!-- <template v-slot:[`item.grade`]="{ item }">
            <span v-if="item.grade == '0'">Αποτυχία</span>
            <span v-if="item.grade == '1'">Επιτυχία</span>
            <span></span>
          </template> -->

          <template v-slot:[`item.dateEvent`]="{ item }">
            <span>{{ formatDate(item.dateEvent, 'el') }}</span>
          </template>

          <template v-slot:[`item.examiner`]="{ item }">
            <span>{{
              item.examiner.firstName + " " + item.examiner.lastName
            }}</span>
          </template>

          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="selectedRole == 3"
                  depressed
                  icon
                  @click="openDialog(item, 'edit')"
                >
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-pen
                  </v-icon>
                </v-btn>
              </template>
              <span>Επεξεργασία</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="selectedRole == 3"
                  depressed
                  icon
                  @click="openDialog(item, 'delete')"
                >
                  <v-icon color="error" small v-bind="attrs" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Διαγραφή</span>
            </v-tooltip>
          </template> -->
        </v-data-table>
      </v-card-text>
      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'add'"
        max-width="500px"
        scrollable
      >
        <AddDegreeEvent
          v-if="dialog.open && dialog.type == 'add'"
          @close="closeDialog"
        />
      </v-dialog>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'edit'"
        max-width="500px"
        scrollable
      >
        <EditDegreeEvent
          v-if="dialog.open && dialog.type == 'edit'"
          :thesis="thesis"
          :degree="dialog.degree"
          @close="closeDialog"
        />
      </v-dialog>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'delete'"
        max-width="500px"
        scrollable
      >
        <DeleteDegreeEvent
          v-if="dialog.open && dialog.type == 'delete'"
          :degree="dialog.degree"
          @close="closeDialog"
        />
      </v-dialog>
    </v-card>

    <div class="d-flex ml-auto">
      <!-- <v-btn
        depressed
        color="primary mr-2"
        @click="openDialog(null, 'add')"
        v-if="selectedRole == 3"
      >
        Δημιουργία
        <v-icon dense right>mdi-plus</v-icon>
      </v-btn> -->

      <v-btn
        v-if="$route.path.split('/')[1] == 'users'"
        depressed
        color="error"
        @click="
          $router.push(`/users/${currentUser.id}/view_student/ptyxiakh_ergasia`)
        "
        >Επιστροφή
      </v-btn>

      <v-btn
        v-if="$route.path.split('/')[1] == 'absences'"
        depressed
        color="error"
        @click="
          $router.push(
            `/absences/${currentUser.id}/view_student/ptyxiakh_ergasia`
          )
        "
        >Επιστροφή
      </v-btn>

      <v-btn
        v-if="$route.path.split('/')[1] == 'students'"
        depressed
        color="error"
        @click="
          $router.push(
            `/students/${currentUser.id}/view_student/ptyxiakh_ergasia`
          )
        "
        >Επιστροφή
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddDegreeEvent from "../../DegreeEventsModals/AddDegreeEvent.vue";
import EditDegreeEvent from "../../DegreeEventsModals/EditDegreeEvent.vue";
import DeleteDegreeEvent from "../../DegreeEventsModals/DeleteDegreeEvent.vue";
import axios from "axios";
import {helperMixin} from "@/helpers/helperMixin";
export default {
  mixins: [helperMixin],
  components: {
    AddDegreeEvent,
    EditDegreeEvent,
    DeleteDegreeEvent,
  },

  data() {
    return {
      poreiaPtyxiakhsErgasias: [],
      poreiaTableLoader: false,
      poreiaOptions: {
        itemsPerPage: 10,
        page: 1,
      },

      poreiaHeaders: [
        {
          text: "Θέμα",
          value: "degree.subject",
          sortable: false,
        },
        {
          text: "Βαθμός",
          value: "grade",
          sortable: false,
        },

        {
          text: "Ημερομηνία εξέτασης",
          value: "dateEvent",
          sortable: false,
        },

        {
          text: "Εξεταστής",
          value: "examiner",
          sortable: false,
        },
      ],

      dialog: {
        degree: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    poreiaOptions: {
      async handler() {
        try {
          this.poreiaTableLoader = true;
          this.setFilters(this.poreiaOptions);

          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/degree/event/list`,
            {
              params: {
                page:
                  this.poreiaOptions.itemsPerPage == -1
                    ? 0
                    : this.poreiaOptions.page,
                limit:
                  this.poreiaOptions.itemsPerPage == -1
                    ? null
                    : this.poreiaOptions.itemsPerPage,
                degreeId: this.currentDegree.id,

                personId: this.currentUser.id,
              },
            }
          );

          this.poreiaPtyxiakhsErgasias = res.data;
          this.poreiaTableLoader = false;
        } catch (e) {
          console.log(e);
          this.poreiaPtyxiakhsErgasias = [];
          this.$router.dispatch("errorHandler", e);

          this.poreiaTableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      currentDegree: (state) => state.degrees.currentDegree,
      pageChanged: (state) => state.base.pageChanged,
      selectedRole: (state) => state.auth.selectedRole,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),

    openDialog(degree, type) {
      this.dialog = {
        degree,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        degree: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.poreiaOptions.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style>
</style>