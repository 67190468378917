<template>
  <v-card class="d-flex flex-column">
    <v-toolbar color="primary" class="white--text">
      Απουσίες
      <v-btn
          depressed
          icon
          color="white"
          class="ml-auto"
          @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex flex-column">
      <v-select
          dense
          outlined
          v-model="absenceType"
          clearable
          type="text"
          hide-details="auto"
          class="mb-4 mt-4"
          :items="absenceTypes"
          item-text="description"
          item-value="id"
          @change="fetchAbsence"
          no-data-text="Δεδομένα μη διαθέσιμα"
      >
        <template v-slot:label>
          <span class="error--text">*</span>
          Τύπος απουσίας
        </template>
      </v-select>
      <v-card class="mt-4 pa-4 flex-grow-1 d-flex flex-column align-center">
        <v-card-text>
          <v-data-table
              no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
              loading-text="Φόρτωση..."
              :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
              :headers="getHeaders"
              :items="apousies.content"
              :server-items-length="apousies.elements"
              :options.sync="options"
              :loading="tableLoader"
          >
            <template v-slot:[`item.absenceDate`]="{ item }">
              <span>{{ formatDate(item.absenceDate, 'el') }}</span>
            </template>
          </v-data-table>
          <span
          ><strong>Σύνολο απουσιών: {{ this.sumAbsence }}</strong></span
          >
        </v-card-text>
      </v-card>
      <v-btn
          depressed
          color="error"
          class="ml-auto ma-4"
          @click="$emit('close')"
      >Επιστροφή
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState, mapMutations, mapActions} from "vuex";
import axios from "axios";
import {helperMixin} from "@/helpers/helperMixin";

export default {
  props: ["gradeProp"],
  mixins: [helperMixin],
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      absenceType: 0,
      absenceTypes: [
        {
          id: 0,
          description: "Αδικαιολόγητες",
        },
        {
          id: 1,
          description: "Δικαιολογημένες",
        },
      ],
      sumAbsence: 0,
      apousies: [],
      headers: [
        {
          text: "Ημερομηνία",
          value: "absenceDate",
          sortable: false,
        },

        {
          text: "Ώρες",
          value: "absenceHours",
          sortable: false,
        },

        {
          text: "Μάθημα",
          value: "attendLesson.lesson.description",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    options: {
      async handler() {
        this.fetchAbsence();
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      currentRegister: (state) => state.users.currentRegister,
    }),
    getHeaders() {
      let absence = this.gradeProp.school.school.absence;

      if (absence.cd == "1") {
        return [
          {
            text: "Ημερομηνία",
            value: "absenceDate",
            sortable: false,
          },

          {
            text: "Ώρες",
            value: "absenceHours",
            sortable: false,
          },

          {
            text: "Μάθημα",
            value: "attendLesson.lesson.description",
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: "Ημερομηνία",
            value: "absenceDate",
            sortable: false,
          },

          {
            text: "Ώρες",
            value: "absenceHours",
            sortable: false,
          },
        ];
      }
    },
  },

  methods: {
    helperMixin() {
      return helperMixin
    },
    ...mapMutations(["setFilters"]),
    ...mapActions(["getApousies"]),
    async fetchAbsence() {
      try {
        this.tableLoader = true;
        this.sumAbsence = 0;
        this.setFilters(this.options);

        const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/daily/absence/list`,
            {
              params: {
                // page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                page: 0,
                limit:
                    this.options.itemsPerPage == -1
                        ? null
                        : this.options.itemsPerPage,
                personId: this.currentUser.id,
                progTrainPeriodId: this.currentRegister.progTrainPrd?.id,
                absenceTypeCd: this.absenceType,
              },
            }
        );

        res.data.content.map((a) => {
          if (a.absenceType == 0) {
            this.sumAbsence = this.sumAbsence + a.absenceHours;
          }

        });

        this.apousies = res.data;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.apousies = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>