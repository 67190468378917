
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="shipCompanyReps.content"
            :options.sync="options"
            :server-items-length="shipCompanyReps.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.streetName`]="{ item }">
              <span>
                {{ item.streetName + " " + item.number }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="800px"
      scrollable
    >
      <AddShipCompanyRep
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="800px"
      scrollable
    >
      <EditShipCompanyRep
        v-if="dialog.open && dialog.type == 'edit'"
        :shipCompanyRep="dialog.shipCompanyRep"
        @close="closeDialog"
        @handler="handler"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteShipCompanyRep
        v-if="dialog.open && dialog.type == 'delete'"
        :shipCompanyRep="dialog.shipCompanyRep"
        @close="closeDialog"
        @handler="handler"
        :shipCompanyReps="shipCompanyReps"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddShipCompanyRep from "../../components/ShipCompanyRepModals/AddShipCompanyRep.vue";
import EditShipCompanyRep from "../../components/ShipCompanyRepModals/EditShipCompanyRep.vue";
import DeleteShipCompanyRep from "../../components/ShipCompanyRepModals/DeleteShipCompanyRep.vue";
import axios from "axios";
export default {
  components: {
    AddShipCompanyRep,
    EditShipCompanyRep,
    DeleteShipCompanyRep,
  },

  created() {
    this.breadcrumb_items.push(
      {
        text: `${this.currentShipCompany.name}`,
        disabled: false,
        link: true,
        exact: true,
        to: "/ship_companies",
      },
      {
        text: `Εκπρόσωποι / Εκπαιδευτές `,
        disabled: true,
      }
    );
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      shipCompanyReps: [],

      breadcrumb_items: [],

      headers: [
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        shipCompanyRep: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
  },

  computed: {
    ...mapState({
      currentShipCompany: (state) => state.ship_companies.currentShipCompany,
      pageChanged: (state) => state.base.pageChanged,
    }),
  },

  methods: {
    ...mapMutations(["changePage"]),

    async handler() {
      try {
        this.tableLoader = true;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/ship/company/representative/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              shipCompanyId: this.$route.params.ship_company_id,
            },
          }
        );

        this.shipCompanyReps = res.data;

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.shipCompanyReps = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(shipCompanyRep, type) {
      this.dialog = {
        shipCompanyRep,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        shipCompanyRep: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.handler();
    },
  },
};
</script>

<style scoped lang="scss">
</style>