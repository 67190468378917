<template>
  <div class="mt-4 d-flex flex-column">
    <v-card class="mb-4">
      <v-card-title class="greyish--text"> Πτυχιακή εργασία </v-card-title>
      <v-card-text>
        <v-data-table
          no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
          loading-text="Φόρτωση..."
          :footer-props="{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :headers="headers"
          :items="ptyxiakhErgasia.content"
          :server-items-length="ptyxiakhErgasia.elements"
          :options.sync="options"
          :loading="tableLoader"
        >
          <!-- <template v-slot:[`item.grade`]="{ item }">
            <span v-if="item.grade == '0'">Αποτυχία</span>
            <span v-if="item.grade == '1'">Επιτυχία</span>
            <span></span>
          </template> -->

          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="selectedRole == 3"
                  depressed
                  icon
                  @click="openDialog(item, 'edit')"
                >
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-pen
                  </v-icon>
                </v-btn>
              </template>
              <span>Επεξεργασία</span>
            </v-tooltip> -->

            <!-- <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="selectedRole == 3"
                  depressed
                  icon
                  @click="openDialog(item, 'delete')"
                >
                  <v-icon color="error" small v-bind="attrs" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Διαγραφή</span>
            </v-tooltip> -->

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="onViewPoreia(item)">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <span>Προβολή</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.examDate`]="{ item }">
            <span>{{ formatDate(item.examDate, 'el') }}</span>
          </template>

          <template v-slot:[`item.exam1`]="{ item }">
            <span>{{
              item.examinerPerson1.firstName +
              " " +
              item.examinerPerson1.lastName
            }}</span>
          </template>

          <template v-slot:[`item.exam2`]="{ item }">
            <span v-if="item.examinerPerson2 != null">{{
              item.examinerPerson2.firstName +
              " " +
              item.examinerPerson2.lastName
            }}</span>
          </template>

          <template v-slot:[`item.exam3`]="{ item }">
            <span v-if="item.examinerPerson3 != null">{{
              item.examinerPerson3.firstName +
              " " +
              item.examinerPerson3.lastName
            }}</span>
          </template>

          <template v-slot:[`item.exam4`]="{ item }">
            <span v-if="item.examinerPerson4 != null">{{
              item.examinerPerson4.firstName +
              " " +
              item.examinerPerson4.lastName
            }}</span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'add'"
        max-width="500px"
        scrollable
      >
        <AddDegree
          v-if="dialog.open && dialog.type == 'add'"
          @close="closeDialog"
        />
      </v-dialog>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'edit'"
        max-width="500px"
        scrollable
      >
        <EditDegree
          v-if="dialog.open && dialog.type == 'edit'"
          :degree="dialog.degree"
          @close="closeDialog"
        />
      </v-dialog>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'delete'"
        max-width="500px"
        scrollable
      >
        <DeleteDegree
          v-if="dialog.open && dialog.type == 'delete'"
          :degree="dialog.degree"
          @close="closeDialog"
        />
      </v-dialog>
    </v-card>
    <!-- <v-btn
      depressed
      color="primary ml-auto"
      @click="openDialog(null, 'add')"
      v-if="selectedRole == 3"
    >
      Δημιουργία
      <v-icon dense right>mdi-plus</v-icon>
    </v-btn> -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddDegree from "../../DegreeModals/AddDegree.vue";
import EditDegree from "../../DegreeModals/EditDegree.vue";
import DeleteDegree from "../../DegreeModals/DeleteDegree.vue";
import axios from "axios";
import {helperMixin} from "@/helpers/helperMixin";
export default {
  mixins: [helperMixin],
  components: {
    AddDegree,
    EditDegree,
    DeleteDegree,
  },
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      ptyxiakhErgasia: [],
      headers: [
        {
          text: "Θέμα",
          value: "subject",
          sortable: false,
        },
        {
          text: "Βαθμός",
          value: "grade",
          sortable: false,
        },
        {
          text: "Επίδοση",
          value: "gradeCharacterised",
          sortable: false,
        },
        {
          text: "Ημερομηνία έναρξης",
          value: "examDate",
          sortable: false,
        },

        {
          text: "Επιβλέπων καθηγητής 1",
          value: "exam1",
          sortable: false,
        },

        {
          text: "Επιβλέπων καθηγητής 2",
          value: "exam2",
          sortable: false,
        },

        {
          text: "Εξεταστής 1",
          value: "exam3",
          sortable: false,
        },

        {
          text: "Εξεταστής 2",
          value: "exam4",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        degree: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);

          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/degree/list`,
            {
              params: {
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                  this.options.itemsPerPage == -1
                    ? null
                    : this.options.itemsPerPage,
                personId: this.currentUser.id,
              },
            }
          );

          this.ptyxiakhErgasia = res.data;
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.ptyxiakhErgasia = [];
          this.$router.dispatch("errorHandler", e);

          this.tableLoader = false;
        }
      },
    },

    deep: true,
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      selectedRole: (state) => state.auth.selectedRole,
      pageChanged: (state) => state.base.pageChanged,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentDegree"]),
    ...mapActions(["getPtyxiakhErgasia"]),

    onViewPoreia(degree) {
      this.setCurrentDegree(degree);
      this.$router.push(`${this.$route.path}/${degree.id}/poreia`);
    },

    openDialog(degree, type) {
      this.dialog = {
        degree,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        degree: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>