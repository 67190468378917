<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="reports.content"
            :loading="tableLoader"
            :options.sync="options"
            :server-items-length="reports.elements"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    icon
                    @click="$router.push(`/reportParameter/${item.id}`)"
                  >
                    <v-icon color="success" small v-bind="attrs" v-on="on">
                      mdi-book-cog-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Παράμετροι</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    icon
                    @click="$router.push(`/reportRoles/${item.id}`)"
                  >
                    <v-icon color="success" small v-bind="attrs" v-on="on">
                      mdi-account-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ρόλοι</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
          <div class="custom-card-buttons">
            <v-btn depressed color="primary ml-auto" @click="openDialog(null, 'add')">
              Δημιουργία
              <v-icon dense right>mdi-plus</v-icon>
            </v-btn>          
          </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddReport
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditReport
        v-if="dialog.open && dialog.type == 'edit'"
        :report="dialog.report"
        @close="closeDialog"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteReport
        v-if="dialog.open && dialog.type == 'delete'"
        :report="dialog.report"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import AddReport from "../../components/Reports/AddReport.vue";
import DeleteReport from "../../components/Reports/DeleteReport.vue";
import EditReport from "../../components/Reports/EditReport.vue";

export default {
  name: "Reports",
  components: {
    AddReport,
    DeleteReport,
    EditReport,
  },
  created() {
    // this.fetchReports();
  },
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },
        {
          text: "Κατηγορία",
          value: "tpy.description",
          sortable: false,
        },
        {
          text: "Όνομα αρχείου εκτύπωσης",
          value: "reportName",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        open: false,
        type: null,
        report: null,
      },
      reports: [],
    };
  },
  watch: {
    options: {
      async handler() {
        this.fetchReports();
      },
    },
    deep: true,
  },
  computed: {},
  methods: {
    async fetchReports() {
      try {
        this.tableLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/report/list/all`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
            },
          }
        );
        this.reports = res.data;
        this.tableLoader = false;
      } catch (e) {
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
    openDialog(item, type) {
      this.dialog = {
        report: item,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        report: null,
        open: false,
        type: null,
      };

      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchReports();
    },
  },
};
</script>