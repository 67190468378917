<template>
  <v-card
    color="transparent"
    class="component-wrapper d-flex align-center justify-center"
  >
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="6"
        @onSearchAction="onSearch"
        :listActive="1"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: true,
          lesson: true,
        }"
      />

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="studentsWithGrades"
            :options.sync="options"
            :server-items-length="studentGrades.elements"
            :loading="tableLoader"
            v-model="selectedStudentGrades"
            show-select
            item-key="person_id"
          >
            <template v-slot:[`item.data-table-select`]="{ item }">
              <v-checkbox
                :disabled="!item.isSelectable"
                v-model="selectedStudentGrades"
                :value="item"
                hide-details
              />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <span v-if="item.studentGrades != null">
                <span v-for="(grade, i) in item.studentGrades" :key="i">
                  <span v-if="grade.attendLesson.lesson.id == courseId">
                    <div>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            icon
                            @click="openDialog(grade, 'approve')"
                          >
                            <v-icon
                              color="success"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-check
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Έλεγχος</span>
                      </v-tooltip>
                    </div>
                  </span>
                </span>
              </span>
            </template>
          </v-data-table>
        </v-card-text>
          <div class="custom-card-buttons">
            <v-btn
              depressed
              color="success"
              class="mr-2"
              @click="onMassApprove"
              :disabled="selectedStudentGrades.length == 0"
              :loading="approveLoader"
            >
              Μαζικός έλεγχος
            </v-btn>
          </div>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";

import { required } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  components: {
    GenericFilter,
  },

  data() {
    return {
      tableLoader: false,
      studentGrades: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      gradeList: [],
      edit: false,

      filtersSet: false,

      menu: false,
      menu1: false,
      dateMenu: false,
      exmDate: null,
      exmDateFormatted: null,
      saveLoader: false,
      selectedStudentGrades: [],
      approveLoader: false,

      headers: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Βαθμολογία",
          value: "oral",
          sortable: false,
        },
      ],

      dialog: {
        grade: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },

    async courseId() {
      await this.getAttendLessons({
        page: 0,
        lessonId: this.courseId,
      });
    },
  },

  validations: {
    exmDate: {
      required,
    },
  },

  computed: {
    ...mapState({
      attendLessons: (state) => state.attend_lessons.attendLessons,
      staticList: (state) => state.auth.userInfo.staticView,
      pageChanged: (state) => state.base.pageChanged,
      masterSchoolId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      yearId: (state) => state.generic_filter.academicYearId,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      classId: (state) => state.generic_filter.classId,
      courseId: (state) => state.generic_filter.courseId,
    }),

    studentsWithGrades() {
      return this.studentGrades?.hashContent?.map((x) => {
        if (x.oral != null)
          return {
            ...x,
            isSelectable: true,
          };
        else
          return {
            ...x,
            isSelectable: false,
          };
      });
    },

    exmDateErrors() {
      const errors = [];
      if (!this.$v.exmDate.$dirty) return errors;
      if (!this.$v.exmDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["addStudentGrade", "getAttendLessons", "approveGrades"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForGrade/oral`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              masterSchoolId: this.masterSchoolId,
              schoolId: this.schoolId,
              yearId: this.yearId,
              progTrainPeriodId: this.progTrainPeriodId, //this.progTrainPeriodId,
              lessonId: this.courseId,
              classId: this.classId,
              approved: false,
            },
          }
        );

        this.studentGrades = res.data;

        this.gradeList = [];
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        for (const student of this.studentGrades.hashContent) {
          //for every student
          if (student.oral != null) {
            this.gradeList.push({
              attendLessonId: student.edu_attend_lessons_id,
              entryDate: student.entry_date,
              exmDate: this.exm_date,
              oral: student.oral,
              personId: student.person_id,
              written: student.written,
              isNew: false,
              menu: false,
              menu1: false,
            });
          } else {
            this.gradeList.push({
              attendLessonId: student.edu_attend_lessons_id,
              exmDate: this.exmDate,
              oral: null,
              personId: student.person_id,
              isNew: true,
              menu: false,
              menu1: false,
            });
          }
        }

        this.selectedStudentGrades = [];

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.studentGrades = [];

        this.tableLoader = false;
      }
    },

    async onMassApprove() {
      try {
        this.approveLoader = true;
        let content = [];
        for (const student of this.selectedStudentGrades) {
          content.push({
            id: student.edu_stu_grades_id,
            approve: true,
          });
        }

        await this.approveGrades(content);
        this.selectedStudentGrades = [];
        this.approveLoader = false;

        await this.handler();
      } catch (e) {
        this.approveLoader = false;
        await this.$store.dispatch("errorHandler", e);

        console.log(e);
      }
    },

    openDialog(grade, type) {
      this.dialog = {
        grade,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        grade: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
