
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="supportingDocuments.content"
            :server-items-length="supportingDocuments.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <!-- <v-btn depressed color="primary ml-auto" @click="openDialog(null, 'add')">
        Δημιουργία
        <v-icon dense right>mdi-plus</v-icon>
      </v-btn> -->
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddSupportingDocument
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditSupportingDocument
        v-if="dialog.open && dialog.type == 'edit'"
        :supportingDocument="dialog.supportingDocument"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteSupportingDocument
        v-if="dialog.open && dialog.type == 'delete'"
        :supportingDocument="dialog.supportingDocument"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddSupportingDocument from "../../../../components/SupportingDocumentsModals/AddSupportingDocument.vue";
import EditSupportingDocument from "../../../../components/SupportingDocumentsModals/EditSupportingDocument.vue";
import DeleteSupportingDocument from "../../../../components/SupportingDocumentsModals/DeleteSupportingDocument.vue";
export default {
  components: {
    AddSupportingDocument,
    EditSupportingDocument,
    DeleteSupportingDocument,
  },
  created() {
    this.breadcrumb_items.push(
      {
        text: `${this.currentFaculty.description}`,
        disabled: false,
        link: true,
        exact: true,
        to: "/faculties",
      },
      {
        text: `${this.currentSchool.description}`,
        disabled: false,
        link: true,
        exact: true,
        to: `/faculties/${this.currentFaculty.id}/schools`,
      },
      {
        text: `Δικαιολογητικά `,
        disabled: true,
        to: `${this.$route.path}`,
      }
    );
  },
  data() {
    return {
      tableLoader: false,
      search: "",
      breadcrumb_items: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Κωδικός",
          value: "cd",
          sortable: false,
        },

        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },
        {
          text: "Διάρκεια δικαιολογητικού",
          value: "yndoDocDur.description",
          sortable: false,
        },

        {
          text: "Διάρκεια σε μήνες",
          value: "yndoDocMm",
          sortable: false,
        },
        // {
        //   text: "Επιλογές",
        //   value: "actions",
        //   sortable: false,
        // },
      ],

      dialog: {
        supportingDocument: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);
          await this.getSupportingDocuments({
            id: this.currentSchool.id,
            page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
            limit:
              this.options.itemsPerPage == -1
                ? null
                : this.options.itemsPerPage,
          });
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      supportingDocuments: (state) =>
        state.supporting_documents.supportingDocuments,
      pageChanged: (state) => state.base.pageChanged,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getSupportingDocuments"]),

    openDialog(supportingDocument, type) {
      this.dialog = {
        supportingDocument,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        supportingDocument: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>