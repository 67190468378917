<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία εκπροσώπου / εκπαιδευτή εταιρίας
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="firstName"
              type="text"
              hide-details="auto"
              :error-messages="firstNameErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Όνομα
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="lastName"
              type="text"
              hide-details="auto"
              :error-messages="lastNameErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Επώνυμο
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="0" md="4"> </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              hide-details="auto"
              outlined
              v-model="mobileNumber"
              type="text"
              :error-messages="mobileNumberErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Κινητό τηλέφωνο
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              hide-details="auto"
              outlined
              v-model="phoneNumber"
              type="text"
              :error-messages="phoneNumberErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τηλέφωνο
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="0" md="4"> </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="region"
              type="text"
              :error-messages="regionErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Νομός
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="city"
              hide-details="auto"
              type="text"
              :error-messages="cityErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Πόλη
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="streetName"
              type="text"
              hide-details="auto"
              :error-messages="streetNameErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Οδός
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="number"
              type="text"
              :error-messages="numberErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Αριθμός
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              hide-details="auto"
              outlined
              v-model="zipCode"
              type="text"
              :error-messages="zipCodeErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τ.Κ
                <div class=""></div>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4"></v-col>

          <v-col cols="12" sm="6" md="4">
            <v-textarea
              dense
              outlined
              hide-details="auto"
              v-model="memo"
              label="Σχόλια"
              type="text"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  props: ["shipCompanyRep"],

  created() {
    this.city = this.shipCompanyRep?.city;
    this.memo = this.shipCompanyRep?.memo;
    this.name = this.shipCompanyRep?.name;
    this.number = this.shipCompanyRep?.number;
    this.region = this.shipCompanyRep?.region;
    this.streetName = this.shipCompanyRep?.streetName;
    this.zipCode = this.shipCompanyRep?.zipCode;
    this.firstName = this.shipCompanyRep?.firstName;
    this.lastName = this.shipCompanyRep?.lastName;
    this.mobileNumber = this.shipCompanyRep?.mobileNumber;
    this.phoneNumber = this.shipCompanyRep?.phoneNumber;
  },

  data() {
    return {
      loader: false,

      firstName: null,
      lastName: null,
      mobileNumber: null,
      phoneNumber: null,
      city: null,
      memo: null,
      number: null,
      region: null,
      streetName: null,
      zipCode: null,
    };
  },

  validations: {
    firstName: { required },
    lastName: {
      required,
    },
    mobileNumber: {
      required,
      numeric,
    },
    phoneNumber: {
      required,
      numeric,
    },
    city: {
      required,
    },

    number: {
      required,
      numeric,
    },

    region: {
      required,
    },

    streetName: {
      required,
    },

    zipCode: {
      required,
      numeric,
    },
  },

  computed: {
    ...mapState({}),

    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      if (!this.$v.firstName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      if (!this.$v.lastName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    mobileNumberErrors() {
      const errors = [];
      if (!this.$v.mobileNumber.$dirty) return errors;
      if (!this.$v.mobileNumber.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.mobileNumber.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      if (!this.$v.phoneNumber.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.phoneNumber.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      if (!this.$v.city.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    numberErrors() {
      const errors = [];
      if (!this.$v.number.$dirty) return errors;
      if (!this.$v.number.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.number.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    regionErrors() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      if (!this.$v.region.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    streetNameErrors() {
      const errors = [];
      if (!this.$v.streetName.$dirty) return errors;
      if (!this.$v.streetName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    zipCodeErrors() {
      const errors = [];
      if (!this.$v.zipCode.$dirty) return errors;
      if (!this.$v.zipCode.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.zipCode.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
  },

  methods: {
    ...mapActions(["editShipCompanyRep"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          firstName: this.firstName,
          lastName: this.lastName,
          mobileNumber: this.mobileNumber,
          phoneNumber: this.phoneNumber,
          city: this.city,
          number: this.number,
          region: this.region,
          streetName: this.streetName,
          zipCode: this.zipCode,
          shipCompanyId: this.$route.params.ship_company_id,
        };

        if (this.memo != null) content.memo = this.memo;

        await this.editShipCompanyRep({
          shipCompanyRepId: this.shipCompanyRep.id,
          content,
        });
        this.loader = false;
        // await this.$emit("handler");

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>