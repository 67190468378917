//function kind

import store from "@/store"
export const INVALID_DATE = 'Invalid date'
export default {
    getFieldName(value) {
        let kindId = store.state.auth.kindId;
        if (value == 1) {
            if (kindId == 4 || kindId == 6 || kindId == 9 || kindId == 10 || kindId == 11) {
                return 'Εκπαιδευτικός Κύκλος Σπουδών'
            } else if (kindId == 5 || kindId == 7 || kindId == 8) {
                return 'Ειδικής Εκπαίδευσης'
            }
            else {
                return 'Εκπαιδευτικός Κύκλος/ Ειδικό Τμήμα'
            }
        } else if(value == 2) {
            if (kindId == 4 || kindId == 6 || kindId == 9 || kindId == 10 || kindId == 11) {
                return 'Εκπαιδευτικοί Κύκλοι Σπουδών'
            } else if (kindId == 5 || kindId == 7 || kindId == 8) {
                return 'Ειδικής Εκπαίδευσης'
            }
            else {
                return 'Εκπαιδευτικοί Κύκλοι/ Ειδικά Τμήματα'
            }
        }

    }

}
export const isEmpty = (value) => {
    return value === undefined || value === null || Number.isNaN(value) || (typeof value === 'object' && Object.keys(value).length === 0 || (typeof value === 'string'
        && value.trim().length === 0));
};

export const formatDate = (value, locale) => {
    if (!isEmpty(value) && value !== '' && value !== INVALID_DATE) {
        return new Date(value).toLocaleDateString(locale)
    } else{
        return ''
    }
};