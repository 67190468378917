<template>
  <v-card class="mt-4">
    <v-card-text>
      <v-data-table
        no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
        loading-text="Φόρτωση..."
        :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }"
        :headers="headers"
        :items="personAwards.hashContent"
        :server-items-length="personAwards.elements"
        :options.sync="options"
        :loading="tableLoader"
      >
        <template v-slot:[`item.issue_date`]="{ item }">
          <span>{{ formatDate(item.issue_date, 'el') }}</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import {helperMixin} from "@/helpers/helperMixin";
export default {
  mixins: [helperMixin],
  data() {
    return {
      personAwards: [],
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Περιγραφή",
          value: "award",
          sortable: false,
        },

        {
          text: "Ημερομηνία δημιουργίας",
          value: "issue_date",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          console.log(this.currentUser);
          this.tableLoader = true;
          this.setFilters(this.options);

          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/person/listForAward`,
            {
              params: {
                personId: this.currentUser.id,
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                  this.options.itemsPerPage == -1
                    ? null
                    : this.options.itemsPerPage,
              },
            }
          );

          this.personAwards = res.data;
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.personAwards = [];
          this.$router.dispatch("errorHandler", e);

          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
    }),
  },

  methods: {
    ...mapMutations(["setFilters"]),
  },
};
</script>

<style scoped lang="scss">
</style>