<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Προσθήκη τμήματος εξεταστικής
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form class="pa-4">
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="description"
              label="Όνομα"
              class="mb-4"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Όνομα
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col>
            <v-select
              dense
              outlined
              label="Τύπος"
              v-model="type"
              placeholder="Τύπος"
              :items="getDataStaticList(56)"
              item-text="description"
              item-value="cd"
              hide-details="auto"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τύπος
              </template>
            </v-select>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="examDateFormatted"
                  prepend-inner-icon="mdi-calendar"
                  class="mb-4"
                  hide-details="auto"
                  @blur="examDate = parseDate(examDateFormatted, 'examDate')"
                >
                  <template v-slot:label>
                    Ημερομηνία εξέτασης <span class="error--text">*</span>
                  </template>
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="examDate"
                scrollable
                @input="menu1 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              dense
              outlined
              label="Εξάμηνο σπουδών"
              v-model="this.progTrainPeriodId"
              placeholder="Εξάμηνο σπουδών"
              :items="listSemester"
              item-text="description"
              disabled
              item-value="cd"
              hide-details="auto"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              dense
              outlined
              label="Μάθημα"
              v-model="this.lessonId"
              placeholder="Μάθημα"
              :items="listLessons"
              item-text="description"
              disabled
              item-value="cd"
              hide-details="auto"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-col>
        </v-row>
        <div class="d-flex justify-center mt-4">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            @click="onSubmit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  name: "AddClassExam",
  created() {
    this.progTrainPeriodId = this.progTrainPeriod;
    this.lessonId = this.examLessonId;
  },
  data() {
    return {
      description: null,
      // type: null,
      progTrainPeriodId: null,
      loader: false,
      menu1: false,
      examDateFormatted: null,
      examDate: null,
      lessonId: null,
    };
  },
  validations: {
    description: {
      required,
    },
    // type: {
    //   required,
    // },
  },
  watch: {
    examDate(val) {
      this.examDateFormatted = this.formatDate(this.examDate);
    },
  },
  computed: {
    ...mapState({
      staticList: (state) => state.auth.userInfo.staticView,
      progTrainPeriod: (state) => state.generic_filter.progExamPeriodId,
      examLessonId: (state) => state.generic_filter.examLessonId,
      listLessons: (state) => state.generic_filter.listExamLessons,
      listSemester: (state) => state.generic_filter.listProgExamPeriods,
    }),
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    // typeErrors() {
    //   const errors = [];
    //   if (!this.$v.type.$dirty) return errors;
    //   if (!this.$v.type.required) errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },
  },

  methods: {
    ...mapActions(["createExamClass"]),
    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loader = true;
      try {
        await this.createExamClass({
          progTrainPeriodId: Number(this.progTrainPeriodId),
          description: this.description,
          // type: this.type,
          lessonId: this.lessonId,
          examDate: this.examDate,
        });
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
        this.loader = false;
        console.log(e);
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "examDate") {
          this.examDate = null;
          this.examDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>