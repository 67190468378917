import axios from "axios";
export default {
  state: {
    applications: [],
    commonOnlineAplication: null,
    currentCommonOnlineApplication: null,
  },

  mutations: {
    getApplications(state, applications) {
      state.applications = applications;
    },

    getCommonOnlineApplication(state, commonOnlineAplication) {
      state.commonOnlineAplication = commonOnlineAplication
    },

    getCurrentCommonOnlineApplication(state, currentCommonOnlineApplication) {
      state.currentCommonOnlineApplication = currentCommonOnlineApplication
    }
  },

  actions: {
    async getApplications({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/online/application/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              categoryTpy: payload.categoryTpy,
              masterSchoolId: payload.masterSchoolId,
            },
          }
        );
        commit("getApplications", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getCommonOnlineApplication({ commit, dispatch }, id) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/online/application/${id}`,

        );
        commit("getCommonOnlineApplication", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getCurrentCommonOnlineApplication({ commit, dispatch }, id) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/${id}`,

        );
        commit("getCurrentCommonOnlineApplication", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async submitApplication({ commit, dispatch, rootState, state }, payload) {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/person/application`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // await dispatch("getApplications", {
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.academicYears.elements
        //       : rootState.base.filters.itemsPerPage,
        //   categoryTpy: rootState.currentApplicationCategoryType,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η υποβολή αίτησης ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
  },
};
