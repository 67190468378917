import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login/Login.vue";
import Notifications from "../views/Notifications.vue";
import ConfigureApplications from "../views/ConfigureApplications/ConfigureApplications.vue";
import Actions from "../views/ConfigureApplications/Actions.vue";
import Requirements from "../views/ConfigureApplications/Requirements.vue";
import Applications from "../views/Applications/Applications.vue";
import ApproveApplications from "../views/ApproveApplications/ApproveApplications.vue";
import TransferApplications from "../views/ApproveApplications/TransferApplications.vue";
import ViewAllApplications from "../views/Applications/ViewAllApplications.vue";
import Profile from "../views/Profile.vue";
import Roles from "../views/Roles/Roles.vue";
import Absences from "../views/Absences/Absences.vue";
import AbsencesApprove from "../views/Absences/AbsencesApprove.vue";
import MassiveAbsences from "../views/Absences/MassiveAbsence.vue";
import Grades from "../views/Grades/Grades.vue";
import OralApprove from "../views/Grades/OralApprove.vue";
import OralGrades from "../views/Grades/OralGrades.vue";
import WrittenGrades from "../views/Grades/WrittenGrades.vue";
import WrittenApprove from "../views/Grades/WrittenApprove.vue";
import ProgTrainSeries from "../views/ProgTrainSeries/ProgTrainSeries.vue";
import Eisaen from "../views/Eisaen/Eisaen.vue";
import Booking from "../views/Booking/Booking.vue";

import SchoolTypes from "../views/SchoolTypes/SchoolTypes.vue";
import Libraries from "../views/Libraries/Libraries.vue";
import Books from "../views/Libraries/Books.vue";
import LibraryBooks from "../views/LendingBooks/LibraryBooks.vue";
import LendingBooks from "../views/LendingBooks/LendingBooks.vue";

import CreateExamClasses from "../views/ExamClasses/CreateExamClasses";

import TeacherLessons from "../views/TeacherLessons/TeacherLessons.vue";

import Announcements from "../views/Announcements/Announcements.vue";
import UserAnnouncements from "../views/Announcements/UserAnnouncements.vue";

import Users from "../views/Users/Users.vue";
import UserRoles from "../views/Users/UserRoles.vue";
import EditUser from "../views/Users/EditUser.vue";
import ViewStudent from "../views/Users/ViewStudent.vue";

import ProgTrips from "../views/ProgTrips/ProgTrips.vue";
import ProgTripDetails from "../views/ProgTrips/ProgTripDetails.vue";

import ShipCompanies from "../views/ShipCompanies/ShipCompanies.vue";
import ShipVessels from "../views/ShipCompanies/ShipVessels.vue";
import ShipCompanyReps from "../views/ShipCompanies/ShipCompanyReps.vue";

import SchoolGenCd from "../views/SchoolGenCd/SchoolGenCd.vue";
import Auditing from "../views/Auditing/Auditing.vue";

//userTabs
import AnastolesFoithshs from "../components/UserModals/ViewStudentTabs/AnastolesFoithshs.vue";
import Apousies from "../components/UserModals/ViewStudentTabs/Apousies.vue";
import AtomikaStoixeia from "../components/UserModals/ViewStudentTabs/AtomikaStoixeia.vue";
import Bathmologia from "../components/UserModals/ViewStudentTabs/Bathmologia.vue";
import Diagrafes from "../components/UserModals/ViewStudentTabs/Diagrafes.vue";
import EkpaideutikaTaksidia from "../components/UserModals/ViewStudentTabs/EkpaideutikaTaksidia.vue";
import Meteggrafes from "../components/UserModals/ViewStudentTabs/Meteggrafes.vue";
import RefusalDetails from "../components/UserModals/ViewStudentTabs/RefusalDetails.vue"
import PtyxiakhErgasia from "../components/UserModals/ViewStudentTabs/PtyxiakhErgasia.vue";
import PoreiaPtyxiakhsErgasias from "../components/UserModals/ViewStudentTabs/PoreiaPtyxiakhsErgasias.vue";
import StoixeiaFoithshs from "../components/UserModals/ViewStudentTabs/StoixeiaFoithshs.vue";
import StudentApplications from "../components/UserModals/ViewStudentTabs/StudentApplications.vue";
import Certifications from "../components/UserModals/ViewStudentTabs/Certifications.vue";
import Awards from "../components/UserModals/ViewStudentTabs/Awards.vue";
import AddUser from "../views/Users/AddUser.vue";
import AddTeacher from "../views/Users/AddTeacher.vue";

import Faculties from "../views/Faculties/Faculties.vue";

import Classes from "../views/Classes/Classes.vue";
import ClassExams from "../views/ExamClasses/ClassExams";
import CreateClasses from "../views/Classes/CreateClasses.vue";
import ClassStudents from "../views/Classes/ClassStudents.vue";

import ClassExamTeachers from "../views/ClassExamTeachers/ClassExamTeachers.vue";
import ClassExamStudents from "../views/ExamClasses/ClassExamStudents.vue";

import Teachers from "../views/Faculties/Teachers/Teachers.vue";
import ViewTeacher from "../views/Faculties/Teachers/ViewTeacher.vue";
import TeacherHours from "../views/TeacherHours/TeacherHours.vue";

//teacher tabs
import TeacherApplications from "../components/TeacherTabs/TeacherApplications.vue";
import TeacherMathimata from "../components/TeacherTabs/TeacherMathimata.vue";
import TeacherProswpikaStoixeia from "../components/TeacherTabs/TeacherProswpikaStoixeia.vue";
import TeacherWresDidaskalias from "../components/TeacherTabs/TeacherWresDidaskalias.vue";
import TeacherExperience from "../components/TeacherTabs/TeacherExperience.vue";
import TeacherEggrafes from "../components/TeacherTabs/TeacherEggrafes.vue";

import Schools from "../views/Faculties/Schools/Schools.vue";
import SchoolRooms from "../views/SchoolRooms/SchoolRooms.vue";

import ArchiveTeaching from "../views/ArchiveTeaching/ArchiveTeaching.vue";

//Admin
import SchoolsAdmin from "../views/Admin/Schools/Schools.vue";
import SemesterAdmin from "../views/Admin/Semesters/Semesters.vue";
import ExamAdmin from "../views/Admin/Exams/Exams.vue";
import CoursesAdmin from "../views/Admin/Courses/Courses.vue";
import CertificateAdmin from "../views/Admin/Certificates/Certificates.vue";

import AcademicYears from "../views/Faculties/Schools/AcademicYears/AcademicYears.vue";

import SchedulingSemesters from "../views/Faculties/Schools/AcademicYears/Semesters/Semesters.vue";
import SchedulingExams from "../views/Faculties/Schools/AcademicYears/Semesters/Exams/Exams.vue";

import Semesters from "../views/Faculties/Schools/Semesters/Semesters.vue";

import Exams from "../views/Faculties/Schools/Semesters/Exams/Exams.vue";
import Courses from "../views/Faculties/Schools/Semesters/Courses/Courses.vue";

import ExamResults from "../views/Faculties/Schools/Semesters/Courses/ExamResults/ExamResults.vue";

import SupportingDocuments from "../views/Faculties/Schools/SupportingDocuments/SupportingDocuments.vue";

import Certificates from "../views/Faculties/Schools/Certificates/Certificates.vue";

import Professors from "../views/Professors/Professors.vue";

import BasicTeacher from "../views/Teachers/Teachers.vue";

import BasicStudents from "../views/Students/Students.vue";

import BasicOperators from "../views/Users/ViewOperators.vue";

import SeperatedStudents from "../views/SeperatedStudents/SeperatedStudents.vue";

// secretary
import AcademicYearsSecretary from "../views/AcademicYear/AcademicYear.vue";
import SemestersSecretary from "../views/SchedulingSemester/SchedulingSemester.vue";
import ExamSecretary from "../views/SchedulingExam/SchedulingExam.vue";

import UnderConstruction from "../components/UnderConstruction/UnderConstruction.vue";

import TrainPeriodClosure from "../views/PeriodClosure/TrainPeriodClosure.vue";
import ExamPeriodClosure from "../views/PeriodClosure/ExamPeriodClosure.vue";
import YearPeriodClosure from "../views/PeriodClosure/YearPeriodClosure.vue";

import PersonAwards from "../views/PersonAwards/PersonAwards.vue";

import Thesis from "../views/Thesis/Thesis.vue";

// reports
import PrintReports from "../views/Reports/PrintReports.vue";
import Reports from "../views/Reports/Reports.vue";
import ReportParameters from "../views/Reports/ReportParameters.vue";
import ReportRoles from "../views/Reports/ReportRoles.vue";

import Workshop from "../views/Grades/Workshop.vue";

//SSO
import SSO from "../views/Sso/Sso.vue";

import store from "../store";
import axios from "axios";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name != "NavigationDuplicated") {
            throw err;
        }
    });
};

const routes = [
    {
        path: "/Login",
        component: Login,
        name: "Είσοδος",
        meta: {isNotAuthenticated: true},
    },
    {
        path: "/notifications",
        component: Notifications,
        meta: {isAuthenticated: true},
    },

    {
        path: "/applications/:id",
        component: Applications,
        meta: {isAuthenticated: true},
    },
    {
        path: "/approve_applications/:id",
        component: ApproveApplications,
        meta: {isAuthenticated: true},
    },
    {
        path: "/transfer_applications",
        component: TransferApplications,
        meta: {isAuthenticated: true},
    },
    {
        path: "/view_all_applications/:id",
        component: ViewAllApplications,
        meta: {isAuthenticated: true},
    },

    {
        path: "/absences",
        component: Absences,
        name: "Απουσίες",
        meta: {isAuthenticated: true},
    },

    {
        path: "/massiveAbsences/:id",
        component: MassiveAbsences,
        meta: {isAuthenticated: true},
    },
    {
        path: "/sso",
        component: SSO,
        meta: {isAuthenticated: false},
    },
    {
        path: "/auditing",
        component: Auditing,
        meta: {isAuthenticated: false},
    },
    {
        path: "/thesis",
        component: Thesis,
        meta: {isAuthenticated: true},
    },

    {
        path: "/teacher_lessons/:id",
        component: TeacherLessons,
        meta: {isAuthenticated: true},
    },

    {
        path: "/class_exams/:id",
        component: ClassExams,
        meta: {isAuthenticated: true},
    },

    {
        path: "/class_exams/:class_exam_id/studentsClassExams",
        component: ClassExamStudents,
        meta: {isAuthenticated: true},
    },

    {
        path: "/class_exam_teachers/:id",
        component: ClassExamTeachers,
        meta: {isAuthenticated: true},
    },

    {
        path: "/create_exam_classes/:id",
        component: CreateExamClasses,
        meta: {isAuthenticated: true},
    },

    {
        path: "/person_awards",
        component: PersonAwards,
        meta: {isAuthenticated: true},
    },

    {
        path: "/libraries",
        component: Libraries,
        meta: {isAuthenticated: true},
    },

    {
        path: "/libraries/:library_id/books",
        component: Books,
        meta: {isAuthenticated: true},
    },

    {
        path: "/library_books",
        component: LibraryBooks,
        meta: {isAuthenticated: true},
    },

    {
        path: "/library_books/:book_id/lend",
        component: LendingBooks,
        meta: {isAuthenticated: true},
    },
    {
        path: "/prog_train_series/:id",
        component: ProgTrainSeries,
        meta: {isAuthenticated: true},
    },
    {
        path: "/eisaen",
        component: Eisaen,
        meta: {isAuthenticated: true},
    },
    {
        path: "/booking/:id",
        component: Booking,
        meta: {isAuthenticated: true},
    },
    {
        path: "/printReports",
        component: PrintReports,
        meta: {isAuthenticated: true},
    },
    {
        path: "/reports",
        component: Reports,
        meta: {isAuthenticated: true},
    },
    {
        path: "/reportParameter/:id",
        component: ReportParameters,
        meta: {isAuthenticated: true},
    },
    {
        path: "/reportRoles/:id",
        component: ReportRoles,
        meta: {isAuthenticated: true},
    },

    {
        path: "/absences_approve/:id",
        component: AbsencesApprove,
        meta: {isAuthenticated: true},
    },
    {
        path: "/absences/:id/view_student",
        meta: {isAuthenticated: true},
        component: ViewStudent,
        children: [
            {
                path: "anastoles_foithshs_absence",
                component: AnastolesFoithshs,
            },

            {
                path: "atomika_stoixeia_absence",
                component: AtomikaStoixeia,
            },
            {
                path: "diagrafes_absence",
                component: Diagrafes,
            },
            {
                path: "ekpaideutika_taksidia_absence",
                component: EkpaideutikaTaksidia,
            },
            {
                path: "meteggrafes_absence",
                component: Meteggrafes,
            },
            {
                path: "ptyxiakh_ergasia_absence",
                component: PtyxiakhErgasia,
            },
            {
                path: "ptyxiakh_ergasia/:ptyxiakh_ergasia_id/poreia",
                component: PoreiaPtyxiakhsErgasias,
            },

            {
                path: "stoixeia_foithshs_absence",
                component: StoixeiaFoithshs,
            },
            {
                path: "stoixeia_foithshs/:register_id/apousies",
                component: Apousies,
            },
            {
                path: "stoixeia_foithshs/:register_id/bathmologies",
                component: Bathmologia,
            },
            {
                path: "applications",
                component: StudentApplications,
            },

            {
                path: "awards",
                component: Awards,
            },
        ],
    },

    {
        path: "/years",
        component: AcademicYears,
        meta: {isAuthenticated: true},
    },

    {
        path: "/classes/:id",
        component: Classes,
        meta: {isAuthenticated: true},
    },

    {
        path: "/classes/:class_id/studentsClass",
        component: ClassStudents,
        meta: {isAuthenticated: true},
    },

    {
        path: "/create_classes/:id",
        component: CreateClasses,
        meta: {isAuthenticated: true},
    },

    {
        path: "/announcements",
        component: Announcements,
        meta: {isAuthenticated: true},
    },

    {
        path: "/user_announcements",
        component: UserAnnouncements,
        meta: {isAuthenticated: true},
    },

    {
        path: "/configure_applications",
        component: ConfigureApplications,
        meta: {isAuthenticated: true},
    },

    {
        path: "/configure_applications/:common_online_application_id/actions",
        component: Actions,
        meta: {isAuthenticated: true},
    },
    {
        path: "/configure_applications/:common_online_application_id/requirements",
        component: Requirements,
        meta: {isAuthenticated: true},
    },
    {
        path: "/profile",
        component: Profile,
        name: "Προφίλ",
        meta: {isAuthenticated: true},
    },
    {
        path: "/under_construction",
        component: UnderConstruction,
        meta: {isAuthenticated: true},
    },
    {
        path: "/periodClosure/:id",
        component: TrainPeriodClosure,
        meta: {isAuthenticated: true},
    },
    {
        path: "/examPeriodClosure/:id",
        component: ExamPeriodClosure,
        meta: {isAuthenticated: true},
    },
    {
        path: "/yearClosure/:id",
        component: YearPeriodClosure,
        meta: {isAuthenticated: true},
    },
    {
        path: "/roles",
        component: Roles,
        name: "Ρόλοι",
        meta: {isAuthenticated: true},
    },

    {
        path: "/school_types",
        component: SchoolTypes,
        meta: {isAuthenticated: true},
    },

    {
        path: "/prog_trips",
        component: ProgTrips,
        meta: {isAuthenticated: true},
    },

    {
        path: "/prog_trips/:prog_trip_id/details",
        component: ProgTripDetails,
        meta: {isAuthenticated: true},
    },

    {
        path: "/ship_companies",
        component: ShipCompanies,
        meta: {isAuthenticated: true},
    },

    {
        path: "/ship_companies/:ship_company_id/vessels",
        component: ShipVessels,
        meta: {isAuthenticated: true},
    },

    {
        path: "/ship_companies/:ship_company_id/company_reps",
        component: ShipCompanyReps,
        meta: {isAuthenticated: true},
    },

    {
        path: "/ship_companies",
        component: ShipCompanies,
        meta: {isAuthenticated: true},
    },
    {
        path: "/users",
        component: Users,
        meta: {isAuthenticated: true},
    },
    {
        path: "/addUser",
        component: AddUser,
        meta: {isAuthenticated: true},
    },
    {
        path: "/add_teacher",
        component: AddTeacher,
        meta: {isAuthenticated: true},
    },
    {
        path: "/users/:user_id/roles",
        component: UserRoles,
        meta: {isAuthenticated: true},
    },
    {
        path: "/teachers",
        component: BasicTeacher,
        meta: {isAuthenticated: true},
    },
    {
        path: "/archive_teaching",
        component: ArchiveTeaching,
        meta: {isAuthenticated: true},
    },
    {
        path: "/grades/:id",
        component: Grades,
        meta: {isAuthenticated: true},
    },
    {
        path: "/workshop",
        component: Workshop,
        meta: {isAuthenticated: true},
    },

    {
        path: "/admin/schools/:id",
        component: SchoolsAdmin,
        name: store.state.auth.appId == 1 ? "Σχολών" : "Σχολέια",
        meta: {isAuthenticated: true},
    },
    {
        path: "/admin/semester/:id",
        component: SemesterAdmin,
        name: store.state.auth.appId == 1 ? "Εξαμήνων Σπουδών" : "Κύκλων Σπουδών",
        meta: {isAuthenticated: true},
    },
    {
        path: "/admin/exams",
        component: ExamAdmin,
        meta: {isAuthenticated: true},
    },
    {
        path: "/admin/courses",
        component: CoursesAdmin,
        meta: {isAuthenticated: true},
    },
    {
        path: "/admin/certificate/:id",
        name: "Πιστοποιήτικά",
        component: CertificateAdmin,
        meta: {isAuthenticated: true},
    },

    {
        path: "/oral/:id",
        component: OralGrades,
        meta: {isAuthenticated: true},
    },

    {
        path: "/oral_approve",
        component: OralApprove,
        meta: {isAuthenticated: true},
    },

    {
        path: "/written/:id",
        component: WrittenGrades,
        meta: {isAuthenticated: true},
    },

    {
        path: "/written_approve/:id",
        component: WrittenApprove,
        meta: {isAuthenticated: true},
    },

    {
        path: "/teachers/:teacher_id/edit",
        component: EditUser,
        meta: {isAuthenticated: true},
    },

    {
        path: "/teachers/:teacher_id/viewteacher",
        component: ViewTeacher,
        meta: {isAuthenticated: true},
        children: [
            {
                path: "proswpika_stoixeia",
                component: TeacherProswpikaStoixeia,
            },
            {
                path: "eggrafes",
                component: TeacherEggrafes,
            },
            {
                path: "eggrafes/:register_id/mathimata",
                component: TeacherMathimata,
            },

            {
                path: "eggrafes/:register_id/wres_didaskalias",
                component: TeacherWresDidaskalias,
            },

            {
                path: "applications",
                component: TeacherApplications,
            },

            {
                path: "experience",
                component: TeacherExperience,
            },
        ],
    },
    /* role teacher -> teacher card */
    {
        path: "/teacherCard",
        component: ViewTeacher,
        meta: {isAuthenticated: true},
        children: [
            {
                path: "proswpika_stoixeia",
                component: TeacherProswpikaStoixeia,
            },
            {
                path: "eggrafes",
                component: TeacherEggrafes,
            },
            {
                path: "eggrafes/:register_id/mathimata",
                component: TeacherMathimata,
            },

            {
                path: "eggrafes/:register_id/wres_didaskalias",
                component: TeacherWresDidaskalias,
            },

            {
                path: "applications",
                component: TeacherApplications,
            },

            {
                path: "experience",
                component: TeacherExperience,
            },
        ],
    },

    /*role student -> student card */
    {
        path: "/studentCard",
        meta: {isAuthenticated: true},
        component: ViewStudent,
        children: [
            {
                path: "anastoles_foithshs",
                component: AnastolesFoithshs,
            },

            {
                path: "atomika_stoixeia",
                component: AtomikaStoixeia,
            },
            {
                path: "diagrafes",
                component: Diagrafes,
            },
            {
                path: "ekpaideutika_taksidia",
                component: EkpaideutikaTaksidia,
            },
            {
                path: "meteggrafes",
                component: Meteggrafes,
            },
            {
                path: "ptyxiakh_ergasia",
                component: PtyxiakhErgasia,
            },
            {
                path: "ptyxiakh_ergasia/:ptyxiakh_ergasia_id/poreia",
                component: PoreiaPtyxiakhsErgasias,
            },
            {
                path: "stoixeia_foithshs",
                component: StoixeiaFoithshs,
            },
            {
                path: "stoixeia_foithshs/:register_id/apousies",
                component: Apousies,
            },
            {
                path: "stoixeia_foithshs/:register_id/bathmologies",
                component: Bathmologia,
            },
            {
                path: "applications",
                component: StudentApplications,
            },
            {
                path: "awards",
                component: Awards,
            },
        ],
    },
    {
        path: "/teacher_hours",
        component: TeacherHours,
        meta: {isAuthenticated: true},
    },
    {
        path: "/teacher_hours/:teacher_id/viewteacher",
        component: ViewTeacher,
        meta: {isAuthenticated: true},
        children: [
            {
                path: "proswpika_stoixeia",
                component: TeacherProswpikaStoixeia,
            },
            {
                path: "eggrafes",
                component: TeacherEggrafes,
            },
            {
                path: "eggrafes/:register_id/mathimata",
                component: TeacherMathimata,
            },

            {
                path: "eggrafes/:register_id/wres_didaskalias",
                component: TeacherWresDidaskalias,
            },

            {
                path: "applications",
                component: TeacherApplications,
            },

            {
                path: "experience",
                component: TeacherExperience,
            },
        ],
    },

    {
        path: "/students",
        component: BasicStudents,
        meta: {isAuthenticated: true},
    },

    {
        path: "/students/:student_id/edit",
        component: EditUser,
        meta: {isAuthenticated: true},
    },

    {
        path: "/students/:id/view_student",
        meta: {isAuthenticated: true},
        component: ViewStudent,
        children: [
            {
                path: "anastoles_foithshs",
                component: AnastolesFoithshs,
            },

            {
                path: "atomika_stoixeia",
                component: AtomikaStoixeia,
            },
            {
                path: "diagrafes",
                component: Diagrafes,
            },
            {
                path: "ekpaideutika_taksidia",
                component: EkpaideutikaTaksidia,
            },
            {
                path: "meteggrafes",
                component: Meteggrafes,
            },
            {
                path: "refusal_details",
                component: RefusalDetails,
            },
            {
                path: "ptyxiakh_ergasia",
                component: PtyxiakhErgasia,
            },
            {
                path: "ptyxiakh_ergasia/:ptyxiakh_ergasia_id/poreia",
                component: PoreiaPtyxiakhsErgasias,
            },
            {
                path: "stoixeia_foithshs",
                component: StoixeiaFoithshs,
            },
            {
                path: "stoixeia_foithshs/:register_id/apousies",
                component: Apousies,
            },
            {
                path: "stoixeia_foithshs/:register_id/bathmologies",
                component: Bathmologia,
            },
            {
                path: "applications",
                component: StudentApplications,
            },
            {
                path: "awards",
                component: Awards,
            },
            {
                path: "certifications",
                component: Certifications,
            },
        ],
    },

    {
        path: "/operators",
        component: BasicOperators,
        meta: {isAuthenticated: true},
    },

    {
        path: "/operators/:id/editOperators",
        component: EditUser,
        meta: {isAuthenticated: true},
    },

    {
        path: "/operators/:id/view_student",
        meta: {isAuthenticated: true},
        component: ViewStudent,
        children: [
            {
                path: "anastoles_foithshs",
                component: AnastolesFoithshs,
            },

            {
                path: "atomika_stoixeia",
                component: AtomikaStoixeia,
            },
            {
                path: "diagrafes",
                component: Diagrafes,
            },
            {
                path: "ekpaideutika_taksidia",
                component: EkpaideutikaTaksidia,
            },
            {
                path: "meteggrafes",
                component: Meteggrafes,
            },
            {
                path: "ptyxiakh_ergasia",
                component: PtyxiakhErgasia,
            },
            {
                path: "ptyxiakh_ergasia/:ptyxiakh_ergasia_id/poreia",
                component: PoreiaPtyxiakhsErgasias,
            },
            {
                path: "stoixeia_foithshs",
                component: StoixeiaFoithshs,
            },
            {
                path: "stoixeia_foithshs/:register_id/apousies",
                component: Apousies,
            },
            {
                path: "stoixeia_foithshs/:register_id/bathmologies",
                component: Bathmologia,
            },
            {
                path: "applications",
                component: StudentApplications,
            },
            {
                path: "awards",
                component: Awards,
            },
        ],
    },

    {
        path: "/school_rooms",
        component: SchoolRooms,
        meta: {isAuthenticated: true},
    },
    {
        path: "/academicYears/:id",
        component: AcademicYearsSecretary,
        meta: {isAuthenticated: true},
    },
    {
        path: "/semesters/:id",
        component: SemestersSecretary,
        meta: {isAuthenticated: true},
    },
    {
        path: "/exams/:id",
        component: ExamSecretary,
        meta: {isAuthenticated: true},
    },
    {
        path: "/users/:id/view_student",
        meta: {isAuthenticated: true},
        component: ViewStudent,
        children: [
            {
                path: "anastoles_foithshs",
                component: AnastolesFoithshs,
            },
            {
                path: "atomika_stoixeia",
                component: AtomikaStoixeia,
            },
            {
                path: "diagrafes",
                component: Diagrafes,
            },
            {
                path: "ekpaideutika_taksidia",
                component: EkpaideutikaTaksidia,
            },
            {
                path: "meteggrafes",
                component: Meteggrafes,
            },
            {
                path: "ptyxiakh_ergasia",
                component: PtyxiakhErgasia,
            },
            {
                path: "stoixeia_foithshs",
                component: StoixeiaFoithshs,
            },
            {
                path: "stoixeia_foithshs/:register_id/apousies",
                component: Apousies,
            },
            {
                path: "stoixeia_foithshs/:register_id/bathmologies",
                component: Bathmologia,
            },
            {
                path: "applications",
                component: StudentApplications,
            },
            {
                path: "awards",
                component: Awards,
            },
        ],
    },

    {
        path: "/users/:id/edit",
        component: EditUser,
        meta: {isAuthenticated: true},
    },
    {
        path: "/faculties",
        component: Faculties,
        name: store.state.auth.appId == 1 ? "Ακαδημίες" : "Δομές Μετεκπαίδευσης",
        meta: {isAuthenticated: true},
    },
    {
        path: "/faculties/:faculty_id/teachers",
        component: Teachers,
        meta: {isAuthenticated: true},
    },

    {
        path: "/faculties/:faculty_id/teachers/:teacher_id/viewteacher",
        component: ViewTeacher,
        meta: {isAuthenticated: true},
        children: [
            {
                path: "proswpika_stoixeia",
                component: TeacherProswpikaStoixeia,
            },
            {
                path: "eggrafes",
                component: TeacherEggrafes,
            },
            {
                path: "eggrafes/:register_id/mathimata",
                component: TeacherMathimata,
            },

            {
                path: "eggrafes/:register_id/wres_didaskalias",
                component: TeacherWresDidaskalias,
            },

            {
                path: "applications",
                component: TeacherApplications,
            },

            {
                path: "experience",
                component: TeacherExperience,
            },
        ],
    },

    {
        path: "/faculties/:faculty_id/schools",
        component: Schools,
        meta: {isAuthenticated: true},
    },
    {
        path: "/faculties/:faculty_id/schools/:school_id/academicyears",
        component: AcademicYears,
        meta: {isAuthenticated: true},
    },

    {
        path:
            "/faculties/:faculty_id/schools/:school_id/academicyears/:academic_year_id/semesters",
        component: SchedulingSemesters,
        meta: {isAuthenticated: true},
    },

    {
        path:
            "/faculties/:faculty_id/schools/:school_id/academicyears/:academic_year_id/semesters/:scheduling_semester_id/exams",
        component: SchedulingExams,
        meta: {isAuthenticated: true},
    },

    {
        path: "/faculties/:faculty_id/schools/:school_id/semesters",
        component: Semesters,
        meta: {isAuthenticated: true},
    },

    {
        path:
            "/faculties/:faculty_id/schools/:school_id/semesters/:semester_id/exams",
        component: Exams,
        meta: {isAuthenticated: true},
    },

    {
        path:
            "/faculties/:faculty_id/schools/:school_id/semesters/:semester_id/courses",
        component: Courses,
        meta: {isAuthenticated: true},
    },

    {
        path:
            "/faculties/:faculty_id/schools/:school_id/semesters/:semester_id/courses/:course_id/examresults",
        component: ExamResults,
        meta: {isAuthenticated: true},
    },

    {
        path: "/faculties/:faculty_id/schools/:school_id/supportingdocuments",
        component: SupportingDocuments,
        meta: {isAuthenticated: true},
    },

    {
        path: "/faculties/:faculty_id/schools/:school_id/certificates",
        component: Certificates,
        meta: {isAuthenticated: true},
    },

    {
        path: "/professors_categories",
        name: "Professors Categories",
        component: Professors,
        meta: {isAuthenticated: true},
    },

    {
        path: "*",
        redirect: `/faculties`,
        meta: {isAuthenticated: true},
    },
    {
        path: "/school_gen_cd",
        component: SchoolGenCd,
        meta: {isAuthenticated: true},
    },
    {
        path: "/seperated_students",
        component: SeperatedStudents,
        meta: {isAuthenticated: true},
    },
    {
        name: "academy",
        path: "/academy",
        component: () => import("@/views/Login/Academy.vue"),
        meta: { isNotAuthenticated: true },
    },
    {
        name: "usefullLinks",
        path: "/usefullLinks",
        component: () => import("@/views/Login/UsefulLinks.vue"),
        meta: { isNotAuthenticated: true },
    },
    {
        name: "oroiXrisis",
        path: "/oroiXrisis",
        component: () => import("@/views/Login/OroiXrisis.vue"),
        meta: { isNotAuthenticated: true },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.isAuthenticated)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.isLoggedIn) {
            next("/login");
        } else {
            next();
        }
    } else if (to.matched.some((record) => record.meta.isNotAuthenticated)) {
        if (store.getters.isLoggedIn) {
            next("/");
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

const DEFAULT_TITLE = "ΥΝΑΝΠ";
router.afterEach( (to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609

    //set menuId
    console.log(
        'to.path != "/login" && to.path != "/login/"',
        to.path != "/login" && to.path != "/login/"
    );
    if (to.path != "/login" && to.path != "/login/") {
        var role = store.state.auth.userInfo.roles.filter((f) => {
            if (f.id == store.state.auth.selectedRole) {
                return f;
            }
        });

        var menuNames = [];
        role[0].menu.map((m) => {
            if (m.childMenus != undefined && m.childMenus.length > 0) {
                m.childMenus.map((c) => {
                    menuNames.push(c);
                    if (c.childMenus != undefined && c.childMenus.length > 0) {
                        c.childMenus.map((d) => {
                            menuNames.push(d);
                        });
                    }
                });
            }
            menuNames.push(m);
        });

        let menuName = menuNames.filter((m) => {
            if (m.path == to.path) {
                return m;
            }
        });
        if (menuName.length > 0) {
            store.commit("setMenu", {
                menuId: menuName[0].id,
                kindId: menuName[0].kind,
            });
            axios.defaults.headers.common["X-ACCESS-LEVEL"] = `${menuName[0].id}`;
        }

        axios.defaults.headers.common["X-ACCESS-DATE"] = null;
    } else {
        store.commit("setMenu", null);
    }
    // tab label
    var name = "";
    if (to.path == "/login" || to.path == "/login/") {
        name = store.state.auth.appId == 1 ? "Α.Ε.Ν" : "Δ.Σ.Ε.Ν.";
        Vue.nextTick(() => {
            document.title = name;
        });
    } else {
        let menuName = menuNames.filter((m) => {
            if (m.path == to.path) {
                return m;
            }
        });
        if (menuName.length > 0) {
            Vue.nextTick(() => {
                document.title = menuName[0].name;
            });
        }
    }

    // clear filter
    let fromPath = from.path.split("/");
    let toPath = to.path.split("/");

    let lastFrom = Number(fromPath[fromPath.length - 1]);
    let lastTo = Number(toPath[toPath.length - 1]);

    var finalFrom;
    var finalTo;

    // console.log(
    //   "isNaN(lastFrom)",
    //   isNaN(lastFrom),
    //   "fromPath[fromPath.length - 1",
    //   fromPath[fromPath.length - 1]
    // );
    if (isNaN(lastFrom)) {
        finalFrom = fromPath[fromPath.length - 1];
    } else {
        finalFrom = fromPath[fromPath.length - 2];
    }
    // console.log("isNaN(lastTo)", isNaN(lastTo));

    if (isNaN(lastTo)) {
        finalTo = toPath[toPath.length - 1];
    } else {
        finalTo = toPath[toPath.length - 2];
    }


    let result = store.state.base.excludeRouter.filter((r) => {
        if (finalFrom == r.main) {
            return r;
        } else if (r.internal.includes(finalFrom)) {
            return r;
        }
    });

    let currentResult = result.filter(row => row.main == finalFrom || row.main == finalTo)
    if (currentResult.length > 0) {
        if (currentResult[0].main == finalFrom) {
            if (!currentResult[0].internal.includes(finalTo)) {
                 store.dispatch("setClearAllFilter");
            }
        } else if (currentResult[0].internal.includes(finalFrom)) {
            if (currentResult[0].main != finalTo) {
                 store.dispatch("setClearAllFilter");
            }
        }
    } else {
        if (result.length > 0) {
            let resultCurrent = result.filter(row => row.internal.includes(finalTo) || row.internal.includes(finalFrom))
            if (!resultCurrent[0].internal.includes(finalTo) && !resultCurrent[0].internal.includes(finalFrom)) {
                 store.dispatch("setClearAllFilter");
            }
        } else {
             store.dispatch("setClearAllFilter");
        }
    }
});

export default router;
