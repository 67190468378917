
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4 pa-4">
        <v-select
          dense
          outlined
          clearable
          v-model="schoolTypeId"
          type="text"
          class="mb-4"
          :items="schoolTypesList"
          @change="setSchoolType"
          item-text="description"
          item-value="cd"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label> Επιλογή</template>
        </v-select>
      </v-card>
      <v-card-title class="greyish--text font-weight-regular"> </v-card-title>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="appId == 1 ? headersAen : headersKesen"
            :items="faculties.content"
            :options.sync="options"
            :server-items-length="faculties.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.flgActive.cd`]="{ item }">
              <span v-if="item.flgActive && item.flgActive.cd == '1'">
                Ενεργή</span
              >
              <span v-else-if="item.flgActive && item.flgActive.cd == '0'">
                Ανενεργή</span
              >
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.schools`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" icon depressed @click="onView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.teachers`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    icon
                    depressed
                    @click="onTeachersView(item)"
                  >
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
          <div class="custom-card-buttons">
            <v-btn
              depressed
              color="primary ml-auto"
              @click="openDialog(null, 'add')"
              v-if="selectedSystemRole == 'ADMIN_ROLE'"
            >
              Δημιουργία
              <v-icon dense right>mdi-plus</v-icon>
            </v-btn>
          </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddFaculty
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditFaculty
        v-if="dialog.open && dialog.type == 'edit'"
        :faculty="dialog.faculty"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteFaculty
        v-if="dialog.open && dialog.type == 'delete'"
        :faculty="dialog.faculty"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import AddFaculty from "../../components/FacultyModals/AddFaculty.vue";
import EditFaculty from "../../components/FacultyModals/EditFaculty.vue";
import DeleteFaculty from "../../components/FacultyModals/DeleteFaculty.vue";
export default {
  components: {
    AddFaculty,
    EditFaculty,
    DeleteFaculty,
  },

  data() {
    return {
      tableLoader: false,
      schoolTypeId: null,
      faculties: [],
      schoolTypesList: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headersAen: [
        {
          text: "Όνομα",
          value: "description",
          sortable: false,
        },
        {
          text: "Κωδικός",
          value: "cd",
          sortable: false,
        },
        {
          text: "Κατηγορία",
          value: "kind.description",
          sortable: false,
        },
        {
          text: "Κατάσταση",
          value: "flgActive.cd",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
        {
          text: "Σχολές",
          value: "schools",
          sortable: false,
        },
        // {
        //   text: "Εκπαιδευτικό προσωπικό",
        //   value: "teachers",
        //   sortable: false,
        // },
      ],
      headersKesen: [
        {
          text: "Όνομα",
          value: "description",
          sortable: false,
        },
        {
          text: "Κωδικός",
          value: "cd",
          sortable: false,
        },
        {
          text: "Κατηγορία",
          value: "kind.description",
          sortable: false,
        },
        {
          text: "Κατάσταση",
          value: "flgActive.cd",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
        {
          text: "Σχολεία",
          value: "schools",
          sortable: false,
        },
        // {
        //   text: "Εκπαιδευτικό προσωπικό",
        //   value: "teachers",
        //   sortable: false,
        // },
      ],

      dialog: {
        faculty: null,
        open: false,
        type: null,
      },
    };
  },
  created() {
    this.fetchSchoolTypes();
    if (this.selectedSchoolType != null && this.selectedSchoolType != "") {
      this.schoolTypeId = this.selectedSchoolType;
    }
  },
  watch: {
    options: {
      async handler() {
        try {
          if (this.schoolTypeId != null && this.selectedSchoolType != "") {
            this.tableLoader = true;
            this.setFilters(this.options);
            await this.fetchFaculties();
            this.tableLoader = false;
          }
        } catch (e) {
          console.log(e);
          this.$store.dispatch("errorHandler", e);

          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      // faculties: (state) => state.faculties.faculties,
      pageChanged: (state) => state.base.pageChanged,
      selectedRole: (state) => state.auth.selectedRole,
      selectedSchoolType: (state) => state.faculties.selectedSchoolType,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
      appId: (state) => state.auth.appId,
    }),
  },

  methods: {
    ...mapMutations(["setCurrentFaculty", "setFilters", "changePage"]),
    ...mapActions(["getFaculties", "getFacultiesCategories"]),

    onView(faculty) {
      this.setCurrentFaculty(faculty);
      this.$router.push({
        path: `${this.$route.path}/${faculty.id}/schools`,
      });
    },

    onTeachersView(faculty) {
      this.setCurrentFaculty(faculty);
      this.$router.push({
        path: `${this.$route.path}/${faculty.id}/teachers`,
      });
    },

    openDialog(faculty, type) {
      this.dialog = {
        faculty,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        faculty: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchFaculties();
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        this.fetchFaculties();
        this.tableLoader = false;
      } catch (e) {
        console.log(e);

        this.tableLoader = false;
      }
    },
    async fetchSchoolTypes() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        this.setFilters(this.options);
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/104`,
          body
        );
        this.schoolTypesList = res.data;
        if (res.data.length == 1) {
          this.schoolTypeId = res.data[0].cd;
          this.fetchFaculties();
        }
      } catch (e) {
        throw e;
      }
    },
    async fetchFaculties() {
      try {
        this.tableLoader = true;
        if (this.schoolTypeId != null) {
          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/master/school/list`,
            {
              params: {
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                  this.options.itemsPerPage == -1
                    ? null
                    : this.options.itemsPerPage,
                kind: this.schoolTypeId,
              },
            }
          );
          this.faculties = res.data;
        } else {
          this.faculties = [];
        }

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    setSchoolType() {
      this.$store
        .dispatch("setSelectedSchoolType", this.schoolTypeId)
        .then(() => {
          this.options.page = 1;
          this.fetchFaculties();
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>