<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία εξεταστικής περιόδου
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-card class="mb-4">
          <v-card-title class="mb-4"> Βασικά στοιχεία </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  dense
                  outlined
                  v-model="cd"
                  type="text"
                  hide-details="auto"
                  :error-messages="cdErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Κωδικός
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-textarea
                  dense
                  outlined
                  v-model="description"
                  hide-details="auto"
                  :error-messages="descriptionErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Περιγραφή
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Επιπλέον πληροφορίες </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="examLims"
                      hide-details="auto"
                      type="text"
                      :items="getDataStaticList(116)"
                      item-text="description"
                      item-value="cd"
                      :error-messages="examLimsErrors"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Είδος χρονικών ορίων εξεταστικής περιόδου
                      </template>
                    </v-select>
                  </template>
                  <span>Είδος χρονικών ορίων εξεταστικής περιόδου *</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      hide-details="auto"
                      v-model="studentPart"
                      type="text"
                      :items="getDataStaticList(117)"
                      item-text="description"
                      item-value="cd"
                      :error-messages="studentPartErrors"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Σπουδαστές που μπορούν να λάβουν μέρος
                      </template>
                    </v-select>
                  </template>
                  <span>Σπουδαστές που μπορούν να λάβουν μέρος *</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Διάρκεια </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  dense
                  outlined
                  v-model="examFromDd"
                  type="text"
                  :items="days"
                  hide-details="auto"
                  :disabled="examLims == 1 ? false : true"
                  :error-messages="examFromDdErrors"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text" v-if="examLims == 1">*</span>
                    Ημέρα έναρξης
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                  dense
                  outlined
                  v-model="examFromMm"
                  type="text"
                  :items="months"
                  item-text="description"
                  item-value="cd"
                  hide-details="auto"
                  :disabled="examLims == 1 ? false : true"
                  :error-messages="examFromMmErrors"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text" v-if="examLims == 1">*</span>
                    Μήνας έναρξης
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                  dense
                  outlined
                  v-model="examToDd"
                  type="text"
                  hide-details="auto"
                  :items="days"
                  :disabled="examLims == 1 ? false : true"
                  :error-messages="examToDdErrors"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text" v-if="examLims == 1">*</span>
                    Ημέρα λήξης
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-select
                  dense
                  outlined
                  v-model="examToMm"
                  hide-details="auto"
                  type="text"
                  :items="months"
                  item-text="description"
                  item-value="cd"
                  :disabled="examLims == 1 ? false : true"
                  :error-messages="examToMmErrors"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text" v-if="examLims == 1">*</span>
                    Μήνας λήξης
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="duration"
                      hide-details="auto"
                      type="text"
                      :error-messages="durationErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Διάρκεια σε ημέρες εξεταστικής περιόδου
                      </template>
                    </v-text-field>
                  </template>
                  <span>Διάρκεια σε ημέρες εξεταστικής περιόδου*</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-col cols="12" sm="6" md="4">
          <v-textarea
            dense
            outlined
            v-model="memo"
            label="Σχόλια"
            hide-details="auto"
          >
          </v-textarea>
        </v-col>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, requiredIf, numeric } from "vuelidate/lib/validators";
export default {
  async created() {
    try {
      for (let i = 0; i < 31; i++) {
        this.days[i] = i + 1;
      }
    } catch (e) {
      console.log(e);
    }
  },
  props: ["semesterId"],
  data() {
    return {
      loader: false,

      cd: null,
      description: null,
      examLims: null,
      examFromDd: null,
      examFromMm: null,
      examToDd: null,
      examToMm: null,
      duration: null,
      studentPart: null,
      memo: null,
      days: [],
      months: [
        {
          description: "Ιανουάριος",
          cd: 1,
        },
        {
          description: "Φεβρουάριος",
          cd: 2,
        },
        {
          description: "Μάρτιος",
          cd: 3,
        },
        {
          description: "Απρίλιος",
          cd: 4,
        },
        {
          description: "Μάϊος",
          cd: 5,
        },
        {
          description: "Ιούνιος",
          cd: 6,
        },
        {
          description: "Ιούλιος",
          cd: 7,
        },
        {
          description: "Αύγουστος",
          cd: 8,
        },

        {
          description: "Σεπτέμβριος",
          cd: 9,
        },

        {
          description: "Οκτώβριος",
          cd: 10,
        },
        {
          description: "Νοέμβριος",
          cd: 11,
        },

        {
          description: "Δεκέμβριος",
          cd: 12,
        },
      ],
    };
  },

  validations: {
    cd: {
      required,
    },
    description: {
      required,
    },
    examLims: {
      required,
    },
    examFromDd: {
      required: requiredIf(function () {
        return this.examLims == 1;
      }),
    },
    examFromMm: {
      required: requiredIf(function () {
        return this.examLims == 1;
      }),
    },
    examToDd: {
      required: requiredIf(function () {
        return this.examLims == 1;
      }),
    },
    examToMm: {
      required: requiredIf(function () {
        return this.examLims == 1;
      }),
    },
    duration: {
      required,
      numeric,
    },
    studentPart: {
      required,
    },
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      currentSemester: (state) => state.semesters.currentSemester,
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    cdErrors() {
      const errors = [];
      if (!this.$v.cd.$dirty) return errors;
      if (!this.$v.cd.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    examLimsErrors() {
      const errors = [];
      if (!this.$v.examLims.$dirty) return errors;
      if (!this.$v.examLims.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    examFromDdErrors() {
      const errors = [];
      if (!this.$v.examFromDd.$dirty) return errors;
      if (!this.$v.examFromDd.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    examFromMmErrors() {
      const errors = [];
      if (!this.$v.examFromMm.$dirty) return errors;
      if (!this.$v.examFromMm.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    examToDdErrors() {
      const errors = [];
      if (!this.$v.examToDd.$dirty) return errors;
      if (!this.$v.examToDd.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    examToMmErrors() {
      const errors = [];
      if (!this.$v.examToMm.$dirty) return errors;
      if (!this.$v.examToMm.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    durationErrors() {
      const errors = [];
      if (!this.$v.duration.$dirty) return errors;
      if (!this.$v.duration.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.duration.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
    studentPartErrors() {
      const errors = [];
      if (!this.$v.studentPart.$dirty) return errors;
      if (!this.$v.studentPart.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addExam", "getStudentParts", "getExamLimss"]),

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          cd: this.cd,
          description: this.description,
          examLims: this.examLims,
          examFromDd: this.examFromDd,
          examFromMm: this.examFromMm,
          examToDd: this.examToDd,
          examToMm: this.examToMm,
          duration: this.duration,
          studentPart: this.studentPart,
          // masterSchoolId: this.currentFaculty.id,
          // schoolId: this.currentSchool.id,
          trainPeriodId: this.semesterId,
        };
        if (this.memo != null) {
          content.memo = this.memo;
        }
        await this.addExam(content);
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>