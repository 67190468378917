import axios from "axios";

export default {
  state: {
    schedulingExams: [],

    //dropdowns
    issues: [],
    allExams: [],
    gradeFlags: [],
  },

  mutations: {
    getSchedulingExams(state, schedulingExams) {
      state.schedulingExams = schedulingExams;
    },

    getIssues(state, issues) {
      state.issues = issues;
    },

    getGradeFlags(state, gradeFlags) {
      state.gradeFlags = gradeFlags;
    },

    getAllExams(state, allExams) {
      state.allExams = allExams;
    },
  },

  actions: {
    async getSchedulingExams({ commit, dispatch }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/prog/exam/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              yearId: payload.id,
              progTrainPeriodId: payload.progTrainPeriodId,
              examPeriodId: payload.examPeriodId
            },
          }
        );
        commit("getSchedulingExams", res.data);
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async addSchedulingExam({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/prog/exam`,
          payload.content
        );
        // await dispatch('getSchedulingExams', {
        //     id: payload.content.yearId,
        //     progTrainPeriodId: payload.progTrainPeriodId,
        //     page: rootState.base.filters.page,
        //     limit: rootState.base.filters.itemsPerPage == -1 ? state.schedulingExams.elements : rootState.base.filters.itemsPerPage,

        // })
        commit("toggleSnackbar", {
          open: true,
          text:
            "Η δημιουργία προγραμματισμού εξεταστικής περιόδου ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editSchedulingExam({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/prog/exam/${payload.id}`,
          payload.content
        );
        // await dispatch('getSchedulingExams', {
        //     id: payload.content.yearId,
        //     progTrainPeriodId: payload.progTrainPeriodId,
        //     page: rootState.base.filters.page,
        //     limit: rootState.base.filters.itemsPerPage == -1 ? state.schedulingExams.elements : rootState.base.filters.itemsPerPage,

        // })
        commit("toggleSnackbar", {
          open: true,
          text:
            "Η επεξεργασία προγραμματισμού εξεταστικής περιόδου ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteSchedulingExam(
      { commit, dispatch, rootState, state },
      payload
    ) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/prog/exam/${payload.id}`
        );
        if (
          state.schedulingExams.limit == 1 &&
          state.schedulingExams.page != 1
        ) {
          commit("changePage", true);
        } else {
          // await dispatch('getSchedulingExams', {
          //     id: payload.yearId,
          //     progTrainPeriodId: payload.progTrainPeriodId,
          //     page: rootState.base.filters.page,
          //     limit: rootState.base.filters.itemsPerPage == -1 ? state.schedulingExams.elements : rootState.base.filters.itemsPerPage,
          // })
        }
        commit("toggleSnackbar", {
          open: true,
          text:
            "Η διαγραφή προγραμματισμού εξεταστικής περιόδου ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async getIssues({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/124`
        );
        commit("getIssues", res.data);
      } catch (e) {

        throw e;
      }
    },

    async getGradeFlags({ commit }) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/value/123`
        );
        commit("getGradeFlags", res.data);
      } catch (e) {
        throw e;
      }
    },

    async getAllExams({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/exam/period/list`,
          {
            params: {
              page: 0,
              trainPeriodId: payload.id,
              progTrainPeriodId: payload.progTrainPeriodId,
            },
          }
        );
        commit("getAllExams", res.data);
      } catch (e) {
        throw e;
      }
    },
  },
};
