<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Χρέωση βιβλίου
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4 d-flex flex-column">
      <v-progress-circular
        v-if="cardLoader"
        indeterminate
        color="primary"
        class="align-self-center ma-4"
        :size="50"
      ></v-progress-circular>
      <v-form v-else @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              dense
              outlined
              hide-details="auto"
              v-model="personId"
              :items="students.content"
              :item-text="(item) => item.firstName + ' ' + item.lastName"
              item-value="personId"
              class="mb-4"
              :error-messages="personIdErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Σπουδαστής
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="startDateFormatted"
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  class="mb-4"
                  @blur="startDate = parseDate(startDateFormatted, 'startDate')"
                  :error-messages="startDateErrors"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία από
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="startDate"
                scrollable
                hide-details="auto"
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  label=" Ημερομηνία έως"
                  hide-details="auto"
                  v-model="endDateFormatted"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  @blur="endDate = parseDate(endDateFormatted, 'endDate')"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="endDate"
                scrollable
                @input="menu1 = false"
                hide-details="auto"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-textarea
              dense
              outlined
              v-model="memo"
              label="Σχόλια"
              hide-details="auto"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  async created() {
    try {
      await this.getStudents({
        page: 0,
        id: this.masterSchoolId,
      });
      this.cardLoader = false;
    } catch (e) {
      this.cardLoader = false;

      console.log(e);
    }
  },

  data() {
    return {
      loader: false,
      endDate: null,
      endDateFormatted: null,
      memo: null,
      personId: null,
      startDate: null,
      startDateFormatted: null,
      cardLoader: true,
      menu: false,
      menu1: false,
    };
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },

  validations: {
    startDate: {
      required,
    },
    personId: {
      required,
    },
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      masterSchoolId: (state) => state.generic_filter.facultyId,
      students: (state) => state.students.students,
    }),

    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;
      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    personIdErrors() {
      const errors = [];
      if (!this.$v.personId.$dirty) return errors;
      if (!this.$v.personId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addLendingBook", "getStudents"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          startDate: this.startDate,
          libraryBookId: this.$route.params.book_id,
          personId: this.personId,
        };

        if (this.endDate != null) {
          content.endDate = this.endDate;
        }
        if (this.memo != null) {
          content.memo = this.memo;
        }

        await this.addLendingBook(content);
        this.loader = false;
        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped lang="scss">
</style>