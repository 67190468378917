<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία κατηγορίας εκπαιδευτικών
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-text-field
          dense
          outlined
          v-model="password"
          hide-details="auto"
          type="text"
          class="mb-4"
          :error-messages="passwordErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Κωδικός
          </template>
        </v-text-field>

        <v-textarea
          dense
          outlined
          v-model="description"
          :error-messages="descriptionErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Ειδικότητα
          </template>
        </v-textarea>

        <!-- <v-text-field
          dense
          outlined
          hide-details="auto"
          v-model="hoursPerWeek"
          type="text"
          class="mb-4"
          :error-messages="hoursPerWeekErrors"
        >
          <template v-slot:label> Ώρες ανά βδομάδα </template>
        </v-text-field> -->

        <v-select
          dense
          outlined
          v-model="contract"
          :items="getDataStaticList(142)"
          item-text="description"
          hide-details="auto"
          item-value="cd"
          class="mb-4"
          :error-messages="contractErrors"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Σχέση εργασίας
          </template>
        </v-select>

        <v-select
          dense
          outlined
          v-model="categoryTeam"
          :items="getDataStaticList(152)"
          hide-details="auto"
          item-value="cd"
          item-text="description"
          class="mb-4"
          :error-messages="categoryTeamErrors"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Βαθμίδα
          </template>
        </v-select>

        <v-checkbox
          v-model="activeRegister"
          label="Ένδειξη ενεργής εγγραφής"
          hide-details="auto"
          class="mb-4"
          :error-messages="activeRegisterErrors"
        >
        </v-checkbox>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  props: ["professorsCategory"],

  created() {
    this.password = this.professorsCategory.cd;
    this.description = this.professorsCategory.description;
    this.activeRegister = this.professorsCategory.enabled;
    // this.hoursPerWeek = this.professorsCategory.hoursPerWeek;
    this.contract = this.professorsCategory.categoryType.cd;
    this.categoryTeam = this.professorsCategory.groupType.cd;
  },

  data() {
    return {
      loader: false,
      password: null,
      description: null,
      activeRegister: 0,
      // hoursPerWeek: null,
      contract: null,
      categoryTeam: null,
    };
  },

  validations: {
    password: {
      required,
    },
    description: {
      required,
    },
    activeRegister: {
      required,
    },
    // hoursPerWeek: {
    //   numeric,
    // },
    contract: {
      required,
    },
    categoryTeam: {
      required,
    },
  },
  computed: {
    ...mapState({
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    activeRegisterErrors() {
      const errors = [];
      if (!this.$v.activeRegister.$dirty) return errors;
      if (!this.$v.activeRegister.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    // hoursPerWeekErrors() {
    //   const errors = [];
    //   if (!this.$v.hoursPerWeek.$dirty) return errors;
    //   if (!this.$v.hoursPerWeek.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
    //   return errors;
    // },
    contractErrors() {
      const errors = [];
      if (!this.$v.contract.$dirty) return errors;
      if (!this.$v.contract.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    categoryTeamErrors() {
      const errors = [];
      if (!this.$v.categoryTeam.$dirty) return errors;
      if (!this.$v.categoryTeam.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["editProfessorsCategory"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        await this.editProfessorsCategory({
          id: this.professorsCategory.id,
          content: {
            cd: this.password,
            description: this.description,
            // hoursPerWeek: this.hoursPerWeek,
            enabled: this.activeRegister,
            categoryTypeCd: this.contract,
            groupTypeCd: this.categoryTeam,
          },
        });
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>