<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
        max-width="1400px"
        width="100%"
        height="100%"
        class="pa-4 d-flex flex-column"
        elevation="0"
        color="#EAF1F5"
    >
      <GenericFilter
          :level="2"
          :search="true"
          @onSearchAction="onSearch"
          :requiredFields="{
          masterSchool: true,
          school: true,
          year: false,
          progTrainPeriod: false,
          class: false,
          lesson: false,
        }"
      />

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
              no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
              loading-text="Φόρτωση..."
              :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
              :headers="appId == 1 ? headers : headersKesen"
              :items="students.content"
              :options.sync="options"
              :server-items-length="students.elements"
              :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onEdit(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onStudentView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.enabled`]="{ item }">
              <span v-if="item.enabled">Ναι</span>
              <span v-else>Οχι</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import axios from "axios";

export default {
  components: {
    GenericFilter,
  },
  async created() {
    try {
      if (this.facultyId != null) await this.handler();
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      search: "",
      tableLoader: false,
      filtersSet: false,
      students: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Σχολή",
          value: "school.description",
          sortable: false,
        },
        // {
        //   text: "Ενεργός",
        //   value: "enabled",
        //   sortable: false,
        // },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      headersKesen: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "ΜΕΘ",
          value: "meth",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        // {
        //   text: "Εκπαιδευτικός Κύκλος/Ειδικό Τμήμα",
        //   value: "school.description",
        //   sortable: false,
        // },
        // {
        //   text: "Ενεργός",
        //   value: "enabled",
        //   sortable: false,
        // },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        teacher: null,
        open: false,
        type: null,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from);
      let array = [
        `/students/${from?.params.student_id}/edit`,
        `/students/${from?.params.student_id}/view_student/atomika_stoixeia`,
        `/students/${from?.params.student_id}/view_student/stoixeia_foithshs`,
        `/students/${from?.params.student_id}/view_student/ekpaideutika_taksidia`,
        `/students/${from?.params.student_id}/view_student/ptyxiakh_ergasia`,
        `/students/${from?.params.student_id}/view_student/meteggrafes`,
        `/students/${from?.params.student_id}/view_student/anastoles_foithshs`,
        `/students/${from?.params.student_id}/view_student/refusal_details`,
        `/students/${from?.params.student_id}/view_student/diagrafes`,
        `/students/${from?.params.student_id}/view_student/applications`,
        `/students/${from?.params.student_id}/view_student/awards`,
        `/students/${from?.params.student_id}/view_student/certifications`,
      ];
      // if (!array.includes(from.path)) {
      //   vm.clearGeneric();
      // }
    });
  },
  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
  },

  computed: {
    ...mapState({
      listFaculty: (state) => state.faculties.faculties.content,
      listApops: (state) => state.schools.apops,
      pageChanged: (state) => state.base.pageChanged,
      appId: (state) => state.auth.appId,
      facultyId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      active: (state) => state.generic_filter.active,
      searchText: (state) => state.generic_filter.searchText,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentStudent"]),
    ...mapActions(["getStudents", "getFaculties", "setCurrentUser"]),

    async onStudentView(student) {
      this.setCurrentStudent(student);
      await this.$router.push(
          `/students/${student.personId}/view_student/atomika_stoixeia`
      );
    },

    clearGeneric() {
      this.$store.dispatch("setAllFilter", {
        facultyId: null,
        schoolId: null,
        academicYearId: null,
        semesterId: null,
        classId: null,
        courseId: null,
        dates: null,
        date: null,
        active: null,
        searchText: null,
        examYearId: null,
        progExamPeriodId: null,
        examClassId: null,
        examLessonId: null,
        examDate: null,
      });
    },
    async onEdit(user) {
      try {
        await this.$router.push(`/students/${user.personId}/edit`);
      } catch (e) {
        console.log(e);
      }
    },

    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        await this.handler();
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/student/register/list/view`,
            {
              params: {
                masterSchoolId: this.facultyId,
                schoolId: this.schoolId,
                // enabled:
                //   this.active == "1" ? true : this.active != null ? false : null,
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                    this.options.itemsPerPage == -1
                        ? null
                        : this.options.itemsPerPage,
                searchText: this.searchText != "" ? this.searchText : null,
              },
            }
        );
        this.students = res.data;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.students = [];
        await this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>