<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Προβολή εκπαιδευτικού ταξιδιού
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <span class="body-1 mr-2">Ημερομηνία έναρξης:</span>
          <span class="body-1">
            {{  formatDate(trip.dateOfShipping, 'el') }}</span
          >
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <span class="body-1 mr-2">Ημερομηνία απόλυσης:</span>
          <span class="body-1">{{
              formatDate(trip.dateOfDismissal, 'el')
          }}</span>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <span class="body-1 mr-2">Συνολική θαλάσσια υπηρεσία σε ημέρες:</span>
          <span class="body-1">{{ trip.totalMaritimeService }}</span>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <span class="body-1 mr-2">Αποτέλεσμα εκπαιδευτικού ταξιδιού:</span>
          <span class="body-1">{{
            trip.educationalTripAssessment.description
          }}</span>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <span class="body-1 mr-2">Μη παρουσίαση για αξιολόγηση:</span>
          <span class="body-1">{{ trip.nonPresEval.description }}</span>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <span class="body-1 mr-2">Μόνιμη διαγραφή:</span>
          <span class="body-1">{{ trip.permanentlyDelete.description }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>



<script>
import {helperMixin} from "@/helpers/helperMixin";

export default {
  props: ["trip"],
  mixins: [helperMixin],
  created() {
    console.log(this.trip);
  },
};
</script>

<style scoped lang="scss">
</style>