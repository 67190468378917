<template>
  <v-card>
    <v-toolbar color="error" class="mb-4" dark
    >Διαγραφή εκπαιδευτικού ταξιδιού
      <v-btn
          depressed
          icon
          class="ml-auto"
          color="white"
          @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <div
          class="d-flex align-center justify-center text-body-1 black--text mb-4"
      >
        <v-icon color="error" class="mr-1">mdi-alert-circle-outline</v-icon>
        <div>
          Είστε σίγουροι ότι θέλετε να διαγράψετε το εκπαιδευτικό ταξίδι;
        </div>
      </div>
      <div class="d-flex justify-center pb-2">
        <v-btn dense outlined color="error" class="mr-4" @click="$emit('close')"
        >Ακύρωση
        </v-btn
        >
        <v-btn depressed color="error" @click="onDelete" :loading="loader"
        >Διαγραφή
        </v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["progTrip", "progTrips"],

  data() {
    return {
      loader: false,
    };
  },

  methods: {
    ...mapActions(["deleteProgTrip"]),
    async onDelete() {
      try {
        this.loader = true;
        await this.deleteProgTrip(this.progTrip.id);
        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>