import { render, staticRenderFns } from "./AddBook.vue?vue&type=template&id=518a1ec8&scoped=true&"
import script from "./AddBook.vue?vue&type=script&lang=js&"
export * from "./AddBook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518a1ec8",
  null
  
)

export default component.exports