vue<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Προβολή ανακοίνωσης
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex align-start flex-column pa-4">
      <span class="black--text body-1 mb-2">
        Ημερομηνία ανακοίνωσης:
        {{ " " + new Date(announcement.creationDate).toLocaleDateString("el") }}
      </span>
      <span class="black--text body-1 mb-2">
        Μήνυμα: {{ " " + announcement.message }}
      </span>
      <span
        class="d-flex flex-column align-start"
        v-if="announcement.attachedFiles != null"
      >
        <v-btn
          v-for="(file, i) in announcement.attachedFiles"
          :key="i + file.id"
          plain
          color="primary"
          class="pa-0"
          @click="onDownload(file.id)"
          >{{ file.name }}
          <v-icon color="success">mdi-download</v-icon>
        </v-btn>
      </span>

      <div class="ml-auto">
        <v-btn depressed color="error" @click="$emit('close')">Επιστροφή</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["announcement"],

  methods: {
    ...mapActions(["downloadFile"]),

    async onDownload(id) {
      try {
        await this.downloadFile(id);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>