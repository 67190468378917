<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Εγγραφές
      <v-btn depressed icon color="white" class="ml-auto" @click="onClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-row>
        <v-col>
          <v-select
            clearable
            outlined
            dense
            v-model="form.propertyType"
            label="Τύπος"
            class="mb-4"
            item-text="name"
            item-value="id"
            :items="types"
            hide-details="auto"
            @change="clearItem"
            no-data-text="Δεδομένα μη διαθέσιμα"
          >
          </v-select>
        </v-col>
        <v-col>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="startDateFormatted"
                prepend-inner-icon="mdi-calendar"
                class="mb-4"
                hide-details="auto"
                @blur="startDate = parseDate(startDateFormatted, 'startDate')"
              >
                <template v-slot:label>
                  Ημερομηνία έναρξης <span class="error--text">*</span>
                </template>
                <template v-slot:prepend-inner>
                  <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              locale="el"
              v-model="startDate"
              scrollable
              @input="menu1 = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                v-model="endDateFormatted"
                label="Ημερομηνία λήξης"
                prepend-inner-icon="mdi-calendar"
                class="mb-4"
                @blur="endDate = parseDate(endDateFormatted, 'endDate')"
              >
                <template v-slot:prepend-inner>
                  <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              locale="el"
              v-model="endDate"
              scrollable
              @input="menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="form.propertyType == 6">
          <v-text-field
            dense
            outlined
            v-model="form.ldapUsername"
            label="LDAP username"
            type="text"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            dense
            outlined
            label="Σχολες"
            v-model="form.masterSchoolId"
            :items="listFaculty"
            item-text="description"
            item-value="cd"
            @change="onSetFaculty"
            no-data-text="Δεδομένα μη διαθέσιμα"
          >
            <template v-slot:label>
              {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="
            this.form.propertyType == 1 ||
            this.form.propertyType == 2 ||
            this.form.propertyType == 3
          "
        >
          <v-select
            dense
            outlined
            v-model="form.schoolId"
            :items="listSchool"
            item-text="description"
            @change="onSetSchool"
            item-value="cd"
            no-data-text="Δεδομένα μη διαθέσιμα"
          >
            <template v-slot:label>
              {{ appId == 1 ? "Σχολή" : getFieldName() }}
            </template></v-select
          >
        </v-col>
        <v-col v-if="this.form.propertyType == 3">
          <v-select
            dense
            outlined
            label="Βαθμίδα-Ειδικότητα"
            v-model="form.teacherCategoryId"
            :items="listProfessorCategories"
            :item-text="
              (item) => item.groupType.description + '-' + item.description
            "
            item-value="id"
            no-data-text="Δεδομένα μη διαθέσιμα"
          ></v-select>
        </v-col>
        <v-col v-if="this.form.propertyType == 1">
          <v-select
            dense
            outlined
            label="Eκπαιδευτική περίοδος"
            v-model="form.progTrainPrdId"
            :items="listProgTrain"
            item-text="description"
            item-value="cd"
            no-data-text="Δεδομένα μη διαθέσιμα"
          >
            <template v-slot:label>
              {{ appId == 1 ? "Εξάμηνο σπουδών" : "Κύκλο σπουδών" }}
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="this.form.propertyType == 1">
        <v-col>
          <v-select
            dense
            outlined
            label="Εξεταστική περίοδος"
            v-model="form.progExamPrdId"
            :items="listProgExam"
            item-text="description"
            item-value="cd"
            no-data-text="Δεδομένα μη διαθέσιμα"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            dense
            outlined
            label="Τύπος διαμονής"
            v-model="form.accomondationType"
            :items="getDataStaticList(154)"
            item-text="description"
            item-value="cd"
            no-data-text="Δεδομένα μη διαθέσιμα"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <div class="d-flex justify-center btn-container">
      <v-btn depressed color="error" @click="$emit('close')" class="mr-4"
        >Ακύρωση</v-btn
      >
      <v-btn
        v-if="this.data.type == 'add'"
        depressed
        color="success"
        class=""
        type="submit"
        :loading="loader"
        @click="createPersonRegister()"
        >Δημιουργία</v-btn
      >
      <v-btn
        v-else
        depressed
        color="success"
        class="mr-4"
        type="submit"
        :loading="loader"
        @click="editPersonRegister()"
        >Αποθήκευση</v-btn
      >
    </div>
    <Loader v-if="this.showLoader" />
  </v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Loader from "../../components/Loader/Loader.vue";
import Helpers from "../../helpers/helpers";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  name: "PersonRegisterModal",
  props: ["data", "user"],
  components: {
    Loader,
  },
  async created() {
    try {
      if (this.data.type == "edit") {
        this.showLoader = true;
        let person = this.data.person;
        this.form.person = person.id;
        this.form.propertyType = person.propertyType.cd;
        this.startDate = person.startDate;
        this.endDate = person.endDate;
        this.form.masterSchoolId = String(person.masterSchool.id);

        if (person.masterSchool.id != "") {
          this.onSetFaculty(person.masterSchool.id);
        }

        console.log(String(person?.teacherCategory?.id));
        if (person.school != undefined) {
          this.form.schoolId = String(person?.school?.id);
          this.onSetSchool(person.school.id);
        }

        this.form.ldapUsername = person.ldapUsername;
        if (person.progTrainPrdId != undefined) {
          this.form.progTrainPrdId = String(person?.progTrainPrd?.id);
        }
        if (person.progExamPrd != undefined) {
          this.form.progExamPrdId = String(person?.progExamPrd?.id);
        }

        this.form.teacherCategoryId = person?.teacherCategory?.id;
      }
    } catch (e) {
      console.log(e);
    }
    await this.loadFaculties();
    await this.fetchProfessorCategories();
    // await this.fetchProgTran();
    // await this.fetchProgExam();
    this.showLoader = false;
  },

  data() {
    return {
      loader: false,
      menu1: false,
      menu: false,
      types: [
        { id: "6", name: "LDAP user" },
        { id: "2", name: "Χειριστής" },
        { id: "1", name: "Σπουδαστής" },
        { id: "3", name: "Εκπαιδευτικός" },
        { id: "9", name: "Διαχειριστής" },
      ],
      form: {
        propertyType: null,
        masterSchoolId: null,
        schoolId: null,
        ldapUsername: null,
        progTrainPrdId: null,
        progExamPrdId: null,
        teacherCategoryId: null,
      },
      listFaculty: [],
      listSchool: [],
      listProfessorCategories: [],
      listProgTrain: [],
      listProgExam: [],
      showLoader: false,
      startDateFormatted: null,
      endDateFormatted: null,
      startDate: null,
      endDate: null,
    };
  },
  watch: {
    startDate(val) {
      console.log(this.startDate);
      this.startDateFormatted = this.formatDate(this.startDate);
    },
    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },
  computed: {
    ...mapState({
      staticList: (state) => state.auth.userInfo.staticView,
      appId: (state) => state.auth.appId,
    }),
  },
  methods: {
    async loadFaculties() {
      let result = await this.fetchDataDynamic(2000, null);
      this.listFaculty = result.data;
    },
    getFieldName() {
      return Helpers.getFieldName(1);
    },
    async onSetFaculty(value) {
      // call end point tou get schools 2001
      let result = await this.fetchDataDynamic(2001, value);
      this.listSchool = result.data;
    },
    async onSetSchool(value) {
      let result = await this.fetchDataDynamic(2002, value);
      this.listAcademicYear = result.data;

      result = await this.fetchDataDynamic(2009, value);
      this.listProgTrain = result.data;

      result = await this.fetchDataDynamic(2010, value);
      this.listProgExam = result.data;
    },
    async fetchProgTran(parentId) {
      let result = await this.fetchDataDynamic(2003, parentId);
      this.listProgTrain = result.data;
    },
    async fetchProgExam(parentId) {
      let result = await this.fetchDataDynamic(2005, parentId);
      this.listProgExam = result.data;
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        groupId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
    async fetchProfessorCategories() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/category/list`
        );
        this.listProfessorCategories = res.data.content;
      } catch (e) {
        throw e;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
    clearItem() {
      this.form.masterSchoolId = null;
      this.form.schoolId = null;
      this.form.accomondationType = null;
      this.form.progTrainPrdId = null;
      this.form.progExamPrdId = null;
      this.form.ldapUsername = null;
      this.form.teacherCategoryId = null;
    },
    async createPersonRegister() {
      try {
        this.loader = true;
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/person/register`,
          {
            ...this.form,
            propertyTypeCd: this.form.propertyType,
            personId: this.data.person.person.id,
            startDate: this.startDate,
            endDate: this.endDate,
          }
        );
        console.log(res);

        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία της εγγραφής ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
        this.$emit("close");
        //show notification
      } catch (e) {
        this.loader = true;
        this.$emit("close");
        throw e;
      }
    },
    async editPersonRegister() {
      try {
        this.loader = true;
        const res = await axios.put(
          `${process.env.VUE_APP_BASE_URL}/person/register/${this.data.person.id}`,
          {
            ...this.form,
            propertyTypeCd: this.form.propertyType,
            personId: this.user.id,
            startDate: this.startDate,
            endDate: this.endDate,
          }
        );

        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Η αποθήκευση της εγγραφής ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
        this.$emit("close");
        //show notification
      } catch (e) {
        this.loader = false;
        this.$emit("close");
        throw e;
      }
    },
    onClose() {
      this.loader = false;
      this.form.masterSchoolId = null;
      this.form.schoolId = null;
      this.form.accomondationType = null;
      this.form.progTrainPrdId = null;
      this.form.progExamPrdId = null;
      this.form.ldapUsername = null;
      this.form.teacherCategoryId = null;
      this.form.propertyType = null;
      this.form.startDate = null;
      this.form.endDate = null;

      this.$emit("close");
    },
  },
};
</script>

<style>
.btn-container {
  padding: 10px;
  border-top: 1px solid #00000024;
}
</style>