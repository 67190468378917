
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <ExamFilter
        :level="6"
        @onSearchAction="handler"
        :key="query"
        :requiredFields="{
          masterSchool: true,
          school: true,
          examYear: true,
          progExamPeriod: true,
          examDate: true,
          examLesson: true,
        }"
      />

      <v-card-text class="pa-0 pb-4 pt-4 d-flex flex-column flex-sm-row">
        <v-card class="flex-grow-1 mr-sm-10 mb-5 mb-sm-0" min-height="500px">
          <v-card-text>
            <v-data-table
              no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
              loading-text="Φόρτωση..."
              :footer-props="{
                'items-per-page-text': 'Σειρές ανά σελίδα',
                'items-per-page-all-text': 'Όλες',
                'items-per-page-options': [5, 10, 15, 100],
              }"
              :headers="headers"
              :items="listForExam.hashContent"
              :server-items-length="!filtersSet ? 0 : listForExam.elements"
              :options.sync="options"
              :loading="tableLoader"
              height="400px"
            >
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card :disabled="disabledDiv">
          <v-card-text>
            <div
              v-for="(input, i) in classesInputs.length"
              :key="i"
              class="d-flex flex-column"
            >
              <div class="align-self-end">
                <v-btn
                  depressed
                  icon
                  @click="classesInputs.pop()"
                  :disabled="classesInputs.length == 1"
                >
                  <v-icon color="error">mdi-minus</v-icon>
                </v-btn>

                <v-btn
                  depressed
                  icon
                  @click="
                    classesInputs.push({
                      classDescr: null,
                      capacity: null,
                    })
                  "
                >
                  <v-icon color="success">mdi-plus</v-icon>
                </v-btn>
              </div>
              <div class="d-flex align-center">
                <v-text-field
                  v-model="classesInputs[i].classDescr"
                  outlined
                  dense
                  label="Περιγραφή τμήματος"
                  class="mr-4"
                  :error-messages="descriptionInputsErrors(i)"
                ></v-text-field>

                <v-text-field
                  v-model="classesInputs[i].capacity"
                  outlined
                  dense
                  label="Χωρητικότητα"
                  clearable
                  class="mr-4"
                  :error-messages="capacityInputsErrors(i)"
                ></v-text-field>

                <!-- <v-select
                  v-model="classesInputs[i].classRoomTpy"
                  outlined
                  dense
                  label="Τύπος"
                  :items="classTypes"
                  item-text="description"
                  item-value="cd"
                  clearable
                  :error-messages="typeInputsErrors(i)"
                ></v-select> -->
              </div>
            </div>

            <v-select
              v-model="sortingType"
              outlined
              dense
              label="Επιλογή ταξινόμησης"
              :items="epiloghTaksinomhshs"
              item-text="description"
              item-value="cd"
              clearable
              :error-messages="sortingTypeErrors"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-card-text>
      <div class="ml-auto">
        <span v-if="this.lessonId == null && filtersSet" style="color: red">
          Με βάση το τμήμα που είχε οταν παρακολουθούσε το μάθημα
        </span>
        <v-btn
          :loading="loader"
          depressed
          color="primary"
          class="mr-2"
          :disabled="!filtersSet || listForExam.hashContent.length == 0"
          @click="onClassCreate"
        >
          Αρχικοποίηση τμημάτων
        </v-btn>
      </div>
    </v-card>
    <Loader :dialog="loader"/>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ExamFilter from "../../components/GenericFilter/ExamFilter.vue";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import axios from "axios";
import Loader from "@/components/Loader/Loader.vue";
export default {
  components: {
    ExamFilter,
    Loader
  },

  async created() {
    try {
      await this.getEpiloghTaksinomhshs();
      await this.getClassTypes();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      tableLoader: false,
      loader: false,
      filtersSet: false,
      sortingType: null,
      listForExam: [],
      query: false,
      classesInputs: [
        {
          classDescr: null,
          capacity: null,
          // classRoomTpy: null,
        },
      ],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },
      ],

      dialog: {
        School: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
    deep: true,
  },
  validations() {
    const self = this;
    return {
      classesInputs: {
        $each: {
          classDescr: {
            required: requiredIf((e) => {
              return self.lessonId != null;
            }),
          },
          capacity: {
            required: requiredIf((e) => {
              return self.lessonId != null;
            }),
            numeric,
          },
          // classRoomTpy: { required },
        },
      },
      sortingType: { required },
    };
  },
  // validations: {
  //   classesInputs: {
  //     $each: {
  //       classDescr: {
  //         required: requiredIf((e) => {
  //           return this.lessonId != null;
  //         }),
  //       },
  //       capacity: { required, numeric },
  //       // classRoomTpy: { required },
  //     },
  //   },

  // },

  computed: {
    ...mapState({
      schoolRooms: (state) => state.school_rooms.schoolRooms,
      masterSchoolId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      yearId: (state) => state.generic_filter.examYearId,
      lessonId: (state) => state.generic_filter.examLessonId,
      examDate: (state) => state.generic_filter.examDate,

      progExamPeriodId: (state) => state.generic_filter.progExamPeriodId,
      approveApplications: (state) => state.approve_applications,
      classTypes: (state) => state.classes.classTypes,
      epiloghTaksinomhshs: (state) => state.classes.epiloghTaksinomhshs,
      progExamDetails: (state) => state.generic_filter.progExamDetails,
    }),

    sortingTypeErrors() {
      const errors = [];
      if (!this.$v.sortingType.$dirty) return errors;
      if (!this.$v.sortingType.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    disabledDiv() {
      if (this.lessonId == null) return true;

      if (!this.filtersSet) return true;

      return false;
    },
  },

  methods: {
    ...mapMutations(["setFilters"]),
    ...mapActions([
      "createExamClasses",
      "getClassTypes",
      "getEpiloghTaksinomhshs",
    ]),

    async onClassCreate() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.loader = true;

        let content = {
          progExamPeriodId: this.progExamPeriodId,
          // lessonId: this.lessonId,
          examDate: this.examDate,
          sortingType: this.sortingType,
          classesInputs: this.classesInputs,
        };

        if (this.lessonId != null) {
          content.lessonId = this.lessonId;
        }

        await this.createExamClasses(content);
        await this.handler();
        this.loader = false;
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },

    descriptionInputsErrors(i) {
      const errors = [];
      if (!this.$v.classesInputs.$each[i].classDescr.$dirty) return errors;
      if (!this.$v.classesInputs.$each[i].classDescr.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    capacityInputsErrors(i) {
      const errors = [];
      if (!this.$v.classesInputs.$each[i].capacity.$dirty) return errors;
      if (!this.$v.classesInputs.$each[i].capacity.required)
        errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.classesInputs.$each[i].capacity.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    // typeInputsErrors(i) {
    //   const errors = [];
    //   if (!this.$v.classesInputs.$each[i].classRoomTpy.$dirty) return errors;
    //   if (!this.$v.classesInputs.$each[i].classRoomTpy.required)
    //     errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },

    async handler() {
      try {
        this.tableLoader = true;

        this.setFilters(this.options);

        axios.defaults.headers.common["X-ACCESS-DATE"] =
          `${this.progExamDetails.startDate}`;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForExam`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              masterSchoolId: this.masterSchoolId,
              schoolId: this.schoolId,
              yearId: this.yearId,
              progExamPeriodId: this.progExamPeriodId,
              lessonId: this.lessonId,
            },
          }
        );
        if (this.lessonId == null) {
          this.sortingType = "3";
        }
        this.listForExam = res.data;

        this.tableLoader = false;
        if (!this.filtersSet) this.filtersSet = true;
      } catch (e) {
        console.log(e);
        this.tableLoader = false;
        this.listForExam = [];
        await this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>