<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="requirement.content"
            :loading="tableLoader"
            :options.sync="options"
            :server-items-length="requirement.elements"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="800px"
      scrollable
    >
      <AddApplicationRec
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
        :cmnApplId="$route.params.common_online_application_id"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="800px"
      scrollable
    >
      <EditApplicationRec
        v-if="dialog.open && dialog.type == 'edit'"
        @close="closeDialog"
        :action="dialog.action"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteApplicationRec
        v-if="dialog.open && dialog.type == 'delete'"
        @close="closeDialog"
        :action="dialog.action"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import AddApplicationRec from "../../components/ApplicationRecModal/AddApplicationRec.vue";
import EditApplicationRec from "../../components/ApplicationRecModal/EditApplicationRec.vue"
import DeleteApplicationRec from "../../components/ApplicationRecModal/DeleteApplicationRec.vue";

import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
    DeleteApplicationRec,
    EditApplicationRec,
    AddApplicationRec,
  },

  async created() {
    await this.getCategoryTypes();
    this.breadcrumb_items.push(
      {
        text: `${this.currentCommonOnlineApplication.description}`,
        disabled: false,
        link: true,
        exact: true,
        to: "/configure_applications",
      },
      {
        text: `Απαίτηση`,
        disabled: true,
      }
    );
  },

  data() {
    return {
      requirement: [],
      categoryType: null,
      tableLoader: false,
      certificate: null,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      breadcrumb_items: [],
      headers: [
        {
          text: "Πιστοποιητικό",
          value: "description",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        open: false,
        type: null,
        action: null,
      },
    };
  },
  watch: {
    async options() {
      await this.handler();
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentCommonOnlineApplication: (state) =>
        state.configure_applications.currentCommonOnlineApplication,
      categoryTypes: (state) => state.configure_applications.categoryTypes,
      commonOnlineApplicationActions: (state) =>
        state.configure_applications.commonOnlineApplicationActions,
      appId: (state) => state.auth.appId,
      pageChanged: (state) => state.base.pageChanged,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getCommonOnlineApplicationActions", "getCategoryTypes"]),
    openDialog(action, type) {
      this.dialog = {
        action,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        action: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.handler();
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        // axios call
        //result axios -> requirements
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/requirement/list`,
          {
            params: {
              cmnApplId: this.$route.params.common_online_application_id,
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
            },
          }
        );
        this.requirement = res.data;

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>