<template>
  <v-card class="mt-4">
    <v-card-text>
      <v-data-table
        no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
        loading-text="Φόρτωση..."
        :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }"
        :headers="headers"
        :items="results.content"
        :server-items-length="results.elements"
        :options.sync="options"
        :loading="tableLoader"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      results: [],
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        { text: "Ακαδημία", value: "aen", sortable: false },
        { text: "Σχολή", value: "school", sortable: false },
        { text: "Ακαδημαϊκό έτος", value: "year", sortable: false },
        { text: "Εξάμηνο σπουδών", value: "progTrainDescr", sortable: false },
        { text: "Τμήμα", value: "eduClass", sortable: false },
        { text: "Μάθημα", value: "lessonDescr", sortable: false }
      ],
    };
  },
  async created() {
    await this.fetchRefusal();
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser.id,
    }),
  },
  methods: {
    async fetchRefusal() {
      try {
        this.tableLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/refusal/detail/list`,
          {
            params: {
              personId: this.currentUser,
              page: 0,
              limit: 10,
            }
          }
        );
        this.tableLoader = false;
        this.results = res.data;
        console.log(this.results);
      } catch (e) {
        this.tableLoader = false;
        this.$store.dispatch("errorHandler", e);
      }
    },
  },
};
</script>

<style>
</style>