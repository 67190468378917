<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία πιστοποιητικού
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-text-field
          dense
          outlined
          v-model="password"
          label="Κωδικός"
          hide-details="auto"
          type="text"
          class="mb-4"
          :error-messages="passwordErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Κωδικός
          </template>
        </v-text-field>
        <v-textarea
          dense
          outlined
          v-model="description"
          hide-details="auto"
          label="Περιγραφή"
          :error-messages="descriptionErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Περιγραφή
          </template>
        </v-textarea>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["certificate", "schoolId"],
  created() {
    this.password = this.certificate.cd;
    this.description = this.certificate.description;
  },
  data() {
    return {
      loader: false,
      password: "",
      description: "",
    };
  },

  validations: {
    password: {
      required,
    },
    description: {
      required,
    },
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
    }),
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["editCertificate"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        await this.editCertificate({
          certificateId: this.certificate.id,
          content: {
            cd: this.password,
            description: this.description,
            // masterSchoolId: this.currentFaculty.id,
            schoolId: this.schoolId,
          },
        });
        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>