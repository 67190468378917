<template>
  <v-card class="mt-4">
    <v-card-text>
      <v-data-table
          no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
          loading-text="Φόρτωση..."
          :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }"
          :headers="headers"
          :items="ekpaideutikaTaksidia.content"
          :server-items-length="ekpaideutikaTaksidia.elements"
          :options.sync="options"
          :loading="tableLoader"
      >
        <template v-slot:[`item.dateOfShipping`]="{ item }">
          <span>{{
              formatDate(item.dateOfShipping, 'el')
            }}</span>
        </template>

        <template v-slot:[`item.dateOfDismissal`]="{ item }">
          <span>{{
              formatDate(item.dateOfDismissal, 'el')
            }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon @click="openDialog(item, 'view')">
                <v-icon color="primary" small v-bind="attrs" v-on="on">
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Προβολή</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'view'"
        max-width="1000px"
        scrollable
    >
      <ViewTrip
          v-if="dialog.open && dialog.type == 'view'"
          :trip="dialog.trip"
          @close="closeDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import {mapState, mapMutations, mapActions} from "vuex";
import ViewTrip from "../../TripModals/ViewTrip.vue";
import axios from "axios";
import {formatDate, isEmpty} from "@/helpers/helpers";
import {helperMixin} from "@/helpers/helperMixin";

export default {
  components: {
    ViewTrip,
  },
  mixins: [helperMixin],
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      dialog: {
        trip: null,
        open: false,
        type: null,
      },
      ekpaideutikaTaksidia: [],
      headers: [
        {
          text: "Ημερομηνία έναρξης",
          value: "dateOfShipping",
          sortable: false,
        },
        {
          text: "Ημερομηνία απόλυσης",
          value: "dateOfDismissal",
          sortable: false,
        },
        {text: "Βαθμός", value: "grade", sortable: false},

        {text: "Επίδοση", value: "gradeCharacterised", sortable: false},
        {
          text: "Αποτέλεσμα",
          value: "educationalTripAssessment.description",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);

          const res = await axios.get(
              `${process.env.VUE_APP_BASE_URL}/prog/trip/list`,
              {
                params: {
                  page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                  limit:
                      this.options.itemsPerPage == -1
                          ? null
                          : this.options.itemsPerPage,
                  personId: this.currentUser.id,
                },
              }
          );

          this.ekpaideutikaTaksidia = res.data;
          console.log(this.ekpaideutikaTaksidia)
          if(!isEmpty(this.ekpaideutikaTaksidia.content)){
            this.ekpaideutikaTaksidia.content.forEach(row => {
              if (!Object.prototype.hasOwnProperty.call(row, 'educationalTripAssessment')) {
                row['educationalTripAssessment'] = {description:'-'}
              }
              if (!Object.prototype.hasOwnProperty.call(row, 'nonPresEval')) {
                row['nonPresEval'] = {description:'-'}
              }
              if (!Object.prototype.hasOwnProperty.call(row, 'permanentlyDelete')) {
                row['permanentlyDelete'] = {description:'-'}
              }
              if (!Object.prototype.hasOwnProperty.call(row, 'totalMaritimeService')) {
                row['totalMaritimeService'] = "-"
              }
              if (!Object.prototype.hasOwnProperty.call(row, 'dateOfDismissal')) {
                row['dateOfDismissal'] = ""
              }
              if (!Object.prototype.hasOwnProperty.call(row, 'grade')) {
                row['grade'] = "-"
              }
              if (!Object.prototype.hasOwnProperty.call(row, 'gradeCharacterised')) {
                row['gradeCharacterised'] = "-"
              }
            })
          }

          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.ekpaideutikaTaksidia = [];
          this.$router.dispatch("errorHandler", e);

          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
    }),
  },

  methods: {
    ...mapMutations(["setFilters"]),

    openDialog(trip, type) {
      this.dialog = {
        trip,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        trip: null,
        open: false,
        type: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
</style>