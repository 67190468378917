<template>
    <v-card>
        <v-toolbar color="primary" class="white--text">
            Επεξεργασία τμήματος
            <v-btn
                    depressed
                    icon
                    color="white"
                    class="ml-auto"
                    @click="$emit('close')"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pa-4">
            <v-form class="pa-4">
                <v-row>
                    <v-col>
                        <v-text-field
                                dense
                                outlined
                                hide-details="auto"
                                v-model="description"
                                label="Όνομα"
                                class="mb-4"
                        >
                            <template v-slot:label>
                                <span class="error--text">*</span>
                                Όνομα
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col>
                    <v-select
                      dense
                      outlined
                      label="Τύπος"
                      v-model="type"
                      placeholder="Τύπος"
                      :items="getDataStaticList(56)"
                      item-text="description"
                      item-value="cd"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Τύπος
                      </template>
                    </v-select>
                  </v-col>
                </v-row> -->
                <v-row>
                    <v-col>
                        <v-select
                                dense
                                outlined
                                label="Εξάμηνο σπουδών"
                                v-model="this.progTrainPeriodId"
                                placeholder="Εξάμηνο σπουδών"
                                :items="listSemester"
                                item-text="description"
                                disabled
                                item-value="cd"
                                hide-details="auto"
                                no-data-text="Δεδομένα μη διαθέσιμα"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row v-if="this.lessonId !== undefined && true">
                    <v-col>
                        <v-select
                                dense
                                outlined
                                label="Μάθημα Εξαμήνου"
                                v-model="this.lessonId"
                                placeholder="Μάθημα Εξαμήνου"
                                :items="listCourse"
                                item-text="description"
                                item-value="cd"
                                hide-details="auto"
                                no-data-text="Δεδομένα μη διαθέσιμα"
                                disabled
                        ></v-select>
                    </v-col>
                </v-row>
                <div class="d-flex justify-center mt-4">
                    <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
                    <v-btn
                            depressed
                            color="success"
                            class="ml-4"
                            @click="onSubmit"
                            :loading="loader"
                    >Αποθήκευση
                    </v-btn
                    >
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</template>


<script>
import {mapState, mapActions} from "vuex";
import {required} from "vuelidate/lib/validators";

export default {
    name: "EditClass",
    props: ["data"],
    created() {
        if (this.data.progTrainPeriod != undefined) {
            this.progTrainPeriodId = String(this.data.progTrainPeriod.id);
        } else {
            this.progTrainPeriodId = this.progTrainPeriod;
        }
        if (this.data.lesson.id != undefined) {
            this.lessonId = String(this.data.lesson.id);
        } else {
            this.lessonId = this.lesson;
        }
        this.description = this.data.description;
        console.log(this.data)
        // this.type = this.data.type.cd;
        this.id = this.data.id;
    },
    data() {
        return {
            id: null,
            description: null,
            // type: null,
            progTrainPeriodId: null,
            lessonId: null,
            loader: false,
        };
    },
    validations: {
        description: {
            required,
        },
        // type: {
        //   required,
        // },
    },
    computed: {
        ...mapState({
            staticList: (state) => state.auth.userInfo.staticView,
            progTrainPeriod: (state) => state.generic_filter.semesterId,
            listSemester: (state) => state.generic_filter.listSemester,
            listCourse: (state) => state.generic_filter.listCourse,
        }),
        descriptionErrors() {
            const errors = [];
            if (!this.$v.description.$dirty) return errors;
            if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
            return errors;
        },
        // typeErrors() {
        //   const errors = [];
        //   if (!this.$v.type.$dirty) return errors;
        //   if (!this.$v.type.required) errors.push("Υποχρεωτικό πεδίο");
        //   return errors;
        // },
    },

    methods: {
        ...mapActions(["editClass"]),
        getDataStaticList(code) {
            return this.staticList.filter((element) => {
                if (element.lov_grp_id == code) {
                    return element;
                }
            });
        },
        async onSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) return;
            this.loader = true;
            try {
                await this.editClass({
                    progTrainPeriodId: this.progTrainPeriodId,
                    description: this.description,
                    // type: this.type,
                    id: this.id,
                });

                this.loader = false;

                this.$emit("close");
            } catch (e) {
                this.$store.dispatch("errorHandler", e);
                this.loader = false;
                console.log(e);
            }
        },
    },
};
</script>