import axios from "axios";
export default {
  state: {
    academicYears: [],
    currentAcademicYear:
      JSON.parse(localStorage.getItem("academicYear")) || null,
  },

  mutations: {
    getAcademicYears(state, academic_years) {
      state.academicYears = academic_years;
    },
    setCurrentAcademicYear(state, academicYear) {
      state.currentAcademicYear = academicYear;
      localStorage.setItem("academicYear", JSON.stringify(academicYear));
    },
  },

  actions: {
    async getAcademicYears({ commit }, payload) {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/year/list`,
          {
            params: {
              page: payload.page,
              limit: payload.limit,
              schoolId: payload.id,
            },
          }
        );
        commit("getAcademicYears", res.data);
      } catch (e) {
        throw e;
      }
    },

    async addAcademicYear({ commit, dispatch }, payload) {
      try {
        await axios.post(`${process.env.VUE_APP_BASE_URL}/year`, payload);
        // await dispatch("getAcademicYears", {
        //   id: payload.schoolId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.academicYears.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία ακαδημαϊκού έτους ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async editAcademicYear({ commit, dispatch }, payload) {
      try {
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/year/${payload.id}`,
          payload.content
        );
        // await dispatch("getAcademicYears", {
        //   id: payload.content.schoolId,
        //   page: rootState.base.filters.page,
        //   limit:
        //     rootState.base.filters.itemsPerPage == -1
        //       ? state.academicYears.elements
        //       : rootState.base.filters.itemsPerPage,
        // });
        commit("toggleSnackbar", {
          open: true,
          text: "Η επεξεργασία εκπαιδευτικού έτους ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },

    async deleteAcademicYear({ commit, dispatch, rootState, state }, payload) {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_URL}/year/${payload.academicYearId}`
        );
        if (state.academicYears.limit == 1 && state.academicYears.page != 1) {
          commit("changePage", true);
        } else {
          //   await dispatch("getAcademicYears", {
          //     id: payload.schoolId,
          //     page: rootState.base.filters.page,
          //     limit:
          //       rootState.base.filters.itemsPerPage == -1
          //         ? state.academicYears.elements
          //         : rootState.base.filters.itemsPerPage,
          //   });
        }
        commit("toggleSnackbar", {
          open: true,
          text: "Η διαγραφή εκπαιδευτικού έτους ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
      } catch (e) {
        dispatch("errorHandler", e);

        throw e;
      }
    },
  },
};
