<template>
  <v-card class="mb-2 pa-2">
    <v-card-text class="d-flex flex-column">
      <v-row>
        <v-col v-if="this.level >= 1" cols="12" sm="6" md="3">
          <v-select dense outlined clearable :label="'Σχολές'" v-model="selectedFaculty" :items="listFaculty"
                    item-text="description" hide-details="auto" item-value="cd" @change="onSetFaculty"
                    :error-messages="selectedFacultyErrors" no-data-text="Δεδομένα μη διαθέσιμα">
            <template v-slot:label v-if="requiredFields.masterSchool">
              <span class="error--text">*</span>{{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
            </template>
          </v-select>
        </v-col>

        <v-col v-if="this.level >= 2" cols="12" sm="6" md="3">
          <v-select dense outlined clearable :label="'Σχολή'" v-model="selectedSchool" :items="listSchool"
                    item-text="description" hide-details="auto" item-value="cd" @change="onSetSchool"
                    :error-messages="selectedSchoolErrors" no-data-text="Δεδομένα μη διαθέσιμα">
            <template v-slot:label v-if="requiredFields.school">
              <span class="error--text">*</span>
              {{ appId == 1 ? "Σχολή" : getFieldName() }}
            </template>
          </v-select>
        </v-col>
        <v-col v-if="
          (this.level >= 1 && $route.path.split('/')[1] == 'teachers')/* ||
          $route.path.split('/')[1] == 'students'*/
        " cols="12" sm="6" md="3">
          <v-select dense outlined clearable label="Ενεργός" v-model="active" hide-details="auto"
                    :items="getDataStaticList(100)" item-text="description" item-value="cd"
                    no-data-text="Δεδομένα μη διαθέσιμα"></v-select>
        </v-col>
        <v-col v-if="this.level >= 3 && this.$route.path != '/announcements'" cols="12" sm="6" md="3">
          <v-select dense clearable outlined :label="'Ακαδημαϊκό έτος'" v-model="selectedAcademicYear"
                    :items="listAcademicYear" item-text="description" hide-details="auto" item-value="cd"
                    no-data-text="Δεδομένα μη διαθέσιμα" @change="onSetAcademicYear"
                    :error-messages="selectedAcademicYearErrors">
            <template v-slot:label v-if="requiredFields.year">
              <span class="error--text">*</span>{{ appId == 1 ? "Ακαδημαϊκό έτος" : "Εκπαιδευτικό έτος" }}
            </template>
          </v-select>
        </v-col>
        <v-col v-if="this.$route.path.split('/')[1] == 'booking'">
          <v-select dense clearable outlined :label="'Σειρά'" v-model="selectedSeries" :items="listSeries"
                    item-text="description" hide-details="auto" item-value="cd" no-data-text="Δεδομένα μη διαθέσιμα"
                    :error-messages="selectedSeriesErrors">
            <template v-slot:label>
              <span class="error--text">*</span>Σειρά
            </template>
          </v-select>
        </v-col>
        <v-col v-if="this.level >= 4 && this.$route.path != '/announcements'" cols="12" sm="6" md="3">
          <v-select dense outlined clearable :label="appId == 1 ? 'Εξάμηνο σπουδών' : 'Κύκλος σπουδών'"
                    v-model="selectedSemester" hide-details="auto" :items="listSemester" item-text="description"
                    no-data-text="Δεδομένα μη διαθέσιμα" item-value="cd" @change="onSetSemester"
                    :error-messages="selectedSemesterErrors">
            <template v-slot:label v-if="requiredFields.progTrainPeriod">
              <span class="error--text">*</span>{{ appId == 1 ? "Εξάμηνο σπουδών" : "Κύκλο σπουδών" }}
            </template>
          </v-select>
        </v-col>
        <v-col v-if="this.level >= 4 && this.$route.path == '/announcements'" cols="12" sm="6" md="3">
          <v-select dense outlined clearable :label="appId == 1 ? 'Έτος-Εξάμηνο σπουδών' : 'Έτος-κύκλος σπουδών'"
                    v-model="selectedYearSemester" hide-details="auto" :items="listYearSemester" item-text="description"
                    item-value="cd" no-data-text="Δεδομένα μη διαθέσιμα" @change="onSetYearSemester"
                    :error-messages="selectedYearSemesterErrors">
            <template v-slot:label v-if="requiredFields.progTrainPeriodYear">
              <span class="error--text">*</span>{{ appId == 1 ? "Έτος-Εξάμηνο σπουδών" : "Έτος-Κύκλος σπουδών" }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="3" v-if="
          this.$route.path.split('/')[1] == `absences` ||
          this.$route.path.split('/')[1] == `massiveAbsences` ||
          this.$route.path.split('/')[1] == 'teacher_hours'
        ">
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined v-model="dateFormatted" v-bind="attrs" hide-details="auto"
                            :error-messages="dateFormattedErrors" v-on="on" class="mb-4"
                            @blur="date = parseDate(dateFormatted, 'date')">
                <template v-slot:prepend-inner>
                  <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                </template>
                <template v-slot:label>
                  <span v-if="requiredFields.date" class="error--text">*</span>
                  Ημερομηνία
                </template>
              </v-text-field>
            </template>
            <v-date-picker locale="el" hide-details="auto" v-model="date" scrollable
                           :error-messages="dateFormattedErrors" @input="menu = false">
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col v-if="this.level >= 5" cols="12" sm="6" md="3">
          <v-select dense outlined clearable :label="'Τμήμα'" v-model="selectedClass" hide-details="auto"
                    :items="listClass" item-text="description" no-data-text="Δεδομένα μη διαθέσιμα" item-value="cd"
                    :error-messages="selectedClassErrors">
            <template v-slot:label v-if="requiredFields.class">
              <span class="error--text">*</span>Τμήμα
            </template>
          </v-select>
        </v-col>

        <v-col v-if="
          this.level >= 6 &&
          this.$route.path != `/massiveAbsences/${this.kindId}` &&
          this.$route.path != `/absences_approve/${this.kindId}` &&
          this.$route.path != `/absences/${this.kindId}`
        " cols="12" sm="6" md="3">
          <v-select dense outlined clearable :label="'Μαθήματα'" v-model="selectedCourse" hide-details="auto"
                    :items="listCourse" item-text="description" no-data-text="Δεδομένα μη διαθέσιμα" item-value="cd"
                    :error-messages="selectedCourseErrors">
            <template v-slot:label v-if="requiredFields.lesson">
              <span class="error--text">*</span>Μαθήματα
            </template>
          </v-select>
        </v-col>
        <v-col v-if="
          this.level >= 6 &&
          (this.$route.path == `/massiveAbsences/${this.kindId}` ||
            this.$route.path == `/absences_approve/${this.kindId}` ||
            this.$route.path == `/absences/${this.kindId}`) &&
          this.schoolDetails != null &&
          this.schoolDetails.absence.cd == '1'
        " cols="12" sm="6" md="3">
          <v-select dense outlined clearable :label="'Μαθήματα'" v-model="selectedCourse" hide-details="auto"
                    :items="listCourse" item-text="description" no-data-text="Δεδομένα μη διαθέσιμα" item-value="cd"
                    :error-messages="selectedCourseErrors">
            <template v-slot:label v-if="requiredFields.lesson">
              <span class="error--text">*</span>Μαθήματα
            </template>
          </v-select>
        </v-col>

        <v-col v-if="search" cols="12" sm="6">
          <v-text-field dense outlined clearable :label="
            this.$route.path == '/announcements'
              ? 'Τίτλος, Μήνυμα'
              : 'ΑΜ, Όνομα, Επώνυμο'
          " v-model="searchText" hide-details="auto" @keyup.enter="onSearch">
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3" v-if="this.$route.path.split('/')[1] == 'archive_teaching'">
          <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined v-model="dateFromFormatted" v-bind="attrs" hide-details="auto" v-on="on"
                            class="mb-4" @blur="
                  selectedDateFrom = parseDate(dateFromFormatted, 'dateFrom')
                ">
                <template v-slot:prepend-inner>
                  <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                </template>
                <template v-slot:label>
                  <span v-if="requiredFields.date" class="error--text">*</span>
                  Ημερομηνία Απο
                </template>
              </v-text-field>
            </template>
            <v-date-picker locale="el" hide-details="auto" v-model="selectedDateFrom" scrollable @input="menu1 = false">
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6" md="3" v-if="this.$route.path.split('/')[1] == 'archive_teaching'">
          <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined v-model="dateToFormatted" v-bind="attrs" hide-details="auto" v-on="on"
                            class="mb-4" @blur="selectedDateTo = parseDate(dateToFormatted, 'dateTo')">
                <template v-slot:prepend-inner>
                  <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                </template>
                <template v-slot:label>
                  <span v-if="requiredFields.date" class="error--text">*</span>
                  Ημερομηνία Εως
                </template>
              </v-text-field>
            </template>
            <v-date-picker locale="el" hide-details="auto" v-model="selectedDateTo" scrollable @input="menu2 = false">
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-btn class="ml-auto mt-2" depressed color="primary" @click="this.onSearch">
        Αναζήτηση
      </v-btn>
    </v-card-text>
  </v-card>
</template>


<script>
import {mapState, mapMutations} from "vuex";
import axios from "axios";
import {required, requiredIf} from "vuelidate/lib/validators";
import Helpers from "../../helpers/helpers";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
      /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: {
    level: Number,
    onSearchAction: Function,
    listActive: Number,
    requiredFields: Object,
    search: Boolean,
  }, //level: level of lists,onSearchAction: function on click search,listActive: prop to know the end point group id (only active or all data)
  async created() {
    await this.loadFaculties();
    console.log('schoolDetails', this.schoolDetails)
  },
  data() {
    return {
      menu: null,
      menu1: null,
      menu2: null,
      date: null,
      dateFormatted: null,
      dateFromFormatted: null,
      dateToFormatted: null,
      // schoolDetails: null,
    };
  },

  validations: {
    selectedFaculty: {
      required: requiredIf(function () {
        return this.requiredFields?.masterSchool;
      }),
    },

    selectedSchool: {
      required: requiredIf(function () {
        return this.requiredFields?.school;
      }),
    },

    selectedYearSemester: {
      required: requiredIf(function () {
        return this.requiredFields?.progTrainPeriodYear;
      }),
    },
    selectedAcademicYear: {
      required: requiredIf(function () {
        return this.requiredFields?.year;
      }),
    },
    selectedSeries: {
      required: requiredIf(function () {
        return this.$route.path.split("/")[1] == "booking";
      }),
    },

    selectedSemester: {
      required: requiredIf(function () {
        return this.requiredFields?.progTrainPeriod;
      }),
    },

    selectedClass: {
      required: requiredIf(function () {
        return this.requiredFields?.class;
      }),
    },

    selectedCourse: {
      required: requiredIf(function () {
        return this.requiredFields?.lesson;
      }),
    },

    dateFormatted: {
      required: requiredIf(function () {
        return this.requiredFields?.date;
      }),
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    selectedDateFrom(val) {
      this.dateFromFormatted = this.formatDate(this.selectedDateFrom);
    },
    selectedDateTo(val) {
      this.dateToFormatted = this.formatDate(this.selectedDateTo);
    },
  },

  computed: {
    ...mapState({
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
      listFaculty: (state) => state.generic_filter.listFaculty,
      listSchool: (state) => state.generic_filter.listSchool,
      listYearSemester: (state) => state.generic_filter.listYearSemester,
      listAcademicYear: (state) => state.generic_filter.listAcademicYear,
      listSemester: (state) => state.generic_filter.listSemester,
      listExam: (state) => state.generic_filter.listExam,
      listClass: (state) => state.generic_filter.listClass,
      listCourse: (state) => state.generic_filter.listCourse,
      listSeries: (state) => state.generic_filter.listSeries,
      staticList: (state) => state.auth.userInfo.staticView,
      schoolDetails: (state) => state.generic_filter.schoolDetails,
      kindId: (state) => state.auth.kindId,
    }),

    active: {
      get() {
        return this.$store.state.generic_filter.active;
      },
      set(value) {
        this.setActive(value);
      },
    },

    searchText: {
      get() {
        return this.$store.state.generic_filter.searchText;
      },
      set(value) {
        this.setSearchText(value);
      },
    },

    selectedFaculty: {
      get() {
        return this.$store.state.generic_filter.facultyId;
      },
      set(value) {
        this.setFaculty(value);
      },
    },

    selectedSchool: {
      get() {
        return this.$store.state.generic_filter.schoolId;
      },
      set(value) {
        this.setSchool(value);
      },
    },
    selectedYearSemester: {
      get() {
        return this.$store.state.generic_filter.yearSemesterId;
      },
      set(value) {
        this.setYearSemester(value);
      },
    },
    selectedSeries: {
      get() {
        return this.$store.state.generic_filter.series;
      },
      set(value) {
        this.setSeries(value);
      },
    },
    selectedDateFrom: {
      get() {
        return this.$store.state.generic_filter.dateFrom;
      },
      set(value) {
        this.setDateFrom(value);
      },
    },
    selectedDateTo: {
      get() {
        return this.$store.state.generic_filter.dateTo;
      },
      set(value) {
        this.setDateTo(value);
      },
    },
    selectedAcademicYear: {
      get() {
        return this.$store.state.generic_filter.academicYearId;
      },
      set(value) {
        this.setAcademicYear(value);
      },
    },

    selectedSemester: {
      get() {
        return this.$store.state.generic_filter.semesterId;
      },
      set(value) {
        this.setSemester(value);
      },
    },

    selectedExam: {
      get() {
        return this.$store.state.generic_filter.examId;
      },
      set(value) {
        this.setExam(value);
      },
    },

    selectedCourse: {
      get() {
        return this.$store.state.generic_filter.courseId;
      },
      set(value) {
        this.setCourse(value);
      },
    },

    selectedClass: {
      get() {
        return this.$store.state.generic_filter.classId;
      },
      set(value) {
        this.setClass(value);
      },
    },

    selectedFacultyErrors() {
      const errors = [];
      if (!this.$v.selectedFaculty.$dirty) return errors;
      if (!this.$v.selectedFaculty.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedSchoolErrors() {
      const errors = [];
      if (!this.$v.selectedSchool.$dirty) return errors;
      if (!this.$v.selectedSchool.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedAcademicYearErrors() {
      const errors = [];
      if (!this.$v.selectedAcademicYear.$dirty) return errors;
      if (!this.$v.selectedAcademicYear.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedSeriesErrors() {
      const errors = [];
      if (!this.$v.selectedSeries.$dirty) return errors;
      if (!this.$v.selectedSeries.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedYearSemesterErrors() {
      const errors = [];
      if (!this.$v.selectedSemester.$dirty) return errors;
      if (!this.$v.selectedSemester.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedSemesterErrors() {
      const errors = [];
      if (!this.$v.selectedSemester.$dirty) return errors;
      if (!this.$v.selectedSemester.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedClassErrors() {
      const errors = [];
      if (!this.$v.selectedClass.$dirty) return errors;
      if (!this.$v.selectedClass.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedCourseErrors() {
      const errors = [];
      if (!this.$v.selectedCourse.$dirty) return errors;
      if (!this.$v.selectedCourse.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    dateFormattedErrors() {
      const errors = [];
      if (!this.$v.dateFormatted.$dirty) return errors;
      if (!this.$v.dateFormatted.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapMutations([
      "getListFaculty",
      "getListSchool",
      "getListAcademicYear",
      "getListYearSemester",
      "getListSemester",
      "getListExam",
      "getListClass",
      "getListCourse",
      "getListSeries",
      "setFaculty",
      "setSchool",
      "setAcademicYear",
      "setSemester",
      "setYearSemester",
      "setExam",
      "setCourse",
      "setClass",
      "setActive",
      "setSearchText",
      "setSchoolDetails",
      "setMaxDept",
      "setProgTrainDetails",
      "setDateFrom",
      "setDateTo",
      "setSeries",
    ]),

    async loadFaculties() {
      let result = await this.fetchDataDynamic(
          this.listActive == 1 ? 2020 : 2000,
          null
      );

      this.getListFaculty(result.data);
      if (result.data.length == 1) {
        this.selectedFaculty = result.data[0].cd;
        await this.onSetFaculty(result.data[0].cd);
      }
    },

    getFieldName() {
      return Helpers.getFieldName(1);
    },

    async onSetFaculty(value) {
      this.setSchool(null);
      this.setAcademicYear(null);
      this.setSemester(null);
      this.setClass(null);
      this.setCourse(null);

      this.getListAcademicYear([]);
      this.getListSemester([]);
      this.getListClass([]);
      this.getListCourse([]);
      // call end point tou get schools 2001
      let result = await this.fetchDataDynamic(
          this.listActive == 1 ? 2021 : 2001,
          value
      );
      this.getListSchool(result.data);
      if (result.data.length == 1) {
        this.selectedSchool = result.data[0].cd;
        this.onSetSchool(result.data[0].cd);
      }
    },

    async onSetSchool(value) {
      this.setAcademicYear(null);
      this.setSemester(null);
      this.setClass(null);
      this.setCourse(null);
      this.setYearSemester(null);

      this.getListSemester([]);
      this.getListClass([]);
      this.getListCourse([]);
      this.getListYearSemester([]);

      await this.fetchSchoolProt(value);

      if (this.$route.path.split("/")[1] == 'exams') {
        let result = await this.fetchDataDynamic(2007, value);
        this.getListSemester(result.data);
      }
      if (this.$route.path != "/announcements") {
        let result = await this.fetchDataDynamic(
            this.listActive == 1 ? 2022 : 2002,
            value
        );
        this.getListAcademicYear(result.data);
        if (result.data.length == 1) {
          this.selectedAcademicYear = result.data[0].cd;
          await this.onSetAcademicYear(result.data[0].cd);
        }
      } else {
        let result = await this.fetchDataDynamic(2015, value);
        if (result.data.length == 1) {
          this.selectedYearSemester = result.data[0].cd;
          await this.onSetYearSemester(result.data[0].cd);
        }
        this.getListYearSemester(result.data);
      }
    },

    async onSetAcademicYear(value) {
      this.setSemester(null);
      this.setClass(null);
      this.setCourse(null);

      this.getListClass([]);
      this.getListCourse([]);
      if (this.$route.path.split("/")[1] != 'exams') {
        let result = await this.fetchDataDynamic(
            this.$route.path.split("/")[1] == "oral" ||
            this.$route.path == "/oral_approve" ||
            this.listActive == 1
                ? 2023
                : this.$route.path == "/prog_trips"
                    ? 2014
                    : 2003,
            value
        );
        if (result.data.length == 1) {
          this.selectedSemester = result.data[0].cd;
          this.onSetSemester(result.data[0].cd);
        }

        this.getListSemester(result.data);
      }

      if (this.$route.path.split("/")[1] == "booking") {
        let result = await this.fetchDataDynamic(2428, value);

        if (result.data.length == 1) {
          this.selectedSeries = result.data[0].cd;
        }
        this.getListSeries(result.data);
      }
    },
    async onSetYearSemester(value) {
      this.setClass(null);
      this.setCourse(null);

      // fetch class
      let result = await this.fetchDataDynamic(
          this.listActive == 1 ? 2024 : 2004,
          value
      );
      this.getListClass(result.data);
      if (result.data.length == 1) {
        this.selectedClass = result.data[0].cd;
      }

      // fetch Courses
      let lessons = await this.fetchDataDynamic(
          this.listActive == 1 ? 2028 : 2008, //2025 : 2005,
          value
      );
      this.getListCourse(lessons.data);
      if (lessons.data.length == 1) {
        this.selectedCourse = result.data[0].cd;
      }
    },
    async onSetSemester(value) {
      this.setClass(null);
      this.setCourse(null);

      // fetch class
      let result = await this.fetchDataDynamic(
          this.listActive == 1 ? 2024 : 2004,
          value
      );
      this.getListClass(result.data);
      if (result.data.length == 1) {
        this.selectedClass = result.data[0].cd;
      }

      // fetch Courses
      let lessons = await this.fetchDataDynamic(
          this.listActive == 1 ? 2028 : 2008, //2025 : 2005,
          value
      );
      this.getListCourse(lessons.data);

      if (lessons.data.length == 1) {
        this.selectedCourse = result.data[0].cd;
      }
      if (this.$route.path.split("/")[1] != 'exams') {
        this.fetchSemesterInfo(value);
      }

    },
    async fetchSemesterInfo(value) {
      try {
        console.log(value);
        if (value != null) {
          const res = await axios.get(
              `${process.env.VUE_APP_BASE_URL}/prog/train/${value}`
          );
          // this.studentPart = res.data.studentPart.cd;
          this.setProgTrainDetails(res.data);
          this.setMaxDept(res.data.trainingPeriod.maxDept);
        }
      } catch (e) {
        return [];
      }
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
            body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
    onSearch() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let payload = {
        facultyId: this.selectedFaculty,
        schoolId: this.selectedSchool,
        academicYearId: this.selectedAcademicYear,
        semesterId: this.selectedSemester,
        classId: this.selectedClass,
        courseId: this.selectedCourse,
        dates: this.date,
        active: this.active,
        searchText: this.searchText,
      };
      this.$store.dispatch("setAllFilter", payload).then(() => {
        this.$emit("onSearchAction");
      });
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "absDate") {
          this.absDate = null;
          this.absDateFormatted = null;
        } else if (dateName == "dateFrom") {
          this.selectedDateFrom = null;
          this.dateFromFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async fetchSchoolProt(schoolId) {
      try {
        const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/combine/${schoolId}`
        );
        this.setSchoolDetails(res.data.school);
        // this.schoolDetails = res.data.school;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style>
</style>