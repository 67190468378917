<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία εκπροσώπου / εκπαιδευτή εταιρίας
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="name"
              type="text"
              :error-messages="nameErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Επωνυμία
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6"> </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="region"
              type="text"
              hide-details="auto"
              :error-messages="regionErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Νομός
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="city"
              type="text"
              hide-details="auto"
              :error-messages="cityErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Πόλη
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="streetName"
              type="text"
              :error-messages="streetNameErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Οδός
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="number"
              type="text"
              hide-details="auto"
              :error-messages="numberErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Αριθμός
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="zipCode"
              type="text"
              :error-messages="zipCodeErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τ.Κ
                <div class=""></div>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4"></v-col>

          <v-col cols="12" sm="6" md="4">
            <v-textarea
              dense
              outlined
              hide-details="auto"
              v-model="memo"
              label="Σχόλια"
              type="text"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      loader: false,

      city: null,
      memo: null,
      name: null,
      number: null,
      region: null,
      streetName: null,
      zipCode: null,
    };
  },

  validations: {
    city: {
      required,
    },

    name: {
      required,
    },

    number: {
      required,
      numeric,
    },

    region: {
      required,
    },

    streetName: {
      required,
    },

    zipCode: {
      required,
      numeric,
    },
  },

  computed: {
    ...mapState({}),
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      if (!this.$v.city.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    numberErrors() {
      const errors = [];
      if (!this.$v.number.$dirty) return errors;
      if (!this.$v.number.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.number.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    regionErrors() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      if (!this.$v.region.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    streetNameErrors() {
      const errors = [];
      if (!this.$v.streetName.$dirty) return errors;
      if (!this.$v.streetName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    zipCodeErrors() {
      const errors = [];
      if (!this.$v.zipCode.$dirty) return errors;
      if (!this.$v.zipCode.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.zipCode.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addShipCompany"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          city: this.city,
          name: this.name,
          number: this.number,
          region: this.region,
          streetName: this.streetName,
          zipCode: this.zipCode,
        };

        if (this.memo != null) content.memo = this.memo;

        await this.addShipCompany(content);
        this.loader = false;
        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>