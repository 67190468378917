<template>
  <v-card
    color="transparent"
    class="component-wrapper d-flex align-center justify-center"
  >
    <v-card
      v-if="!pageLoader"
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4"
    >
      <v-breadcrumbs :items="breadCrumbItems" divider=">"> </v-breadcrumbs>

      <v-tabs center-active show-arrows>
        <v-tab v-for="(tab, i) in tabs" :key="i" :to="tab.path" exact>{{
          tab.name
        }}</v-tab>
      </v-tabs>
      <router-view></router-view>
    </v-card>

    <v-progress-circular
      v-else
      indeterminate
      color="primary"
      class="ml-auto mr-auto mt-auto mb-auto"
    ></v-progress-circular>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  async created() {
    try {
      if (this.$route.path.split("/")[1] == "teacherCard") {
        console.log("person " + this.personId);
        await this.setCurrentTeacher(this.personId);
        this.tabs.push(
          {
            name: "Προσωπικα στοιχεια",
            path: `/faculties/1/teachers/${this.currentTeacher.id}/viewteacher/proswpika_stoixeia`,
          },
          {
            name: "Εγγραφες",
            path: `/faculties/1/teachers/${this.currentTeacher.id}/viewteacher/eggrafes`,
          },
          {
            name: "Αιτησεις",
            path: `/faculties/1/teachers/${this.currentTeacher.id}/viewteacher/applications`,
          },
          {
            name: "Εμπειρια",
            path: `/faculties/1/teachers/${this.currentTeacher.id}/viewteacher/experience`,
          }
        );
      } else {
        await this.setCurrentTeacher(this.$route.params.teacher_id);
      }
      this.pageLoader = true;

      if (this.$route.path.split("/")[1] == "faculties") {
        this.breadCrumbItems.push(
          {
            text: `${this.currentFaculty.description}`,
            disabled: false,
            link: true,
            exact: true,
            to: "/faculties",
          },
          {
            text: `${this.currentTeacher.firstName} ${this.currentTeacher.lastName}`,
            disabled: false,
            link: true,
            exact: true,
            to: `/faculties/${this.currentFaculty.id}/teachers`,
          },
          {
            text: `Προβολή εκπαιδευτή`,
            disabled: true,
            to: `/faculties/${this.currentFaculty.id}/teachers`,
          }
        );

        this.tabs.push(
          {
            name: "Προσωπικα στοιχεια",
            path: `/faculties/${this.currentFaculty.id}/teachers/${this.currentTeacher.id}/viewteacher/proswpika_stoixeia`,
          },
          {
            name: "Εγγραφες",
            path: `/faculties/${this.currentFaculty.id}/teachers/${this.currentTeacher.id}/viewteacher/eggrafes`,
          },

          {
            name: "Αιτησεις",
            path: `/faculties/${this.currentFaculty.id}/teachers/${this.currentTeacher.id}/viewteacher/applications`,
          },

          {
            name: "Εμπειρια",
            path: `/faculties/${this.currentFaculty.id}/teachers/${this.currentTeacher.id}/viewteacher/experience`,
          }
        );
      } else if (this.$route.path.split("/")[1] == "teachers") {
        this.breadCrumbItems.push(
          {
            text: `${this.currentTeacher.firstName} ${this.currentTeacher.lastName}`,
            disabled: false,
            link: true,
            exact: true,
            to: `/teachers`,
          },
          {
            text: `Προβολή εκπαιδευτικού`,
            disabled: true,
          }
        );

        this.tabs.push(
          {
            name: "Προσωπικα στοιχεια",
            path: `/teachers/${this.currentTeacher.id}/viewteacher/proswpika_stoixeia`,
          },
          {
            name: "Εγγραφες",
            path: `/teachers/${this.currentTeacher.id}/viewteacher/eggrafes`,
          },

          {
            name: "Αιτησεις",
            path: `/teachers/${this.currentTeacher.id}/viewteacher/applications`,
          },
          {
            name: "Εμπειρια",
            path: `/teachers/${this.currentTeacher.id}/viewteacher/experience`,
          }
        );
      } else if (this.$route.path.split("/")[1] == "teacher_hours") {
        this.breadCrumbItems.push(
          {
            text: `${this.currentTeacher.firstName} ${this.currentTeacher.lastName}`,
            disabled: false,
            link: true,
            exact: true,
            to: `/teacher_hours`,
          },
          {
            text: `Προβολή εκπαιδευτικού`,
            disabled: true,
          }
        );

        this.tabs.push(
          {
            name: "Προσωπικα στοιχεια",
            path: `/teacher_hours/${this.currentTeacher.id}/viewteacher/proswpika_stoixeia`,
          },
          {
            name: "Εγγραφες",
            path: `/teacher_hours/${this.currentTeacher.id}/viewteacher/eggrafes`,
          },

          {
            name: "Αιτησεις",
            path: `/teacher_hours/${this.currentTeacher.id}/viewteacher/applications`,
          },
          {
            name: "Εμπειρια",
            path: `/teacher_hours/${this.currentTeacher.id}/viewteacher/experience`,
          }
        );
      }
      this.pageLoader = false;
    } catch (e) {
      this.$store.dispatch("errorHandler", e);

      console.log(e);
    }
  },

  data() {
    return {
      tabs: [],
      breadCrumbItems: [],
      pageLoader: true,
    };
  },

  computed: {
    ...mapState({
      currentTeacher: (state) => state.teachers.currentTeacher,
      currentFaculty: (state) => state.faculties.currentFaculty,
      personId: (state) => state.auth.userInfo.person.id,
    }),
  },

  methods: {
    ...mapActions(["setCurrentTeacher"]),
  },
};
</script>

<style scoped lang="scss">
a.v-tab--active.v-tab {
  background: var(--v-primary-base);
}
</style>