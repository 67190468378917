
 <template>
  <div style="width: 100%; height: 100%">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="userRoles"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <div class="ml-auto">
        <v-btn depressed color="light" class="mr-2" @click="$emit('close')">
          Πίσω
        </v-btn>
        <v-btn
          depressed
          color="primary"
          class="mr-2"
          @click="openDialog(null, 'add')"
        >
          Δημιουργία
          <v-icon dense right>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="800px"
      scrollable
    >
      <AddRole
        v-if="dialog.open && dialog.type == 'add'"
        :userRoles="roleList"
        :user="user"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="800px"
      scrollable
    >
      <EditRole
        v-if="dialog.open && dialog.type == 'edit'"
        :role="dialog.role"
        :user="user"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteRole
        v-if="dialog.open && dialog.type == 'delete'"
        :role="dialog.role"
        :user="user"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import AddRole from "../../components/UserModals/Roles/AddRole.vue";
import EditRole from "../../components/UserModals/Roles/EditRole.vue";
import DeleteRole from "../../components/UserModals/Roles/DeleteRole.vue";

export default {
  components: {
    AddRole,
    EditRole,
    DeleteRole,
  },
  props: ["user", "path"],
  created() {
    console.log(this.user);

    this.breadcrumb_items.push(
      {
        text: `${this.user.firstName + " " + this.user.lastName}`,
        disabled: false,
        link: true,
        exact: true,
        to: this.path,
      },
      {
        text: `Ρόλοι`,
        disabled: true,
      }
    );
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      breadcrumb_items: [],
      userRoles: [],
      headers: [
        {
          text: "Όνομα",
          value: "description",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        role: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
  },

  computed: {
    ...mapState({
      // userRoles: (state) => state.user_roles.userRoles,
      pageChanged: (state) => state.base.pageChanged,
      currentUser: (state) => state.users.currentUser,
    }),
    roleList() {
      let result = this.userRoles.map((r) => {
        return r.id;
      });
      return result;
    },
  },

  methods: {
    ...mapMutations(["changePage"]),
    ...mapActions(["getUserRoles"]),

    async handler() {
      try {
        this.tableLoader = true;
        // await this.getUserRoles({
        //   page: this.options.page,
        //   limit:
        //     this.options.itemsPerPage == -1
        //       ? this.userRoles.elements
        //       : this.options.itemsPerPage,
        //   personId: this.user.id,
        // });
        await this.fetchUserRoles();
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.tableLoader = false;
      }
    },
    async fetchUserRoles() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/list/roles`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              personId: this.user.id,
            },
          }
        );
        console.log(res.data);
        if (res.data.content.length > 0) {
          this.userRoles = res.data.content[0]?.roles;
        } else {
          this.userRoles = [];
        }
      } catch (e) {
        throw e;
      }
    },
    openDialog(role, type) {
      this.dialog = {
        role,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        role: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchUserRoles();
    },
  },
};
</script>

<style scoped lang="scss">
</style>