<template>
    <div class="component-wrapper d-flex align-center justify-center">
        <v-card
                max-width="1400px"
                width="100%"
                height="100%"
                class="pa-4 d-flex flex-column"
                elevation="0"
                color="#EAF1F5"
        >
            <GenericFilter
                    :level="6"
                    :listActive="1"
                    :key="query"
                    @onSearchAction="onSearch"
                    :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: false,
          lesson: false,
          examLesson: false,
        }"
            />

            <v-card class="mb-4">
                <v-card-text class="pa-4">
                    <v-data-table
                            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
                            loading-text="Φόρτωση..."
                            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
                            :headers="headers"
                            :items="classes.content"
                            :options.sync="options"
                            :server-items-length="classes.elements"
                            :loading="tableLoader"
                    >
                        <template v-if="lessonId != null" v-slot:[`item.lesson`]="{ item }">
                            {{ item.lesson.description }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn depressed icon @click="onViewStudent(item)">
                                        <v-icon color="primary" small v-bind="attrs" v-on="on">
                                            mdi-eye
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Σπουδαστές</span>
                            </v-tooltip>
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn depressed icon @click="openDialog(item, 'edit')">
                                        <v-icon color="primary" small v-bind="attrs" v-on="on">
                                            mdi-pen
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Επεξεργασία</span>
                            </v-tooltip>
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn depressed icon @click="openDialog(item, 'delete')">
                                        <v-icon color="error" small v-bind="attrs" v-on="on">
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Διαγραφή</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card-text>
                <div class="custom-card-buttons">
                    <v-btn
                            depressed
                            color="primary ml-auto"
                            @click="openDialog(null, 'add')"
                            :disabled="isDisabled"
                    >
                        Δημιουργία
                        <v-icon dense right>mdi-plus</v-icon>
                    </v-btn>
                </div>
            </v-card>
        </v-card>
        <v-dialog
                persistent
                v-model="dialog.open"
                v-if="dialog.open && dialog.type == 'add'"
                max-width="500px"
                scrollable
        >
            <AddClass v-if="dialog.type == 'add'" @close="closeDialog"/>
        </v-dialog>

        <v-dialog
                persistent
                v-model="dialog.open"
                v-if="dialog.open && dialog.type == 'edit'"
                max-width="500px"
                scrollable
        >
            <EditClass
                    v-if="dialog.open && dialog.type == 'edit'"
                    @close="closeDialog"
                    :data="dialog.data"
            />
        </v-dialog>

        <v-dialog
                persistent
                v-model="dialog.open"
                v-if="dialog.open && dialog.type == 'delete'"
                max-width="500px"
                scrollable
        >
            <DeleteClass
                    v-if="dialog.open && dialog.type == 'delete'"
                    @close="closeDialog"
                    :data="dialog.data"
            />
        </v-dialog>
    </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import axios from "axios";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddClass from "../../components/ClassesModals/AddClass.vue";
import EditClass from "../../components/ClassesModals/EditClass.vue";
import DeleteClass from "../../components/ClassesModals/DeleteClass.vue";
import ExamFilter from "@/components/GenericFilter/ExamFilter.vue";

export default {
    components: {
        GenericFilter,
        AddClass,
        EditClass,
        DeleteClass,
    },

    async created() {
        try {
            if (this.semesterId != null) await this.handler();
        } catch (e) {
            throw e;
        }
    },

    data() {
        return {
            tableLoader: false,
            query: false,
            options: {
                itemsPerPage: 10,
                page: 1,
            },
            classes: [],
            filtersSet: false,
            dialog: {
                data: null,
                open: false,
                type: null,
            },
            prevRouter: null,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.path != "/classes/" + from.params?.class_id + "/students") {
                vm.clearGeneric();
            }
        });
    },
    watch: {
        async options() {
            if (this.filtersSet) await this.handler();
        },
        $route() {
            this.query = this.$route.params.id;
        },
    },

    computed: {
        ...mapState({
            pageChanged: (state) => state.base.pageChanged,
            semesterId: (state) => state.generic_filter.semesterId,
            lessonId: (state) => state.generic_filter.courseId,
        }),
        isDisabled() {
            if (this.filtersSet) {
                if (this.semesterId != "" && this.semesterId != null) {
                    return false;
                }
                return true;
            } else {
                return true;
            }
        },
        headers() {
            if (this.lessonId != null) {
                return [
                    {
                        text: "Όνομα",
                        value: "description",
                        sortable: false,
                    },
                    {
                        text: "Μάθημα",
                        value: "lesson.description",
                        sortable: false,
                    },

                    {
                        text: "Επιλογές ",
                        value: "actions",
                        sortable: false,
                    },
                ]
            } else {
                return [
                    {
                        text: "Όνομα",
                        value: "description",
                        sortable: false,
                    },
                    {
                        text: "Επιλογές ",
                        value: "actions",
                        sortable: false,
                    },
                ]
            }
        }
    },

    methods: {
        ...mapMutations(["setFilters", "changePage", "setCurrentClass"]),
        async onSearch() {
            if (this.options.page != 1) {
                this.options.page = 1;
            } else {
                this.handler();
            }
        },
        clearGeneric() {
        },
        async handler() {
            try {
                if (this.semesterId != null) {
                    this.tableLoader = true;
                    this.setFilters(this.options);

                    const res = await axios.get(
                        `${process.env.VUE_APP_BASE_URL}/class/list`,
                        {
                            params: {
                                trainPeriodId: this.semesterId,
                                lessonId: this.lessonId,
                                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                                limit:
                                    this.options.itemsPerPage == -1
                                        ? null
                                        : this.options.itemsPerPage,
                            },
                        }
                    );
                    this.classes = res.data;

                    this.filtersSet = true;
                    this.tableLoader = false;
                } else {
                    this.classes = [];
                }
            } catch (e) {
                console.log(e);
                this.classes = [];
                await this.$store.dispatch("errorHandler", e);

                this.tableLoader = false;
            }
        },

        onViewStudent(schoolClass) {
            this.setCurrentClass(schoolClass);
            this.$router.push(`/classes/${schoolClass.id}/studentsClass`);
        },
        openDialog(data, type) {
            this.dialog = {
                data: data,
                open: true,
                type,
            };
        },
        closeDialog() {
            this.dialog = {
                academicYear: null,
                open: false,
                type: null,
            };
            if (this.pageChanged) {
                this.options.page -= 1;
                this.changePage(false);
            }
            this.handler();
        },
    },
};
</script>

<style scoped lang="scss">
</style>