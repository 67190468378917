import { render, staticRenderFns } from "./TeacherApplications.vue?vue&type=template&id=084ff57b&scoped=true&"
import script from "./TeacherApplications.vue?vue&type=script&lang=js&"
export * from "./TeacherApplications.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084ff57b",
  null
  
)

export default component.exports