<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία {{ appId == 1 ? "ακαδημαϊκού έτους" : "εκπαιδευτικού έτους" }}

      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <!-- <v-text-field
          dense
          outlined
          v-model="year"
          hide-details="auto"
          v-if="appId != 1"
          class="mb-4"
          :error-messages="yearErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Έτος
          </template>
        </v-text-field> -->

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="startDateFormatted"
              hide-details="auto"
              class="mb-4"
              @blur="startDate = parseDate(startDateFormatted, 'startDate')"
              :error-messages="startDateErrors"
            >
              <template v-slot:prepend-inner>
                <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
              </template>
              <template v-slot:label>
                <span class="error--text">*</span>
                Ημερομηνία έναρξης
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            locale="el"
            v-model="startDate"
            scrollable
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>

        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="endDateFormatted"
              hide-details="auto"
              prepend-inner-icon="mdi-calendar"
              class="mb-4"
              @blur="endDate = parseDate(endDateFormatted, 'endDate')"
              :error-messages="endDateErrors"
            >
              <template v-slot:prepend-inner>
                <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
              </template>
              <template v-slot:label>
                <span class="error--text">*</span>
                Ημερομηνία λήξης
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            locale="el"
            v-model="endDate"
            scrollable
            hide-details="auto"
            @input="menu1 = false"
          >
          </v-date-picker>
        </v-menu>

        <!-- <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          v-if="appId != 1"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="absDateFormatted"
              v-bind="attrs"
              hide-details="auto"
              v-on="on"
              class="mb-4"
              @blur="absDate = parseDate(absDateFormatted, 'absDate')"
              :error-messages="absDateErrors"
            >
              <template v-slot:prepend-inner>
                <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
              </template>
              <template v-slot:label>
                <span class="error--text">*</span>
                Ημερομηνία τελευταίας επικύρωσης απουσιών
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            locale="el"
            v-model="absDate"
            scrollable
            @input="menu2 = false"
          >
          </v-date-picker>
        </v-menu> -->

        <v-select
          v-model="trainPrdActive"
          class="mb-4"
          :error-messages="trainPrdActiveErrors"
          :items="getDataStaticList(100)"
          item-text="description"
          hide-details="auto"
          item-value="cd"
          dense
          v-if="appId != 1"
          outlined
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            {{appId==1?'Προγραμματισμός κύκλων σπουδών':appId==2?'Προγραμματισμός σειρών':''}}
          </template>
        </v-select>

        <v-select
          v-model="examPrdActive"
          class="mb-4"
          :error-messages="examPrdActiveErrors"
          :items="getDataStaticList(100)"
          item-text="description"
          hide-details="auto"
          item-value="cd"
          v-if="appId != 1"
          dense
          outlined
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Προγραμματισμός εξεταστικών περιόδων
          </template>
        </v-select>

        <!-- <v-checkbox
          class="mt-2 mr-2"
          v-model="close"
          label="Κλειστό"
          true-value="1"
          false-value="0"
        >
        </v-checkbox> -->

        <v-select
          v-model="flgPublish"
          class="mb-4"
          :error-messages="flgPublishError"
          :items="getDataStaticList(100)"
          item-text="description"
          hide-details="auto"
          item-value="cd"
          dense
          outlined
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Δημοσιευμένο
          </template>
        </v-select>

        <v-checkbox
          class="mt-2 mr-2"
          v-model="flgActive"
          label="Ενεργό"
          hide-details="auto"
          true-value="1"
          false-value="0"
        >
        </v-checkbox>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["from"],
  data() {
    return {
      loader: false,
      year: null,
      startDate: null,
      startDateFormatted: null,
      endDate: null,
      endDateFormatted: null,
      // absDate: null,
      // absDateFormatted: null,
      trainPrdActive: "1",
      examPrdActive: "1",
      // close: 0,
      flgActive: "1",
      flgPublish: "0",
      menu: false,
      menu1: false,
      menu2: false,
    };
  },

  validations() {
    const self = this;
    return {
      // year: {
      //   required,
      //   numeric,
      // },
      startDate: {
        required,
      },
      endDate: {
        required,
      },

      // absDate: {
      //   required: requiredIf((e) => {
      //     return self.appId == 1;
      //   }),
      // },

      trainPrdActive: {
        required,
      },

      examPrdActive: {
        required,
      },
      flgPublish: {
        required,
      },
    };
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
      let year = this.startDateFormatted.split("/");
      this.year = year[2];
    },

    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
      this.absDate = this.endDate;
    },

    // absDate(val) {
    //   this.absDateFormatted = this.formatDate(this.absDate);
    // },
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      staticList: (state) => state.auth.userInfo.staticView,
      genericFilter: (state) => state.generic_filter,
      appId: (state) => state.auth.appId,
    }),

    flgPublishError() {
      const errors = [];
      if (!this.$v.flgPublish.$dirty) return errors;
      if (!this.$v.flgPublish.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    yearErrors() {
      const errors = [];
      if (!this.$v.year.$dirty) return errors;
      if (!this.$v.year.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.year.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;

      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },
    endDateErrors() {
      const errors = [];
      if (!this.$v.endDate.$dirty) return errors;
      if (!this.$v.endDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    // absDateErrors() {
    //   const errors = [];
    //   if (!this.$v.absDate.$dirty) return errors;
    //   if (!this.$v.absDate.required) errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },

    trainPrdActiveErrors() {
      const errors = [];
      if (!this.$v.trainPrdActive.$dirty) return errors;
      if (!this.$v.trainPrdActive.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    examPrdActiveErrors() {
      const errors = [];
      if (!this.$v.examPrdActive.$dirty) return errors;
      if (!this.$v.examPrdActive.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addAcademicYear"]),

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }/* else if (dateName == "absDate") {
          this.absDate = null;
          this.absDateFormatted = null;
        }*/
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async onSubmit() {
      try {
        // if aen automatic fill data
        if (this.appId == 1) {
          this.trainPrdActive = 1;
          this.examPrdActive = 1;
        }

        this.$v.$touch();

        if (this.$v.$invalid) return;
        this.loader = true;
        // console.log()
        await this.addAcademicYear({
          // absDate: this.absDate,
          // close: this.close,
          endDate: this.endDate,
          masterSchoolId:
            this.from == "1"
              ? this.genericFilter.facultyId
              : this.currentFaculty.id,
          schoolId:
            this.from == "1"
              ? this.genericFilter.schoolId
              : this.currentSchool.id,
          startDate: this.startDate,
          year: parseInt(this.year),
          trainPrdActive: this.trainPrdActive,
          examPrdActive: this.examPrdActive,
          flgActive: this.flgActive,
          flgPublish: this.flgPublish,
        });

        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
