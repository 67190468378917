import axios from "axios";

export default {
    state: {
        users: [],
        userRegisters: [],
        userAddress: [],
        addressTypes: [],
        currentUser: JSON.parse(localStorage.getItem('user')) || null,
        currentRegister: null,
        eidhEggrafhs: [],
        typoiXrhsth: [],
        gender: [],
        userHistory: [],


        userProfile: null,

        ///userTabs
        stoixeiaFoithshs: [],
        ekpaideutikaTaksidia: [],

        bathmologia: [],
        apousies: [],
        meteggrafes: [],
        anastoles: [],
        diagrafes: []

    },

    mutations: {
        getUsers(state, users) {
            state.users = users
        },

        getUserRegisters(state, userRegisters) {
            state.userRegisters = userRegisters
        },

        setCurrentUser(state, user) {
            state.currentUser = user
            localStorage.setItem('user', JSON.stringify(user));
        },

        setCurrentRegister(state, register) {
            state.currentRegister = register
        },

        getEidhEggrafhs(state, eidhEggrafhs) {
            state.eidhEggrafhs = eidhEggrafhs
        },

        getTypoiXrhsth(state, typoiXrhsth) {
            state.typoiXrhsth = typoiXrhsth
        },

        getGender(state, gender) {
            state.gender = gender
        },

        getUserHistory(state, userHistory) {
            state.userHistory = userHistory
        },

        getUserProfile(state, userProfile) {
            state.userProfile = userProfile;
        },

        ///userTabs
        getStoixeiaFoithshs(state, stoixeiaFoithshs) {
            state.stoixeiaFoithshs = stoixeiaFoithshs
        },

        getEkpaideutikaTaksidia(state, ekpaideutikaTaksidia) {
            state.ekpaideutikaTaksidia = ekpaideutikaTaksidia
        },





        getBathmologia(state, bathmologia) {
            state.bathmologia = bathmologia
        },

        getApousies(state, apousies) {
            state.apousies = apousies
        },

        getMeteggrafes(state, meteggrafes) {
            state.meteggrafes = meteggrafes
        },

        getAnastoles(state, anastoles) {
            state.anastoles = anastoles
        },

        getDiagrafes(state, diagrafes) {
            state.diagrafes = diagrafes
        },

        getUserAddress(state, userAddress) {
            state.userAddress = userAddress
        },

        getAddressTypes(state, addressTypes) {
            state.addressTypes = addressTypes
        }


    },

    actions: {
        async getUsers({ commit, dispatch }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/list`,
                    {
                        params: {
                            page: filters.page,
                            limit: filters.limit,

                        }
                    })
                commit('getUsers', res.data)

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async getUserRegisters({ commit, dispatch }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/register/list`,
                    {
                        params: {
                            page: filters.page,
                            limit: filters.limit,
                            personId: filters.id,
                            propertyType: filters.propertyType
                        }
                    })
                commit('getUserRegisters', res.data)

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async getUserAddress({ commit, dispatch }, filters) {

            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/address/list`,
                    {
                        params: {
                            page: filters.filters.page,
                            limit: filters.filters.limit,
                            personId: filters.id,
                        }
                    })
                commit('getUserAddress', res.data)

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async addUserAddress({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/person/address`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία στοιχείων επικοινωνίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editUserAddress({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/person/address/${payload.address}`, payload.content)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία στοιχείων επικοινωνίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteUserAddress({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/person/address/${payload.address}`)


                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή στοιχείων επικοινωνίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async setCurrentUser({ commit }, id) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/${id}`)
                commit('setCurrentUser', res.data)
            }
            catch (e) {
                throw e
            }
        },

        async editUser({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/person/${payload.id}`, payload.content)
                await dispatch('getUsers', {
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.users.elements : rootState.base.filters.itemsPerPage,

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία χρήστη ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }
            catch (e) {

                dispatch("errorHandler", e);

                throw e;
            }
        },

        async getEidhEggrafhs({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/126`)
                commit('getEidhEggrafhs', res.data)
            }
            catch (e) {
                throw e;
            }
        },

        async getTypoiXrhsth({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/125`)
                commit('getTypoiXrhsth', res.data)
            }
            catch (e) {
                throw e;
            }
        },

        async getGender({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/128`)
                commit('getGender', res.data)
            }
            catch (e) {
                throw e;
            }
        },

        async getUserProfile({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person`)
                commit('getUserProfile', res.data)
            }
            catch (e) {
                throw e;
            }
        },



        async getUserHistory({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/auditing/log/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        username: payload.username
                    }
                })
                commit('getUserHistory', res.data)
            }
            catch (e) {
                throw e;
            }
        },


        ///student tabs
        async getStoixeiaFoithshs({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/register/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        personId: payload.id,
                        propertyType: 1,
                    }
                })
                commit('getStoixeiaFoithshs', res.data)
            }
            catch (e) {

                throw e;
            }
        },

        async getEkpaideutikaTaksidia({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/prog/trip/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        personId: payload.id
                    }
                })
                commit('getEkpaideutikaTaksidia', res.data)
            }
            catch (e) {
                throw e;
            }
        },





        async getBathmologia({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/student/grade/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        personId: payload.id,
                        progTrainPeriodId: payload.progTrainPeriodId,
                        progExamPeriodId: payload.progExamPeriodId,
                        approved: true
                    }
                })
                commit('getBathmologia', res.data)
            }
            catch (e) {
                throw e;
            }
        },


        async getApousies({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/daily/absence/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        personId: payload.id,
                        progTrainPeriodId: payload.progTrainPeriodId,


                    }
                })
                commit('getApousies', res.data)
            }
            catch (e) {
                throw e;
            }
        },

        async getMeteggrafes({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/register/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        personId: payload.id,
                        registrationType: 5,
                    }
                })
                commit('getMeteggrafes', res.data)
            }
            catch (e) {
                throw e;
            }
        },

        async getAnastoles({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/register/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        personId: payload.id,
                        registrationType: 9,
                    }
                })
                commit('getAnastoles', res.data)
            }
            catch (e) {
                throw e;
            }
        },

        async getDiagrafes({ commit }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/register/list`, {
                    params: {
                        page: payload.page,
                        limit: payload.limit,
                        personId: payload.id,
                        registrationType: 0,
                    }
                })
                commit('getDiagrafes', res.data)
            }
            catch (e) {
                throw e;
            }
        },

        async getAddressTypes({ commit }) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/value/157`)
                commit('getAddressTypes', res.data)
            } catch (e) {
                throw e;
            }
        }
    }
}