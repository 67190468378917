<template>
  <v-footer app color="blue" style="height: 100px">
    <v-row justify="center">
      <div class="col mt-4">
        <v-row no-gutters>
          <router-link
            to="/oroiXrisis"
            custom
            v-slot="{ navigate }"
            class="ml-4 mt-2"
          >
            <a class="white--text text-decoration-underline" @click="navigate"
              >Όροι Χρήσης /
            </a>
            <a class="white--text text-decoration-underline" @click="navigate">
              Πολιτική Απορρήτου
            </a>
          </router-link>
        </v-row>
        <v-row no-gutters>
          <div class="ml-4">
            <a
              class="white--text"
              style="
                pointer-events: none;
                cursor: default;
                text-decoration: none;
              "
              target="_blank"
              >Υλοποίηση από το
            </a>
            <a href="https://grnet.gr/" class="white--text" target="_blank"
              >ΕΔΥΤΕ</a
            >
            <a
              class="white--text"
              style="
                pointer-events: none;
                cursor: default;
                text-decoration: none;
              "
              target="_blank"
            >
              για το </a
            ><a
              href="https://www.ynanp.gr/el/"
              class="white--text"
              target="_blank"
              >Υπουργείο Ναυτιλίας & Νησιωτικής Πολιτικής</a
            >
          </div>
        </v-row>
      </div>
      <div class="col aptPowered">
        <v-list-item class="align-center mt-5 mb-3">
          <a
            href="#"
            class="white--text"
            style="pointer-events: none; cursor: default; text-decoration: none"
            target="_blank"
            >Powered by &nbsp;</a
          ><a href="https://www.apt.gr/" class="white--text" target="_blank"
            >APT</a
          >
        </v-list-item>
      </div>
      <div class="ml-auto d-flex align-center mt-3 mb-3">
        <div class="d-flex justify-content-end align-items-center">
          <v-img
            class="aen_footerlogo"
            src="@/assets/images/logoypourgeio.jpg"
            alt="Υπουργείο Ναυτιλίας & Νησιωτικής Πολιτικής"
          />
        </div>
      </div>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooterPortal",
  methods: {
    toLinks() {
      this.$router.push("/notification");
    },
  },
};
</script>
<style scoped>
.aen_footerlogo {
  width: auto !important;
  max-width: 330px !important;
  float: right !important;
}
</style>
