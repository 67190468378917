
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      v-if="!dialogRole.open"
    >
      <v-card-title class="greyish--text font-weight-regular">
        Χρήστες
      </v-card-title>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, 100],
            }"
            :headers="headers"
            :items="users.content"
            :search="search"
            :server-items-length="users.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:top>
              <v-text-field
                dense
                outlined
                v-model="search"
                label="Αναζήτηση"
                class="mx-4"
              ></v-text-field>
            </template>

            <template v-slot:[`item.dateOfBirth`]="{ item }">
              <span>{{
                new Date(item.dateOfBirth).toLocaleDateString("el")
              }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onEdit(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <!-- <div v-if="item.propertyType != undefined">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.propertyType.description == 'Σπουδαστής'"
                      depressed
                      icon
                      @click="onViewStudent(item)"
                    >
                      <v-icon color="primary" small v-bind="attrs" v-on="on">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Προβολή</span>
                </v-tooltip>
              </div> -->

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onUserRoleView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ρόλοι</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'history')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-history
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ιστορικό ενεργειών</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'history'"
      max-width="500px"
      scrollable
    >
      <UserHistory
        v-if="dialog.open && dialog.type == 'history'"
        @close="closeDialog"
        :user="dialog.user"
      />
    </v-dialog>
    <UserRoles
      v-if="dialogRole.open"
      :user="dialogRole.user"
      path="/teachers"
      @close="closeDialogRole"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import UserHistory from "../../components/UserModals/UserHistory.vue";
import UserRoles from "../Users/UserRoles.vue";

export default {
  components: {
    UserHistory,
    UserRoles,
  },

  data() {
    return {
      search: "",
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        // {
        //   text: "Τύπος χρήστη",
        //   value: "propertyType.description",
        //   sortable: false,
        // },
        {
          text: "Ημερομηνία γέννησης",
          value: "dateOfBirth",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        open: false,
        type: null,
        user: null,
      },
      dialogRole: {
        open: false,
        user: null,
      },
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);
          await this.getUsers({
            page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
            limit:
              this.options.itemsPerPage == -1
                ? null
                : this.options.itemsPerPage,
          });
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      users: (state) => state.users.users,
    }),

    editSnackbar: {
      get() {
        return this.$store.state.users.userEdited;
      },
      set(value) {
        this.setUserEdited(value);
      },
    },
  },

  methods: {
    ...mapMutations(["setFilters"]),
    ...mapActions(["getUsers", "setCurrentUser"]),

    openDialog(user, type) {
      this.dialog = {
        user,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        open: false,
        type: null,
        user: null,
      };
    },

    onEdit(user) {
      // console.log(user);
      // this.setCurrentUser(user.id);
      this.$router.push(`/users/${user.id}/edit`);
    },

    onViewStudent(user) {
      this.$router.push(`/users/${user.id}/view_student/atomika_stoixeia`);
    },
    closeDialogRole() {
      this.dialogRole = {
        user: null,
        open: false,
      };
    },
    onUserRoleView(user) {
      this.dialogRole = {
        user: user,
        open: true,
      };
      // this.$store.commit('setCurrentUser',user)
      // this.$router.push(`/users/${user.id}/roles`);
    },
  },
};
</script>

<style scoped lang="scss">
</style>