import axios from 'axios'

export default {
    state: {
        studentGrades: [],
        examGrades: [],
        classExams: [],

        currentClassExam: null

    },

    mutations: {
        getStudentGrades(state, studentGrades) {
            state.studentGrades = studentGrades
        },

        getExamGrades(state, examGrades) {
            state.examGrades = examGrades
        },

        getClassExams(state, classExams) {
            state.classExams = classExams
        },

        setCurrentClassExam(state, currentClassExam) {
            state.currentClassExam = currentClassExam
        }


    },

    actions: {
        async getStudentGrades({commit, dispatch}, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/listForGrade/oral`, {
                    params: {
                        limit: payload.limit,
                        page: payload.page,

                        masterSchoolId: payload.masterSchoolId,
                        schoolId: payload.schoolId,
                        yearId: payload.yearId,
                        progTrainPeriodId: payload.progTrainPeriodId,
                        classId: payload.classId,
                        lessonId: payload.lessonId,
                        approved: payload.approved
                    }
                })

                commit('getStudentGrades', res.data)
            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async getExamGrades({commit, dispatch}, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/person/listForGrade/written`, {
                    params: {
                        limit: payload.limit,
                        page: payload.page,

                        masterSchoolId: payload.masterSchoolId,
                        schoolId: payload.schoolId,
                        yearId: payload.yearId,
                        progTrainPeriodId: payload.progTrainPeriodId,
                        progExamPeriodId: payload.progExamPeriodId,
                        classId: payload.classId,
                        lessonId: payload.lessonId,
                        approved: payload.approved
                    }
                })

                commit('getExamGrades', res.data)
            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async getClassExams({commit, dispatch}, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/class/exam/list`, {
                    params: {
                        limit: payload.limit,
                        page: payload.page,
                        progExamPeriodId: payload.progExamPeriodId,
                        lessonId: payload.lessonId
                    }
                })

                commit('getClassExams', res.data)
            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async addStudentGrade({commit, dispatch}, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/student/grade`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η καταχώρηση βαθμολογίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            } catch (e) {
                commit('toggleSnackbar', {
                    open: true,
                    text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
                    color: 'error'
                })
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addExamResult({commit, dispatch}, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/exam/result`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η καταχώρηση βαθμολογίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editStudentGrade({commit, dispatch, rootState, state}, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/student/grade/${payload.gradeId}`, payload.content)
                await dispatch('getStudentGrades', {
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.studentGrades.elements : rootState.base.filters.itemsPerPage,
                    masterSchoolId: rootState.generic_filter.facultyId,
                    schoolId: rootState.generic_filter.schoolId,
                    yearId: rootState.generic_filter.academicYearId,
                    lessonId: rootState.generic_filter.courseId,
                    classId: rootState.generic_filter.classId,
                    progTrainPeriodId: rootState.generic_filter.semesterId,
                    approved: false,

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία βαθμολογίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async deleteStudentGrade({commit, dispatch, rootState, state}, gradeId) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/student/grade/${gradeId}`)
                if (state.studentGrades.limit == 1 && state.studentGrades.page != 1) {
                    commit('changePage', true)
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή βαθμολογίας ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async approveGrades({commit, state, dispatch}, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/student/grade/mass/approve`, payload)
                if (state.studentGrades.limit == 1 && state.studentGrades.page != 1) {
                    commit('changePage', true)
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: `Ο έλεγχος ${payload.length > 1 ? 'βαθμολογιών' : 'βαθμολογίας'} ολοκληρώθηκε με επιτυχία`,
                    color: 'success'
                })

            } catch (e) {
                dispatch("errorHandler", e);
                throw e;
            }
        },

        async approveExamGrades({commit, state, dispatch}, payload) {
            let response;
            try {
                response = await axios.post(`${process.env.VUE_APP_BASE_URL}/exam/result/mass/approve`, payload)
                if (state.studentGrades.limit == 1 && state.examGrades.page != 1) {
                    commit('changePage', true)
                }
                if (response.data.code === 200) {
                    console.log('200')
                    commit('toggleSnackbar', {
                        open: true,
                        text: `Ο έλεγχος ${payload.content.length > 1 ? 'βαθμολογιών' : 'βαθμολογίας'} ολοκληρώθηκε με επιτυχία`,
                        color: 'success'
                    })
                } else {
                    dispatch("errorHandler", {response: response});
                }
            } catch (e) {
                dispatch("errorHandler", e);
                throw e;
            }
        },

    }
}