var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('GenenericFilter',{key:_vm.query,attrs:{"level":4,"requiredFields":{
        masterSchool: true,
        school: true,
        year: true,
        progTrainPeriod: true,
        class: false,
        lesson: false,
      }},on:{"onSearchAction":_vm.handler}}),_c('v-card-text',{staticClass:"pa-0 pb-4 pt-4 d-flex flex-column flex-sm-row"},[_c('v-card',{staticClass:"flex-grow-1 mr-sm-10 mb-5 mb-sm-0",attrs:{"min-height":"500px"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, 100],
            },"headers":_vm.headers,"items":_vm.nicStudents.content,"server-items-length":!_vm.filtersSet ? 0 : _vm.nicStudents.elements,"options":_vm.options,"loading":_vm.tableLoader,"height":"400px"},on:{"update:options":function($event){_vm.options=$event}}})],1)],1),_c('v-card',{attrs:{"disabled":!_vm.filtersSet}},[_c('v-card-text',[_vm._l((_vm.classesInputs.length),function(input,i){return _c('div',{key:i,staticClass:"d-flex flex-column"},[_c('div',{staticClass:"align-self-end"},[_c('v-btn',{attrs:{"depressed":"","icon":"","disabled":_vm.classesInputs.length == 1},on:{"click":function($event){return _vm.classesInputs.pop()}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-minus")])],1),_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.classesInputs.push({
                    classDescr: null,
                    capacity: _vm.maxDept,
                  })}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-plus")])],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticClass:"mr-4",attrs:{"outlined":"","dense":"","label":"Περιγραφή τμήματος","error-messages":_vm.descriptionInputsErrors(i)},model:{value:(_vm.classesInputs[i].classDescr),callback:function ($$v) {_vm.$set(_vm.classesInputs[i], "classDescr", $$v)},expression:"classesInputs[i].classDescr"}}),_c('v-text-field',{staticClass:"mr-4",attrs:{"outlined":"","dense":"","label":"Χωρητικότητα","clearable":""},model:{value:(_vm.classesInputs[i].capacity),callback:function ($$v) {_vm.$set(_vm.classesInputs[i], "capacity", $$v)},expression:"classesInputs[i].capacity"}})],1)])}),_c('v-select',{attrs:{"outlined":"","dense":"","label":"Επιλογή ταξινόμησης","items":_vm.items(163),"item-text":"description","item-value":"cd","clearable":"","error-messages":_vm.sortingTypeErrors,"no-data-text":"Δεδομένα μη διαθέσιμα"},model:{value:(_vm.sortingType),callback:function ($$v) {_vm.sortingType=$$v},expression:"sortingType"}})],2)],1)],1),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"mr-2",attrs:{"loading":_vm.loader,"depressed":"","color":"primary","disabled":!_vm.filtersSet || _vm.nicStudents.content.length == 0},on:{"click":_vm.onClassCreate}},[_vm._v(" Αρχικοποίηση τμημάτων ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }