<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία Προαπαιτούμενο
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-select
          v-model="certificate"
          class="mb-4"
          :items="certList"
          :error-messages="certificateError"
          item-text="description"
          hide-details="auto"
          item-value="cd"
          dense
          outlined
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Πιστοποιητικό
          </template>
        </v-select>
        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["from", "cmnApplId"],
  async created() {
    await this.fetchCertificateList();
  },
  data() {
    return {
      loader: false,
      certificate: null,
      certList: [],
      certifcd: null,
    };
  },
  validations: {
    certificate: {
      required,
    },
  },

  computed: {
    ...mapState({
      appId: (state) => state.auth.appId,
    }),
    certificateError() {
      const errors = [];
      if (!this.$v.certificate.$dirty) return errors;
      if (!this.$v.certificate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let body = {
        certifCd: this.certificate,
        commonOnlineApplicationId: this.cmnApplId,
      };
      try {
        this.loader = true;
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/common/online/application/requirement`,
          body
        );
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        console.log(e);
        this.loader = false;
      }
    },
    async fetchCertificateList() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/106`,
          body
        );
        this.certList = res.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>