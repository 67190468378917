<template>
  <div>
    <v-card>
      <v-toolbar color="primary" class="white--text" fixed>
        <span>{{ currentUser.firstName }}  {{ currentUser.lastName }}</span>

        <v-btn
          depressed
          icon
          color="white"
          class="ml-auto"
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text max-width="1400px" width="100%" height="100%" class="pa-4">
        <v-tabs v-model="tabs" centered show-arrows>
          <v-tab> Ατομικα στοιχεια </v-tab>
          <v-tab> Στοιχεια φοιτησης </v-tab>
          <v-tab>Εκπαιδευτικα ταξιδια</v-tab>
          <v-tab>Πτυχιακη εργασια</v-tab>
          <v-tab> Μετεγγραφες </v-tab>
          <v-tab> Αποποίηση Βαθμολογίας</v-tab>
          <v-tab>Αναστολες φοιτησης</v-tab>
          <v-tab>Διαγραφες</v-tab>
          <v-tab> Αιτησεις </v-tab>
          <v-tab>Βραβεια</v-tab>
          <v-tab>Πτυχια</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item> <AtomikaStoixeia /> </v-tab-item>
          <v-tab-item> <StoixeiaFoithshs /> </v-tab-item>
          <v-tab-item> <EkpaideutikaTaksidia /> </v-tab-item>
          <v-tab-item> <PtyxiakhErgasia /> </v-tab-item>
          <v-tab-item> <Meteggrafes /> </v-tab-item>
          <v-tab-item> <RefusalDetails /> </v-tab-item>
          <v-tab-item> <AnastolesFoithshs /> </v-tab-item>
          <v-tab-item> <Diagrafes /> </v-tab-item>
          <v-tab-item> <StudentApplications /> </v-tab-item>
          <v-tab-item> <Awards /> </v-tab-item>
          <v-tab-item> <Certifications /> </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AtomikaStoixeia from "../../components/UserModals/ViewStudentTabs/AtomikaStoixeia.vue";
import StoixeiaFoithshs from "../../components/UserModals/ViewStudentTabs/StoixeiaFoithshs.vue";
import EkpaideutikaTaksidia from "../../components/UserModals/ViewStudentTabs/EkpaideutikaTaksidia.vue";
import PtyxiakhErgasia from "../../components/UserModals/ViewStudentTabs/PtyxiakhErgasia.vue";
import Meteggrafes from "../../components/UserModals/ViewStudentTabs/Meteggrafes.vue";
import RefusalDetails from "../../components/UserModals/ViewStudentTabs/RefusalDetails.vue";
import AnastolesFoithshs from "../../components/UserModals/ViewStudentTabs/AnastolesFoithshs.vue";
import Diagrafes from "../../components/UserModals/ViewStudentTabs/Diagrafes.vue";
import StudentApplications from "../../components/UserModals/ViewStudentTabs/StudentApplications.vue";
import Awards from "../../components/UserModals/ViewStudentTabs/Awards.vue";
import Certifications from "../../components/UserModals/ViewStudentTabs/Certifications.vue";
export default {
  props: ["application", "type"],
  components: {
    AtomikaStoixeia,
    StoixeiaFoithshs,
    EkpaideutikaTaksidia,
    PtyxiakhErgasia,
    Meteggrafes,
    RefusalDetails,
    AnastolesFoithshs,
    Diagrafes,
    StudentApplications,
    Awards,
    Certifications,
  },
  data() {
    return {
      tabs: null,
      loader: false,
    };
  },
  created() {
    this.setCurrentUser(this.application.person.id);
    console.log(this.tabs,' Tabs')
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      appId: (state) => state.auth.appId,
    }),
  },
  methods: {
    ...mapActions(["setCurrentUser"]),
  },
};
</script>

<style>
</style>