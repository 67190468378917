import axios from 'axios'
export default {
    state: {
        books: [],
        currentBook: null
    },

    mutations: {
        getBooks(state, books) {
            state.books = books
        },

        setCurrentBook(state, currentBook) {
            state.currentBook = currentBook
        }
    },

    actions: {
        async getBooks({ commit, dispatch }, payload) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/library/book/list`, {
                    params: {
                        limit: payload.limit,
                        page: payload.page,
                        libraryId: payload.libraryId,
                    }
                })

                commit('getBooks', res.data)
            } catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async addBook({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/library/book`, payload)
                await dispatch('getBooks', {
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.books.elements : rootState.base.filters.itemsPerPage,
                    libraryId: payload.libraryId

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία βιβλίου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async editBook({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/library/book/${payload.bookId}`, payload.content)
                await dispatch('getBooks', {
                    page: rootState.base.filters.page,
                    limit: rootState.base.filters.itemsPerPage == -1 ? state.books.elements : rootState.base.filters.itemsPerPage,
                    libraryId: payload.content.libraryId

                })
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία βιβλίου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteBook({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/library/book/${payload.bookId}`)
                if (state.books.limit == 1 && state.books.page != 1) {
                    commit('changePage', true)
                } else {
                    await dispatch('getBooks', {
                        page: rootState.base.filters.page,
                        limit: rootState.base.filters.itemsPerPage == -1 ? state.books.elements : rootState.base.filters.itemsPerPage,
                        libraryId: payload.libraryId

                    })
                }
                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή βιβλίου ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

    }
}