

<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία {{ appId == 1 ? "σχολής" : getFieldName() }}
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex flex-column pa-4">
      <v-progress-circular
        v-if="cardLoader"
        indeterminate
        color="primary"
        class="align-self-center ma-4"
        :size="50"
      ></v-progress-circular>
      <v-form @submit.prevent="onSubmit" class="pa-4 flex-grow-1" v-else>
        <v-card class="mb-4">
          <v-card-title class="mb-4"> Βασικά στοιχεία </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  hide-details="auto"
                  outlined
                  v-model="schoolName"
                  type="text"
                  :error-messages="schoolNameErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Όνομα
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="schoolId"
                  type="text"
                  :error-messages="schoolIdErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Κωδικός
                  </template>
                </v-text-field>
              </v-col>
              <!-- 
              <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="series"
                  type="text"
                  :error-messages="seriesErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Σειρά
                  </template>
                </v-text-field>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title> Επιπλέον πληροφορίες </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="limTime"
                      type="text"
                      hide-details="auto"
                      :error-messages="limTimeErrors"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Χρονικό όριο περάτωσης (μήνες)
                      </template>
                    </v-text-field>
                  </template>
                  <span>Χρονικό όριο περάτωσης (μήνες)</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="titleId"
                      type="text"
                      :error-messages="titleIdErrors"
                      :items="kindTitleList"
                      hide-details="auto"
                      item-text="description"
                      item-value="cd"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Είδος εκδιδόμενου τίτλου
                      </template>
                    </v-select>
                  </template>
                  <span>Είδος εκδιδόμενου τίτλου</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-select
                  clearable
                  dense
                  outlined
                  v-model="preReq1"
                  hide-details="auto"
                  label="Προαπαιτούμενο σχολή 1"
                  type="text"
                  :items="getSchools"
                  item-text="description"
                  item-value="id"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    {{
                      appId == 1
                        ? "Προαπαιτούμενη σχολή 1"
                        : "Προαπαιτούμενος εκπαιδευτικός κύκλος/ειδικό τμήμα 1"
                    }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-select
                  clearable
                  dense
                  outlined
                  hide-details="auto"
                  v-model="preReq2"
                  label="Προαπαιτούμενο σχολή 2"
                  type="text"
                  :items="getSchools"
                  item-text="description"
                  item-value="id"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    {{
                      appId == 1
                        ? "Προαπαιτούμενη σχολή 2"
                        : "Προαπαιτούμενος εκπαιδευτικός κύκλος/ειδικό τμήμα 2"
                    }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-select
                  clearable
                  dense
                  outlined
                  v-model="trip"
                  label="Θαλάσσιο εκπαιδευτικό ταξίδι"
                  hide-details="auto"
                  type="text"
                  :items="getDataStaticList(100)"
                  item-text="description"
                  item-value="cd"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4">
            {{
              appId == 1 ? "Εξάμηνα σπουδών" : "Κύκλοι και διδακτικές περίοδοι"
            }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="trainPrd"
                      type="text"
                      :error-messages="trainPrdErrors"
                      hide-details="auto"
                      :items="getDataStaticList(105)"
                      item-text="description"
                      item-value="cd"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        {{
                          appId == 1
                            ? "Είδος εξαμήνου σπουδών σχολής"
                            : "Είδος κύκλων σπουδών εκπαιδευτικού κύκλου/ειδικού τμήματος 1"
                        }}
                      </template>
                    </v-select>
                  </template>
                </v-tooltip>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="prdType"
                      type="text"
                      :error-messages="prdTypeErrors"
                      :items="getDataStaticList(107)"
                      item-text="description"
                      hide-details="auto"
                      item-value="cd"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        {{
                          appId == 1
                            ? "Είδος πλήθους εξαμήνων σπουδών"
                            : "Είδος πλήθους κύκλων σπουδών"
                        }}
                      </template>
                    </v-select>
                  </template>
                  <span>{{
                    appId == 1
                      ? "Είδος πλήθους εξαμήνων σπουδών"
                      : "Είδος πλήθους κύκλων σπουδών"
                  }}</span>
                </v-tooltip>
              </v-col>

              <!-- <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  outlined
                  v-model="lastIssNr"
                  hide-details="auto"
                  type="text"
                  :error-messages="lastIssNrErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Τελευταίος αριθμός κύκλου
                  </template>
                </v-text-field>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Απουσίες και βαθμολογίες </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="passGrade"
                  type="text"
                  :error-messages="passGradeErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Βάση βαθμολογίας
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  outlined
                  v-model="maxGrade"
                  hide-details="auto"
                  type="text"
                  :error-messages="maxGradeErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Μέγιστη βαθμολογία
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  outlined
                  v-model="schoolRound"
                  hide-details="auto"
                  label="Στρογγυλοποίηση βαθμολογίας"
                  :error-messages="schoolRoundErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Στρογγυλοποίηση βαθμολογίας
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-select
                      dense
                      outlined
                      v-model="absence"
                      label="Καταχώρηση απουσιών ημέρας"
                      hide-details="auto"
                      type="text"
                      :error-messages="absenceErrors"
                      :items="getDataStaticList(109)"
                      item-text="description"
                      item-value="cd"
                      v-bind="attrs"
                      v-on="on"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Καταχώρηση απουσιών ημέρας
                      </template>
                    </v-select>
                  </template>
                  <span>Καταχώρηση απουσιών ημέρας</span>
                </v-tooltip>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-select
                  dense
                  outlined
                  v-model="apop"
                  label="Αποποίηση βαθμολογίας"
                  type="text"
                  :error-messages="apopErrors"
                  hide-details="auto"
                  :items="getDataStaticList(100)"
                  item-text="description"
                  item-value="cd"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Αποποίηση βαθμολογίας
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-textarea
              dense
              outlined
              v-model="memo"
              hide-details="auto"
              label="Σχόλια"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <v-checkbox
              v-model="schoolStatus"
              label="Ενεργό"
              hide-details="auto"
              :error-messages="schoolStatusErrors"
              true-value="1"
              false-value="0"
            >
            </v-checkbox>
          </v-col>
        </v-row>

        <div class="d-flex justify-center mt-2">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import { required, numeric, decimal } from "vuelidate/lib/validators";
import Helpers from "../../helpers/helpers";
export default {
  props: ["school", "schoolTypeId"],
  async created() {
    try {
      this.cardLoader = true;
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/school/list/prototypes`,
          {
            params: {
              page: 0,
              schoolTypeId: this.schoolTypeId,
            },
          }
        );
        this.schoolList = res.data;
      } catch (e) {
        throw e;
      }
      await this.getKindTitle();
      this.absence = this.school.absence.cd;
      this.apop = this.school.apop == undefined ? null : this.school.apop.cd;
      this.schoolId = this.school.cd;
      this.schoolName = this.school.description;
      this.schoolStatus = this.school.flgActive.cd;
      // this.lastIssNr = this.school.lastIssNr;
      this.limTime = this.school.limTime;
      this.maxGrade = this.school.maxGrade;
      this.passGrade = this.school.passGrade;
      this.prdType = this.school.prdType.cd;
      this.schoolRound = String(this.school.schoolRound);
      // this.series = this.school.series;
      this.titleId = this.school.titleId;
      this.trainPrd = this.school.trainPrd.cd;

      this.memo = this.school.memo;
      if (this.school.preReq1 != undefined) {
        this.preReq1 = this.school.preReq1.id;
      }
      if (this.school.preReq2 != undefined) {
        this.preReq2 = this.school.preReq2.id;
      }

      if (this.school.trip != undefined) {
        this.trip = this.school.trip.cd;
      }
      this.cardLoader = false;
    } catch (e) {
      this.cardLoader = false;
      console.log(e);
    }
  },
  data() {
    return {
      cardLoader: false,
      loader: false,
      schoolName: null,
      schoolId: null,
      schoolStatus: 0,
      trainPrd: null,
      titleId: null,
      prdType: null,
      absence: null,
      passGrade: null,
      maxGrade: null,
      schoolRound: null,
      apop: null,
      lastIssNr: null,
      limTime: null,
      preReq1: null,
      preReq2: null,
      series: null,
      trip: null,
      dateStart: null,
      dateEnd: null,
      memo: null,
      menu: false,
      menu1: false,
      schoolList: [],
      kindTitleList: [],
    };
  },

  validations: {
    schoolName: {
      required,
    },
    schoolId: {
      required,
    },

    trainPrd: {
      required,
    },

    titleId: {
      required,
    },

    prdType: {
      required,
    },
    absence: {
      required,
    },

    passGrade: {
      required,
      numeric,
    },

    maxGrade: {
      required,
      numeric,
    },

    schoolRound: {
      required,
      decimal,
    },

    apop: {
      required,
    },

    // lastIssNr: {
    //   required,
    //   numeric,
    // },

    limTime: {
      required,
      numeric,
    },

    // series: {
    //   required,
    //   numeric,
    // },

    schoolStatus: {
      required,
    },
  },

  computed: {
    ...mapState({
      allSchools: (state) => state.schools.allSchools,
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      staticList: (state) => state.auth.userInfo.staticView,
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
      kind: (state) => state.auth.kindId,
    }),
    schoolNameErrors() {
      const errors = [];
      if (!this.$v.schoolName.$dirty) return errors;
      if (!this.$v.schoolName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    schoolIdErrors() {
      const errors = [];
      if (!this.$v.schoolId.$dirty) return errors;
      if (!this.$v.schoolId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    trainPrdErrors() {
      const errors = [];
      if (!this.$v.trainPrd.$dirty) return errors;
      if (!this.$v.trainPrd.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    titleIdErrors() {
      const errors = [];
      if (!this.$v.titleId.$dirty) return errors;
      if (!this.$v.titleId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    prdTypeErrors() {
      const errors = [];
      if (!this.$v.prdType.$dirty) return errors;
      if (!this.$v.prdType.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    absenceErrors() {
      const errors = [];
      if (!this.$v.absence.$dirty) return errors;
      if (!this.$v.absence.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    passGradeErrors() {
      const errors = [];
      if (!this.$v.passGrade.$dirty) return errors;
      if (!this.$v.passGrade.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.passGrade.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    maxGradeErrors() {
      const errors = [];
      if (!this.$v.maxGrade.$dirty) return errors;
      if (!this.$v.maxGrade.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.maxGrade.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },

    schoolRoundErrors() {
      const errors = [];
      if (!this.$v.schoolRound.$dirty) return errors;
      if (!this.$v.schoolRound.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.schoolRound.decimal) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },

    apopErrors() {
      const errors = [];
      if (!this.$v.apop.$dirty) return errors;
      if (!this.$v.apop.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    // lastIssNrErrors() {
    //   const errors = [];
    //   if (!this.$v.lastIssNr.$dirty) return errors;
    //   if (!this.$v.lastIssNr.required) errors.push("Υποχρεωτικό πεδίο");
    //   if (!this.$v.lastIssNr.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

    //   return errors;
    // },
    limTimeErrors() {
      const errors = [];
      if (!this.$v.limTime.$dirty) return errors;
      if (!this.$v.limTime.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.limTime.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },

    // seriesErrors() {
    //   const errors = [];
    //   if (!this.$v.series.$dirty) return errors;
    //   if (!this.$v.series.required) errors.push("Υποχρεωτικό πεδίο");
    //   if (!this.$v.series.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

    //   return errors;
    // },

    schoolStatusErrors() {
      const errors = [];
      if (!this.$v.schoolStatus.$dirty) return errors;
      if (!this.$v.schoolStatus.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    getSchools() {
      return this.schoolList.content.filter((s) => {
        if (s.id != this.school.id) {
          return s;
        }
      });
    },
  },

  methods: {
    ...mapActions(["editSchool", "getAllSchools"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          absence: this.absence,
          apop: this.apop,
          cd: this.schoolId,
          description: this.schoolName,
          flgActive: this.schoolStatus,
          kind: this.kind,
          // lastIssNr: this.lastIssNr,
          limTime: this.limTime,
          // masterSchoolId: this.currentFaculty.id,
          maxGrade: this.maxGrade,
          passGrade: this.passGrade,
          prdType: this.prdType,
          schoolRound: this.schoolRound,
          series: this.series,
          titleId: this.titleId,
          trainPrd: this.trainPrd,
          trip: this.trip,
          schoolTypeId: this.schoolTypeId,
        };

        if (this.memo != null) content.memo = this.memo;
        if (this.preReq1 != null) content.preReq1 = this.preReq1;
        if (this.preReq2 != null) content.preReq2 = this.preReq2;
        if (this.trip != null) content.trip = this.trip;
        await this.editSchool({
          schoolId: this.school.id,
          content,
        });
        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
    getFieldName() {
      return Helpers.getFieldName(2);
    },

    async getKindTitle() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/106`,
          body
        );
        this.kindTitleList = res.data;
      } catch (e) {
        throw e;
      }
    },
    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>