<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία εκτυπωτικού
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="description"
              type="text"
              class="mb-4"
              hide-details="auto"
              :error-messages="descriptionErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Περιγραφή
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="reportName"
              type="text"
              class="mb-4"
              hide-details="true"
              disabled
            >
              <template v-slot:label> Όνομα εκτυπωτικού </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="reportPath"
              type="text"
              class="mb-4"
              hide-details="auto"
            >
              <template v-slot:label> Διαδρομή </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="tpy"
              dense
              item-text="description"
              item-value="cd"
              hide-details="auto"
              :items="tpyList"
              outlined
              :error-messages="tpyErrors"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Τύπος
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              v-model="attachedFile"
              dense
              outlined
              label="Αρχείο"
              prepend-inner-icon="mdi-paperclip"
              :rules="rules"
              :prepend-icon="null"
              hide-details="auto"
            ></v-file-input>
          </v-col>
        </v-row>
        <div class="d-flex justify-center btn-container">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  async created() {
    await this.fetchTpy();
  },

  data() {
    return {
      loader: false,
      tpyList: [],
      description: null,
      reportName: null,
      reportPath: null,
      attachedFile: null,
      tpy: null,
      rules: [
        value => {
          return !value || value?.size < 20000000 || 'Το αρχείο πρέπει να είναι μικρότερο από 20 MB'
        },
      ],
    };
  },

  validations: {
    description: {
      required,
    },
    tpy: {
      required,
    },
  },
  computed: {
    ...mapState({
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    tpyErrors() {
      const errors = [];
      if (!this.$v.tpy.$dirty) return errors;
      if (!this.$v.tpy.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
  methods: {
    reportNameErrors() {
      const errors = [];
      if (!this.$v.reportName.$dirty) return errors;
      if (!this.$v.reportName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    reportPathErrors() {
      const errors = [];
      if (!this.$v.reportPath.$dirty) return errors;
      if (!this.$v.reportPath.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let formData = new FormData();
        formData.append("description", this.description);
        if (this.reportPath != null)
          formData.append("reportPath", this.reportPath);
        formData.append("tpy", this.tpy);
        formData.append("type", this.appId);
        formData.append("reportFile", this.attachedFile);

        await axios.post(`${process.env.VUE_APP_BASE_URL}/report`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // await axios.post(`${process.env.VUE_APP_BASE_URL}/report`, {
        //   description: this.description,
        //   reportName: this.reportName,
        //   reportPath: this.reportPath,
        //   tpy: this.tpy,
        // });

        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
    async fetchTpy() {
      let result = await this.fetchDataDynamic(221, null);
      this.tpyList = result.data;
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>