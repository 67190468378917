<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Ιστορικό ενεργειών
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text
      class="
        px-sm-6
        pb-sm-5
        px-0
        d-flex
        flex-column
        align-center
        justify-center
      "
    >
      <v-timeline dense class="timeline" v-if="userHistory.content != 0">
        <v-timeline-item
          small
          v-for="(action, i) in userHistory.content"
          :key="i"
        >
          <v-container class="fill-height">
            <v-row>
              <v-col cols="6">{{
                new Date(action.creationDate).toLocaleDateString("el")
              }}</v-col>
              <v-col cols="6"
                ><strong>{{ action.userAction }}</strong></v-col
              >
            </v-row>
          </v-container>
        </v-timeline-item>
      </v-timeline>

      <span v-else-if="userHistory.content == 0" class="body-1 ma-5">
        Δεν υπάρχουν καταγεγραμμένες ενέργειες
      </span>

      <v-pagination
        v-if="userHistory.content != 0"
        :total-visible="5"
        v-model="page"
        :length="userHistory.pages"
        @input="onInput"
        class="elevation-0"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["user"],

  async created() {
    try {
      await this.getUserHistory({
        page: this.page,
        limit: 5,
        username: this.user.ldapUsername,
      });
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      page: 1,
    };
  },

  computed: {
    ...mapState({
      userHistory: (state) => state.users.userHistory,
    }),
  },

  methods: {
    ...mapActions(["getUserHistory"]),

    async onInput() {
      try {
        await this.getUserHistory({
          page: this.page,
          limit: 5,
          username: this.user.ldapUsername,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>