
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenenericFilter
        :level="4"
        @onSearchAction="handler"
        :key="query"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: false,
          lesson: false,
        }"
      />

      <v-card-text class="pa-0 pb-4 pt-4 d-flex flex-column flex-sm-row">
        <v-card class="flex-grow-1 mr-sm-10 mb-5 mb-sm-0" min-height="500px">
          <v-card-text>
            <v-data-table
              no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
              loading-text="Φόρτωση..."
              :footer-props="{
                'items-per-page-text': 'Σειρές ανά σελίδα',
                'items-per-page-all-text': 'Όλες',
                'items-per-page-options': [5, 10, 15, 100],
              }"
              :headers="headers"
              :items="nicStudents.content"
              :server-items-length="!filtersSet ? 0 : nicStudents.elements"
              :options.sync="options"
              :loading="tableLoader"
              height="400px"
            >
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card :disabled="!filtersSet">
          <v-card-text>
            <div
              v-for="(input, i) in classesInputs.length"
              :key="i"
              class="d-flex flex-column"
            >
              <div class="align-self-end">
                <v-btn
                  depressed
                  icon
                  @click="classesInputs.pop()"
                  :disabled="classesInputs.length == 1"
                >
                  <v-icon color="error">mdi-minus</v-icon>
                </v-btn>

                <v-btn
                  depressed
                  icon
                  @click="
                    classesInputs.push({
                      classDescr: null,
                      capacity: maxDept,
                    })
                  "
                >
                  <v-icon color="success">mdi-plus</v-icon>
                </v-btn>
              </div>
              <div class="d-flex align-center">
                <v-text-field
                  v-model="classesInputs[i].classDescr"
                  outlined
                  dense
                  label="Περιγραφή τμήματος"
                  class="mr-4"
                  :error-messages="descriptionInputsErrors(i)"
                ></v-text-field>

                <v-text-field
                  v-model="classesInputs[i].capacity"
                  outlined
                  dense
                  label="Χωρητικότητα"
                  clearable
                  class="mr-4"
                ></v-text-field>

                <!-- <v-select
                  v-model="classesInputs[i].classRoomTpy"
                  outlined
                  dense
                  label="Τύπος"
                  :items="classTypes"
                  item-text="description"
                  item-value="cd"
                  clearable
                  :error-messages="typeInputsErrors(i)"
                ></v-select> -->
              </div>
            </div>

            <v-select
              v-model="sortingType"
              outlined
              dense
              label="Επιλογή ταξινόμησης"
              :items="items(163)"
              item-text="description"
              item-value="cd"
              clearable
              :error-messages="sortingTypeErrors"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-card-text>
      <div class="ml-auto">
        <v-btn
          :loading="loader"
          depressed
          color="primary"
          class="mr-2"
          :disabled="!filtersSet || nicStudents.content.length == 0"
          @click="onClassCreate"
        >
          Αρχικοποίηση τμημάτων
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GenenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import { required, numeric } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  components: {
    GenenericFilter,
  },

  async created() {
    try {
      await this.getEpiloghTaksinomhshs();
      await this.getClassTypes();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      tableLoader: false,
      loader: false,
      filtersSet: false,
      sortingType: null,
      query: false,
      nicStudents: [],

      classesInputs: [
        {
          classDescr: null,
          capacity: this.maxDept,
          // classRoomTpy: null,
        },
      ],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "ΑΜ",
          value: "studentCode",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },
      ],
      dialog: {
        School: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.semesterId != null) await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
    deep: true,
  },

  validations: {
    classesInputs: {
      $each: {
        classDescr: { required },
        // capacity: { required, numeric },
        // classRoomTpy: { required },
      },
    },

    sortingType: { required },
  },

  computed: {
    ...mapState({
      schoolRooms: (state) => state.school_rooms.schoolRooms,
      facultyId: (state) => state.generic_filter.facultyId,
      semesterId: (state) => state.generic_filter.semesterId,
      maxDept: (state) => state.generic_filter.maxDept,
      approveApplications: (state) => state.approve_applications,
      classTypes: (state) => state.classes.classTypes,
      epiloghTaksinomhshs: (state) => state.classes.epiloghTaksinomhshs,
      progTrainDetails: (state) => state.generic_filter.progTrainDetails,
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    sortingTypeErrors() {
      const errors = [];
      if (!this.$v.sortingType.$dirty) return errors;
      if (!this.$v.sortingType.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapMutations(["setFilters"]),
    ...mapActions([
      "getSchoolRooms",

      "createClasses",
      "getClassTypes",
      // "getEpiloghTaksinomhshs",
    ]),

    async onClassCreate() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.loader = true;

        let content = {
          progTrainPeriodId: this.semesterId,
          sortingType: this.sortingType,
          classesInputs: this.classesInputs,
        };

        await this.createClasses(content);
        this.handler();
        this.loader = false;
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },

    descriptionInputsErrors(i) {
      const errors = [];
      if (!this.$v.classesInputs.$each[i].classDescr.$dirty) return errors;
      if (!this.$v.classesInputs.$each[i].classDescr.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    capacityInputsErrors(i) {
      const errors = [];
      if (!this.$v.classesInputs.$each[i].capacity.$dirty) return errors;
      if (!this.$v.classesInputs.$each[i].capacity.required)
        errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.classesInputs.$each[i].capacity.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    // typeInputsErrors(i) {
    //   const errors = [];
    //   if (!this.$v.classesInputs.$each[i].classRoomTpy.$dirty) return errors;
    //   if (!this.$v.classesInputs.$each[i].classRoomTpy.required)
    //     errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },

    async handler() {
      try {
        this.tableLoader = true;

        this.setFilters(this.options);

        axios.defaults.headers.common["X-ACCESS-DATE"] =
          await `${this.progTrainDetails.startDate}`;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForClass`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              progTrainPeriodId: this.semesterId,
              // propertyType: 1,
              masterSchoolId: this.facultyId,
            },
          }
        );
        this.classesInputs[0].capacity = this.maxDept;
        this.nicStudents = res.data;

        this.tableLoader = false;
        if (!this.filtersSet) this.filtersSet = true;
      } catch (e) {
        console.log(e);
        this.nicStudents = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
    // async getEpiloghTaksinomhshs() {
    //   try {
    //     const res = await axios.get(
    //       `${process.env.VUE_APP_BASE_URL}/value/163`
    //     );
    //     this.items = res.data;
    //   } catch (e) {
    //     throw e;
    //   }
    // },
    items(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>