<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία βιβλιοθήκης
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col>
            <v-select
              dense
              outlined
              v-model="masterSchoolId"
              label="Σχολή"
              :items="listFaculty"
              item-text="description"
              item-value="cd"
              hide-details="auto"
              :error-messages="masterSchoolIdErrors"
              :disabled="facultyId != null"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              dense
              outlined
              hide-details="auto"
              v-model="description"
              label="Περιγραφή"
              :error-messages="descriptionErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Περιγραφή
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="memo"
              label="Σχόλια"
              hide-details="auto"
              type="text"
              class="mb-4"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  created() {
    this.masterSchoolId = this.facultyId;
  },

  data() {
    return {
      loader: false,
      memo: "",
      description: "",
      masterSchoolId: null,
    };
  },

  validations: {
    description: {
      required,
    },
    masterSchoolId: {
      required,
    },
  },

  computed: {
    ...mapState({
      facultyId: (state) => state.generic_filter.facultyId,
      listFaculty: (state) => state.generic_filter.listFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      appId: (state) => state.auth.appId,
    }),

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    masterSchoolIdErrors() {
      const errors = [];
      if (!this.$v.masterSchoolId.$dirty) return errors;
      if (!this.$v.masterSchoolId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addLibrary"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        const content = {
          description: this.description,
          masterSchoolId: this.masterSchoolId,
        };

        if (this.memo != null) {
          content.memo = this.memo;
        }

        await this.addLibrary(content);

        this.loader = false;
        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>