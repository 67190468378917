<template>
  <div class="component-wrapper d-flex justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
    >
      <v-toolbar color="primary" class="white--text">
        Σύνδεση αίτησης {{ this.application.description }} με
        {{ appId == 1 ? "σχολές" : getFieldName() }}
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-data-table
          loading-text="Φόρτωση..."
          :footer-props="{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :headers="headers"
          :items="data.content"
          :server-items-length="data.elements"
          :options.sync="options"
          :loading="tableLoader"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="onEdit(item)">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-pen
                  </v-icon>
                </v-btn>
              </template>
              <span>Επεξεργασία</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialog(item, 'delete')">
                  <v-icon color="error" small v-bind="attrs" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Διαγραφή</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <div class="d-flex justify-center mb-2">
        <v-btn depressed color="light" class="mr-2" @click="$emit('close')"
          >Πίσω</v-btn
        >
        <v-btn depressed color="primary" @click="onCreate">
          Δημιουργία
          <v-icon dense right>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'delete'"
        max-width="500px"
        scrollable
      >
        <DeleteAssignApplication
          v-if="dialog.open && dialog.type == 'delete'"
          :application="dialog.application"
          @close="closeDialog"
        />
      </v-dialog>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'add' || dialog.type == 'edit'"
        max-width="700px"
        scrollable
      >
        <v-card>
          <v-toolbar color="primary" class="white--text">
            Σύνδεση με {{ appId == 1 ? "σχολές" : getFieldName() }}
            <v-btn
              depressed
              icon
              color="white"
              class="ml-auto"
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form class="pa-4">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  v-model="description"
                  label="Περιγραφή"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  deletable-chips
                  dense
                  outlined
                  v-model="masterSchool"
                  :items="masterSchoolList"
                  item-text="description"
                  item-value="cd"
                  :error-messages="masterSchoolErrors"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    {{ appId == 1 ? "Ακαδημία" : "Κέντρο επιμόρφωσης" }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-checkbox v-model="initial" label="Αρχική"></v-checkbox>
              </v-col>
            </v-row>

            <div class="d-flex justify-center">
              <v-btn depressed color="error" @click="closeDialog" class="mr-2"
                >Ακύρωση</v-btn
              >
              <v-btn
                depressed
                color="success"
                class="mr-4"
                type="submit"
                :loading="loader"
                v-if="this.editItem == null"
                @click="onSubmit"
                >Σύνδεση
              </v-btn>
              <v-btn
                depressed
                color="success"
                class="mr-4"
                type="submit"
                :loading="loader"
                @click="onSave"
                v-else
                >Αποθήκευση
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>
    <Loader v-if="this.showLoader" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import Loader from "../../components/Loader/Loader.vue";
import DeleteAssignApplication from "../../components/Applications/DeleteAssignApplication.vue";
import Helpers from "../../helpers/helpers";

export default {
  props: ["application"],
  components: {
    DeleteAssignApplication,
    Loader,
  },
  async created() {
    try {
      this.showLoader = true;
      console.log(this.application);
      // await this.getFaculties({
      //   page: 0,
      // });
      await this.fetchFaculties();
      await this.getApplicationSchool();
      this.showLoader = false;
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      tableLoader: false,
      id: null,
      description: null,
      masterSchool: null,
      masterSchoolList: [],
      initial: false,
      loader: false,
      editItem: null,
      showLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        { text: "Περιγραφή", value: "description", sortable: false },
        { text: "Σχολή", value: "masterSchool.description", sortable: false },
        { text: "Επιλογές", value: "actions", sortable: false },
      ],
      data: { content: [], elements: 0 },
      dialog: {
        application: null,
        open: false,
        type: null,
      },
    };
  },

  validations: {
    masterSchool: {
      required,
    },
  },
  watch: {
    async options() {
      await this.getApplicationSchool();
    },
  },

  computed: {
    ...mapState({
      faculties: (state) => state.faculties.faculties,
      appId: (state) => state.auth.appId,
    }),

    masterSchoolErrors() {
      const errors = [];
      if (!this.$v.masterSchool.$dirty) return errors;
      if (!this.$v.masterSchool.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["assignApplication", "editAssignApplication"]),
    ...mapMutations(["setFilters", "changePage"]),
    async fetchFaculties() {
      let res = await this.fetchDataDynamic(2000, null);
      this.masterSchoolList = res.data;
    },

    getFieldName() {
      return Helpers.getFieldName(2);
    },
    async onSubmit() {
      try {
        event.preventDefault();
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          masterSchoolId: this.masterSchool,
          initial: this.initial,
          commonOnlineApplicationId: this.application.id,
        };
        if (this.description != null) content.description = this.description;

        await this.assignApplication({
          content,
        });
        this.loader = false;
        this.$v.$reset();
        this.closeDialog();
        this.getApplicationSchool();
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
    async onSave() {
      try {
        event.preventDefault();
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          masterSchoolId: this.masterSchool,
          initial: this.initial,
          commonOnlineApplicationId: this.application.id,
          id: this.id,
        };
        if (this.description != null) content.description = this.description;

        await this.editAssignApplication({
          content,
        });
        this.loader = false;
        console.log("onClose");
        this.getApplicationSchool();
        this.$v.$reset();
        this.description = null;
        this.masterSchool = null;
        this.closeDialog();
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
    onEdit(item) {
      this.editItem = item;
      console.log(item.masterSchool.id);
      this.masterSchool = item.masterSchool.id.toString();
      this.description = item.description;
      this.initial = item.initial;
      this.id = item.id;
      this.openDialog(null, "edit");
    },
    onCreate() {
      this.masterSchool = null;
      this.description = null;
      this.initial = null;
      this.id = null;
      this.editItem = null;
      this.openDialog(null, "add");
    },

    openDialog(application, type) {
      this.dialog = {
        application,
        open: true,
        type,
      };
    },

    closeDialog() {
      if (this.dialog.type == "delete") this.getApplicationSchool();
      this.dialog = {
        application: null,
        open: false,
        type: null,
      };

      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },

    async getApplicationSchool() {
      try {
        this.setFilters(this.options);
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/online/application/list`,
          {
            params: {
              cmnOnlineApplicationId: this.application.id,
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
            },
          }
        );
        this.data = res.data;
      } catch (e) {
        throw e;
      }
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
  },
  // watch: {
  //   options: {
  //     async handler() {
  //       this.getApplicationSchool();
  //     },
  //   },
  // },
};
</script>

<style scoped lang="scss">
</style>