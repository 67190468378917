<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card width="100%" height="100%" class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table 
            :class="miniMenu?'divContent_miniMenuTrue':'divContent_miniMenuFalse'"
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="roles.content"
            :loading="tableLoader"
            :options.sync="options"
            :server-items-length="roles.elements"
          >
            <template v-slot:[`item.privileges`]="{ item }">
              <span
                v-for="(privilege, i) in item.privileges"
                :key="privilege.id"
              >
                {{ privilege.name
                }}<span v-if="i != item.privileges.length - 1">,</span>
              </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.menu`]="{ item }">
              <v-btn depressed icon @click="openMenu(item, 'edit')">
                <v-icon color="primary" small>
                  mdi-text-box-search-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
          <div class="custom-card-buttons">
            <!-- Not need to create a new role -->
            <v-btn depressed color="primary ml-auto" @click="openDialog(null, 'add')">
              Δημιουργία
              <v-icon dense right>mdi-plus</v-icon>
            </v-btn>            
          </div>
      </v-card>
    </v-card>
    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="500px"
      scrollable
    >
      <AddRole
        v-if="dialog.open && dialog.type == 'add'"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="500px"
      scrollable
    >
      <EditRole
        v-if="dialog.open && dialog.type == 'edit'"
        :role="dialog.role"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteRole
        v-if="dialog.open && dialog.type == 'delete'"
        :role="dialog.role"
        @close="closeDialog"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="menuDialog.open"
      max-width="800px"
      max-height="600px"
      scrollable
    >
      <MenuRole
        v-if="menuDialog.open"
        :role="menuDialog.role"
        @close="closeMenu"
      />
    </v-dialog>
  </div>
</template>

<script>
import AddRole from "../../components/RoleModals/AddRole.vue";
import EditRole from "../../components/RoleModals/EditRole.vue";
import DeleteRole from "../../components/RoleModals/DeleteRole.vue";
import MenuRole from "../../components/MenuModal/MenuRoleModal.vue";

import { mapState, mapMutations, mapActions } from "vuex";
export default {
  components: {
    DeleteRole,
    AddRole,
    EditRole,
    MenuRole,
  },

  async created() {
    try {
      this.getPrivileges();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      tableLoader: true,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Κατηγορία",
          value: "schoolType.description",
          sortable: false,
          width: "100px",
        },
        {
          text: "Όνομα",
          value: "name",
          sortable: false,
          width: "100px",
        },
        {
          text: "Περιγραφή",
          value: "description",
          sortable: false,
        },
        // {
        //   text: "Δικαιώματα πρόσβασης",
        //   value: "privileges",
        //   sortable: false,
        // },
        {
          text: "Συστεμικός ρόλος",
          value: "systemRole",
          sortable: false,
        },
        {
          text: "Default Page",
          value: "defaultPage",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
        {
          text: "Μενού",
          value: "menu",
          sortable: false,
        },
      ],
      dialog: {
        open: false,
        type: null,
        role: null,
      },
      menuDialog: {
        open: false,
        role: null,
      },
    };
  },
  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);
          await this.getRoles({
            page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
            limit:
              this.options.itemsPerPage == -1
                ? null
                : this.options.itemsPerPage,
          });
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.$store.dispatch("errorHandler", e);

          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      roles: (state) => state.roles.roles,
      pageChanged: (state) => state.base.pageChanged,
      miniMenu: (state) => state.base.miniMenu,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getRoles", "getPrivileges"]),
    openDialog(role, type) {
      this.dialog = {
        role,
        open: true,
        type,
      };
    },
    closeDialog() {
      this.dialog = {
        role: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
    openMenu(role) {
      this.menuDialog = {
        open: true,
        role: role,
      };
    },
    closeMenu() {
      this.menuDialog = {
        open: false,
        role: null,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen and (min-width: 600px) {
  .v-data-table {
    /*overflow-x: scroll !important;*/
    /*
    display: block;
    width: calc(100vw - 400px);
    overflow-x: auto;
    */
  }
  .divContent_miniMenuTrue {
    width: calc(100vw - 150px);
    overflow-x: auto;
  }
  .divContent_miniMenuFalse {
    width: calc(100vw - 400px);
    overflow-x: auto;
  }
}
</style>