<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      id="form"
    >
      <v-card class="mb-4">
        <v-card-title class="mb-4 greyish--text">
          Προσωπικά στοιχεία
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                v-model="firstName"
                type="text"
                hide-details="auto"
                :error-messages="firstNameErrors"
              >
                <template v-slot:label>
                  Όνομα <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                v-model="lastName"
                type="text"
                hide-details="auto"
                :error-messages="lastNameErrors"
              >
                <template v-slot:label>
                  Επώνυμο <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                v-model="fatherName"
                type="text"
                hide-details="auto"
                :error-messages="fatherNameErrors"
              >
                <template v-slot:label>
                  Πατρώνυμο <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                v-model="motherName"
                label="Μητρώνυμο "
                hide-details="auto"
                type="text"
                :error-messages="motherNameErrors"
              >
                <template v-slot:label>
                  Μητρώνυμο <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    v-model="dateOfBirthFormatted"
                    prepend-inner-icon="mdi-calendar"
                    class="mb-4"
                    hide-details="auto"
                    @blur="
                      dateOfBirth = parseDate(
                        dateOfBirthFormatted,
                        'dateOfBirth'
                      )
                    "
                    :error-messages="dateOfBirthErrors"
                  >
                    <template v-slot:label>
                      Ημερομηνία γέννησης <span class="error--text">*</span>
                    </template>
                    <template v-slot:prepend-inner>
                      <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="el"
                  v-model="dateOfBirth"
                  scrollable
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                v-model="countryOfBirth"
                type="text"
                :error-messages="countryOfBirthErrors"
                hide-details="auto"
              >
                <template v-slot:label>
                  Χώρα γέννησης <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                v-model="regionOfBirth"
                :error-messages="regionOfBirthErrors"
                type="text"
                hide-details="auto"
              >
                <template v-slot:label>
                  Περιοχή γέννησης <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                v-model="cityOfBirth"
                type="text"
                hide-details="auto"
                :error-messages="cityOfBirthErrors"
              >
                <template v-slot:label>
                  Πόλη γέννησης <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-select
                clearable
                dense
                outlined
                v-model="sex"
                :error-messages="sexIdErrors"
                :items="getDataStaticList(128)"
                item-text="description"
                hide-details="auto"
                item-value="cd"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
                <template v-slot:label>
                  Φύλο <span class="red--text">*</span>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                v-model="municipalRegister"
                label="Δημοτολόγιο "
                type="text"
                :error-messages="municipalRegisterErrors"
              >
                <template v-slot:label>
                  Δημοτολόγιο <span class="red--text">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-select
                dense
                outlined
                v-model="roleId"
                placeholder="Ρόλος"
                :items="roles"
                item-text="description"
                item-value="id"
                class="roleSelected"
                :error-messages="roleIdErrors"
                hide-details="auto"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
                <template v-slot:label>
                  Ρολος <span class="red--text">*</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title class="mb-4 greyish--text"> Εγγραφή </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                clearable
                dense
                outlined
                v-model="propertyType"
                class="mb-4"
                item-text="name"
                item-value="id"
                :items="types"
                hide-details="auto"
                @change="onChangeType"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
                <template v-slot:label>
                  Τύπος <span class="red--text">*</span>
                </template>
              </v-select></v-col
            >
            <v-col>
              <v-select
                dense
                outlined
                label="Βαθμίδα-Ειδικότητα *"
                v-model="teacherCategoryId"
                :items="teacherCategoryList"
                :item-text="
                  (item) => item.groupType.description + '-' + item.description
                "
                item-value="id"
                hide-details="auto"
                :error-messages="teacherCategoryIdErrors"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    v-model="startDateFormatted"
                    prepend-inner-icon="mdi-calendar"
                    class="mb-4"
                    :error-messages="startDateErrors"
                    hide-details="auto"
                    @blur="
                      startDate = parseDate(startDateFormatted, 'startDate')
                    "
                  >
                    <template v-slot:label>
                      Ημερομηνία έναρξης <span class="error--text">*</span>
                    </template>
                    <template v-slot:prepend-inner>
                      <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="el"
                  v-model="startDate"
                  scrollable
                  @input="menu2 = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                ref="menu"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    v-model="endDateFormatted"
                    label="Ημερομηνία λήξης"
                    prepend-inner-icon="mdi-calendar"
                    class="mb-4"
                    hide-details="auto"
                    @blur="endDate = parseDate(endDateFormatted, 'endDate')"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="el"
                  v-model="endDate"
                  scrollable
                  @input="menu1 = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-select
                dense
                outlined
                v-model="masterSchoolId"
                :items="listFaculty"
                item-text="description"
                item-value="cd"
                @change="onSetFaculty"
                hide-details="auto"
                :error-messages="masterSchoolIdErrors"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
                <template v-slot:label>
                  {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης"
                  }}<span class="red--text">*</span>
                </template>
              </v-select>
            </v-col>
            <v-col>
              <v-select
                dense
                outlined
                label="Σχολή"
                v-model="schoolId"
                :items="listSchool"
                item-text="description"
                item-value="cd"
                hide-details="auto"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
                <template v-slot:label>
                  {{ appId == 1 ? "Σχολή" : getFieldName()
                  }}<span class="red--text">*</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="d-flex align-center ml-auto mt-2">
        <v-btn depressed color="error" class="mr-2" @click="$router.push('/teachers')"
          >Επιστροφή
        </v-btn>
        <v-btn
          depressed
          color="primary"
          
          @click="onCreate"
          :loading="loader"
          >Αποθήκευση
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Helpers from "../../helpers/helpers";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AddTeacher",
  async created() {
    this.loadFaculties();
    this.fetchRoles();
    this.fetchProfCategories();
    this.propertyType = "3";
  },

  components: {},

  data() {
    return {
      menu: null,
      menu1: null,
      menu2: null,
      pageLoader: false,
      loader: false,
      cardLoader: false,
      types: [{ id: "3", name: "Εκπαιδευτικός" }],
      listFaculty: [],
      listSchool: [],
      roles: [],
      firstName: null,
      lastName: null,
      fatherName: null,
      motherName: null,
      countryOfBirth: null,
      countryOfBirthLatin: null,
      dateOfBirth: null,
      dateOfBirthFormatted: null,
      cityOfBirth: null,
      regionOfBirth: null,
      dateEnd: null,
      sex: null,
      municipalRegister: null,
      propertyType: null,
      startDate: null,
      startDateFormatted: null,
      endDate: null,
      endDateFormatted: null,
      masterSchoolId: null,
      schoolId: null,
      roleId: null,
      teacherCategoryId: null,
      teacherCategoryList: [],
    };
  },

  validations: {
    firstName: {
      required,
    },

    lastName: {
      required,
    },

    fatherName: {
      required,
    },

    motherName: {
      required,
    },

    countryOfBirth: {
      required,
    },

    dateOfBirth: {
      required,
    },

    cityOfBirth: {
      required,
    },

    sex: {
      required,
    },
    municipalRegister: {
      required,
    },
    regionOfBirth: {
      required,
    },
    propertyType: {
      required,
    },
    masterSchoolId: {
      required,
    },
    startDate: {
      required,
    },
    roleId: {
      required,
    },
    teacherCategoryId: {
      required,
    },
  },

  watch: {
    dateOfBirth(val) {
      this.dateOfBirthFormatted = this.formatDate(this.dateOfBirth);
    },

    addressDateFrom(val) {
      this.addressDateFromFormatted = this.formatDate(this.addressDateFrom);
    },

    addressDateTo(val) {
      this.addressDateToFormatted = this.formatDate(this.addressDateTo);
    },
    startDate() {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate() {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      staticList: (state) => state.auth.userInfo.staticView,
      appId: (state) => state.auth.appId,
      pageChanged: (state) => state.base.pageChanged,
      selectedRole: (state) => state.auth.selectedRole,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
    }),

    //validations
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      if (!this.$v.firstName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      if (!this.$v.lastName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    fatherNameErrors() {
      const errors = [];
      if (!this.$v.fatherName.$dirty) return errors;
      if (!this.$v.fatherName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    motherNameErrors() {
      const errors = [];
      if (!this.$v.motherName.$dirty) return errors;
      if (!this.$v.motherName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    countryOfBirthErrors() {
      const errors = [];
      if (!this.$v.countryOfBirth.$dirty) return errors;
      if (!this.$v.countryOfBirth.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    dateOfBirthErrors() {
      const errors = [];
      if (!this.$v.dateOfBirth.$dirty) return errors;
      if (!this.$v.dateOfBirth.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    cityOfBirthErrors() {
      const errors = [];
      if (!this.$v.cityOfBirth.$dirty) return errors;
      if (!this.$v.cityOfBirth.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    regionOfBirthErrors() {
      const errors = [];
      if (!this.$v.regionOfBirth.$dirty) return errors;
      if (!this.$v.regionOfBirth.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    sexIdErrors() {
      const errors = [];
      if (!this.$v.sex.$dirty) return errors;
      if (!this.$v.sex.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    municipalRegisterErrors() {
      const errors = [];
      if (!this.$v.municipalRegister.$dirty) return errors;
      if (!this.$v.municipalRegister.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    propertyTypeErrors() {
      const errors = [];
      if (!this.$v.propertyType.$dirty) return errors;
      if (!this.$v.propertyType.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    masterSchoolIdErrors() {
      const errors = [];
      if (!this.$v.masterSchoolId.$dirty) return errors;
      if (!this.$v.masterSchoolId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    roleIdErrors() {
      const errors = [];
      if (!this.$v.roleId.$dirty) return errors;
      if (!this.$v.roleId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;
      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    teacherCategoryIdErrors() {
      const errors = [];
      if (!this.$v.teacherCategoryId.$dirty) return errors;
      if (!this.$v.teacherCategoryId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["editUser", "setCurrentUser"]),
    async onCreate() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          //todo scroll gia mobile scroll sto prwto error
          document
            .getElementById("form")
            .scrollIntoView({ behavior: "smooth" });
          return;
        }
        this.loader = true;
        let content = {
          cityOfBirth: this.cityOfBirth,
          countryOfBirth: this.countryOfBirth,
          regionOfBirth: this.regionOfBirth,
          dateOfBirth: this.dateOfBirth,
          fatherName: this.fatherName,
          firstName: this.firstName,
          lastName: this.lastName,
          motherName: this.motherName,
          sex: this.sex,
          municipalRegister: this.municipalRegister,
          masterSchoolId: this.masterSchoolId,
          schoolId: this.schoolId,
          roleId: this.roleId,
          propertyType: this.propertyType,
          startDate: this.startDate,
          endDate: this.endDate,
          teacherCategoryId: this.teacherCategoryId,
        };

        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/person/teacher`,
          content
        );

        if (res.status != 201) {
          this.$store.dispatch("toggleSnackbar", {
            open: true,
            text: `Σφάλμα. παρακαλώ ξαναπροσπαθήστε σε λίγο`,
            color: "error",
          });
        } else {
          this.$store.dispatch("toggleSnackbar", {
            open: true,
            text: "Η αποθήκευση της εγγραφής ολοκληρώθηκε με επιτυχία",
            color: "success",
          });
          this.$router.push("/teachers");
        }

        this.loader = false;
      } catch (e) {
        this.$router.dispatch("errorHandler", e);
        this.loader = false;
      }
    },
    getFieldName() {
      return Helpers.getFieldName(1);
    },

    async fetchProfCategories() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/category/list`,
          {
            params: {
              page: 0,
            },
          }
        );
        this.teacherCategoryList = res.data.content;
      } catch (e) {
        console.log(e);
        this.$store.dispatch("errorHandler", e);
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
    onChangeType() {
      if (this.propertyType == "9") {
        this.schoolId = null;
      }
    },

    async loadFaculties() {
      let result = await this.fetchDataDynamic(2000, null);
      this.listFaculty = result.data;
    },
    async onSetFaculty(value) {
      // call end point tou get schools 2001
      let result = await this.fetchDataDynamic(2001, value);
      this.listSchool = result.data;
    },
    async fetchRoles() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/role/list/view`,
          {
            params: {
              page: 0,
              roleType: 3,
            },
          }
        );
        this.roles = res.data.content;
      } catch (e) {
        throw e;
      }
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        groupId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>