<template>
  <v-card :loading="cardLoader">
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία αίτησης
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="categoryType"
              label="Κατηγοριοποίηση αιτήσεων"
              class="mb-4"
              :error-messages="categoryTypeErrors"
              :items="categoryTypes"
              item-text="description"
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <v-textarea
              dense
              hide-details="auto"
              outlined
              rows="3"
              v-model="description"
              label="Περιγραφή"
              :error-messages="descriptionErrors"
              no-data-text="Δεδομένα μη διαθέσιμα"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="approveLevel"
                label="Επίπεδα εγκρίσεων"
                class="mb-4"
                clearable
                :items="approvalLevels"
                item-text="text"
                item-value="id"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="reportId"
                label="Εκτύπωση"
                class="mb-4"
                :items="reportList"
                clearable
                item-text="description"
                item-value="id"
                no-data-text="Δεδομένα μη διαθέσιμα"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-row>

        <div
          v-for="(input, i) in commonOnlineApplicationSetupList.length"
          :key="i"
        >
          <div class="d-flex justify-space-between">
            <div>
              <span class="body-1">Πεδίο: {{ i + 1 }}</span>
            </div>
            <div>
              <v-btn plain icon color="error" @click="onInputRemove(i)">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn
                plain
                @click="atAddInput"
                class="ml-auto"
                color="success"
                icon
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
          <hr />

          <v-row class="mt-2">
            <v-col sm="12" md="8">
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="commonOnlineApplicationSetupList[i].lineAa"
                    :error-messages="lineAaErrors(i)"
                    label="Αριθμός γραμμής"
                    class="mb-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    dense
                    outlined
                    v-model="commonOnlineApplicationSetupList[i].positionAa"
                    label="Θέση εμφάνισης στην ίδια γραμμή"
                    :error-messages="positionAaErrors(i)"
                    class="mb-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    dense
                    outlined
                    v-model="commonOnlineApplicationSetupList[i].label"
                    label="Ετικέτα πεδίου"
                    class="mb-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    v-model="commonOnlineApplicationSetupList[i].type"
                    label="Τύπος πεδίου"
                    class="mb-4"
                    :items="types"
                    item-text="description"
                    item-value="cd"
                    :error-messages="typeErrors(i)"
                    dense
                    clearable
                    outlined
                    no-data-text="Δεδομένα μη διαθέσιμα"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    v-model="commonOnlineApplicationSetupList[i].alignment"
                    label="Ευθυγράμμιση"
                    class="mb-4"
                    :items="aligments"
                    item-text="description"
                    item-value="cd"
                    :error-messages="aligmentErrors(i)"
                    dense
                    clearable
                    outlined
                    no-data-text="Δεδομένα μη διαθέσιμα"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Περισσότερα
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-text-field
                      dense
                      outlined
                      v-model="commonOnlineApplicationSetupList[i].convertExp"
                      label="Mετατροπή έκφρασης για τιμή πεδίου"
                      class="mb-4"
                      hide-details="true"
                    ></v-text-field>

                    <v-text-field
                      dense
                      outlined
                      v-model="commonOnlineApplicationSetupList[i].fieldName"
                      label="Πεδίο αποθήκευσης"
                      class="mb-4"
                      hide-details="true"
                    ></v-text-field>
                    <v-text-field
                      dense
                      outlined
                      v-model="commonOnlineApplicationSetupList[i].fieldSize"
                      label="Μέγεθος πεδίου 1-12 (12 ολόκληρη γραμμή)"
                      class="mb-4"
                      min="1"
                      max="12"
                      hide-details="true"
                    ></v-text-field>

                    <v-select
                      v-model="commonOnlineApplicationSetupList[i].flgAttach"
                      label="Ένδειξη υποχρεωτικότητας επισύναψης αρχείου"
                      class="mb-4"
                      :items="apops"
                      item-text="description"
                      item-value="cd"
                      clearable
                      hide-details
                      dense
                      outlined
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                    </v-select>

                    <!-- <v-text-field
                      dense
                      outlined
                      v-model="commonOnlineApplicationSetupList[i].lovId"
                      label="Κωδικός LOV"
                      class="mb-4"
                      hide-details="true"
                    ></v-text-field> -->
                    <v-autocomplete
                      dense
                      outlined
                      v-model="commonOnlineApplicationSetupList[i].lovId"
                      label="Κωδικός LOV"
                      :items="lovCodes"
                      item-text="description"
                      item-value="cd"
                    >
                    </v-autocomplete>

                    <v-autocomplete
                      dense
                      outlined
                      v-model="commonOnlineApplicationSetupList[i].editLovId"
                      label="Κωδικός edit LOV"
                      :items="lovCodes"
                      item-text="description"
                      item-value="cd"
                    >
                    </v-autocomplete>
                    <v-text-field
                      dense
                      outlined
                      v-model="
                        commonOnlineApplicationSetupList[i].lovSelectStatement
                      "
                      label="Λίστα τιμών απο πίνακα με select"
                      class="mb-4"
                      hide-details="true"
                    ></v-text-field>

                    <!-- <v-text-field
                      dense
                      outlined
                      v-model="commonOnlineApplicationSetupList[i].parentRefId"
                      label="Εξαρτώμενο ηδη καταχωρημένο πεδιο"
                      class="mb-4"
                      hide-details="true"
                    ></v-text-field> -->
                    <v-select
                      v-model="commonOnlineApplicationSetupList[i].parentRefId"
                      label="Εξαρτώμενο ηδη καταχωρημένο πεδιο"
                      class="mb-4"
                      :items="listExtrFields"
                      hide-details="true"
                      item-text="description"
                      item-value="cd"
                      clearable
                      dense
                      outlined
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                    </v-select>
                    <v-select
                      v-model="commonOnlineApplicationSetupList[i].defaultValue"
                      label="Αρχική τιμή"
                      v-if="defaultValues.length > 0"
                      class="mb-4"
                      :items="defaultValues"
                      hide-details="true"
                      item-text="description"
                      clearable
                      item-value="cd"
                      dense
                      outlined
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                    </v-select>

                    <v-select
                      v-model="commonOnlineApplicationSetupList[i].required"
                      label="Ένδειξη υποχρεωτικότητας πεδίου"
                      class="mb-4"
                      :items="apops"
                      item-text="description"
                      item-value="cd"
                      dense
                      outlined
                      clearable
                      hide-details="true"
                      no-data-text="Δεδομένα μη διαθέσιμα"
                    >
                    </v-select>

                    <v-text-field
                      dense
                      outlined
                      v-model="commonOnlineApplicationSetupList[i].tblName"
                      label="Πίνακας αποθήκευσης"
                      class="mb-4"
                      hide-details="true"
                    ></v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- <v-btn plain @click="atAddInput" class="ml-auto" color="success" icon>
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
      </v-form>
    </v-card-text>
    <div class="d-flex justify-center btn-container">
      <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
      <v-btn
        depressed
        color="success"
        class="ml-4"
        type="submit"
        :loading="loader"
        @click="onSubmit"
        >Αποθήκευση
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  props: ["application"],
  async created() {
    try {
      this.cardLoader = true;
      this.categoryType = this.application.categoryType.cd;
      this.description = this.application.description;
      this.approveLevel = this.application.approveLevel;
      this.reportId = this.application?.reportId;
      if (this.application.commonOnlineApplicationSetupList != undefined) {
        this.commonOnlineApplicationSetupList =
          this.application.commonOnlineApplicationSetupList.map((m) => {
            console.log(m.parentRefId);
            return {
              ...m,
              parentRefId:
                m.parentRefId == undefined ? null : String(m.parentRefId),
            };
          });

        this.commonOnlineApplicationSetupList.filter(
          (el) => (el.required = el.required?.cd)
        );

        this.commonOnlineApplicationSetupList.filter(
          (el) => (el.flgAttach = el.flgAttach?.cd)
        );
      }
      await this.getCategoryTypes();
      await this.getApops();
      await this.getDefaultValues();
      await this.extrFields();
      await this.fetchLovCodes();
      await this.fetchReportList();

      this.cardLoader = false;
    } catch (e) {
      this.cardLoader = false;
      throw e;
    }
  },

  data() {
    return {
      categoryType: null,
      commonOnlineApplicationSetupList: [],
      description: null,
      approveLevel: null,
      cardLoader: false,
      loader: false,
      reportId: null,
      reportList: [],
      aligments: [
        { description: "Αριστερά", cd: 1 },
        { description: "Δεξιά", cd: 2 },
        { description: "Κέντρο", cd: 3 },
        { description: "Αρχή", cd: 4 },
        { description: "Τέλος", cd: 5 },
      ],
      types: [
        { description: "label without field", cd: "0" },
        { description: "alpha", cd: "1" },
        { description: "Number", cd: "2" },
        { description: "date(simple)", cd: "3" },
        { description: "check box", cd: "4" },
        { description: "attach file", cd: "5" },
        { description: "lov", cd: "6" },
        { description: "text area", cd: "7" },
      ],
      defaultValues: [],
      listExtrFields: [],
      lovCodes: [],
      approvalLevels: [
        { id: 1, text: 1 },
        { id: 2, text: 2 },
      ],
    };
  },

  validations: {
    categoryType: {
      required,
    },
    description: {
      required,
    },
    commonOnlineApplicationSetupList: {
      $each: {
        lineAa: {
          required,
        },
        positionAa: {
          required,
        },
        type: {
          required,
        },

        alignment: {
          required,
        },
      },
    },
  },

  computed: {
    ...mapState({
      categoryTypes: (state) => state.configure_applications.categoryTypes,
      apops: (state) => state.schools.apops,
      appId: (state) => state.auth.appId,
    }),

    categoryTypeErrors() {
      const errors = [];
      if (!this.$v.categoryType.$dirty) return errors;
      if (!this.$v.categoryType.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["getCategoryTypes", "getApops", "editApplication"]),

    lineAaErrors(i) {
      const errors = [];
      if (!this.$v.commonOnlineApplicationSetupList.$each[i].lineAa.$dirty)
        return errors;
      if (!this.$v.commonOnlineApplicationSetupList.$each[i].lineAa.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    positionAaErrors(i) {
      const errors = [];
      if (!this.$v.commonOnlineApplicationSetupList.$each[i].positionAa.$dirty)
        return errors;
      if (
        !this.$v.commonOnlineApplicationSetupList.$each[i].positionAa.required
      )
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    typeErrors(i) {
      const errors = [];
      if (!this.$v.commonOnlineApplicationSetupList.$each[i].type.$dirty)
        return errors;
      if (!this.$v.commonOnlineApplicationSetupList.$each[i].type.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    aligmentErrors(i) {
      const errors = [];
      if (!this.$v.commonOnlineApplicationSetupList.$each[i].alignment.$dirty)
        return errors;
      if (!this.$v.commonOnlineApplicationSetupList.$each[i].alignment.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    atAddInput() {
      this.commonOnlineApplicationSetupList.push({
        aligment: null,
        convertExp: null,
        fieldName: null,
        fieldSize: null,
        flgAttach: null,
        label: null,
        lineAa: null,
        lovId: null,
        editLovId: null,
        lovSelectStatement: null,
        parentRefId: null,
        positionAa: null,
        required: null,
        tblName: null,
        type: null,
      });
    },

    onInputRemove(i) {
      this.commonOnlineApplicationSetupList.splice(i, 1);
    },

    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          categoryType: this.categoryType,
          description: this.description,
          type: this.appId,
          approveLevel: this.approveLevel,
          reportId: this.reportId,
        };

        for (
          let index = 0;
          index < this.commonOnlineApplicationSetupList.length;
          index++
        ) {
          this.clean(this.commonOnlineApplicationSetupList[index]);
        }
        content.commonOnlineApplicationSetupList =
          this.commonOnlineApplicationSetupList;

        await this.editApplication({
          content,
          id: this.application.id,
          appId: this.appId,
        });
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },

    async getDefaultValues() {
      let body = {
        type: this.appId,
        parentCd: null,
        roleId: null,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2222`,
          body
        );
        res.data.forEach((m) => {
          this.defaultValues.push(m);
        });
      } catch (e) {
        throw e;
      }
    },
    async extrFields() {
      let result = await this.fetchDataDynamic(2224, this.application.id);
      this.listExtrFields = result.data;
    },
    async fetchLovCodes() {
      let result = await this.fetchDataDynamic(2223, null);
      this.lovCodes = result.data;
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },

    async fetchReportList() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/report/list/all`,
          {
            params: {
              page: 0,
              category: 4,
            },
          }
        );
        this.reportList = res.data.content;
      } catch (e) {
        this.reportList = [];
        throw e;
      }
    },
  },
};
</script>

<style scope lang="scss">
.btn-container {
  padding: 10px;
  border-top: 1px solid #00000024;
}
</style>