<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Προβολή μαθήματος
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-card class="mb-4">
        <v-card-title class="mb-4">Βασικά στοιχεία </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Κωδικός:</span>
              <span class="body-1">{{ course.cd }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Περιγραφή:</span>
              <span class="body-1">{{ course.description }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Σύντομη περιγραφή:</span>
              <span class="body-1">{{ course.shortDescription }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-title class="mb-4">Επιπλέον πληροφορίες </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Είδος μαθήματος:</span>
              <span class="body-1">{{ course.lessonType.description }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Ώρες ανά βδομάδα:</span>
              <span class="body-1">{{ course.hoursPerWeek }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Ώρες εργαστηρίου ανά βδομάδα:</span>
              <span class="body-1">{{ course.labHoursPerWeek }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Σύνολο ωρών:</span>
              <span class="body-1">{{ course.totalHours }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Σύνολο εργαστηριακών ωρών:</span>
              <span class="body-1">{{ course.totalLabHours }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Είδος εξέτασης μαθήματος:</span>
              <span class="body-1">{{ course.examType.description }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2"
                >Αριθμός εξεταστικών περιόδων που μπορεί να εξεταστεί
                κάποιος:</span
              >
              <span class="body-1">{{ course.examPeriodNr }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Χρόνος επανάληψης:</span>
              <span class="body-1">{{ course.periodicity }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2"
                >Μέγιστο χρόνος που μπορεί να οφείλεται:</span
              >
              <span class="body-1">{{ course.examinationTime }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-title class="mb-4">Απουσίες και βαθμολογίες </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Σύνολο αδικαιολόγητων απουσιών:</span>
              <span class="body-1">{{ course.unjustifiedAbsences }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Σύνολο απουσιών:</span>
              <span class="body-1">{{ course.totalAbsences }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2">Βάση βαθμολογίας μαθήματος:</span>
              <span class="body-1">{{ course.passGrade }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2"
                >Ελάχιστο όριο βαθμολογίας μαθήματος:</span
              >
              <span class="body-1">{{ course.minGrade }}</span>
            </v-col>

            <v-col
              col="12"
              sm="6"
              md="3"
              class="d-flex align-center justify-start"
            >
              <span class="body-1 mr-2"
                >Μέγιστο όριο βαθμολογίας μαθήματος:</span
              >
              <span class="body-1">{{ course.maxGrade }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row class="mb-4">
        <v-col col="12" sm="6" md="3" class="d-flex align-center justify-start">
          <span class="body-1 mr-2">Σχόλια:</span>
          <span class="body-1">{{ course.memo }}</span>
        </v-col>

        <v-col col="12" sm="6" md="3" class="d-flex align-center justify-start">
          <span class="body-1 mr-2">Ενεργό μάθημα:</span>
          <span class="body-1">{{ course.flgActive.description }}</span>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["course"],
};
</script>

<style scoped lang="scss">
</style>