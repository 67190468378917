<template>
  <v-card color="transparent" class="component-wrapper d-flex align-center justify-center">
    <v-card max-width="1400px" width="100%" height="100%" class="pa-4 d-flex flex-column" elevation="0" color="#EAF1F5">
      <ExamFilter :level="5" :key="query" @onSearchAction="onSearch" :requiredFields="{
        masterSchool: true,
        school: true,
        examYear: true,
        progExamPeriod: true,
        examClass: true,
      }"/>
      <!--
      <div>
        <v-row>
          <v-col cols="12" md="6" sm="4">
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="exmDateFormatted"
                  class="mb-4"
                  @blur="exmDate = parseDate(exmDateFormatted, 'exmDate')"
                  :error-messages="exmDateErrors"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία έναρξης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="exmDate"
                scrollable
                @input="dateMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      -->
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <div>
            <v-row>
              <v-col cols="12" md="3" sm="12">
                <v-menu ref="dateMenu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense outlined v-model="exmDateFormatted" class="mb-4"
                                  @blur="exmDate = parseDate(exmDateFormatted, 'exmDate')"
                                  :error-messages="exmDateErrors">
                      <template v-slot:prepend-inner>
                        <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                      </template>
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ημερομηνία εξέτασης
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker locale="el" v-model="exmDate" scrollable @input="dateMenu = false">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="9" v-if="progExam && progExam.endDate">
                <h4 style="line-height: 3">
                  Ημερομηνία Τελευταίας Καταχώρησης: {{ progExamEndDate }}
                </h4>
              </v-col>
            </v-row>
          </div>
          <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          }" :headers="getHeaders" :items="examGrades.hashContent" :options.sync="options"
                        :server-items-length="examGrades.elements" :loading="tableLoader">
            <template v-slot:[`item.grade`]="{ item }">
              <span v-for="(grade, i) in gradeList" :key="i">
                <span v-if="grade.person_id == item.person_id">
                  <v-text-field v-model="gradeList[i].grade" dense single-line :error-messages="errorMessages(i)"
                                @change="
                      () => {
                        setMo(i);
                      }
                    " v-if="edit"></v-text-field>

                  <span v-else>{{ item.written }}</span>
                </span>
              </span>
            </template>
            <template v-slot:[`item.mo`]="{ item }">
              <span v-for="(grade, i) in gradeList" :key="i">
                <span v-if="grade.person_id == item.person_id">
                  <v-text-field v-model="gradeList[i].mo" dense single-line hide-details v-if="edit" disabled
                                :style="(item.mo < item.pass_grade || item.mo == 'ΔΠ')? 'color:red' : ''"></v-text-field>

                  <span v-else :style="(item.mo < item.pass_grade || item.mo == 'ΔΠ')? 'color:red' : ''">{{
                      item.mo
                    }}</span>
                </span>
              </span>
            </template>
            <template v-slot:[`item.absenceFlag`]="{ item }">
              <span v-for="(grade, i) in gradeList" :key="i">
                <span v-if="grade.person_id == item.person_id">
                  <v-checkbox v-if="edit" v-model="gradeList[i].absenceFlg" label="Δεν προσήλθε"></v-checkbox>

                  <span v-else>{{
                      item.absence_flg
                          ? "Δεν προσήλθε"
                          : item.absence_flg == true
                              ? "Προσήλθε"
                              : ""
                    }}</span>
                </span>
              </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <span v-if="item.written != null">
                <div v-if="!edit">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed icon @click="openDialog(item, 'delete')">
                        <v-icon color="error" small v-bind="attrs" v-on="on">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </div>
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn depressed color="primary" v-if="showMassiveAction" class="mr-2"
                 :disabled="!filtersSet || exmDate == null" @click="openDialogMassive">
            Μαζική ενημέρωση
          </v-btn>
          <v-btn depressed color="primary" class="mr-2" :disabled="!filtersSet || examGrades.hashContent.length == 0"
                 v-if="!edit" @click="edit = !edit">
            Επεξεργασία
            <v-icon dense right>mdi-pencil-outline</v-icon>
          </v-btn>

          <v-btn depressed color="error" class="mr-2" v-if="edit" @click="onClose">
            Ακύρωση
          </v-btn>

          <v-btn depressed color="success" class="mr-2" v-if="edit" @click="onSave" :loading="saveLoader">
            Αποθήκευση
          </v-btn>
        </div>
      </v-card>
    </v-card>
    <v-dialog persistent v-model="dialogMassive.open" max-width="500px" scrollable>
      <MassiveGrades v-if="dialogMassive.open" :onSubmit="submitMassive" @close="closeDialogMassive"/>
    </v-dialog>
    <v-dialog persistent v-model="dialog.open" v-if="dialog.type == 'delete'" max-width="500px" scrollable>
      <DeleteGrade v-if="dialog.open && dialog.type == 'delete'" :gradeProp="dialog.grade" @close="closeDialog"
                   @onDelete="handler"/>
    </v-dialog>
  </v-card>
</template>

<script>
import {mapState, mapMutations, mapActions} from "vuex";
import ExamFilter from "../../components/GenericFilter/ExamFilter.vue";
import DeleteGrade from "../../components/ExamGradesModals/DeleteExamGrade.vue";
import MassiveGrades from "../../components/ΜassiveGrades/MassiveGrades.vue";
import {
  required,
  between,
  betweenValue,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  components: {
    ExamFilter,
    DeleteGrade,
    MassiveGrades,
  },


  data() {
    return {
      tableLoader: false,
      progExam: null,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      query: false,
      gradeList: [],
      lessonInfo: null,
      edit: false,
      dialogMassive: {
        open: false,
      },
      filtersSet: false,

      menu: false,
      menu1: false,
      dateMenu: false,
      exmDate: null,
      exmDateFormatted: null,
      saveLoader: false,
      examGrades: [],
      headers: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Πρόοδος",
          value: "oral_grade",
          sortable: false,
        },

        {
          text: "Βαθμολογία",
          value: "grade",
          sortable: false,
        },
        {
          text: "Τελικός βαθμός",
          value: "mo",
          sortable: false,
        },
        {
          text: "Προσέλευση",
          value: "absenceFlag",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      headersOnlyWritten: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },

        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Βαθμολογία",
          value: "grade",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      headersWrittenAndWorkshop: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },

        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Πρακτικά",
          value: "workshop",
          sortable: false,
        },

        {
          text: "Βαθμολογία",
          value: "grade",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        grade: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },

    async courseId() {
      await this.getAttendLessons({
        page: 0,
        lessonId: this.courseId,
      });
    },

    exmDate(val) {
      this.exmDateFormatted = this.formatDate(this.exmDate);
    },

    $route() {
      this.query = this.$route.params.id;
    },
  },
  validations() {
    const self = this;
    return {
      exmDate: {
        required,
        minValue(val) {
          return new Date(val) >= new Date(self.progExam.startDate);
        },
        maxValue(val) {
          return new Date(self.progExam.endDate) >= new Date(val);
        },
      },
      gradeList: {
        $each: {
          grade: {
            betweenValue: between(
                self.lessonInfo?.minGrade,
                self.lessonInfo?.maxGrade
            ),
          },
        },
      },
    };
  },

  computed: {
    ...mapState({
      attendLessons: (state) => state.attend_lessons.attendLessons,
      staticList: (state) => state.auth.userInfo.staticView,
      pageChanged: (state) => state.base.pageChanged,
      appId: (state) => state.auth.appId,
      masterSchoolId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      examYearId: (state) => state.generic_filter.examYearId,
      progExamPeriodId: (state) => state.generic_filter.progExamPeriodId,
      examClassId: (state) => state.generic_filter.examClassId,
      examLessonId: (state) => state.generic_filter.examLessonId,
    }),
    progExamEndDate() {
      return this.formatDate(this.progExam.endDate);
    },
    showMassiveAction() {
      if (this.lessonInfo != null) {
        if (this.lessonInfo.maxGrade == 1 && this.appId == 2) {
          return true;
        }
      }
      return false;
    },
    exmDateErrors() {
      const errors = [];
      if (!this.$v.exmDate.$dirty) return errors;
      if (!this.$v.exmDate.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.exmDate.minValue)
        errors.push("Η ημερομηνία δεν είναι μέσα στα όρια της εξεταστικής");
      if (!this.$v.exmDate.maxValue)
        errors.push("Η ημερομηνία δεν είναι μέσα στα όρια της εξεταστικής");
      return errors;
    },
    getHeaders() {
      if (this.lessonInfo != null) {
        return this.lessonInfo.examType.cd == "3"
            ? this.headers
            : this.lessonInfo.examType.cd == "1"
                ? this.headersOnlyWritten
                : this.lessonInfo.examType.cd == "5"
                    ? this.headersWrittenAndWorkshop
                    : this.headers;
      } else {
        return this.headersOnlyWritten;
      }
    },
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getExamGrades", "addExamResult", "getAttendLessons"]),
    setMo(i) {
      // console.log(Number(this.examGrades.hashContent[i].oral_grade)+' '+);
      if (this.examGrades.hashContent[i].round_grade) {
        let grade = Number(this.gradeList[i].grade);
        let decimals = this.decimalCount(
            this.examGrades.hashContent[i].round_grade
        );
        this.gradeList[i].grade = grade.toFixed(decimals);
      }

      if (this.gradeList[i].absenceFlg) {
        // let newMo = (Number(this.examGrades.hashContent[i].oral_grade) + 0) / 2 - 0.1;

        // this.gradeList[i].mo = Number.parseFloat(newMo).toFixed(1);
        this.gradeList[i].mo = 'ΔΠ';
      } else if (!this.gradeList[i].absenceFlg) {
        var newMo;
        if (this.examGrades.hashContent[i].oral_grade != "ΑΠΑΛ") {
          newMo = (Number(this.examGrades.hashContent[i].oral_grade) + Number(this.gradeList[i].grade)) / 2;
        } else {
          newMo = Number(this.gradeList[i].grade);
        }
        this.gradeList[i].mo = Number.parseFloat(newMo).toFixed(1);
      } else {
        this.gradeList[i].mo = null;
      }
    },
    decimalCount(num) {
      // Convert to String
      const numStr = String(num);
      // String Contains Decimal
      if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
      }
      // String Does Not Contain Decimal
      return 0;
    },
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        // get lesson info
        const resLesson = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/lesson/${this.examClassId.split(".")[1]
            }`,
            {
              params: {
                page: 0,
              },
            }
        );
        this.lessonInfo = resLesson.data;

        const resExamPeriod = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/prog/exam/${this.progExamPeriodId}`,
            {
              params: {
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                    this.options.itemsPerPage == -1
                        ? null
                        : this.options.itemsPerPage,
                progExamPeriodId: this.progExamPeriodId,
              },
            }
        );
        this.progExam = resExamPeriod.data;
        console.log(this.progExam, "Result");

        const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/person/listForGrade/written`,
            {
              params: {
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                    this.options.itemsPerPage == -1
                        ? null
                        : this.options.itemsPerPage,
                masterSchoolId: this.masterSchoolId,
                schoolId: this.schoolId,
                yearId: this.examYearId,
                // lessonId: this.examLessonId,
                classId: this.examClassId.split(".")[0],
                approved: false,
                progExamPeriodId: this.progExamPeriodId,
              },
            }
        );
        let result = res.data;

        result.hashContent = await result.hashContent.map((d) => {
          if (d.lesson_refusal || d.oral_grade == "ΑΠΑΛ") {
            if (d.absence_flg) {
              return {...d, mo: null};
            } else {
              if (d.written != null) {
                return {...d, mo: d.written};
              } else {
                return {...d, mo: null};
              }
            }
          } else {
            if (d.absence_flg) {
              return {...d, mo: "ΔΠ"}; // kokkino
            } else {
              if (d.written != null) {
                if (d.oral_grade == "ΑΠ") {
                  return {...d, mo: Number.parseFloat(Number(d.written)).toFixed(1)}
                } else {
                  return {...d, mo: Number.parseFloat((Number(d.oral_grade) + Number(d.written)) / 2).toFixed(1)}
                }
              } else {
                return {...d, mo: null};
              }
            }
          }

          // let res = d.absence_flg
          //   ? 0
          //   : d.absence_flg == false
          //   ? d.written == null
          //     ? null
          //     : Number(d.written)
          //   : null;
          // let oral = d.lesson_refusal ? 0 : Number(d.oral_grade);
          // return {
          //   ...d,
          //   mo: d.oral_grade != undefined ? (Number(oral) + res) / 2 : res,
          // };
        });

        this.examGrades = result;

        this.gradeList = [];
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        for (const student of this.examGrades.hashContent) {
          //for every student
          if (student.written != null) {
            this.gradeList.push({
              classExamPersonId: student.edu_class_id,
              person_id: student.person_id,
              examDate: this.exm_date,
              grade: student.written == "ΔΠ" ? "" : student.written,
              isNew: false,
              mo: student.mo,
              absenceFlg: student.absence_flg,
            });
          } else {
            this.gradeList.push({
              classExamPersonId: student.edu_class_id,
              person_id: student.person_id,
              examDate: this.exm_date,
              grade: null,
              isNew: true,
              mo: null,
              absenceFlg: false,
            });
          }
        }

        this.tableLoader = false;
      } catch (e) {
        this.examGrades = [];
        this.$store.dispatch("errorHandler", e);

        console.log(e);
        this.tableLoader = false;
      }
    },
    openDialogMassive() {
      this.dialogMassive = {
        open: true,
      };
    },

    closeDialogMassive() {
      this.dialogMassive = {
        open: false,
      };
    },

    async submitMassive(grade) {
      const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/exam/result/init`,
          {
            examDate: this.exmDate,
            grade: grade,
            examClassId: this.examClassId.split(".")[1],
          }
      );

      console.log(res);

      await this.closeDialogMassive();
      await this.handler();
    },

    onClose() {
      for (let [i, grade] of this.gradeList.entries()) {
        if (grade.isNew) {
          this.gradeList[i] = {
            person_id: grade.person_id,
            classExamPersonId: grade.classExamPersonId,
            examDate: this.exm_date,
            grade: null,
            isNew: true,
            mo: null,
            absenceFlg: false,
          };
        }

        this.edit = false;
      }
    },

    async onSave() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.saveLoader = true;
        let content = JSON.parse(
            JSON.stringify(
                this.gradeList.filter((el) => {
                  if ((el.grade == null || el.grade == "") && !el.absenceFlg) {
                    null;
                  } else if (el.absenceFlg) {
                    return {...el, grade: 0};
                  } else {
                    return el;
                  }
                  // el.grade != null;
                })
            )
        );

        for (let element of content) {
          element.examDate = this.exmDate;
          delete element["menu"];
          delete element["menu1"];
          delete element["person_id"];
        }

        await this.addExamResult(content);
        await this.handler();
        this.saveLoader = false;
        this.onClose();
      } catch (e) {
        this.saveLoader = false;

        this.onClose();
        console.log(e);
      }
    },

    openDialog(grade, type) {
      this.dialog = {
        grade,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        grade: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "exmDate") {
          this.exmDate = null;
          this.exmDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    errorMessages(i) {
      if (
          this.gradeList[i].grade != undefined &&
          this.gradeList[i].grade != null
      ) {
        const errors = [];
        if (!this.$v.gradeList.$each[i].grade.$dirty) return errors;
        if (this.$v.gradeList.$each[i].grade.$error)
          errors.push(
              "Ο βαθμός δεν είναι εντός του εύρους της επιτρεπόμενης βαθμολογίας "
          );
        return errors;
      } else return;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
