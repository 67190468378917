<template>
  <v-card class="mt-4">
    <v-card-text>
      <v-data-table
        no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
        loading-text="Φόρτωση..."
        :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }"
        :headers="appId == 1 ? headersAen : headersKesen"
        :items="meteggrafes.content"
        :server-items-length="meteggrafes.elements"
        :options.sync="options"
        :loading="tableLoader"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon @click="openDialog(item, 'view')">
                <v-icon color="primary" small v-bind="attrs" v-on="on">
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Προβολή</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.startDate`]="{ item }">
          {{ formatDate(item.startDate, 'el') }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import {helperMixin} from "@/helpers/helperMixin";
export default {
  mixins: [helperMixin],
  data() {
    return {
      tableLoader: false,
      meteggrafes: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headersAen: [
        {
          text: "Ημερομηνία",
          value: "startDate",
          sortable: false,
        },

        {
          text: "Ακαδημία",
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: "Σχολή",
          value: "school.school.description",
          sortable: false,
        },
      ],
      headersKesen: [
        {
          text: "Ημερομηνία",
          value: "startDate",
          sortable: false,
        },

        {
          text: "Δομή Μετεκπαίδευσης",
          value: "masterSchool.description",
          sortable: false,
        },
        {
          text: "Εκπαιδευτικός Κύκλος/Ειδικό Τμήμα",
          value: "school.school.description",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);

          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/person/register/list`,
            {
              params: {
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                  this.options.itemsPerPage == -1
                    ? null
                    : this.options.itemsPerPage,
                personId: this.currentUser.id,
                registrationType: 5,
              },
            }
          );

          this.meteggrafes = res.data;
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.meteggrafes = [];
          this.$router.dispatch("errorHandler", e);

          this.tableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      appId: (state) => state.auth.appId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters"]),
  },
};
</script>

<style scoped lang="scss">
</style>