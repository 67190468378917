export default {
  state: {
    facultyId: null,
    schoolId: null,
    yearSemesterId: null,
    series: null,
    academicYearId: null,
    semesterId: null,
    examId: null,
    courseId: null,
    classId: null,
    dates: [],
    date: null,
    active: null,
    searchText: null,
    dateFrom: null,
    dateTo: null,

    //examFilter
    examYearId: null,
    progExamPeriodId: null,
    examClassId: null,
    examLessonId: null,
    examDate: null,

    //lists
    listFaculty: [],
    listSchool: [],
    listYearSemester: [],
    listAcademicYear: [],
    listSemester: [],
    listExam: [],
    listClass: [],
    listCourse: [],
    listSeries:[],

    //examlist
    listExamYears: [],
    listProgExamPeriods: [],
    listExamClasses: [],
    listExamLessons: [],
    maxDept: null,
    schoolDetails: null,
    progTrainDetails: null,
    progExamDetails: null,
  },

  getters: {
    facultyChanged: (state) => state.facultyId,
  },

  mutations: {
    setFaculty(state, facId) {
      state.facultyId = facId;
    },
    setSchool(state, schId) {
      state.schoolId = schId;
    },
    setAcademicYear(state, acadYearId) {
      state.academicYearId = acadYearId;
    },
    setSeries(state, series) {
      state.series = series;
    },
    setSemester(state, semesterId) {
      state.semesterId = semesterId;
    },
    setExam(state, examId) {
      state.examId = examId;
    },

    setClass(state, classId) {
      state.classId = classId;
    },
    setCourse(state, courseId) {
      state.courseId = courseId;
    },
    setDates(state, dates) {
      state.dates = dates;
    },
    setDateFrom(state, payload) {
      state.dateFrom = payload;
    },
    setDateTo(state, payload) {
      state.dateTo = payload;
    },
    setActive(state, active) {
      state.active = active;
    },

    setSearchText(state, searchText) {
      state.searchText = searchText;
    },

    //exam filter
    setExamYearId(state, examYearId) {
      state.examYearId = examYearId;
    },

    setProgExamPeriodId(state, progExamPeriodId) {
      state.progExamPeriodId = progExamPeriodId;
    },

    setExamClassId(state, examClassId) {
      state.examClassId = examClassId;
    },

    setExamLessonId(state, examLessonId) {
      state.examLessonId = examLessonId;
    },

    setExamDate(state, examDate) {
      state.examDate = examDate;
    },
    setYearSemester(state, yearSemester) {
      state.yearSemesterId = yearSemester;
    },
    setMaxDept(state, maxDept) {
      state.maxDept = maxDept;
    },

    setProgTrainDetails(state, payload) {
      state.progTrainDetails = payload;
    },

    setProgExamDetails(state, payload) {
      state.progExamDetails = payload;
    },

    setSchoolDetails(state, payload) {
      state.schoolDetails = payload;
    },

    setAllFilter(state, payload) {
      state.facultyId = payload.facultyId;
      state.schoolId = payload.schoolId;
      state.academicYearId = payload.academicYearId;
      state.semesterId = payload.semesterId;
      state.classId = payload.classId;
      state.courseId = payload.courseId;
      state.dates = payload.dates;
      state.date = payload.date;
      state.active = payload.active;
      state.searchText = payload.searchText;
      state.examClassId = payload.examClassId;
      state.examYearId = payload.examYearId;
      state.examLessonId = payload.examLessonId;
      state.examDate = payload.examDate;
      state.progExamPeriodId = payload.progExamPeriodId;
    },

    setExamFilters(state, payload) {
      state.facultyId = payload.facultyId;
      state.schoolId = payload.schoolId;
      state.examYearId = payload.examYearId;
      state.progExamPeriodId = payload.progExamPeriodId;
      state.examClassId = payload.examClassId;
      state.examLessonId = payload.examLessonId;
      state.examDate = payload.examDate;
      state.maxDept = payload.maxDept;
    },

    clearAllFilter(state) {
      state.facultyId = null;
      state.schoolId = null;
      state.academicYearId = null;
      state.series = null;
      state.semesterId = null;
      state.classId = null;
      state.courseId = null;
      state.date = null;
      state.active = null;
      state.dates = [];
      state.examYearId = null;
      state.progExamPeriodId = null;
      state.examClassId = null;
      state.examLessonId = null;
      state.examDate = null;
      state.yearSemesterId = null;
      state.examId = null;
      state.active = null;
      state.searchText = null;
      state.dateFrom = null;
      state.dateTo = null;

      state.listFaculty = [];
      state.listSchool = [];
      state.listAcademicYear = [];
      state.listSemester = [];
      state.listExam = [];
      state.listClass = [];
      state.listCourse = [];
      state.listYearSemester = [];
      state.maxDept = null;

      state.listExamYears = [];
      state.listProgExamPeriods = [];
      state.listExamClasses = [];
      state.listExamLessons = [];
      state.maxDept = null;
      state.schoolDetails = null;
      state.progTrainDetails = null;
      state.progExamDetails = null;
    },

    getListFaculty(state, listFaculty) {
      state.listFaculty = listFaculty;
    },

    getListSchool(state, listSchool) {
      state.listSchool = listSchool;
    },

    getListAcademicYear(state, listAcademicYear) {
      state.listAcademicYear = listAcademicYear;
    },

    getListYearSemester(state, listYearSemester) {
      state.listYearSemester = listYearSemester;
    },

    getListSemester(state, listSemester) {
      state.listSemester = listSemester;
    },

    getListExam(state, listExam) {
      state.listExam = listExam;
    },

    getListClass(state, listClass) {
      state.listClass = listClass;
    },

    getListCourse(state, listCourse) {
      state.listCourse = listCourse;
    },

    getListSeries(state,listSeries){
      state.listSeries = listSeries
    },

    //exam

    getListExamYears(state, listExamYears) {
      state.listExamYears = listExamYears;
    },

    getListProgExamPeriods(state, listProgExamPeriods) {
      state.listProgExamPeriods = listProgExamPeriods;
    },

    getListExamClasses(state, listExamClasses) {
      state.listExamClasses = listExamClasses;
    },

    getListExamLessons(state, listExamLessons) {
      state.listExamLessons = listExamLessons;
    },
  },

  actions: {
    setFaculty({ commit }, payload) {
      commit("setFaculty", payload);
    },
    setSchool({ commit }, payload) {
      commit("setSchool", payload);
    },
    setAcademicYear({ commit }, payload) {
      commit("setAcademicYear", payload);
    },
    setSeries({commit},payload){
      commit("setSeries",payload);
    },
    setSemester({ commit }, payload) {
      commit("setSemester", payload);
    },
    setExam({ commit }, payload) {
      commit("setExam", payload);
    },
    setCourse({ commit }, payload) {
      commit("setCourse", payload);
    },
    setYearSemester({ commit }, payload) {
      commit("setYearSemester", payload);
    },
    setDateFrom({ commit }, payload) {
      commit("setDateFrom", payload);
    },
    setDateTo({ commit }, payload) {
      commit("setDateTo", payload);
    },
    ///exam

    setExamYearId({ commit }, payload) {
      commit("setExamYearId", payload);
    },
    setProgExamPeriodId({ commit }, payload) {
      commit("setProgExamPeriodId", payload);
    },
    setExamClassId({ commit }, payload) {
      commit("setExamClassId", payload);
    },
    setExamLessonId({ commit }, payload) {
      commit("setExamLessonId", payload);
    },
    //
    onSetMaxDept({ commit }, payload) {
      commit("setMaxDept", payload);
    },

    setAllFilter({ commit }, payload) {
      commit("setAllFilter", payload);
    },

    setExamFilters({ commit }, payload) {
      commit("setExamFilters", payload);
    },
    setSchoolDetails({ commit }, payload) {
      commit("setSchoolDetails", payload);
    },
    setClearAllFilter({ commit }) {
      commit("clearAllFilter");
    },
  },
};
