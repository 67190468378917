import {INVALID_DATE, isEmpty} from "@/helpers/helpers";

export const helperMixin = {
    methods: {
        formatDate: (value, locale) => {
            if (!isEmpty(value) && value !== '' && value !== INVALID_DATE) {
                return new Date(value).toLocaleDateString(locale)
            } else {
                return ''
            }
        }
    }
}