<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-height="1400px"
      width="100%"
      height="100%"
      class="pa-d d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="level"
        @onSearchAction="onSearch"
        :key="query"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: false,
          class: false,
          lesson: false,
        }"
      />
      <v-card>
        <v-card-text class="pa-4">
          <v-row
            v-for="(d, i) in data"
            :key="i"
            style="border-bottom: 1px solid #00000030"
          >
            <v-col cols="12" md="3" sm="6">
              <v-text-field
                dense
                outlined
                v-model="d.serialNr"
                type="text"
                :error-messages="serialNrErrorMessages(d, i)"
                hide-details="auto"
              >
                <template v-slot:label>
                  <span class="error--text">*</span>Σειρά
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-menu
                ref="menu"
                v-model="d.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    v-model="d.startDateFormatted"
                    hide-details="auto"
                    class="mb-4"
                    :error-messages="startDateErrorMessages(d, i)"
                    @blur="
                      d.startDate = parseDate(d.startDateFormatted, 'startDate')
                    "
                  >
                    <template v-slot:prepend-inner>
                      <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                    </template>
                    <template v-slot:label>
                      <span class="error--text">*</span>
                      Ημερομηνία έναρξης
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="el"
                  v-model="d.startDate"
                  hide-details="auto"
                  scrollable
                  @change="onChangeStartDate(i)"
                  @input="d.menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="3" sm="6">
              <v-menu
                ref="menu"
                v-model="d.menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    v-model="d.endDateFormatted"
                    hide-details="auto"
                    prepend-inner-icon="mdi-calendar"
                    class="mb-4"
                    :error-messages="endDateErrorMessages(d, i)"
                    clearable
                    @blur="d.endDate = parseDate(d.endDateFormatted, 'endDate')"
                  >
                    <template v-slot:label>
                      <span class="error--text">*</span>
                      Ημερομηνία λήξης
                    </template>
                    <template v-slot:prepend-inner>
                      <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="el"
                  v-model="d.endDate"
                  scrollable
                  @input="d.menu1 = false"
                  @change="onChangeEndDate(i)"
                  hide-details="auto"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              md="3"
              sm="6"
              style="display: flex; justify-content: center"
            >
              <v-btn
                depressed
                color="success"
                class="mr-1"
                @click="addDuration(i)"
              >
                <v-icon>mdi-plus-box</v-icon>
              </v-btn>
              <v-btn depressed color="error" @click="removeDuration(i)">
                <v-icon>mdi-minus-box</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="data.length > 0">
            <v-col align="right">
              <v-btn color="success" @click="onSubmit" :loading="saveLoader">
                Αποθήκευση
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import { required, numeric } from "vuelidate/lib/validators";
import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};
export default {
  name: "ProgTrainSeries",
  components: {
    GenericFilter,
  },
  data() {
    return {
      level: 3,
      query: false,
      initialNumber: 0,
      loader: false,
      startDate: null,
      startDateFormatted: null,
      endDate: null,
      endDateFormatted: null,
      data: [],
      saveLoader: false,
    };
  },
  validations: {
    data: {
      $each: {
        startDate: {
          required,
        },
        endDate: {
          required,
        },
        serialNr: {
          required,
          numeric,
        },
      },
    },
  },
  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },
  computed: {
    ...mapState({
      genericFilter: (state) => state.generic_filter,
      appId: (state) => state.auth.appId,
    }),
    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;
      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    endDateErrors() {
      const errors = [];
      if (!this.$v.endDate.$dirty) return errors;
      if (!this.$v.endDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
  methods: {
    async onSearch() {
      try {
        this.loader = true;
        this.data = [];
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/prog/train/number`,
          {
            params: {
              schoolId: this.genericFilter.schoolId,
            },
          }
        );
        this.initialNumber = res.data;
    
        for (let i = 0; i < res.data; i++) {
          this.data.push({
            startDate: null,
            startDateFormatted: null,
            endDate: null,
            endDateFormatted: null,
            serialNr: null,
            menu: false,
            menu1: false,
          });
        }
        this.loader = false;
      } catch (e) {
        console.log(e);
        this.loader = false;
        throw e;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    removeDuration(i) {
      this.data = this.data.filter((r, j) => {
        if (j != i) {
          return r;
        }
      });
    },
    addDuration() {
      this.data.push({
        startDate: null,
        startDateFormatted: null,
        endDate: null,
        endDateFormatted: null,
        serialNr: null,
        menu: false,
        menu1: false,
      });
    },
    startDateErrorMessages(item, i) {
      const errors = [];
      if (!this.$v.data.$each[i].startDate.$dirty) return errors;
      if (!this.$v.data.$each[i].startDate.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
      // } else return;
    },
    endDateErrorMessages(item, i) {
      // if (item.ENDdA != null || item.endDate != null) {
      const errors = [];
      if (!this.$v.data.$each[i].endDate.$dirty) return errors;
      if (!this.$v.data.$each[i].endDate.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
      // } else return;
    },
    serialNrErrorMessages(item, i) {
      const errors = [];
      if (!this.$v.data.$each[i].serialNr.$dirty) return errors;
      if (!this.$v.data.$each[i].serialNr.required)
        errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.data.$each[i].serialNr.numeric)
        errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    onChangeStartDate(i) {
      this.data[i].startDateFormatted = this.formatDate(this.data[i].startDate);
    },
    onChangeEndDate(i) {
      this.data[i].endDateFormatted = this.formatDate(this.data[i].endDate);
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.saveLoader = true;
        let dateList = this.data.map((d) => {
          return {
            startDate: d.startDate,
            endDate: d.endDate,
            serialNr: d.serialNr,
          };
        });
        var body = {
          dateList: dateList,
          schoolId: this.genericFilter.schoolId,
          yearId: this.genericFilter.academicYearId,
        };
        console.log("body", body);

        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/prog/train/init`,
          body
        );
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Η διαδικασία ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
        console.log("resp", res);
        this.saveLoader = false;
        this.data = [];
      } catch (e) {
        console.log(e);
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Η διαδικασία δεν ολοκληρώθηκε",
          color: "error",
        });
        this.saveLoader = false;
        throw e;
      }
    },
  },
};
</script>

<style>
</style>