
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="3"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: false,
          class: false,
          lesson: false,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="teacherHours.content"
            :options.sync="options"
            :server-items-length="teacherHours.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.entryDate`]="{ item }">
              {{ new Date(item.entryDate).toLocaleDateString("el") }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onTeacherView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
          <div class="custom-card-buttons">
            <v-btn
              depressed
              color="primary"
              class="mr-2"
              :disabled="!filtersSet"
              @click="openDialog(null, 'add')"
            >
              Δημιουργία
              <v-icon dense right>mdi-plus</v-icon>
            </v-btn>          
          </div>
      </v-card>
      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'add'"
        max-width="500px"
        scrollable
      >
        <AddTeacherHour
          v-if="dialog.open && dialog.type == 'add'"
          @close="closeDialog"
          @handler="handler"
          from="1"
        />
      </v-dialog>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'edit'"
        max-width="500px"
        scrollable
      >
        <EditTeacherHour
          v-if="dialog.open && dialog.type == 'edit'"
          :teacherHour="dialog.teacherHour"
          @close="closeDialog"
          @handler="handler"
          from="1"
        />
      </v-dialog>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'delete'"
        max-width="500px"
        scrollable
      >
        <DeleteTeacherHour
          v-if="dialog.open && dialog.type == 'delete'"
          :teacherHour="dialog.teacherHour"
          @close="closeDialog"
          @handler="handler"
          :teacherHours="teacherHours"
        />
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddTeacherHour from "..//../components/TeachHoursModals/AddTeacherHour.vue";
import EditTeacherHour from "..//../components/TeachHoursModals/EditTeacherHour.vue";
import DeleteTeacherHour from "..//../components/TeachHoursModals/DeleteTeacherHour.vue";
import axios from "axios";
export default {
  components: {
    GenericFilter,
    AddTeacherHour,
    EditTeacherHour,
    DeleteTeacherHour,
  },

  async created() {
    try {
      if (this.progTrainPeriodId != null) await this.handler();
    } catch (e) {
      throw e;
    }
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filtersSet: false,
      teacherHours: [],
      headers: [
        {
          text: "Επώνυμο",
          value: "person.lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "person.firstName",
          sortable: false,
        },

        {
          text: "Ώρες",
          value: "numOfHours",
          sortable: false,
        },

        {
          text: "Τύπος",
          value: "tpy.description",
          sortable: false,
        },

        {
          text: "Ημερομηνία",
          value: "entryDate",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        teacherHour: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      academicYearId: (state) => state.generic_filter.academicYearId,
      courseId: (state) => state.generic_filter.courseId,
      classId: (state) => state.generic_filter.classId,
      dates: (state) => state.generic_filter.dates,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),

    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/hours/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              // progTrainPeriodId: this.progTrainPeriodId,
              // lessonId: this.courseId,
              // classId: this.classId,
              yearId: this.academicYearId,
              entryDate: this.dates,
            },
          }
        );

        this.teacherHours = res.data;
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.teacherHours = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    async onTeacherView(teacher) {
      this.$router.push(
        `${this.$route.path}/${teacher.person.id}/viewteacher/proswpika_stoixeia`
      );
    },

    openDialog(teacherHour, type) {
      this.dialog = {
        teacherHour,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        teacherHour: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>