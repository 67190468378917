
export default {
    state: {
        listMasterSchool: null,
        listSchool: null,
    },

    mutations: {
        setListMasterSchool(state, listMasterSchool) {
            state.listMasterSchool = listMasterSchool
        },

        setListSchool(state, listSchool) {
            state.listSchool = listSchool
        }
    }

}