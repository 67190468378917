<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-height="1400px"
      width="100%"
      height="100%"
      class="pa-d d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="level"
        @onSearchAction="onSearch"
        :key="query"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: false,
          class: false,
          lesson: false,
        }"
      />
      <v-card >

      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
export default {
  name: "SeperatedStudents",
  components: {
    GenericFilter,
  },
  data() {
    return {
      level: 3,
      query: false,
    };
  },
  methods: {
    ...mapState({
      genericFilter: (state) => state.generic_filter,
      appId: (state) => state.auth.appId,
    }),
    onSearch() {
      console.log("Pressed");
    },
  },
};
</script>

<style>
</style>