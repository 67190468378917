var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('ClosurePeriodFilter',{key:_vm.query,attrs:{"level":3,"listActive":1,"requiredFields":{
        masterSchool: true,
        school: true,
        year: true,
        dateFormatted: true,
        date: false,
      }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card-text',{staticClass:"pa-0 pb-4 pt-4 d-flex flex-column flex-sm-row"},[_c('v-card',{staticClass:"flex-grow-1 mb-5 mb-sm-0"},[_c('v-card-text',[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, 100],
            },"headers":_vm.appId == 1 ? _vm.headersExamAen : _vm.headersExam,"items":_vm.data.content,"options":_vm.options,"server-items-length":_vm.data.elements,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.endDate`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(new Date(item.endDate).toLocaleDateString("el")))])]}},{key:`item.startDate`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(new Date(item.startDate).toLocaleDateString("el")))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.openDialog(item, _vm.type)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red darken-1"}},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar-remove ")])],1)]}}],null,true)},[_c('span',[_vm._v("Κλείσιμο περιόδου")])])]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.dialog.open),callback:function ($$v) {_vm.$set(_vm.dialog, "open", $$v)},expression:"dialog.open"}},[(_vm.dialog.open)?_c('ExamPeriodClosureModal',{attrs:{"type":_vm.dialog.type,"data":_vm.dialog.data},on:{"close":_vm.closeDialog}}):_vm._e()],1),(this.showLoader)?_c('Loader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }