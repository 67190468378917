import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import store from "../store"

Vue.use(Vuetify);

const color=store.state.auth.appId ==1?'#2962FF':'#28357E'
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: color,
        secondary: '#4FC3F7',
        accent: '#7460EE',
        greyish: '#3E5569',
        error: '#F62D51',
        info: '#2196F3',
        success: '#36BEA6',
        warning: '#FB8C00',
        grey: '#c7c5c5',
        blue: "#2e4b93",
      },
    },
  },
});
