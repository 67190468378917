<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία βραβείου
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex flex-column pa-4">
      <v-progress-circular
        v-if="cardLoader"
        indeterminate
        color="primary"
        class="align-self-center ma-4"
        :size="50"
      ></v-progress-circular>
      <v-form v-else @submit.prevent="onSubmit" class="pa-4">
        <v-select
          dense
          outlined
          hide-details="auto"
          v-model="personId"
          :items="students.content"
          :item-text="(el) => el.firstName + ' ' + el.lastName"
          no-data-text="Δεδομένα μη διαθέσιμα"
          item-value="personId"
          class="mb-4"
          :error-messages="personIdErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Σπουδαστής
          </template>
        </v-select>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="issueDateFormatted"
              class="mb-4"
              hide-details="auto"
              @blur="issueDate = parseDate(issueDateFormatted, 'issueDate')"
              :error-messages="issueDateErrors"
            >
              <template v-slot:prepend-inner>
                <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
              </template>
              <template v-slot:label>
                <span class="error--text">*</span>
                Ημερομηνία δημιουργίας
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            locale="el"
            v-model="issueDate"
            hide-details="auto"
            scrollable
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>

        <v-textarea
          dense
          outlined
          v-model="award"
          hide-details="auto"
          :error-messages="awardErrors"
          class="mb-4"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Περιγραφή
          </template>
        </v-textarea>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["personAward"],

  async created() {
    try {
      await this.getStudents({
        page: 0,
        id: this.facultyId,
        schoolId: this.schoolId,
        classId: this.classId,
      });

      this.personId = this.personAward.person_id;
      this.award = this.personAward.award;
      this.issueDate = this.personAward.issue_date;

      this.cardLoader = false;
    } catch (e) {
      this.cardLoader = false;
      console.log(e);
    }
  },

  data() {
    return {
      loader: false,
      award: null,
      issueDate: null,
      issueDateFormatted: null,
      personId: null,

      cardLoader: true,
      menu: false,
    };
  },

  watch: {
    issueDate(val) {
      this.issueDateFormatted = this.formatDate(this.issueDate);
    },
  },

  validations: {
    award: {
      required,
    },
    issueDate: {
      required,
    },
    personId: {
      required,
    },
  },

  computed: {
    ...mapState({
      facultyId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      classId: (state) => state.generic_filter.classId,

      students: (state) => state.students.students,
    }),

    awardErrors() {
      const errors = [];
      if (!this.$v.award.$dirty) return errors;
      if (!this.$v.award.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    issueDateErrors() {
      const errors = [];
      if (!this.$v.issueDate.$dirty) return errors;
      if (!this.$v.issueDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    personIdErrors() {
      const errors = [];
      if (!this.$v.personId.$dirty) return errors;
      if (!this.$v.personId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["getStudents"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          award: this.award,
          issueDate: this.issueDate,
          personId: this.personId,
        };
        await axios.put(
          `${process.env.VUE_APP_BASE_URL}/person/award/${this.personAward.edu_person_awards_id}`,
          content
        );
        await this.$emit("handler");
        this.$emit("close");
        this.loader = false;
      } catch (e) {
        this.loader = false;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "issueDate") {
          this.issueDate = null;
          this.issueDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped lang="scss">
</style>