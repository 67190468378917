<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Προσθήκη επιτηρητή σε τμήμα
      <v-btn
          depressed
          icon
          color="white"
          class="ml-auto"
          @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="d-flex flex-column pa-4">
      <v-progress-circular
          v-if="cardLoader"
          indeterminate
          color="primary"
          class="align-self-center ma-4"
          :size="50"
      ></v-progress-circular>
      <v-form v-else @submit.prevent="onSubmit" class="pa-4">
        <v-select
            dense
            outlined
            v-model="classExamId"
            type="text"
            class="mb-4"
            hide-details="auto"
            :items="listExamClasses"
            item-text="description"
            item-value="cd"
            :error-messages="classExamIdErrors"
            :disabled="examClassId != null"
            no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Τμήμα
          </template>
        </v-select>

        <v-select
            dense
            outlined
            v-model="teacher"
            type="text"
            hide-details="auto"
            class="mb-4"
            :items="teacherList"
            item-text="teacherName"
            item-value="id"
            :error-messages="teacherErrors"
            no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Εκπαιδευτικός
          </template>
        </v-select>

        <v-select
            v-model="type"
            class="mb-4"
            :error-messages="typeErrors"
            :items="getDataStaticList(156)"
            item-text="description"
            item-value="cd"
            hide-details="auto"
            dense
            outlined
            no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span> Τύπος
          </template>
        </v-select>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
              depressed
              color="success"
              class="ml-4"
              type="submit"
              :loading="loader"
          >Δημιουργία
          </v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {required} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  async created() {
    try {
      if (this.examClassId != null) this.classExamId = this.examClassId;

      const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/list`,
          {
            params: {
              page: 0,
              masterSchoolId: this.facultyId,
              schoolId: this.schoolId,
            },
          }
      );
      this.teacherList = res.data.content.map((t) => {
        return {
          teacherName: t.lastName + " " + t.firstName,
          id: t.personId,
        };
      });

      this.cardLoader = false;
    } catch (e) {
    }
  },

  data() {
    return {
      teacherList: [],
      cardLoader: true,
      loader: false,
      type: null,
      classExamId: null,
      teacher: null,
    };
  },

  validations: {
    classExamId: {
      required,
    },

    teacher: {
      required,
    },

    type: {
      required,
    },
  },

  computed: {
    ...mapState({
      listExamClasses: (state) => state.generic_filter.listExamClasses,
      schoolId: (state) => state.generic_filter.schoolId,
      examClassId: (state) => state.generic_filter.examClassId,
      staticList: (state) => state.auth.userInfo.staticView,
      facultyId: (state) => state.generic_filter.facultyId,
    }),
    classExamIdErrors() {
      const errors = [];
      if (!this.$v.classExamId.$dirty) return errors;
      if (!this.$v.classExamId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    teacherErrors() {
      const errors = [];
      if (!this.$v.teacher.$dirty) return errors;
      if (!this.$v.teacher.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      if (!this.$v.type.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addClassExamTeacher", "getTeachers"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          personId: this.teacher,
          type: this.type,
          classExamId: this.classExamId.split(".")[0],
        };
        await this.addClassExamTeacher(content);
        this.loader = false;

        await this.$emit("handler");
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>