<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Προσθήκη σπουδαστή
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pa-4 d-flex flex-column">
      <v-data-table
        class="mb-4"
        no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
        loading-text="Φόρτωση..."
        :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }"
        :headers="headers"
        :items="listForExam.hashContent"
        :options.sync="options"
        :server-items-length="listForExam.elements"
        :loading="tableLoader"
        show-select
        :single-select="false"
        v-model="selectedStudent"
        item-key="id"
      >
      </v-data-table>

      <v-btn
        depressed
        color="primary"
        class="ml-auto"
        @click="onSubmit"
        :loading="loader"
      >
        Προσθήκη
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
export default {
  created() {
    console.log(this.currentClassExam);
  },
  data() {
    return {
      tableLoader: false,
      loader: false,
      selectedStudent: [],
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      listForExam: [],

      headers: [
        {
          text: "ΑΜ",
          value: "cd",
          sortable: false,
        },

        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    async options() {
      await this.handler();
    },
    selectedStudent(prev, curr){
      console.log({prev: prev})
      console.log({curr: curr})
    }
  },

  computed: {
    ...mapState({
      currentClassExam: (state) => state.grades.currentClassExam,
      masterSchoolId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      yearId: (state) => state.generic_filter.examYearId,
      lessonId: (state) => state.generic_filter.examLessonId,
      progExamPeriodId: (state) => state.generic_filter.progExamPeriodId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters"]),
    ...mapActions(["addClassExamPerson"]),

    async onSubmit() {
      try {
        this.loader = true;
        let body = this.selectedStudent.map((s) => {
          return {
            classExamId: this.currentClassExam.id,
            personId: s.id,
            progTrainPeriodId: s.edu_prog_train_prd_id,
          };
        });
        await this.addClassExamPerson(body);
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;

        console.log(e);
      }
    },

    async handler() {
      try {
        this.tableLoader = true;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForExam`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              masterSchoolId: this.masterSchoolId,
              schoolId: this.schoolId,
              yearId: this.yearId,
              progExamPeriodId: this.progExamPeriodId,
              lessonId: this.lessonId,
            },
          }
        );

        this.listForExam = res.data;
        this.selectedStudent = []
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.listForExam = [];

        this.tableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>