<template>
  <v-main v-if="appId == 1">
    <AppBarPortal/>
    <v-container class="pt-14 pb-5" fluid>
      <v-row v-if="!loginClicked" class="aen_lr_padding">
        <div class="col-xxl-8 col-xl-8 col-lg-7 col-md-6 col-sm-12 customPaddingRight">
          <h1>Σύστημα Ηλεκτρονικής Διαχείρισης Σπουδών Ακαδημιών Εμπορικού Ναυτικού (ΑΕΝ)</h1>
          <p>Οι Ακαδημίες Εμπορικού Ναυτικού (ΑΕΝ) είναι δημόσιες σχολές που ανήκουν στην τρίτη βαθμίδα της
            ανώτερης τεχνικής και επαγγελματικής εκπαίδευσης, ενώ αποτελούν περιφερειακές υπηρεσίες του Υπουργείου
            Ναυτιλίας και Νησιωτικής Πολιτικής που επιβλέπονται από την αρμόδια Διεύθυνση Εκπαίδευσης Ναυτικών του
            Αρχηγείου Λιμενικού Σώματος−Ελληνικής Ακτοφυλακής. Οι ΑΕΝ είναι οι κύριοι φορείς εκπαίδευσης των
            μελλοντικών αξιωματικών εμπορικού ναυτικού στην Ελλάδα και διακρίνονται σε Σχολές Πλοιάρχων και Σχολές
            Μηχανικών. Οι ΑΕΝ στο σύνολό τους είναι έντεκα (11) και λειτουργούν σε διάφορες πόλεις της
            Ελλάδας.</p>
          <p>Οι ΑΕΝ παρέχουν σύγχρονη εκπαίδευση στη ναυτική/ναυτιλιακή θεωρία και τη χρήση νέων τεχνολογιών,
            ανοίγοντας προοπτικές για σταδιοδρομία στους αυριανούς αξιωματικούς του Εμπορικού Ναυτικού. Στόχος του
            Υπουργείου Ναυτιλίας και Νησιωτικής Πολιτικής είναι η συνεχής βελτίωση των παρεχόμενων υπηρεσιών
            ναυτικής εκπαίδευσης μέσω της εκτεταμένης χρήσης τεχνολογιών πληροφορικής.</p>
          <p>
            <v-btn
                dense
                large
                class="white--text btn-aen"
                @click="loginWithTaxis"
            >
              Είσοδος στην εφαρμογή
            </v-btn>
          </p>
          <p>
            <v-btn
                dense
                large
                class="white--text btn-ldap"
                @click="loginClicked = true"
            >
              Σύνδεση χρηστών πιστοποιημένων από Υ.ΝΑ.Ν.Π./Δ.ΕΚ.Ν.
            </v-btn>
          </p>
        </div>
        <div class="col-xxl-4 col-xl-4 col-lg-5 col-md-6 col-sm-12">
          <v-img class="img-fluid rounded sticky-top mt-1"
                 lazy-src="../../assets/images/aen_image.jpg"
                 src="../../assets/images/aen_image.jpg" alt="Σύστημα Ηλεκτρονικής Διαχείρισης Σπουδών ΑΕΝ"/>
        </div>
      </v-row>
      <v-row v-else align="center" justify="center">
        <div class="component-wrapper d-flex align-center justify-center">
          <v-card
              elevation="4"
              max-width="500px"
              width="100%"
              class="d-flex flex-column"
          >
            <div class="pa-5 greyish--text text-center text-h5" v-if="appId == 1">
              <v-avatar>
                <v-img src="../../assets/ethnosimo.png"></v-img>
              </v-avatar>
              Ακαδημία Εμπορικού Ναυτικού
            </div>
            <div class="pa-5 greyish--text text-center text-h5" v-else>
              <v-avatar>
                <v-img src="../../assets/aggyres.png"></v-img>
              </v-avatar>
              Δημόσια Σχολή Εμπορικού Ναυτικού
            </div>
            <div v-if="!loginClicked" class="d-flex flex-column align-center pa-5">
              <v-btn
                  dense
                  large
                  color="primary mb-4"
                  width="300px"
                  @click="loginWithTaxis"
              >Σύνδεση με taxisnet
              </v-btn
              >
              <v-btn
                  text
                  @click="loginClicked = true"
                  color="primary "
              >Σύνδεση χρηστών πιστοποιημένων από Υ.ΝΑ.Ν.Π./Δ.ΕΚ.Ν.
              </v-btn
              >
            </div>

            <v-form
                v-if="loginClicked"
                @submit.prevent="onLogin"
                class="d-flex flex-column pa-5 pt-0"
            >
              <v-text-field
                  dense
                  outlined
                  v-model="username"
                  label="Όνομα χρήστη"
                  type="text"
                  prepend-inner-icon="mdi-account"
                  class="mb-4"
              ></v-text-field>
              <v-text-field
                  dense
                  outlined
                  v-model="password"
                  label="Κωδικός πρόσβασης"
                  type="password"
                  prepend-inner-icon="mdi-lock"
                  class="mb-4"
              ></v-text-field>
              <div class="mb-4 d-flex">
                <v-btn depressed @click="onReturn" color="error " width="50%"
                >Επιστροφή
                </v-btn
                >
                <v-btn
                    dense
                    :disabled="username == '' || password == '' ? true : false"
                    :loading="loader"
                    type="submit"
                    color="blue"
                    class="ml-2 white--text"
                    width="50%"
                >Σύνδεση
                </v-btn
                >
              </div>
            </v-form>
          </v-card>
          <v-snackbar v-model="snackbar.visible" color="error">
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                  dense
                  text
                  icon
                  x-small
                  v-bind="attrs"
                  @click="snackbar.visible = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-row>
    </v-container>
    <AppFooterPortal/>
  </v-main>
  <KesenLogin v-else/>
</template>

<script>
import {mapState, mapActions} from "vuex";
import AppBarPortal from "@/components/AppBarPortal.vue";
import AppFooterPortal from "@/components/AppFooterPortal.vue";
import KesenLogin from "@/views/Login/KesenLogin.vue";

export default {
  components: {AppFooterPortal, AppBarPortal, KesenLogin},
  created() {
    console.log("type ", this.appId);
  },
  data() {
    return {
      loginClicked: false,
      username: "",
      password: "",
      loader: false,
      snackbar: {
        visible: false,
        text: "",
      },
      taxisLink: `${process.env.VUE_APP_BASE_URL}/oauth2/authorization/taxi`,
    };
  },
  computed: {
    ...mapState({
      appId: (state) => state.auth.appId,
      selectedRole: (state) => state.auth.selectedRole,
      userInfo: (state) => state.auth,
    }),
  },
  methods: {
    ...mapActions(["login"]),

    async onLogin() {
      try {
        this.loader = true;
        await this.login({
          username: this.username,
          password: this.password,
          type: this.appId,
        });
        this.loader = false;
        let page = this.userInfo.userInfo.roles.filter((m) => {
          if (m.id == this.selectedRole) {
            return m;
          }
        });
        console.log(page);
        this.$router.push(page[0].defaultPage);
      } catch (e) {
        console.log(e);
        this.loader = false;
        this.snackbar.visible = true;
        this.snackbar.text = "Τα στοιχεία σύνδεσης είναι εσφαλμένα";
      }
    },

    onReturn() {
      this.username = "";
      this.password = "";
      this.loginClicked = false;
    },
    async loginWithTaxis() {
      console.log("loginWithTaxis");
      window.location.href = `${process.env.VUE_APP_BASE_URL}/oauth2/authorization/taxis`;
    },
  },
};
</script>

<style scoped lang="scss">
.ani_blue {
  background-color: #2e4b93;
}

.aen_lr_padding {
  padding-left: 6rem;
  padding-right: 6rem;
}

.btn-ldap {
  background-color: white !important;
  color: var(--v-blue-base) !important;
  font-weight: bold !important;
}


.btn-aen {
  background-color: #2e4b93 !important;
  color: #fff !important;
  font-weight: bold !important;
}

.btn-aen:hover {
  background-color: #f5f5f5 !important;
  color: #2e4b93 !important;
  font-weight: bold !important;
}

.mainContent p {
  text-align: justify !important;
}

.sticky-top {
  top: 1em;
}

@media (min-width: 1400px) {
  .customPaddingRight {
    padding-right: 120px;
  }
}

.text {
  width: 100% !important;
}
</style>