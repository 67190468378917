<template>
  <div class="mt-4 d-flex flex-column" style="width: 100%; height: 100%">
    <v-card class="mb-4">
      <v-card-title class="greyish--text">
        Πορεία πτυχιακής εργασίας
        {{ thesis.person.firstName + " " + thesis.person.lastName }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
          loading-text="Φόρτωση..."
          :footer-props="{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          }"
          :headers="poreiaHeaders"
          :items="poreiaPtyxiakhsErgasias.content"
          :server-items-length="poreiaPtyxiakhsErgasias.elements"
          :options.sync="poreiaOptions"
          :loading="poreiaTableLoader"
        >
          <template v-slot:[`item.dateEvent`]="{ item }">
            <span>{{ new Date(item.dateEvent).toLocaleDateString("el") }}</span>
          </template>

          <template v-slot:[`item.examiner`]="{ item }">
            <span>{{
              item.examiner.firstName + " " + item.examiner.lastName
            }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialog(item, 'edit')">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">
                    mdi-pen
                  </v-icon>
                </v-btn>
              </template>
              <span>Επεξεργασία</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed icon @click="openDialog(item, 'delete')">
                  <v-icon color="error" small v-bind="attrs" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Διαγραφή</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <div class="custom-card-buttons">
        <v-btn depressed color="error" @click="$emit('close')"
          >Επιστροφή
        </v-btn>
        <v-btn depressed color="primary ml-2" @click="openDialog(null, 'add')">
          Δημιουργία
          <v-icon dense right>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'add'"
        max-width="500px"
        scrollable
      >
        <AddDegreeEvent
          v-if="dialog.open && dialog.type == 'add'"
          @close="closeDialog"
          :thesis="thesis"
        />
      </v-dialog>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'edit'"
        max-width="500px"
        scrollable
      >
        <EditDegreeEvent
          v-if="dialog.open && dialog.type == 'edit'"
          :degree="dialog.degree"
          @close="closeDialog"
          :thesis="thesis"
        />
      </v-dialog>

      <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'delete'"
        max-width="500px"
        scrollable
      >
        <DeleteDegreeEvent
          v-if="dialog.open && dialog.type == 'delete'"
          :degree="dialog.degree"
          @close="closeDialog"
        />
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import AddDegreeEvent from "../../components/DegreeEventsModals/AddDegreeEvent.vue";
import EditDegreeEvent from "../../components/DegreeEventsModals/EditDegreeEvent.vue";
import DeleteDegreeEvent from "../../components/DegreeEventsModals/DeleteDegreeEvent.vue";

export default {
  components: {
    AddDegreeEvent,
    EditDegreeEvent,
    DeleteDegreeEvent,
  },
  props: ["thesis"],
  data() {
    return {
      poreiaTableLoader: false,
      poreiaOptions: {
        itemsPerPage: 10,
        page: 1,
      },
      poreiaHeaders: [
        {
          text: "Θέμα",
          value: "degree.subject",
          sortable: false,
        },
        {
          text: "Βαθμός",
          value: "grade",
          sortable: false,
        },
        {
          text: "Ημερομηνία εξέτασης",
          value: "dateEvent",
          sortable: false,
        },
        {
          text: "Εξεταστής",
          value: "examiner",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        degree: null,
        open: false,
        type: null,
      },
    };
  },
  created() {
    console.log(this.thesis,'Thesis')
  },

  watch: {
    poreiaOptions: {
      async handler() {
        try {
          this.poreiaTableLoader = true;
          this.setFilters(this.poreiaOptions);

          await this.getPoreiaPtyxiakhsErgasias({
            id: this.thesis.person.id,
            degreeId: this.thesis.id,
            page: this.poreiaOptions.page,
            limit:
              this.poreiaOptions.itemsPerPage == -1
                ? this.poreiaPtyxiakhsErgasias.elements
                : this.poreiaOptions.itemsPerPage,
          });
          this.poreiaTableLoader = false;
        } catch (e) {
          console.log(e);
          this.$store.dispatch("errorHandler", e);

          this.poreiaTableLoader = false;
        }
      },
    },
    deep: true,
  },

  computed: {
    ...mapState({
      poreiaPtyxiakhsErgasias: (state) => state.degrees.poreiaPtyxiakhsErgasias,
      currentUser: (state) => state.users.currentUser,
      currentDegree: (state) => state.degrees.currentDegree,
      pageChanged: (state) => state.base.pageChanged,
      selectedRole: (state) => state.auth.selectedRole,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getPoreiaPtyxiakhsErgasias"]),
    async fetchThesisDetails() {
      this.poreiaTableLoader = true;
      this.setFilters(this.poreiaOptions);

      await this.getPoreiaPtyxiakhsErgasias({
        id: this.thesis.person.id,
        degreeId: this.thesis.id,
        page: this.poreiaOptions.page,
        limit:
          this.poreiaOptions.itemsPerPage == -1
            ? this.poreiaPtyxiakhsErgasias.elements
            : this.poreiaOptions.itemsPerPage,
      });
      this.poreiaTableLoader = false;
    },
    openDialog(degree, type) {
      this.dialog = {
        degree,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        degree: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchThesisDetails();
    },
  },
};
</script>

<style>
</style>