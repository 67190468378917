<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία δικαιολογητικού
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              v-model="password"
              type="text"
              hide-details="auto"
              :error-messages="passwordErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Κωδικός
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              dense
              outlined
              v-model="yndoDocDur"
              :items="getDataStaticList(100)"
              item-text="description"
              hide-details="auto"
              item-value="cd"
              :error-messages="yndoDocDurErrors"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Διάρκεια δικαιολογητικού
              </template>
            </v-select></v-col
          >

          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              v-model="yndoDocMm"
              label="Διάρκεια σε μήνες"
              type="text"
              hide-details="auto"
              :error-messages="yndoDocMmErrors"
              :disabled="yndoDocDur == null || yndoDocDur == 0 ? true : false"
            >
              <template
                v-if="yndoDocDur != null && yndoDocDur != 0"
                v-slot:label
              >
                <span class="error--text">*</span>
                Διάρκεια σε μήνες
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              dense
              outlined
              v-model="description"
              label="Περιγραφή"
              :error-messages="descriptionErrors"
              hide-details="auto"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Περιγραφή
              </template>
            </v-textarea>
          </v-col>

          <v-col cols="12">
            <v-textarea
              dense
              outlined
              hide-details="auto"
              v-model="memo"
              label="Σχόλια"
            ></v-textarea>
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              class="mt-2 mr-2"
              hide-details="auto"
              v-model="flgActive"
              label="Ενεργό"
              true-value="1"
              false-value="0"
            >
            </v-checkbox>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, requiredIf, numeric } from "vuelidate/lib/validators";
export default {
  async created() {
    try {
      this.getApops();
    } catch (e) {
      console.log(e);
    }
  },

  data() {
    return {
      loader: false,
      password: null,
      description: null,
      yndoDocDur: null,
      yndoDocMm: null,
      memo: null,
      flgActive: 0,
    };
  },

  validations: {
    password: {
      required,
    },

    description: {
      required,
    },

    yndoDocDur: {
      required,
    },
    yndoDocMm: {
      required: requiredIf(function () {
        return this.yndoDocDur == "1";
      }),
      numeric,
    },
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    yndoDocDurErrors() {
      const errors = [];
      if (!this.$v.yndoDocDur.$dirty) return errors;
      if (!this.$v.yndoDocDur.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    yndoDocMmErrors() {
      const errors = [];
      if (!this.$v.yndoDocMm.$dirty) return errors;
      if (!this.$v.yndoDocMm.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.yndoDocMm.numeric) errors.push("Επιτρέπονται μόνο ψηφίασ");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addSupportingDocument", "getApops"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          masterSchoolId: this.currentFaculty.id,
          schoolId: this.currentSchool.id,
          cd: this.password,
          description: this.description,
          yndoDocDur: this.yndoDocDur,
          flgActive: this.flgActive,
        };
        if (this.yndoDocDur == "1") content.yndoDocMm = this.yndoDocMm;
        if (this.memo != null) content.memo = this.memo;

        await this.addSupportingDocument(content);
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>