<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία απουσίας
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pa-4 d-flex flex-column">
      <v-progress-circular
        v-if="cardLoader"
        indeterminate
        color="primary"
        class="align-self-center ma-4"
        :size="50"
      ></v-progress-circular>
      <v-form v-else @submit.prevent="onSubmit" class="pa-4">
        <div>
          <span class="font-weight-bold">Σπουδαστής:</span>
          <p class="font-weight-medium">
            {{ absence.firstName }} {{ absence.lastName }}
          </p>
        </div>

        <v-select
          v-model="attendLessonId"
          v-if="schoolDetails != null && schoolDetails.absence.cd == '1'"
          class="mb-4"
          :items="attendLessons.content"
          item-text="lesson.description"
          hide-details="auto"
          item-value="id"
          dense
          outlined
          :loading="lessonLoader"
          :disabled="personId == null || lessonLoader"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span> Μάθημα
          </template>
        </v-select>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="absDateFormatted"
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
              class="mb-4"
              @blur="absDate = parseDate(absDateFormatted, 'absDate')"
              :error-messages="absDateErrors"
              :disabled="personId == null || lessonLoader"
            >
              <template v-slot:prepend-inner>
                <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
              </template>
              <template v-slot:label>
                <span class="error--text">*</span>
                Ημερομηνία απουσίας
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            locale="el"
            v-model="absDate"
            scrollable
            hide-details="auto"
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>

        <v-text-field
          dense
          outlined
          v-model="absHours"
          hide-details="auto"
          class="mb-4"
          :error-messages="absHoursErrors"
          :disabled="personId == null || lessonLoader"
        >
          <template v-slot:label>
            <span class="error--text">*</span> Ώρες απουσιών
          </template>
        </v-text-field>
        <div
          v-if="
            progTrainDetails.trainingPeriod.dailyHours != null &&
            progTrainDetails.trainingPeriod.dailyHours != undefined &&
            progTrainDetails.trainingPeriod.dailyHours != 0
          "
        >
          <div class="d-flex flex-direction-row">
            <v-checkbox
              v-for="j in progTrainDetails.trainingPeriod.dailyHours"
              :key="j"
              v-model="absPerHour"
              :label="j + 'η'"
              :value="String(j)"
              class="ml-1"
            ></v-checkbox>
          </div>
          <span
            v-if="absPerHourErrors.length > 0"
            style="color: red; margin-bottom: 1px"
            >{{ absPerHourErrors[0] }}</span
          >
        </div>
        <v-select
          v-model="absType"
          class="mb-4 mt-1"
          :error-messages="absTypeErrors"
          :items="getDataStaticList(100)"
          hide-details="auto"
          item-text="description"
          item-value="cd"
          dense
          outlined
          :disabled="personId == null || lessonLoader"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span> Δικαιολογημένη
          </template>
        </v-select>
        <v-textarea
          dense
          outlined
          v-model="notes"
          hide-details="auto"
          v-if="absType == '1'"
        >
          <template v-slot:label> Σχόλιο </template>
        </v-textarea>

        <div class="d-flex justify-center mt-2">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  required,
  numeric,
  maxLength,
  maxValue,
} from "vuelidate/lib/validators";
import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["absence", "type", "maxAbsence"],

  async created() {
    try {
      this.absDate = this.absence?.absDate;
      this.absType = String(this.absence?.absType);
      this.absHours = this.absence?.absHours;
      this.attendLessonId = this.absence?.attendLessonId;
      this.personId = this.absence?.personId;
      this.notes = this.absence?.notes;
      this.absPerHour =
        this.absence.absPerHour.length > 0 ? this.absence.absPerHour.split(",") : [];

      // await this.getStudents({
      //   page: 0,
      //   progTrainPrdId: this.progTrainPeriodId,
      //   classId: this.classId,
      // });

      await this.getAttendLessons({
        page: 0,
        progTrainPeriodId: this.progTrainPeriodId,
        personId: this.personId,
      });
      await this.fetchDetailShool();

      this.cardLoader = false;
    } catch (e) {
      console.log(e);
      this.cardLoader = false;
    }
  },

  data() {
    return {
      cardLoader: true,
      lessonLoader: false,
      loader: false,
      absDate: null,
      absDateFormatted: null,
      absHours: null,
      absType: null,
      absPerHour: [],
      attendLessonId: null,
      notes: null,
      menu: false,
      personId: [],
      school: {},
      showLesson: false,
    };
  },

  validations() {
    const self = this;
    return {
      absDate: {
        required,
      },
      absHours: {
        maxValue: maxValue(self.maxAbsence),
      },
      absType: {
        required,
      },
      // attendLessonId: {
      //   required,
      // },
      absPerHour: {
        maxLength: maxLength(3),
      },
    };
  },

  watch: {
    absDate(val) {
      this.absDateFormatted = this.formatDate(this.absDate);
    },
  },

  computed: {
    ...mapState({
      students: (state) => state.students.students,
      attendLessons: (state) => state.attend_lessons.attendLessons,
      staticList: (state) => state.auth.userInfo.staticView,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      schoolId: (state) => state.generic_filter.schoolId,
      schoolDetails: (state) => state.generic_filter.schoolDetails,
      classId: (state) => state.generic_filter.classId,
      progTrainDetails: (state) => state.generic_filter.progTrainDetails,
    }),

    absDateErrors() {
      const errors = [];
      if (!this.$v.absDate.$dirty) return errors;
      if (!this.$v.absDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    absHoursErrors() {
      const errors = [];
      if (!this.$v.absHours.$dirty) return errors;
      if (!this.$v.absHours.maxValue)
        errors.push("Έχετε ξεπεράσει τον επιτρεπόμενο αριθμό απουσιών");
      if (!this.$v.absHours.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },
    absPerHourErrors() {
      const errors = [];
      if (!this.$v.absPerHour.$dirty) return errors;
      if (!this.$v.absPerHour.maxLength)
        errors.push(
          "Ασυμφωνία δεδομένων μεταξύ συνολικών ωρών απουσιών και αντίστοιχων ωρών διδασκαλίας"
        );
      return errors;
    },

    absTypeErrors() {
      const errors = [];
      if (!this.$v.absType.$dirty) return errors;
      if (!this.$v.absType.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    // attendLessonIdErrors() {
    //   const errors = [];
    //   if (!this.$v.attendLessonId.$dirty) return errors;
    //   if (!this.$v.attendLessonId.required) errors.push("Υποχρεωτικό πεδίο");
    //   return errors;
    // },
  },

  methods: {
    ...mapActions(["editAbsence", "getStudents", "getAttendLessons"]),

    async onStudentChange() {
      try {
        this.lessonLoader = true;
        await this.getAttendLessons({
          page: 0,
          progTrainPeriodId: this.progTrainPeriodId,
          personId: this.personId,
        });
        this.lessonLoader = false;
      } catch (e) {
        this.lessonLoader = false;
        console.log(e);
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "absDate") {
          this.absDate = null;
          this.absDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async onSubmit() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) return;
        this.loader = true;
        let id;
        let content = {
          absDate: this.absDate,
          absHours: this.absHours,
          absType: this.absType,
          attendLessonId: this.attendLessonId,
          personId: this.personId,
          notes: this.notes,
          progTrainPrdId: this.progTrainPeriodId,
          absPerHour: this.absPerHour.join(),
        };
        if (this.type == "massive") {
          id = this.absence.absenceId;
        } else {
          id = this.absence.dailyAbsenceId;
        }

        await this.editAbsence({
          absenceId: id,
          content,
        });
        await this.$emit("handler");

        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },

    async fetchDetailShool() {
      if (this.schoolId) {
        let response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/combine/${this.schoolId}`
        );
        this.school = response.data.school;

        if (
          response.data.school.absence &&
          response.data.school.absence.cd == "1"
        ) {
          this.showLesson = true;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>