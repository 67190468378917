export default {
  state: {
    snackbar: {
      open: false,
      text: null,
      color: null,
    },
    filters: {},
    pageChanged: false, //in case of deleting the last row in a page
    miniMenu: false,
    excludeRouter: [
      {
        main: "students",
        internal: [
          "atomika_stoixeia",
          "stoixeia_foithshs",
          "ekpaideutika_taksidia",
          "ptyxiakh_ergasia",
          "meteggrafes",
          "anastoles_foithshs",
          "refusal_details",
          "diagrafes",
          "applications",
          "awards",
          "certifications",
          "edit",
        ],
      },
      {
        main: "teachers",
        internal: [
          "proswpika_stoixeia",
          "eggrafes",
          "applications",
          "experience",
          "edit",
        ],
      },
      { main: "classes", internal: ["studentsClass"] },
      { main: "class_exams", internal: ["studentsClassExams"] },
      { main: "prog_trips", internal: ["details"] },
      { main: "libraries", internal: ["books"] },
      { main: "library_books", internal: ["lend"] },
      { main: "faculties", internal: ["schools"] },
      { main: "semester", internal: ["exams", "courses"] },
      { main: "operators", internal: ["editOperators"] },
      {
        main: "absences_approve",
        internal: [
          "atomika_stoixeia_absence",
          "stoixeia_foithshs_absence",
          "ekpaideutika_taksidia_absence",
          "ptyxiakh_ergasia_absence",
          "meteggrafes_absence",
          "anastoles_foithshs_absence",
          "diagrafes_absence",
          "applications_absence",
          "awards_absence",
          "certifications_absence",
          "poreia",
          "apousies",
          "bathmologia",
          "applications",
          "awards",
        ],
      },
    ],
  },
  mutations: {
    toggleSnackbar(state, snackbar) {
      state.snackbar = {
        ...state.snackbar,
        ...snackbar,
      };
    },

    setFilters(state, filters) {
      state.filters = filters;
    },

    changePage(state, pageChanged) {
      state.pageChanged = pageChanged;
    },
    setMiniMenu(state, payload) {
      state.miniMenu = payload;
    },
  },
  actions: {
    toggleSnackbar({ commit }, payload) {
      commit("toggleSnackbar", payload);
    },
    setMiniMenu({ commit }, payload) {
      commit("setMiniMenu", payload);
    },
  },
};
