<template>
  <v-card>
    <v-toolbar
      :color="appType == 'approve' ? 'success' : 'error'"
      class="white--text"
    >
      <span v-if="appType == 'approve'"> Έλεγχος αίτησης </span>
      <span v-else-if="appType == 'reject'"> Απόρριψη αίτησης </span>

      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pa-4">
      <div class="d-flex flex-column">
        <div
          class="
            d-flex
            align-center
            justify-center
            text-body-1
            black--text
            mb-4
          "
        >
          <v-icon color="error" class="mr-1">mdi-alert-circle-outline</v-icon>
          <div v-if="appType == 'approve'">
            Είστε σίγουροι ότι θέλετε να εγκρίνετε αυτή την αίτηση?
          </div>
          <div v-if="appType == 'reject'">
            Είστε σίγουροι ότι θέλετε να απορρίψετε αυτή την αίτηση?
          </div>
        </div>
        <v-text-field
          dense
          outlined
          v-model="protocolNo"
          :error-messages="protocolNoErrors"
          class="mt-4"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Αριθμός Πρωτοκόλλου
          </template>
        </v-text-field>
        <v-file-input
          v-if="appType == 'approve'"
          label="Επισυναπτόμενο αρχείο"
          dense
          outlined
          v-model="attachedFile"
          :rules="rules"
          accept="image/png, image/jpeg, image/jpg, application/pdf, application/csv"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          class="mt-4"
        >
        </v-file-input>

        <v-text-field
          v-if="appType == 'reject'"
          label="Λόγος απόρριψης"
          dense
          outlined
          v-model="rejectReason"
          class="mt-4"
        >
        </v-text-field>
      </div>

      <div v-if="appType == 'approve'" class="d-flex justify-center pb-2">
        <v-btn depressed outlined color="error" class="mr-4" @click="$emit('close')"
          >Ακύρωση</v-btn
        >
        <v-btn
          depressed
          color="success"
          class="mr-2"
          @click="onAccept"
          :loading="loader"
        >
          Έλεγχος
        </v-btn>
      </div>

      <div v-else-if="appType == 'reject'" class="d-flex justify-center pb-2">
        <v-btn
          depressed
          outlined
          color="error"
          class="mr-4"
          @click="$emit('close')"
          >Ακύρωση</v-btn
        >

        <v-btn depressed color="error" @click="onReject" :loading="loader">
          Απόρριψη
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["appType", "application"],

  data() {
    return {
      loader: false,
      attachedFile: [],
      rejectReason: null,
      protocolNo: null,
      rules: [
        value => {
          return !value || value?.size < 20000000 || 'Το αρχείο πρέπει να είναι μικρότερο από 20 MB'
        },
      ],
    };
  },
  validations: {
    protocolNo: {
      required,
    },
  },
  computed: {
    protocolNoErrors() {
      const errors = [];
      if (!this.$v.protocolNo.$dirty) return errors;
      if (!this.$v.protocolNo.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
  methods: {
    ...mapActions(["approveApplication"]),

    async onAccept() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        const formData = new FormData();
        if (this.attachedFile.length != 0)
          formData.append("replyAttachFile", this.attachedFile);

        await this.approveApplication({
          personApplicationId: this.application.id,
          approve: true,
          protocolNo: this.protocolNo,
          formData,
        });
        this.loader = false;
        await this.$emit("handler");

        this.$emit("close");
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },

    async onReject() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        const formData = new FormData();
        if (this.rejectReason != null)
          formData.append("rejectReason", this.rejectReason);

        await this.approveApplication({
          personApplicationId: this.application.id,
          approve: false,
          protocolNo: this.protocolNo,
          formData,
        });
        this.loader = false;
        await this.$emit("handler");

        this.$emit("close");
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
  },
};
</script>

<style>
</style>