<template>
  <v-app-bar app class="blue" style="height: 100px">
    <v-row>
      <div class="d-flex justify-content-end align-items-center">
        <router-link
          to="/login"
          custom
          v-slot="{ navigate }"
          class="pr-10 mt-10 list-height"
        >
          <a class="mx-2 pl-2 mt-10 white--text" @click="navigate">
            <img
              class="img-fluid pl-2"
              src="@/assets/images/dnelogo.png"
              alt="Σύστημα Ηλεκτρονικής Έκδοσης Αποδεικτικών Ναυτικής Ικανότητας και Endorsement Ναυτικών"
            />
          </a>
        </router-link>
      </div>
      <div class="ml-auto d-flex align-center">
        <div class="d-flex justify-content-end align-items-center">
          <router-link
            to="/academy"
            custom
            v-slot="{ navigate }"
            class="pr-10 mt-10 list-height"
          >
            <a
              class="white--text text-decoration-underline"
              @click="navigate"
              role="link"
            >
              ΑΚΑΔΗΜΙΕΣ
            </a>
          </router-link>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <router-link
            to="/usefullLinks"
            custom
            v-slot="{ navigate }"
            class="pr-10 mt-10 list-height"
          >
            <a
              class="white--text text-decoration-underline"
              @click="navigate"
              role="link"
            >
              ΧΡΗΣΙΜΟΙ ΣΥΝΔΕΣΜΟΙ
            </a>
          </router-link>
        </div>
      </div>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    toLinks() {
      this.$router.push("/usefullLinks");
    },
    toAcademy() {
      this.$router.push("/academy");
    },
  },
};
</script>

<style scoped></style>
