<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία πορείας πτυχιακής εργασίας
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              outlined
              v-model="dateEventFormatted"
              hide-details="auto"
              class="mb-4"
              @blur="dateEvent = parseDate(dateEventFormatted, 'dateEvent')"
              :error-messages="dateEventErrors"
            >
              <template v-slot:prepend-inner>
                <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
              </template>
              <template v-slot:label>
                <span class="error--text">*</span>
                Ημερομηνία εξέτασης
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            locale="el"
            v-model="dateEvent"
            scrollable
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>

        <v-select
          v-model="examinerPersonId"
          class="mb-4"
          :error-messages="examinerPersonIdErrors"
          :items="examinerList"
          hide-details="auto"
          :item-text="(item) => item.firstName + ' ' + item.lastName"
          item-value="id"
          dense
          outlined
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Εξεταστής
          </template>
        </v-select>

        <v-text-field
          v-model="grade"
          dense
          outlined
          type="number"
          :error-messages="gradeErrors"
          :min="0"
          :max="10"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Βαθμολογία
          </template>
        </v-text-field>

        <!-- <v-select
          dense
          outlined
          v-model="grade"
          hide-details="auto"
          :items="getDataStaticList(153)"
          item-text="description"
          item-value="cd"
          type="text"
          class="mb-4"
          :error-messages="gradeErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Βαθμολογία
          </template>
        </v-select> -->

        <v-textarea
          dense
          outlined
          v-model="memo"
          :error-messages="memoErrors"
          hide-details="auto"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Σχόλια
          </template>
        </v-textarea>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["degree", "thesis"],
  created() {
    console.log(this.degree);

    this.examinerList.push(this.thesis.examinerPerson1);
    if (this.thesis?.examinerPerson2 != undefined) {
      this.examinerList.push(this.thesis?.examinerPerson2);
    }
    if (this.thesis?.examinerPerson3 != undefined) {
      this.examinerList.push(this.thesis?.examinerPerson3);
    }
    if (this.thesis?.examinerPerson4 != undefined) {
      this.examinerList.push(this.thesis?.examinerPerson4);
    }

    this.examinerPersonId = this.degree.examiner.id;
    this.dateEvent = this.degree.dateEvent;
    this.grade = this.degree.grade;
    this.memo = this.degree?.memo;
  },

  data() {
    return {
      menu: false,
      loader: false,
      dateEvent: null,
      dateEventFormatted: null,
      examinerPersonId: null,
      examinerList: [],

      grade: null,
      memo: null,
    };
  },

  watch: {
    dateEvent(val) {
      this.dateEventFormatted = this.formatDate(this.dateEvent);
    },
  },

  validations: {
    dateEvent: {
      required,
    },
    examinerPersonId: {
      required,
    },
    grade: {
      required,
    },
    memo: {
      required,
    },
  },

  computed: {
    ...mapState({
      currentDegree: (state) => state.degrees.currentDegree,
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    dateEventErrors() {
      const errors = [];
      if (!this.$v.dateEvent.$dirty) return errors;
      if (!this.$v.dateEvent.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },

    examinerPersonIdErrors() {
      const errors = [];
      if (!this.$v.examinerPersonId.$dirty) return errors;
      if (!this.$v.examinerPersonId.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },

    gradeErrors() {
      const errors = [];
      if (!this.$v.grade.$dirty) return errors;
      if (!this.$v.grade.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },

    memoErrors() {
      const errors = [];
      if (!this.$v.memo.$dirty) return errors;
      if (!this.$v.memo.required) errors.push("Υποχρεωτικό πεδίο");

      return errors;
    },
  },

  methods: {
    ...mapActions(["editDegreeEvent"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          dateEvent: this.dateEvent,
          degreeId: this.thesis.id,
          examinerPersonId: this.examinerPersonId,
          grade: this.grade,
        };

        if (this.memo != null);
        content.memo = this.memo;

        await this.editDegreeEvent({
          degreeId: this.degree.id,
          content,
        });

        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "dateEvent") {
          this.dateEvent = null;
          this.dateEventFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>