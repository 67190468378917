<template>
  <v-card
    color="transparent"
    class="component-wrapper d-flex align-center justify-center"
  >
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <GenericFilter
        :level="6"
        @onSearchAction="onSearch"
        :listActive="1"
        :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: true,
          lesson: true,
        }"
      />
      <!--
      <div>
        <v-row>
          <v-col cols="12" md="6" sm="4">
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="exmDateFormatted"
                  class="mb-4"
                  @blur="exmDate = parseDate(exmDateFormatted, 'exmDate')"
                  :error-messages="exmDateErrors"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία ενημέρωσης
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="exmDate"
                scrollable
                @input="dateMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      -->
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <div>
            <v-row>
              <v-col cols="12" md="3" sm="12">
                <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="exmDateFormatted"
                      class="mb-4"
                      @blur="exmDate = parseDate(exmDateFormatted, 'exmDate')"
                      :error-messages="exmDateErrors"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                      </template>
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ημερομηνία ενημέρωσης
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="el"
                    v-model="exmDate"
                    scrollable
                    @input="dateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="9" v-if="progExam && progExam.endDate">
                <h4 style="line-height: 3">
                  Ημερομηνία Τελευταίας Καταχώρησης:
                  {{ progEndDate }}
                </h4></v-col
              >
            </v-row>
          </div>
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="studentGrades.hashContent"
            :options.sync="options"
            :server-items-length="studentGrades.elements"
            :loading="tableLoader"
          >
            <template v-slot:[`item.oral`]="{ item }">
              <span v-for="(grade, i) in gradeList" :key="i">
                <span v-if="grade.personId == item.person_id">
                  <v-text-field
                    v-model="gradeList[i].oral"
                    dense
                    single-line
                    :error-messages="errorMessages(i)"
                    @change="() => onChangeGrade(i)"
                    v-if="edit"
                  ></v-text-field>

                  <span v-else>{{ item.oral }}</span>
                </span>
              </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <span v-if="item.oral != null">
                <div v-if="!edit">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn depressed icon @click="openDialog(item, 'delete')">
                        <v-icon color="error" small v-bind="attrs" v-on="on">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Διαγραφή</span>
                  </v-tooltip>
                </div>
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary"
            class="mr-2"
            :disabled="!filtersSet || studentGrades.hashContent.length == 0"
            v-if="!edit"
            @click="edit = !edit"
          >
            Επεξεργασία
            <v-icon dense right>mdi-pencil-outline</v-icon>
          </v-btn>

          <v-btn
            depressed
            color="error"
            class="mr-2"
            v-if="edit"
            @click="onClose"
          >
            Ακύρωση
          </v-btn>

          <v-btn
            depressed
            color="success"
            class="mr-2"
            v-if="edit"
            @click="onSave"
            :loading="saveLoader"
          >
            Αποθήκευση
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteGrade
        v-if="dialog.open && dialog.type == 'delete'"
        :gradeProp="dialog.grade"
        @close="closeDialog"
        @onDelete="handler"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import DeleteGrade from "../../components/Grades/DeleteGrade.vue";
import {
  required,
  between,
  betweenValue,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import axios from "axios";
const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  components: {
    GenericFilter,
    DeleteGrade,
  },
  data() {
    return {
      tableLoader: false,
      progExam: null,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      gradeList: [],
      edit: false,
      lessonInfo: null,
      filtersSet: false,

      menu: false,
      menu1: false,
      dateMenu: false,
      exmDate: null,
      exmDateFormatted: null,
      saveLoader: false,
      studentGrades: [],
      headers: [
        {
          text: "ΑΜ",
          value: "student_school_code",
          sortable: false,
        },
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Βαθμολογία",
          value: "oral",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        grade: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.filtersSet) await this.handler();
    },

    async courseId() {
      await this.getAttendLessons({
        page: 0,
        lessonId: this.courseId,
      });
    },

    exmDate(val) {
      this.exmDateFormatted = this.formatDate(this.exmDate);
    },
  },
  validations() {
    const self = this;
    return {
      exmDate: {
        required,
        minValue(val) {
          return new Date(val) >= new Date(self.progExam.startDate);
        },
        maxValue(val) {
          return new Date(self.progExam.endDate) >= new Date(val);
        },
      },
      gradeList: {
        $each: {
          oral: {
            betweenValue: between(
              self.lessonInfo?.minGrade,
              self.lessonInfo?.maxGrade
            ),
          },
        },
      },
    };
  },

  computed: {
    ...mapState({
      attendLessons: (state) => state.attend_lessons.attendLessons,
      staticList: (state) => state.auth.userInfo.staticView,
      pageChanged: (state) => state.base.pageChanged,
      masterSchoolId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      yearId: (state) => state.generic_filter.academicYearId,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      classId: (state) => state.generic_filter.classId,
      courseId: (state) => state.generic_filter.courseId,
      progExamPeriodId: (state) => state.generic_filter.semesterId,
    }),
    progEndDate() {
      return this.formatDate(this.progExam.endDate);
    },
    exmDateErrors() {
      const errors = [];
      if (!this.$v.exmDate.$dirty) return errors;
      if (!this.$v.exmDate.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.exmDate.minValue)
        errors.push("Η ημερομηνία δεν είναι μέσα στα όρια της εξεταστικής");
      if (!this.$v.exmDate.maxValue)
        errors.push("Η ημερομηνία δεν είναι μέσα στα όρια της εξεταστικής");
      return errors;
    },
  },
  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions(["getStudentGrades", "addStudentGrade", "getAttendLessons"]),
    decimalCount(num) {
      // Convert to String
      const numStr = String(num);
      // String Contains Decimal
      if (numStr.includes(".")) {
        return numStr.split(".")[1].length;
      }
      // String Does Not Contain Decimal
      return 0;
    },

    onChangeGrade(i) {
      if (this.studentGrades.hashContent[i].train_period_round_grade) {
        let grade = Number(this.gradeList[i].oral);
        let decimals = this.decimalCount(
          this.studentGrades.hashContent[i].train_period_round_grade
        );
        this.gradeList[i].oral = grade.toFixed(decimals);
      }
    },
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const resLesson = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/lesson/${this.courseId}`,
          {
            params: {
              page: 0,
            },
          }
        );
        this.lessonInfo = resLesson.data;

        const resExamPeriod = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/prog/train/${this.progExamPeriodId}`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              progExamPeriodId: this.progExamPeriodId,
            },
          }
        );
        this.progExam = resExamPeriod.data;
        console.log(this.progExam, "This is the Result");

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/person/listForGrade/oral`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              masterSchoolId: this.masterSchoolId,
              schoolId: this.schoolId,
              yearId: this.yearId,
              progTrainPeriodId: this.progTrainPeriodId, //this.progTrainPeriodId,
              lessonId: this.courseId,
              classId: this.classId,
              approved: false,
            },
          }
        );

        this.studentGrades = res.data;

        this.gradeList = [];
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        for (const student of this.studentGrades.hashContent) {
          //for every student
          if (student.oral != null) {
            this.gradeList.push({
              attendLessonId: student.edu_attend_lessons_id,
              entryDate: student.entry_date,
              exmDate: this.exm_date,
              oral: student.oral,
              personId: student.person_id,
              written: student.written,
              isNew: false,
              menu: false,
              menu1: false,
            });
          } else {
            this.gradeList.push({
              attendLessonId: student.edu_attend_lessons_id,
              exmDate: this.exmDate,
              oral: null,
              personId: student.person_id,
              isNew: true,
              menu: false,
              menu1: false,
            });
          }
        }

        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.studentGrades = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    onClose() {
      console.log(this.gradeList);
      for (let [i, grade] of this.gradeList.entries()) {
        if (grade.isNew) {
          this.gradeList[i] = {
            attendLessonId: grade.attendLessonId,
            exmDate: this.exmDate,
            oral: null,
            personId: grade.personId,
            isNew: true,
            menu: false,
            menu1: false,
          };
        }

        this.edit = false;
      }
    },

    async onSave() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.saveLoader = true;
        let content = this.gradeList.filter((el) => el.oral != null);

        for (let element of content) {
          element.exmDate = this.exmDate;
          delete element["menu"];
          delete element["menu1"];
        }

        console.log("to send ", content);
        await this.addStudentGrade(content);
        await this.handler();
        this.saveLoader = false;
        this.onClose();
      } catch (e) {
        this.saveLoader = false;

        this.onClose();
        console.log(e);
      }
    },

    openDialog(grade, type) {
      this.dialog = {
        grade,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        grade: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "exmDate") {
          this.exmDate = null;
          this.exmDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    errorMessages(i) {
      if (
        this.gradeList[i].oral != undefined &&
        this.gradeList[i].oral != null
      ) {
        const errors = [];
        if (!this.$v.gradeList.$each[i].oral.$dirty) return errors;
        if (this.$v.gradeList.$each[i].oral.$error)
          errors.push(
            "Ο βαθμός δεν είναι εντός του εύρους της επιτρεπόμενης βαθμολογίας "
          );
        return errors;
      } else return;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
