<template>
  <v-card>
    <v-toolbar color="error" class="mb-4" dark
      >Κλείσιμο {{ appId == 1 ? "εξαμήνου σπουδών" : "κύκλου σπουδών" }}
      <v-btn
        depressed
        icon
        class="ml-auto"
        color="white"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <div
        class="d-flex align-center justify-center text-body-1 black--text mb-4"
      >
        <v-icon color="error" class="mr-1">mdi-alert-circle-outline</v-icon>
        <div>
          Είστε σίγουροι ότι θέλετε να κλείσετε
          {{ appId == 1 ? "το εξαμήνο σπουδών" : "το κύκλο σπουδών" }}
          <strong>{{ this.data.progTrainPrdDescription }}</strong> ;
        </div>
      </div>
      <div class="d-flex justify-center pb-2">
        <v-btn dense outlined color="error" class="mr-4" @click="$emit('close')"
          >Ακύρωση</v-btn
        >
        <v-btn depressed color="error" @click="onClose" :loading="loader"
          >Κλείσιμο</v-btn
        >
      </div>
    </v-card-text>
    <v-dialog
      persistent
      max-width="600px"
      v-model="showError"
      scrollable
      transition="dialog-bottom-transition"
      id="errorDialog"
    >
      <ErrorModal
        :data="this.errorText"
        v-if="this.showError"
        @close="closeDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ErrorModal from "../PeriodClosureModal/ErrorModal.vue";

export default {
  name: "PeriodClosureModal",
  props: ["data"],
  components: {
    ErrorModal,
  },
  data() {
    return {
      loader: false,
      errorText: null,
      showError: false,
    };
  },
  methods: {
    async onClose() {
      this.loader = true;

      try {
        const res = await axios.put(
          `${process.env.VUE_APP_BASE_URL}/prog/train/close?progTrainPrdId=${this.data.progTrainPrdId}`
        );
        this.errorText = null;
        this.showError = false;
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.errorText = e.response.data.error;
        this.showError = true;
      }
    },
    closeDialog() {
      this.errorText = null;
      this.showError = false;
      this.$emit("close");
    },
  },
  computed: {
    ...mapState({
      genericFilter: (state) => state.generic_filter,
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),
  },
};
</script>