<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card width="100%" height="100%">
      <v-card-text cols="12" sm="12" md="12" class="m-4">
        <h2 class="text-h6">Παρακαλώ Ανεβάστε το αρχείο Eisaen σας εδώ</h2>
      </v-card-text>
      <v-row>
        <v-col>
          <v-file-input
            v-model="attachedFile"
            dense
            outlined
            label="Αρχείο"
            :rules="rules"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
            prepend-inner-icon="mdi-paperclip"
            :prepend-icon="null"
            hide-details="auto"
            class="mr-5 ml-5"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-col>
        <v-btn
          @click="onSubmit"
          :loading="loader"
          class="primary mr-4 float-right"
          >Ανεβάστε</v-btn
        >
      </v-col>
      <v-row justify="center">
        <v-dialog v-model="dialog" v-if="dialog.open == true" width="500">
          <v-card v-if="dialog.type == 'error' && dialog.open == true">
            <v-card-title class="text-h5 error lighten-2">
              Παρουσίαστηκε Προβλημα
            </v-card-title>

            <v-card-text>
              Παρακαλώ Ανεβάστε to αρχείο σε μορφή csv
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="dialog.open = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="dialog.type == 'sucess' && dialog.open == true">
            <v-card-title class="text-h5 primary lighten-2">
              Ανέβηκε με επιτυχία
            </v-card-title>

            <v-card-text>
             Το αρχείο ανέβηκε με επιτυχία
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog.open = false">
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "eisaen",
  data() {
    return {
      attachedFile: null,
      loader: false,
      dialog: {
        open: false,
        type: null,
        rules: [
          value => {
            return !value || value?.size < 20000000 || 'Το αρχείο πρέπει να είναι μικρότερο από 20 MB'
          },
        ],
      },
    };
  },
  methods: {
    async onSubmit() {
      console.log(this.attachedFile,'File format');
      if (this.attachedFile.type == "application/vnd.ms-excel" || this.attachedFile.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        console.log(this.attachedFile);

        try {
          this.loader = true
          let formData = new FormData();
          formData.append("file", this.attachedFile);
          const res = await Axios.post(
            `${process.env.VUE_APP_BASE_URL}/eis/aen/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          this.dialog.open = true;
          this.dialog.type = "sucess";
          this.loader = false;
        } catch(e) {
           this.loader = false
          this.$store.dispatch("errorHandler", e);
        }
      } else {
        this.dialog.open = true;
        this.dialog.type = "error";
        this.loader = false
      }
    },
  },
};
</script>

<style  scoped lang="scss" >
.v-application .error.lighten-2 {
  color: #fff;
}
</style>