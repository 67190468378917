
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-4 pa-4">
        <v-select
          dense
          outlined
          v-model="schoolTypeId"
          type="text"
          class="mb-4"
          :items="schoolTypesList"
          @change="fetchSchools"
          item-text="description"
          item-value="cd"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label> Επιλογή </template>
        </v-select>
      </v-card>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="schools.content"
            :server-items-length="schools.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.flgActive.cd`]="{ item }">
              <span v-if="item.flgActive && item.flgActive.cd == '1'">
                Ενεργό
              </span>
              <span v-else> Ανενεργό </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
            v-if="selectedSystemRole == 'ADMIN_ROLE'"
            :disabled="!filtersSet"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="1000px"
      scrollable
    >
      <AddSchool
        v-if="dialog.open && dialog.type == 'add'"
        :schoolTypeId="schoolTypeId"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="1200px"
      scrollable
    >
      <EditSchool
        v-if="dialog.open && dialog.type == 'edit'"
        :school="dialog.school"
        :schoolTypeId="schoolTypeId"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteSchool
        v-if="dialog.open && dialog.type == 'delete'"
        :school="dialog.school"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import AddSchool from "../../../components/SchoolModals/AddSchool.vue";
import EditSchool from "../../../components/SchoolModals/EditSchool.vue";
import DeleteSchool from "../../../components/SchoolModals/DeleteSchool.vue";
export default {
  components: {
    AddSchool,
    EditSchool,
    DeleteSchool,
  },
  async created() {
    this.$store.dispatch("setFilterSemester", {
      schoolTypeId: null,
      schoolId: null,
    });
    await this.fetchSchoolTypes();
  },
  data() {
    return {
      breadcrumb_items: [],
      tableLoader: false,
      schools: [],
      schoolTypesList: [],
      // query: false,
      schoolTypeId: null,
      filtersSet: false,
      // query: null,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Όνομα ",
          value: "description",
          sortable: false,
        },
        {
          text: "Κωδικός ",
          value: "cd",
          sortable: false,
        },
        {
          text: "Κατάσταση",
          value: "flgActive.cd",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        School: null,
        open: false,
        type: null,
      },
    };
  },
  watch: {
    options: {
      async handler() {
        try {
          if (this.schoolTypeId != null) {
            this.tableLoader = true;
            this.setFilters(this.options);
            this.fetchSchools();
            this.tableLoader = false;
          }
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
    },
    $route() {
      // this.query = this.$route.params.id;
      this.fetchSchoolTypes();
    },
    deep: true,
  },
  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      //   schools: (state) => state.schools.schools,
      pageChanged: (state) => state.base.pageChanged,
      selectedRole: (state) => state.auth.selectedRole,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
    }),
  },

  methods: {
    ...mapMutations(["setCurrentSchool", "setFilters", "changePage"]),
    ...mapActions(["getSchools"]),

    onAcademicYearsView(school) {
      this.setCurrentSchool(school);
      this.$router.push(`${this.$route.path}/${school.id}/academicyears`);
    },

    onSupportingDocumentsView(school) {
      this.setCurrentSchool(school);
      this.$router.push(`${this.$route.path}/${school.id}/supportingdocuments`);
    },

    onCertificatesView(school) {
      this.setCurrentSchool(school);
      this.$router.push(`${this.$route.path}/${school.id}/certificates`);
    },

    onSemestersView(school) {
      this.setCurrentSchool(school);
      this.$router.push(`${this.$route.path}/${school.id}/semesters`);
    },

    openDialog(school, type) {
      this.dialog = {
        school,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        school: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }

      this.fetchSchools();
    },
    async fetchSchoolTypes() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/104`,
          body
        );
        this.schoolTypesList = res.data;
        if (res.data.length == 1) {
          this.schoolTypeId = res.data[0].cd;
          this.fetchSchools();
        }
      } catch (e) {
        throw e;
      }
    },
    async fetchSchools() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        this.filtersSet = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/school/list/prototypes`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              schoolTypeId: this.schoolTypeId,
            },
          }
        );
        this.schools = res.data;
        this.tableLoader = false;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>