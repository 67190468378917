import { render, staticRenderFns } from "./DeleteSchool.vue?vue&type=template&id=fff24f68&scoped=true&"
import script from "./DeleteSchool.vue?vue&type=script&lang=js&"
export * from "./DeleteSchool.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fff24f68",
  null
  
)

export default component.exports