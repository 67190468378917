<template>
  <div class="component-wrapper d-flex align-start justify-center">
    <v-card max-width="1400px" width="100%" class="pa-4 d-flex flex-column" v-if="!dialog.open">
      <v-row class="pa-0 ma-0">
        <v-col>
          <v-select clearable dense outlined v-model="faculty" :items="faculties" item-text="description"
                    item-value="cd" no-data-text="Δεδομένα μη διαθέσιμα" @change="getSchools"
                    :error-messages="facultyErrors">
            <template v-slot:label>
              <span class="error--text">*</span> {{ appId == 1 ? "Ακαδημίες" : "Δομές Μετεκπαίδευσης" }}
            </template>
          </v-select>
        </v-col>
        <v-col v-if="selectedSystemRole == 'SECRETARY_ROLE' && appId == 1">
          <v-select clearable dense outlined v-model="schoolId" :items="schoolList" item-text="description"
                    item-value="cd" no-data-text="Δεδομένα μη διαθέσιμα" :error-messages="schoolIdErrors">
            <template v-slot:label>
              <span class="error--text">*</span>Σχολή
            </template>
          </v-select>
        </v-col>
        <v-col>
          <v-btn class="ml-auto" depressed color="primary" @click="fetchApplications">
            Αναζήτηση
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-tabs vertical left v-if="
          this.applications.lovValueResponsesSet &&
          this.applications.lovValueResponsesSet.length > 0
        " v-model="activeTab">
          <div class="headerTitle">Κατηγορίες</div>
          <v-tab v-for="(row, i) in this.applications.lovValueResponsesSet" :key="i">
            <span style="text-transform: capitalize">{{
                row.description
              }}</span>
          </v-tab>

          <v-tab-item v-for="(row, i) in this.applications.lovValueResponsesSet" :key="i" v-model="activeTab[i]">
            <div class="headerTitle">{{ row.description }}</div>

            <div v-for="(ap, j) in getApplication(row.id)" :key="j" class="applicationCard">
              <v-card flat>
                <v-card-text style="font-size: 15px">
                  <a @click="openDialog(ap, 'view')">{{ ap.description }}</a>
                </v-card-text>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-card>

    <Loader v-if="this.showLoader"/>

    <!-- <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'view'"
      max-width="1600px"
      scrollable
    > -->
    <ViewApplication v-if="dialog.open && dialog.type == 'view'" :application="dialog.application"
                     :onlineApplicationId="dialog.onlineApplicationId" :type="'submit'" @close="closeDialog"
                     :faculties="faculties"
                     :masterSchool="faculty" :schoolId="schoolId"/>
    <!-- </v-dialog> -->
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from "vuex";
import {required, requiredIf} from "vuelidate/lib/validators";
import ViewApplication from "../../components/Applications/ViewApplication.vue";
import Loader from "../../components/Loader/Loader.vue";
import axios from "axios";
import {isEmpty} from "@/helpers/helpers";

export default {
  components: {
    ViewApplication,
    Loader,
  },

  async created() {
    try {
      // await this.fetchApplications();
      await this.getFaculties();
    } catch (e) {
      console.log(e);
    }
  },
  data() {
    return {
      tableLoader: false,
      categoryType: null,
      activeTab: !isEmpty(this.activeTab) ? this.activeTab : [],
      faculty: null,
      faculties: [],
      start: false,
      showLoader: false,
      teacherList: [],
      applications: {
        commonOnlineApplicationResponse: [],
        lovValueResponsesSet: [],
      },
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Όνομα",
          value: "description",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        open: false,
        type: null,
        role: null,
      },
      schoolId: null,
      schoolList: []
    };
  },
  validations: {
    faculty: {required},
    schoolId: {
      required: requiredIf((e) => {
        if (e.appId == 1 && e.selectedSystemRole == 'SECRETARY_ROLE')
          return true;
        return false;
      }),
    }
  },
  watch: {
    options: {
      async handler() {
        try {
          this.tableLoader = true;
          this.setFilters(this.options);
          // await this.getApplications({
          //   page: this.options.page,
          //   limit:
          //     this.options.itemsPerPage == -1
          //       ? this.applications.elements
          //       : this.options.itemsPerPage,
          //   masterSchoolId: this.faculty,
          //   categoryTpy:
          //     this.categoryType != null ? parseInt(this.categoryType) : null,
          // });
          this.tableLoader = false;
        } catch (e) {
          console.log(e);
          this.$store.dispatch("errorHandler", e);

          this.tableLoader = false;
        }
      },
    },
    $route() {
      this.getFaculties();
    },
    deep: true,
  },

  computed: {
    ...mapState({
      categoryTypes: (state) => state.configure_applications.categoryTypes,
      //   applications: (state) => state.applications.applications,
      selectedRole: (state) => state.auth.selectedRole,
      currentApplication: (state) =>
          state.configure_applications.currentApplication,
      appId: (state) => state.auth.appId,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
    }),
    getApplication() {
      return (catId) =>
          this.applications.commonOnlineApplicationResponse.filter((m) => {
            if (catId == m.categoryType.id) {
              return m;
            }
          });
    },
    facultyErrors() {
      const errors = [];
      if (!this.$v.faculty.$dirty) return errors;
      if (!this.$v.faculty.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    schoolIdErrors() {
      const errors = [];
      if (!this.$v.schoolId.$dirty) return errors;
      if (!this.$v.schoolId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapMutations(["setFilters"]),
    ...mapActions([
      "getApplications",
      "getCurrentApplication",
      "getCategoryTypes",
    ]),

    async handler() {
      try {
        (this.start = true), (this.tableLoader = true);
        this.setFilters(this.options);
        await this.getApplications({
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
              this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
          masterSchoolId: this.faculty,
          categoryTpy:
              this.categoryType != null ? parseInt(this.categoryType) : null,
        });
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.tableLoader = false;
      }
    },

    async openDialog(application, type) {
      try {
        this.showLoader = true;
        await this.getCurrentApplication(application.id);
        this.dialog = {
          application: this.currentApplication,
          onlineApplicationId: application.onlineApplicationId,
          open: true,
          type,
        };
        this.showLoader = false;
      } catch (e) {
        console.log(e);
      }
      console.log(this.activeTab);
    },

    closeDialog() {
      this.dialog = {
        application: null,
        onlineApplicationId: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchApplications();
    },
    async getFaculties() {
      try {
        let body = {
          type: this.appId,
          parentCd: null,
          roleId: this.selectedRole,
        };
        const res = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/value/call/2000`,
            body
        );
        res.data.forEach((m) => {
          this.faculties.push(m);
        });

        if (res.data.length == 1) {
          this.faculty = res.data[0].cd;
          this.getSchools();
          // this.fetchApplications();
        }
      } catch (e) {
        throw e;
      }
    },
    async getSchools() {
      try {
        let body = {
          type: this.appId,
          parentCd: this.faculty,
          roleId: this.selectedRole,
        };
        const res = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/value/call/2001`,
            body
        );
        res.data.forEach((m) => {
          this.schoolList.push(m);
        });

        if (res.data.length == 1) {
          this.schoolId = res.data[0].cd;
          // this.fetchApplications();
        }
      } catch (e) {
        throw e;
      }
    },

    async fetchApplications() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.showLoader = true;
        if (this.faculty != null) {
          const res = await axios.get(
              `${process.env.VUE_APP_BASE_URL}/common/online/application/list2`,
              {
                params: {
                  roleId: this.selectedRole,
                  type: 1,
                  masterSchoolId: this.faculty,
                },
              }
          );
          this.applications = res.data.content[0];
        } else {
          this.applications = [];
        }
        this.showLoader = false;
      } catch (e) {
        this.applications = [];
        // throw e;
      }
    },
  },
};
</script>

<style lang="css">
.v-tab--active {
  color: inherit;
  background-color: #2962ff;
  color: white !important;
}

.applicationCard {
  margin: 5px;
  /* border-bottom: 1px solid; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.applicationCard:hover {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
}

.v-tabs-items {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.headerTitle {
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.v-tab {
  display: flex !important;
  justify-content: inherit !important;
}
</style>