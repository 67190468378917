<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία σύνδεσης εκπαιδευτή
      <v-btn
          depressed
          icon
          color="white"
          class="ml-auto"
          @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-select
            dense
            outlined
            v-model="eduClass"
            type="text"
            hide-details="auto"
            class="mb-4"
            :items="listClass"
            item-text="description"
            item-value="cd"
            :error-messages="eduClassErrors"
            :disabled="classId != null"
            no-data-text="Δεδομένα μη διαθέσιμα"

        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Τμήμα
          </template>
        </v-select>

        <v-select
            dense
            outlined
            v-model="lesson"
            type="text"
            class="mb-4"
            :items="listCourse"
            item-text="description"
            item-value="cd"
            :error-messages="lessonErrors"
            hide-details="auto"
            :disabled="courseId != null"
            no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Μάθημα
          </template>
        </v-select>

        <v-select
            dense
            outlined
            v-model="teacher"
            type="text"
            hide-details="auto"
            class="mb-4"
            :items="teacherList"
            item-text="teacherName"
            item-value="id"
            :error-messages="teacherErrors"
            no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Εκπαιδευτικός
          </template>
        </v-select>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
              depressed
              color="success"
              class="ml-4"
              type="submit"
              :loading="loader"
          >Αποθήκευση
          </v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {required} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  props: ["teacherLesson"],

  async created() {
    try {
      this.eduClass = this.teacherLesson?.eduClass?.id.toString();
      this.lesson = this.teacherLesson?.lesson?.id.toString();
      this.teacher = this.teacherLesson?.person?.id;
      const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/teacher/list`,
          {
            params: {
              page: 0,
              masterSchoolId: this.facultyId,
              schoolId: this.schoolId
            },
          }
      );
      this.teacherList = res.data.content.map((t) => {
        return {
          teacherName: t.lastName + " " + t.firstName,
          id: t.personId,
        };
      });
    } catch (e) {
    }
  },

  data() {
    return {
      teacherList: [],
      loader: false,
      lesson: null,
      eduClass: null,
      teacher: null,
    };
  },

  validations: {
    lesson: {
      required,
    },
    eduClass: {
      required,
    },

    teacher: {
      required,
    },
  },

  computed: {
    ...mapState({
      teachers: (state) => state.teachers.teachers,
      listClass: (state) => state.generic_filter.listClass,
      listCourse: (state) => state.generic_filter.listCourse,
      facultyId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      semesterId: (state) => state.generic_filter.semesterId,
      classId: (state) => state.generic_filter.classId,
      courseId: (state) => state.generic_filter.courseId,
    }),

    lessonErrors() {
      const errors = [];
      if (!this.$v.lesson.$dirty) return errors;
      if (!this.$v.lesson.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    eduClassErrors() {
      const errors = [];
      if (!this.$v.eduClass.$dirty) return errors;
      if (!this.$v.eduClass.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    teacherErrors() {
      const errors = [];
      if (!this.$v.teacher.$dirty) return errors;
      if (!this.$v.teacher.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["editTeacherLesson", "getTeachers"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          lessonId: this.lesson,
          personId: this.teacher,
          progTrainPeriodId: this.semesterId,
          eduClassId: this.eduClass,
          classTeacherId: this.teacherLesson.classTeachersId,
        };
        await this.editTeacherLesson(content);
        this.loader = false;
        await this.$emit("handler");

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>