<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία ενέργειας
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="8">
            <v-row>
              <v-col>
                <v-select
                  dense
                  outlined
                  v-model="commonApplicationActionId"
                  :items="commonApplicationActions.content"
                  item-text="description"
                  item-value="id"
                  type="text"
                  hide-details
                  @change="onChangeAction"
                  class="mb-4"
                  :error-messages="commonApplicationActionIdErrors"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Common application action id
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  dense
                  outlined
                  v-model="valueList"
                  :items="selectedFields"
                  item-text="description"
                  item-value="cd"
                  type="text"
                  hide-details
                  class="mb-4"
                  :error-messages="valueListErrors"
                  multiple
                  chips
                  small-chips
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Selected Fields
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  v-model="aa"
                  type="text"
                  hide-details
                  class="mb-4"
                  :error-messages="aaErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Order
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-card style="padding: 5px" v-if="actionOrder.length > 0">
              <!-- <span> Πεδία</span> -->
              <ul style="overflow-y: scroll">
                <li v-for="i in actionOrder" :key="i">
                  <span>{{ i }}</span>
                </li>
              </ul>
            </v-card>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  async created() {
    await this.getCommonApplicationActions({
      page: 0,
    });

    await this.getSelectedFields({
      parentCd: this.$route.params.common_online_application_id,
      roleId: this.selectedRole,
      type: this.appId,
    });
  },

  data() {
    return {
      loader: false,
      aa: null,
      commonApplicationActionId: null,
      valueList: [],
      actionOrder: [],
    };
  },

  validations: {
    aa: {
      required,
      numeric,
    },

    commonApplicationActionId: {
      required,
    },

    valueList: {
      required,
    },
  },

  computed: {
    ...mapState({
      selectedFields: (state) => state.configure_applications.selectedFields,
      commonApplicationActions: (state) =>
        state.configure_applications.commonApplicationActions,

      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),
    aaErrors() {
      const errors = [];
      if (!this.$v.aa.$dirty) return errors;
      if (!this.$v.aa.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.aa.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    commonApplicationActionIdErrors() {
      const errors = [];
      if (!this.$v.commonApplicationActionId.$dirty) return errors;
      if (!this.$v.commonApplicationActionId.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    valueListErrors() {
      const errors = [];
      if (!this.$v.valueList.$dirty) return errors;
      if (!this.$v.valueList.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions([
      "addAction",
      "getCommonApplicationActions",
      "getSelectedFields",
    ]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          aa: this.aa,
          commonApplicationActionId: this.commonApplicationActionId,
          commonOnlineApplicationId:
            this.$route.params.common_online_application_id,
          valueList: this.valueList.toString(),
        };

        await this.addAction(content);
        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
    async onChangeAction() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/common/application/action/order/${this.commonApplicationActionId}`
        );
        this.actionOrder = res.data;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>