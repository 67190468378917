
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-breadcrumbs :items="breadcrumb_items" divider=">"></v-breadcrumbs>

      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="schools.content"
            :server-items-length="schools.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.flgActive.cd`]="{ item }">
              <span v-if="item.flgActive.cd == '1'"> Ενεργό </span>
              <span v-else> Ανενεργό </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.academicYears`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onAcademicYearsView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.supportingDocuments`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    icon
                    @click="onSupportingDocumentsView(item)"
                  >
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.certificates`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onCertificatesView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.description`]="{ item }">
              {{
                item.description != undefined
                  ? item.description
                  : item.school.description
              }}
            </template>

            <template v-slot:[`item.semesters`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onSemestersView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
            depressed
            color="primary ml-auto"
            @click="openDialog(null, 'add')"
            v-if="selectedSystemRole == 'ADMIN_ROLE'"
          >
            Δημιουργία
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'add'"
      max-width="1000px"
      scrollable
    >
      <AddCombineSchool
        v-if="dialog.open && dialog.type == 'add'"
        :masterSchoolId="this.currentFaculty.id"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'edit'"
      max-width="1200px"
      scrollable
    >
      <EditCombineSchool
        v-if="dialog.open && dialog.type == 'edit'"
        :school="dialog.school"
        :masterSchoolId="this.currentFaculty.id"
        @close="closeDialog"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialog.open"
      v-if="dialog.type == 'delete'"
      max-width="500px"
      scrollable
    >
      <DeleteCombineSchool
        v-if="dialog.open && dialog.type == 'delete'"
        :school="dialog.school"
        :masterSchoolId="this.currentFaculty.id"
        @close="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import AddCombineSchool from "../../../components/CombineSchoolsModal/AddCombineSchool.vue";
import EditCombineSchool from "../../../components/CombineSchoolsModal/EditCombineSchool.vue";
import DeleteCombineSchool from "../../../components/CombineSchoolsModal/DeleteCombineSchool.vue";
import Helpers from "../../../helpers/helpers";
export default {
  components: {
    AddCombineSchool,
    EditCombineSchool,
    DeleteCombineSchool,
  },
  created() {
    this.breadcrumb_items.push(
      {
        text: `${this.currentFaculty.description}`,
        disabled: false,
        link: true,
        exact: true,
        to: "/faculties",
      },
      {
        text: `${this.appId == 1 ? "Σχολές" : this.getFieldName()} `,
        disabled: true,
        to: `/faculties/${this.currentFaculty.id}/schools`,
      }
    );
  },

  data() {
    return {
      breadcrumb_items: [],
      schools: [],
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headersAdminKesen: [
        {
          text: "Όνομα ",
          value: "description",
          sortable: false,
        },
        {
          text: "Κωδικός ",
          value: "school.cd",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
        {
          text: "Εκπαιδευτικά έτη",
          value: "academicYears",
          sortable: false,
        },
        {
          text: "Διδακτικές περίοδοι",
          value: "semesters",
          sortable: false,
        },

        {
          text: "Πιστοποιητικά",
          value: "certificates",
          sortable: false,
        },
      ],
      headersAdminAen: [
        {
          text: "Όνομα ",
          value: "description",
          sortable: false,
        },
        {
          text: "Κωδικός ",
          value: "school.cd",
          sortable: false,
        },
        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
        {
          text: "Ακαδημαϊκό έτος",
          value: "academicYears",
          sortable: false,
        },
        {
          text: "Εξάμηνο σπουδών",
          value: "semesters",
          sortable: false,
        },

        {
          text: "Πιστοποιητικά",
          value: "certificates",
          sortable: false,
        },
      ],
      headersOtherAen: [
        {
          text: "Όνομα σχολής",
          value: "description",
          sortable: false,
        },
        {
          text: "Κωδικός σχολής",
          value: "cd",
          sortable: false,
        },
        {
          text: "Κατάσταση",
          value: "flgActive.cd",
          sortable: false,
        },
        {
          text: "Ακαδημαϊκό έτος",
          value: "academicYears",
          sortable: false,
        },
        {
          text: "Εξάμηνο σπουδών",
          value: "semesters",
          sortable: false,
        },
      ],
      headersOtherKesen: [
        {
          text: "Όνομα Εκπαιδευτικού Κύκλου/Ειδικού Τμήματος",
          value: "description",
          sortable: false,
        },
        {
          text: "Κωδικός Εκπαιδευτικού Κύκλου/Ειδικού Τμήματος",
          value: "cd",
          sortable: false,
        },
        {
          text: "Κατάσταση",
          value: "flgActive.cd",
          sortable: false,
        },
        {
          text: "Ακαδημαϊκό έτος",
          value: "academicYears",
          sortable: false,
        },
        {
          text: "Διδακτικές περίοδοι",
          value: "semesters",
          sortable: false,
        },
      ],
      dialog: {
        School: null,
        open: false,
        type: null,
      },
    };
  },
  watch: {
    options: {
      async handler() {
        try {
          this.setFilters(this.options);
          this.fetchSchoolsCombine();
        } catch (e) {
          console.log(e);
        }
      },
    },
    deep: true,
  },
  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      // schools: (state) => state.schools.schools,
      pageChanged: (state) => state.base.pageChanged,
      selectedRole: (state) => state.auth.selectedRole,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
      appId: (state) => state.auth.appId,
    }),
    headers() {
      if (this.selectedSystemRole == "ADMIN_ROLE") {
        if (this.appId == 1) {
          return this.headersAdminAen;
        } else {
          return this.headersAdminKesen;
        }
      } else {
        if (this.appId == 1) {
          return this.headersOtherAen;
        } else {
          return this.headersOtherKesen;
        }
      }
    },

  },
  methods: {
    ...mapMutations(["setCurrentSchool", "setFilters", "changePage"]),
    ...mapActions(["getSchools"]),
        getFieldName() {
      return Helpers.getFieldName(2);
    },

    onAcademicYearsView(school) {
      this.setCurrentSchool(school.school);
      this.$router.push(`${this.$route.path}/${school.id}/academicyears`);
    },

    onSupportingDocumentsView(school) {
      this.setCurrentSchool(school.school);
      this.$router.push(`${this.$route.path}/${school.id}/supportingdocuments`);
    },

    onCertificatesView(school) {
      this.setCurrentSchool(school.school);
      this.$router.push(`${this.$route.path}/${school.id}/certificates`);
    },

    onSemestersView(school) {
      this.setCurrentSchool(school.school);
      this.$router.push(`${this.$route.path}/${school.id}/semesters`);
    },

    openDialog(school, type) {
      this.dialog = {
        school,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        school: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.fetchSchoolsCombine();
    },
    async fetchSchoolsCombine() {
      try {
        this.tableLoader = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/combine/list`,
          {
            params: {
              page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
              limit:
                this.options.itemsPerPage == -1
                  ? null
                  : this.options.itemsPerPage,
              masterSchoolId: this.currentFaculty.id,
            },
          }
        );
        this.schools = res.data;
        this.tableLoader = false;
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>