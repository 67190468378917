<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
        max-width="1400px"
        width="100%"
        height="100%"
        class="pa-4 d-flex flex-column"
        elevation="0"
        color="#EAF1F5"
    >
      <GenericFilter
          :level="6"
          :key="query"
          @onSearchAction="handler"
          :requiredFields="{
          masterSchool: true,
          school: true,
          year: true,
          progTrainPeriod: true,
          class: false,
          lesson: false,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
              no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
              loading-text="Φόρτωση..."
              :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
              :headers="headers"
              :items="teacherLessons.content"
              :options.sync="options"
              :server-items-length="teacherLessons.elements"
              :loading="tableLoader"
          >
            <template v-slot:[`item.absDate`]="{ item }">
              <span>{{ new Date(item.absDate).toLocaleDateString("el") }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
              depressed
              color="primary"
              class="mr-2"
              :disabled="!filtersSet"
              @click="openDialog(null, 'add')"
          >
            Σύνδεση
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'add'"
        max-width="500px"
        scrollable
    >
      <AddTeacherLesson
          v-if="dialog.open && dialog.type == 'add'"
          @close="closeDialog"
          from="1"
          @handler="handler"
      />
    </v-dialog>

    <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'edit'"
        max-width="500px"
        scrollable
    >
      <EditTeacherLesson
          v-if="dialog.open && dialog.type == 'edit'"
          :teacherLesson="dialog.teacherLesson"
          @close="closeDialog"
          from="1"
          @handler="handler"
      />
    </v-dialog>

    <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'delete'"
        max-width="500px"
        scrollable
    >
      <DeleteTeacherLesson
          v-if="dialog.open && dialog.type == 'delete'"
          :teacherLesson="dialog.teacherLesson"
          @close="closeDialog"
          @handler="handler"
          :teacherLessons="teacherLessons"
      />
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import AddTeacherLesson from "../../components/TeacherLessonsModals/AddTeacherLesson.vue";
import EditTeacherLesson from "../../components/TeacherLessonsModals/EditTeacherLesson.vue";
import DeleteTeacherLesson from "../../components/TeacherLessonsModals/DeleteTeacherLesson.vue";
import axios from "axios";

export default {
  components: {
    GenericFilter,
    AddTeacherLesson,
    EditTeacherLesson,
    DeleteTeacherLesson,
  },

  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      query: false,
      filtersSet: false,
      teacherLessons: [],
      headers: [
        {
          text: "Όνομα",
          value: "person.firstName",
          sortable: false,
        },

        {
          text: "Επώνυμο",
          value: "person.lastName",
          sortable: false,
        },

        {
          text: "Τμήμα",
          value: "eduClass.description",
          sortable: false,
        },

        {
          text: "Μάθημα",
          value: "lesson.description",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        teacherLessons: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.progTrainPeriodId != null) await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      progTrainPeriodId: (state) => state.generic_filter.semesterId,
      courseId: (state) => state.generic_filter.courseId,
      facultyId: (state) => state.generic_filter.facultyId,
      schoolId: (state) => state.generic_filter.schoolId,
      academicYearId: (state) => state.generic_filter.academicYearId,
      classId: (state) => state.generic_filter.classId,
      dates: (state) => state.generic_filter.dates,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentStudent"]),

    onStudentView(student) {
      this.setCurrentStudent(student);
      this.$router.push(
          `/absences/${student.personId}/view_student/atomika_stoixeia`
      );
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        let res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/teacher/lessons/list`,
            {
              params: {
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                    this.options.itemsPerPage == -1
                        ? null
                        : this.options.itemsPerPage,
                masterSchoolId: this.facultyId,
                // schoolId: this.schoolId,
                yearId: this.academicYearId,
                progTrainPrdId: this.progTrainPeriodId,
                lessonId: this.courseId,
                classId: this.classId,
              },
            }
        );
        this.teacherLessons = res.data;
        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.teacherLessons = [];
        this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(teacherLesson, type) {
      this.dialog = {
        teacherLesson,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        teacherLesson: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page = 0;
        this.changePage(false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>