<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-select
                  dense
                  outlined
                  v-model="masterSchoolId"
                  type="text"
                  hide-details="auto"
                  :error-messages="masterSchoolIdErrors"
                  :items="masterSchools.content"
                  item-text="description"
                  item-value="id"
                  v-bind="attrs"
                  v-on="on"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}
                  </template>
                </v-select>
              </template>
              <span> {{ appId == 1 ? "Ακαδημία" : "Δομή Μετεκπαίδευσης" }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="description"
                  hide-details="auto"
                  type="text"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:label> Περιγραφή </template>
                </v-text-field>
              </template>
              <span>Περιγραφή</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="lastNum"
                  hide-details="auto"
                  :error-messages="lastNumErrors"
                  type="text"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Τελευταίος αριθμός
                  </template>
                </v-text-field>
              </template>
              <span>Τελευταίος αριθμός</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <div class="d-flex justify-center mt-2">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
export default {
  name: "addSchoolGenCd",
  props: ["schoolTypeId"],
  async created() {
    await this.fetchMasterSchool();
  },
  data() {
    return {
      masterSchools: [],
      masterSchoolId: null,
      lastNum: null,
      description: null,
      loader: false,
    };
  },
  validations: {
    masterSchoolId: {
      required,
    },
    lastNum: {
      required,
    },
  },
  computed: {
    ...mapState({
      appId: (state) => state.auth.appId,
    }),
    masterSchoolIdErrors() {
      const errors = [];
      if (!this.$v.masterSchoolId.$dirty) return errors;
      if (!this.$v.masterSchoolId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    lastNumErrors() {
      const errors = [];
      if (!this.$v.lastNum.$dirty) return errors;
      if (!this.$v.lastNum.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },
  methods: {
    async fetchMasterSchool() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/master/school/list`,
          {
            params: {
              page: 0,
              kind: this.schoolTypeId,
            },
          }
        );
        this.masterSchools = res.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async onSubmit() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          description: this.description,
          masterSchoolId: this.masterSchoolId,
          lastNum: this.lastNum,
        };

        await axios.post(`${process.env.VUE_APP_BASE_URL}/school/cd`, content);
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Η δημιουργία ολοκληρώθηκε με επιτυχία",
          color: "success",
        });
        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.$router.dispatch("errorHandler", e);
        this.loader = false;
      }
    },
  },
};
</script>