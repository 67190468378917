<template>
  <v-card
    color="transparent"
    class="component-wrapper d-flex align-center justify-center"
  >
    <v-card
      v-if="!pageLoader"
      max-width="1500px"
      width="100%"
      height="100%"
      class="pa-4"
    >
      <v-breadcrumbs :items="breadCrumbItems" divider=">"> </v-breadcrumbs>

      <v-tabs show-arrows >
        <v-tab v-for="(tab, i) in tabs" :key="i" :to="tab.path" exact >{{
          tab.name
        }}</v-tab>
      </v-tabs>

      <router-view></router-view>
    </v-card>
    <v-progress-circular
      v-else
      indeterminate
      color="primary"
      class="ml-auto mr-auto mt-auto mb-auto"
    ></v-progress-circular>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  async created() {
    try {
      if (this.$route.path.split("/")[1] == "studentCard") {
        await this.setCurrentUser(this.personId);
        if (this.appId == 1) {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/students/${this.currentUser.id}/view_student/atomika_stoixeia`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/students/${this.currentUser.id}/view_student/stoixeia_foithshs`,
            },
            {
              name: `Εκπαιδευτικα ταξιδια`,
              path: `/students/${this.currentUser.id}/view_student/ekpaideutika_taksidia`,
            },
            {
              name: `Πτυχιακη εργασια`,
              path: `/students/${this.currentUser.id}/view_student/ptyxiakh_ergasia`,
            },
            {
              name: `Μετεγγραφες`,
              path: `/students/${this.currentUser.id}/view_student/meteggrafes`,
            },
            {
              name: `Αναστολες φοιτησης`,
              path: `/students/${this.currentUser.id}/view_student/anastoles_foithshs`,
            },
            {
              name: `Διαγραφες`,
              path: `/students/${this.currentUser.id}/view_student/diagrafes`,
            },
            {
              name: `Αιτησεις`,
              path: `/students/${this.currentUser.id}/view_student/applications`,
            },

            {
              name: `Βραβεια`,
              path: `/students/${this.currentUser.id}/view_student/awards`,
            },
            {
              name: `Πτυχια`,
              path: `/students/${this.currentUser.id}/view_student/certifications`,
            }
          );
        } else {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/students/${this.currentUser.id}/view_student/atomika_stoixeia`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/students/${this.currentUser.id}/view_student/stoixeia_foithshs`,
            },
            {
              name: `Διαγραφες`,
              path: `/students/${this.currentUser.id}/view_student/diagrafes`,
            },
            {
              name: `Αιτησεις`,
              path: `/students/${this.currentUser.id}/view_student/applications`,
            },
            {
              name: `Πτυχια`,
              path: `/students/${this.currentUser.id}/view_student/certifications`,
            }
          );
        }
      } else {
        await this.setCurrentUser(this.$route.params.id);
      }
      this.pageLoader = false;

      if (this.$route.path.split("/")[1] == "users") {
        this.breadCrumbItems.push(
          {
            text: `${this.currentUser.firstName} ${this.currentUser.lastName} (${this.currentUser.studentCode})`,
            disabled: false,
            link: true,
            exact: true,
            to: "/users",
          },
          {
            text: `Προβολή χρήστη`,
            disabled: true,
          }
        );
        if (this.appId == 1) {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/users/${this.currentUser.id}/view_student/atomika_stoixeia`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/users/${this.currentUser.id}/view_student/stoixeia_foithshs`,
            },
            {
              name: `Εκπαιδευτικα ταξιδια`,
              path: `/users/${this.currentUser.id}/view_student/ekpaideutika_taksidia`,
            },
            {
              name: `Πτυχιακη εργασια`,
              path: `/users/${this.currentUser.id}/view_student/ptyxiakh_ergasia`,
            },

            {
              name: `Μετεγγραφες`,
              path: `/users/${this.currentUser.id}/view_student/meteggrafes`,
            },
            {
              name: `Αναστολες φοιτησης`,
              path: `/users/${this.currentUser.id}/view_student/anastoles_foithshs`,
            },
            {
              name: `Διαγραφες`,
              path: `/users/${this.currentUser.id}/view_student/diagrafes`,
            },
            {
              name: `Αιτήσεις`,
              path: `/users/${this.currentUser.id}/view_student/applications`,
            },
            {
              name: `Βραβεια`,
              path: `/users/${this.currentUser.id}/view_student/awards`,
            },
            {
              name: `Πτυχια`,
              path: `/students/${this.currentUser.id}/view_student/certifications`,
            }
          );
        } else {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/users/${this.currentUser.id}/view_student/atomika_stoixeia`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/users/${this.currentUser.id}/view_student/stoixeia_foithshs`,
            },
            {
              name: `Διαγραφες`,
              path: `/users/${this.currentUser.id}/view_student/diagrafes`,
            },
            {
              name: `Αιτήσεις`,
              path: `/users/${this.currentUser.id}/view_student/applications`,
            },
            {
              name: `Πτυχια`,
              path: `/students/${this.currentUser.id}/view_student/certifications`,
            }
          );
        }
      } else if (this.$route.path.split("/")[1] == "students") {
        this.breadCrumbItems.push(
          {
            text: `${this.currentUser.firstName} ${this.currentUser.lastName} (${this.currentUser.studentCode})`,
            disabled: false,
            link: true,
            exact: true,
            to: "/students",
          },
          {
            text: `Προβολή σπουδαστή`,
            disabled: true,
          }
        );
        if (this.appId == 1) {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/students/${this.currentUser.id}/view_student/atomika_stoixeia`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/students/${this.currentUser.id}/view_student/stoixeia_foithshs`,
            },
            {
              name: `Εκπαιδευτικα ταξιδια`,
              path: `/students/${this.currentUser.id}/view_student/ekpaideutika_taksidia`,
            },
            {
              name: `Πτυχιακη εργασια`,
              path: `/students/${this.currentUser.id}/view_student/ptyxiakh_ergasia`,
            },

            {
              name: `Μετεγγραφες`,
              path: `/students/${this.currentUser.id}/view_student/meteggrafes`,
            },
            {
              name: `Αποποίηση Βαθμολογίας`,
              path: `/students/${this.currentUser.id}/view_student/refusal_details`,
            },
            {
              name: `Αναστολες φοιτησης`,
              path: `/students/${this.currentUser.id}/view_student/anastoles_foithshs`,
            },
            {
              name: `Διαγραφες`,
              path: `/students/${this.currentUser.id}/view_student/diagrafes`,
            },
            {
              name: `Αιτησεις`,
              path: `/students/${this.currentUser.id}/view_student/applications`,
            },

            {
              name: `Βραβεια`,
              path: `/students/${this.currentUser.id}/view_student/awards`,
            },
            {
              name: `Πτυχια`,
              path: `/students/${this.currentUser.id}/view_student/certifications`,
            }
          );
        } else {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/students/${this.currentUser.id}/view_student/atomika_stoixeia`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/students/${this.currentUser.id}/view_student/stoixeia_foithshs`,
            },
            {
              name: `Διαγραφες`,
              path: `/students/${this.currentUser.id}/view_student/diagrafes`,
            },
            {
              name: `Αιτησεις`,
              path: `/students/${this.currentUser.id}/view_student/applications`,
            },

            {
              name: `Πτυχια`,
              path: `/students/${this.currentUser.id}/view_student/certifications`,
            }
          );
        }
      } else if (this.$route.path.split("/")[1] == "absences") {
        this.breadCrumbItems.push(
          {
            text: `${this.currentUser.firstName} ${this.currentUser.lastName} (${this.currentUser.studentCode})`,
            disabled: false,
            link: true,
            exact: true,
            to: `/absences_approve/${this.kindId}`,
          },
          {
            text: `Προβολή σπουδαστή`,
            disabled: true,
          }
        );
        if (this.appId == 1) {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/absences/${this.currentUser.id}/view_student/atomika_stoixeia_absence`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/absences/${this.currentUser.id}/view_student/stoixeia_foithshs_absence`,
            },
            {
              name: `Εκπαιδευτικα ταξιδια`,
              path: `/absences/${this.currentUser.id}/view_student/ekpaideutika_taksidia_absence`,
            },
            {
              name: `Πτυχιακη εργασια`,
              path: `/absences/${this.currentUser.id}/view_student/ptyxiakh_ergasia_absence`,
            },

            {
              name: `Μετεγγραφες`,
              path: `/absences/${this.currentUser.id}/view_student/meteggrafes_absence`,
            },
            {
              name: `Αναστολες φοιτησης`,
              path: `/absences/${this.currentUser.id}/view_student/anastoles_foithshs_absence`,
            },
            {
              name: `Διαγραφες`,
              path: `/absences/${this.currentUser.id}/view_student/diagrafes_absence`,
            },
            {
              name: `Αιτησεις`,
              path: `/absences/${this.currentUser.id}/view_student/applications`,
            },
            {
              name: `Βραβεια`,
              path: `/absences/${this.currentUser.id}/view_student/awards`,
            }
          );
        } else {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/absences/${this.currentUser.id}/view_student/atomika_stoixeia_absence`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/absences/${this.currentUser.id}/view_student/stoixeia_foithshs_absence`,
            },
            {
              name: `Διαγραφες`,
              path: `/absences/${this.currentUser.id}/view_student/diagrafes_absence`,
            },
            {
              name: `Αιτησεις`,
              path: `/absences/${this.currentUser.id}/view_student/applications`,
            }
          );
        }
      } else if (this.$route.path.split("/")[1] == "operators") {
        this.breadCrumbItems.push(
          {
            text: `${this.currentUser.firstName} ${this.currentUser.lastName} (${this.currentUser.studentCode})`,
            disabled: false,
            link: true,
            exact: true,
            to: "/operators",
          },
          {
            text: `Προβολή χειριστή`,
            disabled: true,
          }
        );
        if (this.appId == 1) {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/operators/${this.currentUser.id}/view_student/atomika_stoixeia`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/operators/${this.currentUser.id}/view_student/stoixeia_foithshs`,
            },
            {
              name: `Εκπαιδευτικα ταξιδια`,
              path: `/operators/${this.currentUser.id}/view_student/ekpaideutika_taksidia`,
            },
            {
              name: `Πτυχιακη εργασια`,
              path: `/operators/${this.currentUser.id}/view_student/ptyxiakh_ergasia`,
            },

            {
              name: `Μετεγγραφες`,
              path: `/operators/${this.currentUser.id}/view_student/meteggrafes`,
            },
            {
              name: `Αναστολες φοιτησης`,
              path: `/operators/${this.currentUser.id}/view_student/anastoles_foithshs`,
            },
            {
              name: `Διαγραφες`,
              path: `/operators/${this.currentUser.id}/view_student/diagrafes`,
            },
            {
              name: `Αιτησεις`,
              path: `/operators/${this.currentUser.id}/view_student/applications`,
            },

            {
              name: `Βραβεια`,
              path: `/operators/${this.currentUser.id}/view_student/awards`,
            }
          );
        } else {
          this.tabs.push(
            {
              name: `Ατομικα στοιχεια`,
              path: `/operators/${this.currentUser.id}/view_student/atomika_stoixeia`,
            },
            {
              name: `Στοιχεια φοιτησης`,
              path: `/operators/${this.currentUser.id}/view_student/stoixeia_foithshs`,
            },

            {
              name: `Διαγραφες`,
              path: `/operators/${this.currentUser.id}/view_student/diagrafes`,
            },
            {
              name: `Αιτησεις`,
              path: `/operators/${this.currentUser.id}/view_student/applications`,
            }
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
  data() {
    return {
      pageLoader: true,
      breadCrumbItems: [],
      tabs: [],
    };
  },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
      personId: (state) => state.auth.userInfo.person.id,
      kindId: (state) => state.auth.kindId,
      appId: (state) => state.auth.appId,
    }),
  },

  methods: {
    ...mapActions(["setCurrentUser"]),
  },
};
</script>

<style scoped lang="scss">
a.v-tab--active.v-tab{
  background:var(--v-primary-base);
}
</style>