<template>
  <div class="component-wrapper d-flex align-center justify-center"></div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "SSO",
  created() {
    this.token = this.$route.query.token;
    this.fetchData();
  },
  data() {
    return {
      token: null,
      data: null,
    };
  },
  computed: {
    ...mapState({
      appId: (state) => state.auth.appId,
      selectedRole: (state) => state.auth.selectedRole,
      userInfo: (state) => state.auth,
    }),
  },
  methods: {
    async fetchData() {
      try {
        localStorage.setItem("token", this.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;

        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/after/taxis/login`,
          {
            params: {
              page: 0,
            },
          }
        );
        this.data = res.data;
        this.login();
      } catch (e) {}
    },

    async login() {
      let taxis = { taxis: this.data.taxisUserDetails, token: this.token };
      this.$store
        .dispatch("loginWithTaxis", { login: this.data, taxis })
        .then(() => {
          console.log(this.userInfo);
          let page = this.userInfo.userInfo.roles.filter((m) => {
            if (m.id == this.selectedRole) {
              return m;
            }
          });
          this.$router.push(page[0].defaultPage);
        });
    },
  },
};
</script>

<style>
</style>
