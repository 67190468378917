import { render, staticRenderFns } from "./AddLibrary.vue?vue&type=template&id=32b72132&scoped=true&"
import script from "./AddLibrary.vue?vue&type=script&lang=js&"
export * from "./AddLibrary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b72132",
  null
  
)

export default component.exports