<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Προβολή ειδοποίησης
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-title>{{ notification.title }} </v-card-title>
    <v-card-subtitle>
      {{ new Date(notification.creationDate).toLocaleDateString("el") }}
    </v-card-subtitle>

    <v-card-text class="d-flex flex-column align-start">
      <span>{{ notification.content }}</span>
      <v-btn
        plain
        color="primary"
        class="pa-0"
        @click="onDownload(notification.attachedFile.id)"
        v-if="notification.attachedFile"
        >{{ notification.attachedFile.name }}
        <v-icon color="success">mdi-download</v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  async created() {
    try {
      await this.readNotification(this.notification.id);
      await this.getUnreadNotifications({
        page: 1,
        limit: 10,
      });
      await this.getNotifications({
        page: 1,
        limit: 10,
      });
    } catch (e) {
      console.log(e);
    }
  },
  props: ["notification"],

  methods: {
    ...mapActions([
      "readNotification",
      "getUnreadNotifications",
      "getNotifications",
      "downloadFile",
    ]),

    async onDownload(id) {
      try {
        await this.downloadFile(id);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>