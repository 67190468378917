<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
      v-if="!dialogRole.open"
    >
      <GenericFilter
        :level="1"
        :search="false"
        @onSearchAction="onSearch"
        :requiredFields="{
          masterSchool: true,
          school: false,
          year: false,
          progTrainPeriod: false,
          class: false,
          lesson: false,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="operators.content"
            :server-items-length="operators.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <!-- <template v-slot:top>
              <v-row>
                <v-col>
                  <v-select
                    clearable
                    dense
                    outlined
                    label="Σχολή"
                    v-model="selectedMasterSchoolId"
                    :items="listFaculty"
                    item-text="description"
                    item-value="id"
                    @change="onChangeFilter"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    clearable
                    dense
                    outlined
                    label="Ενεργός"
                    v-model="isActive"
                    :items="listApops"
                    item-text="description"
                    item-value="id"
                    @change="onChangeFilter"
                  ></v-select>
                </v-col>
              </v-row>
            </template> -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onEdit(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>
              <v-tooltip left v-if="selectedSystemRole != 'ADMIN_ROLE'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onStudentView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Προβολή</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="onUserRoleView(item)">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-account-key-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ρόλοι</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
          <div class="custom-card-buttons">
            <v-btn
              depressed
              color="primary "
              class="mr-2"
              @click="$router.push('/addUser')"
              v-if="selectedSystemRole == 'ADMIN_ROLE'"
              :disabled="!filtersSet"
            >
              Δημιουργία
              <v-icon dense right>mdi-plus</v-icon>
            </v-btn>
          </div>
      </v-card>
    </v-card>
    <UserRoles
      v-if="dialogRole.open"
      :user="dialogRole.user"
      path="/operators"
      @close="closeDialogRole"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import UserRoles from "../Users/UserRoles.vue";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";

export default {
  components: {
    UserRoles,
    GenericFilter,
  },
  async created() {
    // try {
    //   await this.handler();
    // } catch (e) {
    //   console.log(e);
    // }
    this.handler();
  },

  data() {
    return {
      search: "",
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filtersSet: false,
      headers: [
        {
          text: "Επώνυμο",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "firstName",
          sortable: false,
        },

        {
          text: "Όνομα πατρός",
          value: "fatherName",
          sortable: false,
        },
        {
          text: "Όνομα Μητρός",
          value: "motherName",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: {
        teacher: null,
        open: false,
        type: null,
      },
      selectedMasterSchoolId: null,
      isActive: 8,
      operators: [],
      dialogRole: {
        open: false,
        user: null,
      },
    };
  },
  watch: {
    options: {
      async handler() {
        try {
          if (this.genericFilter.facultyId != null) {
            this.tableLoader = true;
            // this.setFilters(this.options);
            console.log(this.options);
            this.filtersSet = true;
            const res = await axios.get(
              `${process.env.VUE_APP_BASE_URL}/operators/list`,
              {
                params: {
                  page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                  limit:
                    this.options.itemsPerPage == -1
                      ? this.operators.elements
                      : this.options.itemsPerPage,
                  masterSchoolId: this.genericFilter.facultyId,
                },
              }
            );
            this.operators = res.data;
            this.tableLoader = false;
          }
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
      // async getData() {
      //   await this.handler();
      // },
    },
    deep: true,
  },
  computed: {
    ...mapState({
      listFaculty: (state) => state.faculties.faculties.content,
      listApops: (state) => state.schools.apops,
      students: (state) => state.students.students,
      pageChanged: (state) => state.base.pageChanged,
      selectedSystemRole: (state) => state.auth.selectedSystemRole,
      genericFilter: (state) => state.generic_filter,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage"]),
    ...mapActions([
      "getStudents",
      "getFaculties",
      "getApops",
      "setCurrentUser",
    ]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        this.handler();
      }
    },
    async onStudentView(operator) {
      this.$router.push(
        `/operators/${operator.id}/view_student/atomika_stoixeia`
      );
    },
    onEdit(user) {
      // this.setCurrentUser(user.id);
      this.$router.push(`/operators/${user.id}/editOperators`);
    },
    onChangeFilter() {
      this.handler();
    },
    async handler() {
      try {
        if (this.genericFilter.facultyId != null) {
          this.tableLoader = true;
          this.setFilters(this.options);
          this.filtersSet = true;
          const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/operators/list`,
            {
              params: {
                page: 0,
                limit:
                  this.options.itemsPerPage == -1
                    ? this.operators.elements
                    : this.options.itemsPerPage,
                masterSchoolId: this.genericFilter.facultyId,
              },
            }
          );
          this.operators = res.data;
          this.tableLoader = false;
        }
      } catch (e) {
        console.log(e);
        this.tableLoader = false;
      }
    },
    closeDialogRole() {
      this.dialogRole = {
        user: null,
        open: false,
      };
    },
    onUserRoleView(user) {
      this.dialogRole = {
        user: user,
        open: true,
      };
      // this.$store.commit("setCurrentUser", user);
      // this.$router.push(`/users/${user.id}/roles`);
    },
  },
};
</script>

<style scoped lang="scss">
</style>