<template>
  <v-card class="mb-2 pa-2">
    <v-card-text class="d-flex flex-column">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-select
              dense
              outlined
              clearable
              label="Σχολες"
              v-model="selectedFaculty"
              :items="listFaculty"
              hide-details="auto"
              item-text="description"
              item-value="cd"
              @change="onSetFaculty"
              :error-messages="selectedFacultyErrors"
              v-if="this.level >= 1"
          >
            <template v-slot:label v-if="requiredFields.masterSchool">
              <span class="error--text">*</span
              >{{ appId == 1 ? "Ακαδημία" : "Δομη Μετεκπαίδευσης" }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
              dense
              outlined
              clearable
              label="Σχολή"
              v-model="selectedSchool"
              :items="listSchool"
              item-text="description"
              item-value="cd"
              hide-details="auto"
              no-data-text="Δεδομένα μη διαθέσιμα"
              @change="onSetSchool"
              :error-messages="selectedSchoolErrors"
              v-if="this.level >= 2"
          >
            <template v-slot:label v-if="requiredFields.school">
              <span class="error--text">*</span>
              {{ appId == 1 ? "Σχολή" : getFieldName() }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
              dense
              clearable
              outlined
              label="Εκπαιδευτικό έτος"
              v-model="selectedExamYearId"
              :items="listExamYears"
              item-text="description"
              item-value="cd"
              hide-details="auto"
              no-data-text="Δεδομένα μη διαθέσιμα"
              @change="onSetAcademicYear"
              :error-messages="selectedExamYearIdErrors"
              v-if="this.level >= 3"
          >
            <template v-slot:label v-if="requiredFields.examYear">
              <span class="error--text">*</span
              >{{ appId == 1 ? "Ακαδημαϊκό έτος" : "Εκπαιδευτικό έτος" }}
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
              dense
              outlined
              clearable
              label="Εξεταστική περίοδος"
              v-model="selectedProgExamPeriodId"
              :items="listProgExamPeriods"
              hide-details="auto"
              item-text="description"
              no-data-text="Δεδομένα μη διαθέσιμα"
              item-value="cd"
              @change="onSetSemester"
              :error-messages="selectedProgExamPeriodIdErrors"
              v-if="this.level >= 4"
          >
            <template v-slot:label v-if="requiredFields.progExamPeriod">
              <span class="error--text">*</span>Εξεταστική περίοδος
            </template>
          </v-select>
        </v-col>

        <v-col
            cols="12"
            sm="6"
            v-if="this.$route.path.split('/')[1] == `create_exam_classes`"
        >
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="examDateFormatted"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  @blur="date = parseDate(examDateFormatted, 'selectedExamDate')"
                  clearable
                  :error-messages="selectedExamDateErrors"
              >
                <template v-slot:prepend-inner>
                  <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                </template>
                <template v-slot:label>
                  <span class="error--text">*</span>
                  Ημερομηνία
                </template>
              </v-text-field>
            </template>
            <v-date-picker
                locale="el"
                hide-details="auto"
                v-model="selectedExamDate"
                scrollable
                @input="menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col
            cols="12"
            sm="6"
            md="3"
            v-if="
            $route.path != `/class_exams/${kindId}` &&
            $route.path != `/create_exam_classes/${kindId}`
          "
        >
          <v-select
              dense
              outlined
              clearable
              label="Τμήμα"
              v-model="selectedExamClassId"
              :items="listExamClasses"
              item-text="description"
              hide-details="auto"
              no-data-text="Δεδομένα μη διαθέσιμα"
              item-value="cd"
              :error-messages="selectedExamClassIdErrors"
              v-if="this.level >= 5"
          >
            <template v-slot:label v-if="requiredFields.examClass">
              <span class="error--text">*</span>Τμήμα
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-select
              dense
              v-if="showLesson && this.level >= 6"
              outlined
              clearable
              label="Μάθημα"
              hide-details="auto"
              v-model="selectedExamLessonId"
              :items="listExamLessons"
              item-text="description"
              no-data-text="Δεδομένα μη διαθέσιμα"
              item-value="cd"
              :error-messages="selectedExamLessonIdErrors"
          >
            <template v-slot:label v-if="requiredFields.examLesson">
              <span class="error--text">*</span>Μάθημα
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-btn class="ml-auto" depressed color="primary" @click="this.onSearch">
        Αναζήτηση
      </v-btn>
    </v-card-text>
  </v-card>
</template>


<script>
import {mapState, mapMutations} from "vuex";
import axios from "axios";
import {required, requiredIf} from "vuelidate/lib/validators";
import Helpers, {isEmpty} from "../../helpers/helpers";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
      /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: {
    level: Number,
    onSearchAction: Function,
    listActive: Number,
    requiredFields: Object,
  }, //level: level of lists,onSearchAction: function on click search,listActive: prop to know the end point group id (only active or all data)
  async created() {
    console.log(this.$route.path.split("/"));
    await this.loadFaculties();
    if (!isEmpty(this.selectedSchool)) {
      let result = await this.fetchDataDynamic(2022, this.selectedSchool);
      this.getListExamYears(result.data);
      await this.onSetAcademicYear(this.selectedExamYearId);
    }
  },
  data() {
    return {
      menu: null,
      dates: [],
      examDateFormatted: null,
      studentPart: "1",
    };
  },

  validations: {
    selectedFaculty: {
      required: requiredIf(function () {
        return this.requiredFields?.masterSchool;
      }),
    },

    selectedSchool: {
      required: requiredIf(function () {
        return this.requiredFields?.school;
      }),
    },

    selectedExamYearId: {
      required: requiredIf(function () {
        return this.requiredFields?.examYear;
      }),
    },

    selectedProgExamPeriodId: {
      required: requiredIf(function () {
        return this.requiredFields?.progExamPeriod;
      }),
    },

    selectedExamClassId: {
      required: requiredIf(function () {
        return this.requiredFields?.examClass;
      }),
    },

    selectedExamLessonId: {
      required: requiredIf(function () {
        console.log(this.$route.path == `/create_exam_classes/${this.kindId}`);
        if (this.$route.path == `/create_exam_classes/${this.kindId}`) {
          if (this.studentPart != "1") {
            return this.requiredFields?.examLesson;
          } else {
            return false;
          }
        } else {
          return this.requiredFields?.examLesson;
        }
      }),
    },
    selectedExamDate: {
      required: requiredIf(function () {
        return this.requiredFields?.examDate;
      }),
    },
  },

  watch: {
    selectedExamDate(val) {
      this.examDateFormatted = this.formatDate(this.selectedExamDate);
    },
  },

  computed: {
    ...mapState({
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,

      listFaculty: (state) => state.generic_filter.listFaculty,
      listSchool: (state) => state.generic_filter.listSchool,
      listExamYears: (state) => state.generic_filter.listExamYears,
      listProgExamPeriods: (state) => state.generic_filter.listProgExamPeriods,
      listExamClasses: (state) => state.generic_filter.listExamClasses,
      listExamLessons: (state) => state.generic_filter.listExamLessons,
      staticList: (state) => state.auth.userInfo.staticView,
      kindId: (state) => state.auth.kindId,
    }),

    active: {
      get() {
        return this.$store.state.generic_filter.active;
      },
      set(value) {
        this.setActive(value);
      },
    },

    selectedFaculty: {
      get() {
        return this.$store.state.generic_filter.facultyId;
      },
      set(value) {
        this.setFaculty(value);
      },
    },
    showLesson() {
      if (this.$route.path == `/create_exam_classes/${this.kindId}`) {
        if (this.studentPart != "1") {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
    selectedSchool: {
      get() {
        return this.$store.state.generic_filter.schoolId;
      },
      set(value) {
        this.setSchool(value);
      },
    },

    selectedExamYearId: {
      get() {
        return this.$store.state.generic_filter.examYearId;
      },
      set(value) {
        this.setExamYearId(value);
      },
    },

    selectedProgExamPeriodId: {
      get() {
        return this.$store.state.generic_filter.progExamPeriodId;
      },
      set(value) {
        this.setProgExamPeriodId(value);
      },
    },

    selectedExamClassId: {
      get() {
        return this.$store.state.generic_filter.examClassId;
      },
      set(value) {
        this.setExamClassId(value);
      },
    },

    selectedExamLessonId: {
      get() {
        return this.$store.state.generic_filter.examLessonId;
      },
      set(value) {
        this.setExamLessonId(value);
      },
    },

    selectedExamDate: {
      get() {
        return this.$store.state.generic_filter.examDate;
      },
      set(value) {
        this.setExamDate(value);
      },
    },

    selectedFacultyErrors() {
      const errors = [];
      if (!this.$v.selectedFaculty.$dirty) return errors;
      if (!this.$v.selectedFaculty.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedSchoolErrors() {
      const errors = [];
      if (!this.$v.selectedSchool.$dirty) return errors;
      if (!this.$v.selectedSchool.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedExamYearIdErrors() {
      const errors = [];
      if (!this.$v.selectedExamYearId.$dirty) return errors;
      if (!this.$v.selectedExamYearId.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedProgExamPeriodIdErrors() {
      const errors = [];
      if (!this.$v.selectedProgExamPeriodId.$dirty) return errors;
      if (!this.$v.selectedProgExamPeriodId.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedExamClassIdErrors() {
      const errors = [];
      if (!this.$v.selectedExamClassId.$dirty) return errors;
      if (!this.$v.selectedExamClassId.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedExamLessonIdErrors() {
      const errors = [];
      if (!this.$v.selectedExamLessonId.$dirty) return errors;
      if (!this.$v.selectedExamLessonId.required)
        errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    selectedExamDateErrors() {
      const errors = [];
      if (!this.$v.selectedExamDate.$dirty) return errors;
      if (!this.$v.selectedExamDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapMutations([
      "getListFaculty",
      "getListSchool",
      "getListExamYears",
      "getListProgExamPeriods",
      "getListExamClasses",
      "getListExamLessons",
      "setFaculty",
      "setSchool",
      "setExamYearId",
      "setProgExamPeriodId",
      "setExamClassId",
      "setExamLessonId",
      "setExamDate",
      "setActive",
      "setProgExamDetails",
    ]),

    getFieldName() {
      return Helpers.getFieldName(1);
    },

    async loadFaculties() {
      let result = await this.fetchDataDynamic(
          this.listActive == 1 ? 2020 : 2000,
          null
      );
      this.getListFaculty(result.data);
      if (result.data.length == 1) {
        this.selectedFaculty = result.data[0].cd;
        this.onSetFaculty(result.data[0].cd);
      }
    },

    async onSetFaculty(value) {
      this.setSchool(null);
      this.setExamYearId(null);
      this.setProgExamPeriodId(null);
      this.setExamClassId(null);
      this.setExamLessonId(null);

      this.getListExamYears([]);
      this.getListProgExamPeriods([]);
      this.getListExamClasses([]);
      this.getListExamLessons([]);
      // call end point tou get schools 2001
      let result = await this.fetchDataDynamic(
          this.listActive == 1 ? 2021 : 2001,
          value
      );
      this.getListSchool(result.data);
      if (result.data.length == 1) {
        this.selectedSchool = result.data[0].cd;
        this.onSetSchool(result.data[0].cd);
      }
    },

    async onSetSchool(value) {
      this.setExamYearId(null);
      this.setProgExamPeriodId(null);
      this.setExamClassId(null);
      this.setExamLessonId(null);

      this.getListProgExamPeriods([]);
      this.getListExamClasses([]);
      this.getListExamLessons([]);

      let result = await this.fetchDataDynamic(2022, value);
      this.getListExamYears(result.data);
      if (result.data.length == 1) {
        this.selectedExamYearId = result.data[0].cd;
        await this.onSetAcademicYear(result.data[0].cd);
      }
    },

    async onSetAcademicYear(value) {
      this.setProgExamPeriodId(null);
      this.setExamClassId(null);
      this.setExamLessonId(null);

      this.getListExamClasses([]);
      this.getListExamLessons([]);

      let result = await this.fetchDataDynamic(2025, value);
      if (result.data.length == 1) {
        this.selectedProgExamPeriodId = result.data[0].cd;
        await this.onSetSemester(result.data[0].cd);
      }
      this.getListProgExamPeriods(result.data);
    },

    async onSetSemester(value) {
      this.setExamClassId(null);
      this.setExamLessonId(null);

      // fetch class
      let result = await this.fetchDataDynamic(2012, value);
      this.getListExamClasses(result.data);

      if (result.data.length == 1) {
        this.selectedExamClassId = result.data[0].cd;
      }

      // fetch Courses
      let lessons = await this.fetchDataDynamic(
          this.$route.path == `/create_exam_classes/${this.kindId}` ? 2109 : 2231,
          value
      );

      if (lessons.data.length == 1) {
        this.selectedExamLessonId = result.data[0].cd;
      }
      this.onChangeProgExam(value);
      this.getListExamLessons(lessons.data);
    },

    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
            body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
    async onSearch() {
      console.log(this.requiredFields?.examLesson && this.studentPart != "1");
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let payload = {
        facultyId: this.selectedFaculty,
        schoolId: this.selectedSchool,
        examYearId: this.selectedExamYearId,
        progExamPeriodId: this.selectedProgExamPeriodId,
        examClassId: this.selectedExamClassId,
        examLessonId: this.selectedExamLessonId,
        active: this.active,
        examDate: this.selectedExamDate,
      };
      await this.$store.dispatch("setExamFilters", payload);
      await this.$emit("onSearchAction");
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "selectedExamDate") {
          this.selectedExamDate = null;
          this.examDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async onChangeProgExam(value) {
      try {
        if (value != null) {
          const res = await axios.get(
              `${process.env.VUE_APP_BASE_URL}/prog/exam/${value}`
          );
          this.setProgExamDetails(res.data);
          this.studentPart = res.data.studentPart.cd;
        }
      } catch (e) {
        return [];
      }
    },
  },
};
</script>

<style>
</style>