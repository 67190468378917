<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Επεξεργασία πλοίου εταιρίας
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              hide-details="auto"
              v-model="name"
              type="text"
              :error-messages="nameErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Όνομα
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-select
              dense
              outlined
              v-model="category"
              type="text"
              :error-messages="categoryErrors"
              hide-details="auto"
              :items="getDataStaticList(155)"
              item-text="description"
              item-value="cd"
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Κατηγορία
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              dense
              outlined
              v-model="kox"
              hide-details="auto"
              type="text"
              :error-messages="koxErrors"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Κόροι ολικής χωρητικότητας
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="dateFromFormatted"
                  v-bind="attrs"
                  v-on="on"
                  @blur="dateFrom = parseDate(dateFromFormatted, 'dateFrom')"
                  :error-messages="dateFromErrors"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Ημερομηνία από
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="dateFrom"
                scrollable
                @input="menu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="dateToFormatted"
                  v-bind="attrs"
                  v-on="on"
                  label="Ημερομηνία έως"
                  @blur="dateTo = parseDate(dateToFormatted, 'dateTo')"
                >
                  <template v-slot:prepend-inner>
                    <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                locale="el"
                v-model="dateTo"
                scrollable
                @input="menu1 = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="4"> </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-textarea
              dense
              outlined
              v-model="memo"
              hide-details="auto"
              label="Σχόλια"
              type="text"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Αποθήκευση</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["shipCompanyVessel"],

  created() {
    this.category = this.shipCompanyVessel?.category.cd;
    this.dateFrom = this.shipCompanyVessel?.dateFrom?.split(" ")[0];
    this.dateTo = this.shipCompanyVessel?.dateTo?.split(" ")[0];
    this.kox = this.shipCompanyVessel?.kox;
    this.memo = this.shipCompanyVessel?.memo;
    this.name = this.shipCompanyVessel?.name;
  },

  data() {
    return {
      loader: false,

      category: null,
      dateFrom: null,
      dateFromFormatted: null,
      dateTo: null,
      dateToFormatted: null,
      kox: null,
      memo: null,
      name: null,
      menu: false,
      menu1: false,
    };
  },

  watch: {
    dateFrom(val) {
      this.dateFromFormatted = this.formatDate(this.dateFrom);
    },

    dateTo(val) {
      this.dateToFormatted = this.formatDate(this.dateTo);
    },
  },

  validations: {
    category: {
      required,
    },

    dateFrom: {
      required,
    },

    kox: {
      required,
    },

    name: {
      required,
    },
  },

  computed: {
    ...mapState({
      staticList: (state) => state.auth.userInfo.staticView,
    }),

    categoryErrors() {
      const errors = [];
      if (!this.$v.category.$dirty) return errors;
      if (!this.$v.category.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    dateFromErrors() {
      const errors = [];
      if (!this.$v.dateFrom.$dirty) return errors;
      if (!this.$v.dateFrom.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    koxErrors() {
      const errors = [];
      if (!this.$v.kox.$dirty) return errors;
      if (!this.$v.kox.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["editShipCompanyVessel"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        let content = {
          category: this.category,
          dateFrom: this.dateFrom,
          kox: this.kox,
          name: this.name,
          shipCompanyId: this.$route.params.ship_company_id,
        };

        if (this.memo != null) content.memo = this.memo;
        if (this.dateTo != null) content.dateTo = this.dateTo;

        await this.editShipCompanyVessel({
          shipCompanyVesselId: this.shipCompanyVessel.id,
          content,
        });
        this.loader = false;
        // await this.$emit("handler");

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "dateFrom") {
          this.dateFrom = null;
          this.dateFromFormatted = null;
        } else if (dateName == "dateTo") {
          this.dateTo = null;
          this.dateToFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>