import axios from 'axios'
export default {
    state: {
        progTrips: [],
        progTripDetails: [],
        currentProgTrip: null
    },

    mutations: {
        getProgTrips(state, progTrips) {
            state.progTrips = progTrips
        },

        getProgTripDetails(state, progTripDetails) {
            state.progTripDetails = progTripDetails
        },

        setCurrentProgTrip(state, currentProgTrip) {
            state.currentProgTrip = currentProgTrip
        }
    },

    actions: {
        async getProgTrips({ commit, dispatch }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/prog/trip/list`,
                    {
                        params: {
                            masterSchoolId: filters.masterSchoolId,
                            schoolId: filters.schoolId,
                            yearId: filters.yearId,
                            progTrainPeriodId: filters.progTrainPeriodId,
                            page: filters.page,
                            limit: filters.limit,
                            searchText: filters.searchText

                        }
                    })
                commit('getProgTrips', res.data)

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async addProgTrip({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/prog/trip`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία εκπαιδευτικού ταξιδιού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editProgTrip({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/prog/trip/${payload.progTripId}`, payload.content)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία εκπαιδευτικού ταξιδιού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteProgTrip({ commit, dispatch, rootState, state }, progTripId) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/prog/trip/${progTripId}`)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή εκπαιδευτικού ταξιδιού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }


            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },



        async getProgTripDetails({ commit, dispatch }, filters) {
            try {
                const res = await axios.get(`${process.env.VUE_APP_BASE_URL}/prog/trip/detail/list`,
                    {
                        params: {
                            progTripId: filters.progTripId,
                            page: filters.page,
                            limit: filters.limit,

                        }
                    })
                commit('getProgTripDetails', res.data)

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },


        async addProgTripDetail({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.post(`${process.env.VUE_APP_BASE_URL}/prog/trip/detail`, payload)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η δημιουργία λεπτομερειών εκπαιδευτικού ταξιδιού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async editProgTripDetail({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.put(`${process.env.VUE_APP_BASE_URL}/prog/trip/detail/${payload.progTripDetailId}`, payload.content)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η επεξεργασία λεπτομερειών εκπαιδευτικού ταξιδιού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })

            }
            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },

        async deleteProgTripDetail({ commit, dispatch, rootState, state }, payload) {
            try {
                await axios.delete(`${process.env.VUE_APP_BASE_URL}/prog/trip/detail/${payload.progTripDetailId}`)

                commit('toggleSnackbar', {
                    open: true,
                    text: 'Η διαγραφή λεπτομερειών εκπαιδευτικού ταξιδιού ολοκληρώθηκε με επιτυχία',
                    color: 'success'
                })
            }


            catch (e) {
                dispatch("errorHandler", e);

                throw e;
            }
        },
    }
}