<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία {{ appId == 1 ? "Ακαδημίας" : "Δομής Μετεκπαίδευσης" }}
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-text-field
          dense
          outlined
          v-model="facultyName"
          hide-details="auto"
          type="text"
          class="mb-4"
          :error-messages="facultyNameErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Όνομα
          </template>
        </v-text-field>

        <v-text-field
          dense
          outlined
          v-model="facultyId"
          hide-details="auto"
          type="text"
          class="mb-4"
          :error-messages="facultyIdErrors"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Κωδικός
          </template>
        </v-text-field>

        <v-select
          dense
          outlined
          v-model="facultyCategory"
          :items="schoolTypes"
          item-text="description"
          hide-details="auto"
          item-value="cd"
          class="mb-4"
          :error-messages="facultyCategoryErrors"
          no-data-text="Δεδομένα μη διαθέσιμα"
        >
          <template v-slot:label>
            <span class="error--text">*</span>
            Κατηγορία
          </template>
        </v-select>

        <v-textarea
          dense
          outlined
          v-model="memo"
          label="Σχόλια"
          hide-details="auto"
        ></v-textarea>

        <v-checkbox
          v-model="facultyStatus"
          label="Ενεργή"
          class="mb-4"
          hide-details="auto"
          :error-messages="facultyStatusErrors"
          true-value="1"
          false-value="0"
        >
        </v-checkbox>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  created() {
    this.getSchoolTypes();
  },
  data() {
    return {
      facultyName: null,
      facultyId: null,
      facultyCategory: null,
      facultyStatus: 0,
      menu: false,
      menu1: false,
      loader: false,
      memo: null,
      schoolTypes: [],
    };
  },

  validations: {
    facultyName: {
      required,
    },
    facultyId: {
      required,
    },
    facultyCategory: {
      required,
    },
    facultyStatus: {
      required,
    },
  },

  computed: {
    ...mapState({
      staticList: (state) => state.auth.userInfo.staticView,
      selectedRole: (state) => state.auth.selectedRole,
      appId: (state) => state.auth.appId,
    }),

    facultyNameErrors() {
      const errors = [];
      if (!this.$v.facultyName.$dirty) return errors;
      if (!this.$v.facultyName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    facultyIdErrors() {
      const errors = [];
      if (!this.$v.facultyId.$dirty) return errors;
      if (!this.$v.facultyId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    facultyCategoryErrors() {
      const errors = [];
      if (!this.$v.facultyCategory.$dirty) return errors;
      if (!this.$v.facultyCategory.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    facultyStatusErrors() {
      const errors = [];
      if (!this.$v.facultyStatus.$dirty) return errors;
      if (!this.$v.facultyStatus.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addFaculty"]),
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        let content = {
          cd: this.facultyId,
          description: this.facultyName,
          kind: parseInt(this.facultyCategory),
          flgActive: this.facultyStatus,
        };
        if (this.memo != null) content.memo = this.memo;
        await this.addFaculty(content);

        this.loader = false;
        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
    async getSchoolTypes() {
      let body = {
        type: 1,
        parentCd: null,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/104`,
          body
        );
        this.schoolTypes = res.data;
      } catch (e) {
        throw e;
      }
    },
    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>