<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card max-width="1400px" width="100%" height="100%" class="pa-4 d-flex flex-column" elevation="0" color="#EAF1F5">
      <GenericFilter :level="3" @onSearchAction="onSearch" :requiredFields="{
        masterSchool: true,
        school: true,
        year: true,
        progTrainPeriod: false,
        class: false,
        lesson: false,
      }" />

      <v-row>
        <v-col cols="8">
          <v-col v-if="successResMsg == true">
            <h3 class="primary center">Η σειρά έχει οριστικοποιηθεί</h3>
          </v-col>
          <v-row>
            <v-col cols="12" v-for="period in semesterList" :key="period.id">
              <v-card>
                <v-card-title style="color: black">{{
                    period.description
                }}</v-card-title>
                <v-card-text>
                  <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
                    'items-per-page-text': 'Σειρές ανά σελίδα',
                    'items-per-page-all-text': 'Όλες',
                    'items-per-page-options': [5, 10, 15, -1],
                  }" :headers="semesterTableHeaders" :items="semesters[`${period.id}`]" :items-per-page="10"
                    :loading="loading">
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn depressed icon @click="transferToDraft(item, period.id)">
                            <v-icon color="error" v-bind="attrs" v-on="on">
                              mdi-swap-horizontal
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Μεταφορά στο πρόχειρο</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" style="border-left: 1px solid #28357e">
          <v-card class="pa-2" elevation="0">
            <v-card-title class="justify-content">Πρόχειρο</v-card-title>
            <v-card-text class="pa-1">
              <v-select dense outlined clearable label="Επιλογή Κύκλου" v-model="selectedSemester"
                item-text="description" item-value="id" :items="semesterList" :loading="loading"
                no-data-text="Δεδομένα μη διαθέσιμα"></v-select>

              <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
                'items-per-page-text': 'Σειρές ανά σελίδα',
                'items-per-page-all-text': 'Όλες',
                'items-per-page-options': [5, 10, 15, -1],
              }" :headers="tableHeaders" :items="draftData" :items-per-page="10" :loading="loading"
                v-if="selectedSemester == null"></v-data-table>

              <v-data-table no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα" loading-text="Φόρτωση..." :footer-props="{
                'items-per-page-text': 'Σειρές ανά σελίδα',
                'items-per-page-all-text': 'Όλες',
                'items-per-page-options': [5, 10, 15, -1],
              }" v-else :headers="tableHeaders" :items="draftData" :items-per-page="10" :loading="loading" show-select
                :single-select="false" v-model="selectedStudent"></v-data-table>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-btn width="100%" color="primary" v-if="selectedSemester != null"
                    :disabled="selectedStudent.length == 0" @click="moveStudents">Μεταφορά</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn width="100%" color="error" @click="openFinalDialog">Οριστικοποιήση</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn width="100%" color="primary" @click="openResetDialog">
                    <v-icon>mdi-reload</v-icon>Επαναφορά
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-show="dialog.open"></v-dialog>
    <v-dialog v-model="dialog" v-if="dialog.open == true" width="500">
      <v-card v-if="dialog.type == 'final' && dialog.open == true">
        <v-card-title class="text-h5 primary lighten-2">
          Οριστικοποιήση
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <h3 class="mt-1">
                Είστε σίγουροι ότι θέλετε να οριστηκοποιήσετε τα δεδομένα σας;
              </h3>
            </v-col>
          </v-row>

          <v-row v-for="period in semesterList" :key="period.id"
            style="border-bottom: 1px solid black; border-top: 1px solid black">
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>
                <strong>{{ period.description }}:</strong>
              </span>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <span>{{ semesters[period.id].length }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialog"> Ακύρωση </v-btn>
          <v-btn color="primary" @click="finalSubmit" :loading="loader">
            Οριστικοποιήση
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="dialog.type == 'reset' && dialog.open == true">
        <v-card-title class="text-h5 primary lighten-2">
          Επαναφορά
        </v-card-title>
        <v-card-text>
          Είστε σίγουροι ότι θέλετε να επαναφέρετε τα δεδομένα σας;
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialog"> Ακύρωση </v-btn>
          <v-btn color="primary" @click="fetchReset" :loading="loader">
            Επαναφορά
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GenericFilter from "../../components/GenericFilter/GenericFilter.vue";
import axios from "axios";

export default {
  name: "booking",
  components: {
    GenericFilter,
  },
  data() {
    return {
      selectedSemester: null,
      successResMsg: false,
      semesterList: [],
      tableHeaders: [
        { text: "ΜΕΘ", value: "meth", sortable: false },
        { text: "Επώνυμο", value: "lastName", sortable: false },
        { text: "Ονομα", value: "firstName", sortable: false },
        { text: "Διαθέσιμοι Κύκλοι", value: "available", sortable: false },
      ],
      semesterTableHeaders: [
        { text: "Ενέργειες", value: "actions", sortable: false },
        { text: "ΜΕΘ", value: "meth", sortable: false },
        { text: "Επώνυμο", value: "lastName", sortable: false },
        { text: "Ονομα", value: "firstName", sortable: false },
        { text: "Διαθέσιμοι Κύκλοι", value: "available", sortable: false },
      ],
      selectedStudent: [],
      draftTable: [],
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      semesters: {},
      loading: false,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      schoolId: (state) => state.generic_filter.schoolId,
      series: (state) => state.generic_filter.series,
    }),
    draftData() {
      if (this.selectedSemester == null) {
        return this.draftTable;
      } else {
        let result = [];
        result = this.draftTable.filter((d) => {
          if (
            d.availablePrds.some(
              (el) => el.progTrainPrdId == this.selectedSemester
            )
          ) {
            return d;
          }
        });
        return result;
      }
    },
  },
  methods: {
    async onSearch() {
      //init or fetch exist data
      try {
        this.loading = true;
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/temp/kesen/booking/result/init`,
          {
            params: {
              schoolId: this.schoolId,
              seiraNr: this.series,
            },
          }
        );
        if (
          res.data.distinctTrainPeriodIds != undefined ||
          res.data.distinctTrainPeriodIds != ""
        ) {
          this.successResMsg = true;
          this.semesterList = res.data.distinctTrainPeriodIds;
          //initial draft table

          res.data.distinctTrainPeriodIds.map((p) => {
            this.semesters[p.id] = res.data.content.filter((c) => {
              if (c.progTrainPrdId == p.id) {
                var av = [];
                c.availablePrds.map((p) => {
                  let name = this.fetchName(p.progTrainPrdId);
                  av.push(name);
                });
                c.available = av.join();
                return c;
              }
            });
          });
        } else if (res.data.distinctTrainPeriodIds == undefined) {
          this.draftTable = res.data.content.filter((c) => {
            if (c.progTrainPrdId == 0) {
              if (c.availablePrds) {
                var av = [];
                c.availablePrds.map((p) => {
                  let name = this.fetchName(p.progTrainPrdId);
                  av.push(name);
                });
                c.available = av.join();
              }

              return c;
            }
          });
        }

        this.loading = false;
      } catch (e) {
        throw e;
      }
    },
    fetchName(id) {
      return this.semesterList.filter((s) => {
        if (id == s.id) {
          return s;
        }
      })[0].description;
    },
    async transferToDraft(item, prdId) {
      try {
        const res = await axios.put(
          `${process.env.VUE_APP_BASE_URL}/temp/kesen/booking/result/${item.id}`,
          null,
          {
            params: {
              progTrainPrdId: 0,
            },
          }
        );
        console.log(res);
        let newItem = { ...item, progTrainPrdId: 0 };
        this.draftTable.unshift(newItem);
        this.semesters[`${prdId}`] = this.semesters[`${prdId}`].filter((s) => {
          return item.id != s.id;
        });
      } catch (e) {
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Παρουσιάστηκε πρόβλημα, παρακαλώ προσπαθήστε αργότερα!",
          color: "error",
        });
      }
    },
    async moveStudents() {
      try {
        let body = this.selectedStudent.map((s) => {
          return {
            bookingResultId: s.id,
            progTrainPrdId: this.selectedSemester,
          };
        });

        const res = await axios.put(
          `${process.env.VUE_APP_BASE_URL}/temp/kesen/booking/result/mass`,
          body
        );
        console.log(res);

        this.selectedStudent.map((s) => {
          this.semesters[`${this.selectedSemester}`] = [
            ...this.semesters[`${this.selectedSemester}`],
            s,
          ];
          // this.semesters[`${this.selectedSemester}`].unshift(s);
        });

        this.draftTable = this.draftTable.filter((d) => {
          if (!this.selectedStudent.some((elem) => elem.id == d.id)) return d;
        });

        this.selectedStudent = [];
      } catch (e) {
        this.$store.dispatch("toggleSnackbar", {
          open: true,
          text: "Παρουσιάστηκε πρόβλημα, παρακαλώ προσπαθήστε αργότερα!",
          color: "error",
        });
      }
    },
    openFinalDialog() {
      this.dialog = {
        open: true,
        type: "final",
      };
    },
    openResetDialog() {
      this.dialog = {
        open: true,
        type: "reset",
      };
    },
    closeDialog() {
      this.dialog.open = false;
    },
    async fetchReset() {
      this.dialog.open = false;
      try {
        this.loader = true;
        await axios.get(
          `${process.env.VUE_APP_BASE_URL}/temp/kesen/booking/result/reset`,
          {
            params: {
              seiraNr: this.series,
              schoolId: this.schoolId,
            },
          }
        );
        this.loader = false;
        this.onSearch()
      } catch (e) {
        this.loader = false;
        this.$store.dispatch("errorHandler", e);
      }
    },
    async finalSubmit() {
      this.dialog.open = false;
      try {
        this.loader = true;
        await axios.get(
          `${process.env.VUE_APP_BASE_URL}/temp/kesen/booking/result/confirm`,
          {
            params: {
              seiraNr: this.series,
              schoolId: this.schoolId,
            },
          }
        );
        this.onSearch();
        this.loader = false;
      } catch (e) {
        this.$store.dispatch("errorHandler", e);
        this.loader = false;
      }
    },
    fetchSums(id) {
      console.log("id ", id);
      console.log(this.semesters);
      console.log("semesters.id ", this.semesters[`${id}`]);
      return this.semesters[`${id}`].length;
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  color: white;
}
</style>
