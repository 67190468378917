<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Δημιουργία παράμετρου εκτυπωτικού {{ this.report.description }}
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="key"
              type="text"
              class="mb-4"
              hide-details="true"
            >
              <template v-slot:label>
                Key
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="label"
              type="text"
              class="mb-4"
              hide-details="true"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Περιγραφή
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="position"
              type="number"
              class="mb-4"
              hide-details="true"
            >
              <template v-slot:label>
                <span class="error--text">*</span>
                Θέση
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="type"
              label="Τύπος πεδίου"
              dense
              item-text="description"
              item-value="id"
              hide-details="auto"
              :items="fieldTypes"
              @change="clearLovs"
              outlined
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="type == 6">
          <v-col>
            <v-select
              v-model="lovId"
              label="Lov"
              dense
              clearable
              item-text="description"
              item-value="cd"
              hide-details="auto"
              :items="lovCodes"
              outlined
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="type == 6">
          <v-col>
            <v-select
              v-model="parentRefId"
              label="Εξαρτόμενη ηδη καταχωρημενη παράμετρος"
              dense
              clearable
              item-text="description"
              item-value="cd"
              hide-details="auto"
              :items="listExtrFields"
              outlined
              no-data-text="Δεδομένα μη διαθέσιμα"
            >
            </v-select>
          </v-col>
        </v-row>
        <div class="d-flex justify-center btn-container">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  async created() {
    await this.fetchLovCodes();
    await this.extrFields();
  },
  props: ["report"],
  data() {
    return {
      loader: false,
      lovCodes: [],
      listExtrFields: [],
      key: null,
      label: null,
      parentRefId: null,
      position: null,
      lovId: null,
      reportId: null,
      type: "",
      fieldTypes: [
        {
          description: "Alpha",
          id: "1",
        },
        {
          description: "Number",
          id: "2",
        },
        {
          description: "Date",
          id: "3",
        },
        {
          description: "Checkbox",
          id: "4",
        },
        {
          description: "Lov",
          id: "6",
        },
      ],
    };
  },

  validations: {


    label: {
      required,
    },

    position: {
      required,
    },

    type: {
      required,
    },
  },
  computed: {
    ...mapState({
      appId: (state) => state.auth.appId,
      selectedRole: (state) => state.auth.selectedRole,
    }),
  },
  methods: {
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    reportNameErrors() {
      const errors = [];
      if (!this.$v.reportName.$dirty) return errors;
      if (!this.$v.reportName.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    reportPathErrors() {
      const errors = [];
      if (!this.$v.reportPath.$dirty) return errors;
      if (!this.$v.reportPath.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },

    tpyErrors() {
      const errors = [];
      if (!this.$v.tpy.$dirty) return errors;
      if (!this.$v.tpy.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    clearLovs() {
      console.log('clear lovs')
      if (this.type != 6) {
        console.log('!=6')
        this.lovId = null;
        this.parentRefId = null;
      }
    },
    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;

        await axios.post(`${process.env.VUE_APP_BASE_URL}/report/parameter`, {
          reportId: this.report.id,
          label: this.label,
          key: this.key,
          position: this.position,
          type: String(this.type),
          lovId: this.lovId,
          parentRefId: this.parentRefId,
        });

        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },
    async extrFields() {
      let result = await this.fetchDataDynamic(2230, this.report.id);
      this.listExtrFields = result.data;
    },
    async fetchLovCodes() {
      let result = await this.fetchDataDynamic(2223, null);
      this.lovCodes = result.data;
    },
    async fetchDataDynamic(groupId, parentId) {
      let body = {
        type: this.appId,
        parentCd: parentId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/${groupId}`,
          body
        );
        return res;
      } catch (e) {
        return [];
        // throw e;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>