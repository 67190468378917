<template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
        max-width="1400px"
        width="100%"
        height="100%"
        class="pa-4 d-flex flex-column"
        elevation="0"
        color="#EAF1F5"
    >
      <ExamFilter
          :level="5"
          :key="query"
          @onSearchAction="onSearch"
          :requiredFields="{
          masterSchool: true,
          school: true,
          examYear: true,
          progExamPeriod: true,
          examClass: true,
        }"
      />
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
              no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
              loading-text="Φόρτωση..."
              :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
              :headers="headers"
              :items="!filtersSet ? [] : classExamTeachers.hashContent"
              :options.sync="options"
              :server-items-length="classExamTeachers.elements"
              :loading="tableLoader"
          >
            <template v-slot:[`item.date_start`]="{ item }">
              <span>{{
                  new Date(item.date_start).toLocaleDateString("el")
                }}</span>
            </template>

            <template v-slot:[`item.date_end`]="{ item }">
              <span>{{
                  new Date(item.date_end).toLocaleDateString("el")
                }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'edit')">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">
                      mdi-pen
                    </v-icon>
                  </v-btn>
                </template>
                <span>Επεξεργασία</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon @click="openDialog(item, 'delete')">
                    <v-icon color="error" small v-bind="attrs" v-on="on">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Διαγραφή</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <div class="custom-card-buttons">
          <v-btn
              depressed
              color="primary"
              class="mr-2"
              :disabled="!filtersSet"
              @click="openDialog(null, 'add')"
          >
            Προσθήκη
            <v-icon dense right>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-card>

    <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'add'"
        max-width="500px"
        scrollable
    >
      <AddClassExamTeacher
          v-if="dialog.open && dialog.type == 'add'"
          @close="closeDialog"
          from="1"
          @handler="handler"
      />
    </v-dialog>

    <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'edit'"
        max-width="500px"
        scrollable
    >
      <EditClassExamTeacher
          v-if="dialog.open && dialog.type == 'edit'"
          :classExamTeacher="dialog.classExamTeacher"
          @close="closeDialog"
          from="1"
          @handler="handler"
      />
    </v-dialog>

    <v-dialog
        persistent
        v-model="dialog.open"
        v-if="dialog.type == 'delete'"
        max-width="500px"
        scrollable
    >
      <DeleteClassExamTeacher
          v-if="dialog.open && dialog.type == 'delete'"
          :classExamTeacher="dialog.classExamTeacher"
          @close="closeDialog"
          @handler="handler"
          :classExamTeachers="classExamTeachers"
      />
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import ExamFilter from "../../components/GenericFilter/ExamFilter.vue";
import AddClassExamTeacher from "../../components/ClassExamTeachers/AddClassExamTeacher.vue";
import EditClassExamTeacher from "../../components/ClassExamTeachers/EditClassExamTeacher.vue";
import DeleteClassExamTeacher from "../../components/ClassExamTeachers/DeleteClassExamTeacher.vue";
import axios from "axios";

export default {
  components: {
    ExamFilter,
    AddClassExamTeacher,
    EditClassExamTeacher,
    DeleteClassExamTeacher,
  },
  created() {
  },
  data() {
    return {
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      filtersSet: false,
      query: false,
      classExamTeachers: [],
      headers: [
        {
          text: "Επώνυμο",
          value: "last_name",
          sortable: false,
        },
        {
          text: "Όνομα",
          value: "first_name",
          sortable: false,
        },

        {
          text: "Τμήμα",
          value: "edu_class_exam_hd_description",
          sortable: false,
        },

        {
          text: "Μάθημα",
          value: "edu_lessons_p_description",
          sortable: false,
        },
        {
          text: "Τύπος",
          value: "ecet_tpy_description",
          sortable: false,
        },
        {
          text: "Ημερομηνία από",
          value: "date_start",
          sortable: false,
        },

        {
          text: "Ημερομηνία έως",
          value: "date_end",
          sortable: false,
        },

        {
          text: "Επιλογές",
          value: "actions",
          sortable: false,
        },
      ],

      dialog: {
        classExamTeacher: null,
        open: false,
        type: null,
      },
    };
  },

  watch: {
    async options() {
      if (this.classExamId != null) await this.handler();
    },
    $route() {
      this.query = this.$route.params.id;
    },
  },

  computed: {
    ...mapState({
      pageChanged: (state) => state.base.pageChanged,
      progExamPeriodId: (state) => state.generic_filter.progExamPeriodId,
      classExamId: (state) => state.generic_filter.examClassId,
    }),
  },

  methods: {
    ...mapMutations(["setFilters", "changePage", "setCurrentStudent"]),
    async onSearch() {
      if (this.options.page != 1) {
        this.options.page = 1;
      } else {
        await this.handler();
      }
    },

    async handler() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/class/exam/teacher/list`,
            {
              params: {
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                    this.options.itemsPerPage == -1
                        ? null
                        : this.options.itemsPerPage,
                progExamPeriodId: this.progExamPeriodId,
                classExamId: this.classExamId.split(".")[0],
              },
            }
        );

        this.classExamTeachers = res.data;

        if (!this.filtersSet) this.filtersSet = true;
        this.filtersSet = true;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.classExamTeachers = [];
        await this.$store.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },

    openDialog(classExamTeacher, type) {
      this.dialog = {
        classExamTeacher,
        open: true,
        type,
      };
    },

    closeDialog() {
      this.dialog = {
        classExamTeacher: null,
        open: false,
        type: null,
      };
      if (this.pageChanged) {
        this.options.page -= 1;
        this.changePage(false);
      }
      this.handler();
    },
  },
};
</script>

<style scoped lang="scss">
</style>