
 <template>
  <div class="component-wrapper d-flex align-center justify-center">
    <v-card
      max-width="1400px"
      width="100%"
      height="100%"
      class="pa-4 d-flex flex-column"
      elevation="0"
      color="#EAF1F5"
    >
      <v-card class="mb-2 pa-2">
        <v-card-text class="d-flex flex-column">
          <v-row>
            <v-col>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    v-model="dateFromFormatted"
                    v-bind="attrs"
                    hide-details="auto"
                    v-on="on"
                    class="mb-4"
                    @blur="dateFrom = parseDate(dateFromFormatted, 'dateFrom')"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                    </template>
                    <template v-slot:label> Ημερομηνία Από </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="el"
                  hide-details="auto"
                  v-model="dateFrom"
                  scrollable
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    v-model="dateToFormatted"
                    v-bind="attrs"
                    hide-details="auto"
                    v-on="on"
                    class="mb-4"
                    @blur="dateTo = parseDate(dateToFormatted, 'dateTo')"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                    </template>
                    <template v-slot:label> Ημερομηνία Έως </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="el"
                  hide-details="auto"
                  v-model="dateTo"
                  scrollable
                  @input="menu1 = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field
                dense
                label="Χρήστης"
                outlined
                v-model="user"
                class="mb-4"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="ml-auto">
            <v-col class="ml-auto">
              <v-btn
                class="mr-2 mt-2"
                depressed
                color="green"
               :loading="loader"
                @click="printData"
                :disabled='isDisabled'
              >
                Εκτύπωση
              </v-btn>
              <v-btn
                class="ml-auto mt-2"
                depressed
                color="primary"
                @click="fetchAuditing"
              >
                Αναζήτηση
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-text class="pa-4">
          <v-data-table
            no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
            loading-text="Φόρτωση..."
            :footer-props="{
              'items-per-page-text': 'Σειρές ανά σελίδα',
              'items-per-page-all-text': 'Όλες',
              'items-per-page-options': [5, 10, 15, -1],
            }"
            :headers="headers"
            :items="data.content"
            :server-items-length="data.elements"
            :options.sync="options"
            :loading="tableLoader"
          >
            <template v-slot:[`item.username`]="{ item }">
              {{ item.createdByLastname + " " + item.createdByFirstname }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};
export default {
  async created() {},
  data() {
    return {
      tableLoader: false,
      data: [],
      dateToFormatted: "",
      dateTo: "",
      dateFromFormatted: "",
      dateFrom: "",
      loader: false,
      user: "",
      menu: false,
      menu1: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "User Action",
          value: "userAction",
          sortable: false,
        },
        {
          text: "Creation Date",
          value: "creationDate",
          sortable: false,
        },
        {
          text: "User",
          value: "username",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    options: {
      async handler() {
        try {
          this.setFilters(this.options);
          this.fetchAuditing();
        } catch (e) {
          console.log(e);
          this.tableLoader = false;
        }
      },
    },
    dateFrom(val) {
      this.dateFromFormatted = this.formatDate(this.dateFrom);
    },
    dateTo(val) {
      this.dateToFormatted = this.formatDate(this.dateTo);
    },
    deep: true,
  },
  computed: {
    ...mapState({
      roleSelected: (state) => state.auth.selectedRole,
    }),
    isDisabled() {
      if (this.data.content != "" && this.data.content != null) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations(["setFilters"]),
    async fetchAuditing() {
      try {
        this.tableLoader = true;
        this.setFilters(this.options);
        this.filtersSet = true;
        let body = {};
        let params = {
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
        };
        if (this.dateFrom != "" && this.dateFrom != null)
          params.from = this.dateFrom;
        if (this.dateTo != "" && this.dateTo != null) params.to = this.dateTo;
        if (this.user != "" && this.user != null) params.username = this.user;
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/auditing/log/list`,
          body,
          {
            params,
          }
        );
        this.data = res.data;
        this.tableLoader = false;
      } catch (e) {
        this.tableLoader = false;
        throw e;
      }
    },
    async printData() {
      try {
        this.loader = true;
        let body = [
          { attributes: "userAction", title: "User Action" },
          { attributes: "creationDate", title: "creation Date" },
          { attributes: "username", title: "User" },
        ];
        let params = {
          page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
          limit:
            this.options.itemsPerPage == -1 ? null : this.options.itemsPerPage,
        };
        if (this.dateFrom != "" && this.dateFrom != null)
          params.from = this.dateFrom;
        if (this.dateTo != "" && this.dateTo != null) params.to = this.dateTo;
        if (this.user != "" && this.user != null) params.username = this.user;
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/auditing/log/list/export`,
          body,
          {
            params,
          }
        );
        this.loader = false;
        console.log(res, "Sucess");
      } catch (err) {
        this.loader = false;
        console.log(err, "Error");
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "dateFrom") {
          this.dateFrom = null;
          this.dateFromFormatted = null;
        } else if (dateName == "dateTo") {
          this.dateTo = null;
          this.dateToFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped lang="scss">
.v-application .green {
  background-color: #4ebb52 !important;
  border-color: #4caf50 !important;
  color: white !important;
}
</style>