<template>
  <v-card class="mt-4">
    <v-card-text>
      <v-data-table
          no-data-text="Δεν υπάρχουν διαθέσιμα δεδομένα"
          loading-text="Φόρτωση..."
          :footer-props="{
          'items-per-page-text': 'Σειρές ανά σελίδα',
          'items-per-page-all-text': 'Όλες',
          'items-per-page-options': [5, 10, 15, -1],
        }"
          :headers="headers"
          :items="certifications.content"
          :server-items-length="certifications.elements"
          :options.sync="options"
          :loading="tableLoader"
      >
        <template v-slot:[`item.startDate`]="{ item }">
          <span>{{ formatDate(item.startDate, 'el') }}</span>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          <span v-if="!isEmpty(item.endDate)">{{ formatDate(item.endDate, 'el') }}</span>
          <span v-else>-</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import {mapMutations, mapState} from "vuex";
import {helperMixin} from "@/helpers/helperMixin";
import {isEmpty} from "@/helpers/helpers";

export default {
  mixins: [helperMixin],
  created() {
    this.handler();
  },
  data() {
    return {
      certifications: [],
      tableLoader: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Κωδικός",
          value: "certifNr",
          sortable: false,
        },
        {
          text: "Ημερομηνία έναρξης",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Ημερομηνία λήξης",
          value: "endDate",
          sortable: false,
        },
        {
          text: "Βαθμός",
          value: "grade",
          sortable: false,
        },
        {
          text: "Επίδοση",
          value: "gradeCharac",
          sortable: false,
        },
      ],
    };
  },

  //   watch: {
  //     options: {
  //       async handler() {
  //         try {
  //           console.log(this.currentUser);
  //           this.tableLoader = true;
  //           //   this.setFilters(this.options);

  //           const res = await axios.get(
  //             `${process.env.VUE_APP_BASE_URL}/person/certification/list`,
  //             {
  //               params: {
  //                 personId: this.currentUser.id,
  //                 page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
  //                 limit:
  //                   this.options.itemsPerPage == -1
  //                     ? null
  //                     : this.options.itemsPerPage,
  //               },
  //             }
  //           );

  //           this.certifications = res.data;
  //           this.tableLoader = false;
  //         } catch (e) {
  //           console.log(e);
  //           this.certifications = [];

  //           this.tableLoader = false;
  //         }
  //       },
  //     },
  //     deep: true,
  //   },

  computed: {
    ...mapState({
      currentUser: (state) => state.users.currentUser,
    }),
  },

  methods: {
    isEmpty,
    ...mapMutations(["setFilters"]),
    async handler() {
      try {
        console.log(this.currentUser);
        console.log(this.options);
        this.tableLoader = true;
        this.setFilters(this.options);

        const res = await axios.get(
            `${process.env.VUE_APP_BASE_URL}/person/certification/list`,
            {
              params: {
                personId: this.currentUser.id,
                page: this.options.itemsPerPage == -1 ? 0 : this.options.page,
                limit:
                    this.options.itemsPerPage == -1
                        ? null
                        : this.options.itemsPerPage,
              },
            }
        );

        this.certifications = res.data;
        this.tableLoader = false;
      } catch (e) {
        console.log(e);
        this.certifications = [];
        this.$router.dispatch("errorHandler", e);

        this.tableLoader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>