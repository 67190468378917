<template>
  <v-card>
    <v-toolbar color="primary" class="white--text">
      Προγραμματισμού
      {{ appId == 1 ? "εξαμήνου σπουδών" : "κύκλων σπουδών " }}
      <v-btn
        depressed
        icon
        color="white"
        class="ml-auto"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-form @submit.prevent="onSubmit" class="pa-4">
        <v-card class="mb-4">
          <v-card-title class="mb-4"> Βασικά στοιχεία </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" sm="6">
                <v-select
                  dense
                  outlined
                  v-model="trainingPeriodId"
                  type="text"
                  class="mb-4"
                  hide-details="auto"
                  :items="semesterList"
                  item-text="description"
                  @change="onChangeSemester"
                  item-value="cd"
                  :error-messages="trainingPeriodIdErrors"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    {{ appId == 1 ? "Εξάμηνο σπουδών" : "Κύκλος σπουδών" }}
                  </template>
                </v-select>
              </v-col>
              <!-- <v-col cols="12" md="6" sm="6">
                <v-text-field
                  dense
                  outlined
                  v-model="serialNr"
                  type="text"
                  hide-details="auto"
                  :error-messages="serialNrErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Σειρά
                  </template>
                </v-text-field>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  dense
                  hide-details="auto"
                  outlined
                  v-model="description"
                  type="text"
                  disabled
                  :error-messages="descriptionErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Περιγραφή
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4" v-if="viewDetail()">
          <v-card-title class="mb-4"> Επιπλέον πληροφορίες </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="classHours"
                  type="text"
                  :error-messages="classHoursErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Θεωρητικές ώρες
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  outlined
                  v-model="labHours"
                  type="text"
                  hide-details="auto"
                  :error-messages="labHoursErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Εργαστηριακές ώρες
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-select
                  clearable
                  dense
                  hide-details="auto"
                  outlined
                  v-model="absFlag"
                  label="Καταχώρηση απουσιών "
                  type="text"
                  class="mb-4"
                  :items="getDataStaticList(120)"
                  item-text="description"
                  item-value="cd"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-select
                  clearable
                  dense
                  outlined
                  v-model="active"
                  hide-details="auto"
                  disabled
                  label="Ενεργοποίηση κύκλου"
                  type="text"
                  class="mb-4"
                  :items="getDataStaticList(118)"
                  item-text="description"
                  item-value="cd"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-select
                  clearable
                  dense
                  outlined
                  hide-details="auto"
                  v-model="registerFlag"
                  label="Καταχώρηση εγγραφών"
                  type="text"
                  class="mb-4"
                  :items="getDataStaticList(119)"
                  item-text="description"
                  item-value="cd"
                  no-data-text="Δεδομένα μη διαθέσιμα"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4">
          <v-card-title class="mb-4"> Διάρκεια </v-card-title>
          <v-card-text>
            <v-row
              v-if="semesterInfo != null && semesterInfo.lessonRepeatNr == 1"
            >
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="startDateFormatted"
                      hide-details="auto"
                      class="mb-4"
                      @blur="
                        startDate = parseDate(startDateFormatted, 'startDate')
                      "
                      :error-messages="startDateErrors"
                    >
                      <template v-slot:prepend-inner>
                        <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                      </template>
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ημερομηνία έναρξης
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="el"
                    v-model="startDate"
                    hide-details="auto"
                    scrollable
                    @input="menu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="4" sm="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="endDateFormatted"
                      hide-details="auto"
                      prepend-inner-icon="mdi-calendar"
                      class="mb-4"
                      @blur="endDate = parseDate(endDateFormatted, 'endDate')"
                      :error-messages="endDateErrors"
                    >
                      <template v-slot:label>
                        <span class="error--text">*</span>
                        Ημερομηνία λήξης
                      </template>
                      <template v-slot:prepend-inner>
                        <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="el"
                    v-model="endDate"
                    scrollable
                    @input="menu1 = false"
                    hide-details="auto"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" sm="6" v-if="this.appId == 2">
                <v-text-field
                  dense
                  outlined
                  v-model="serialNr"
                  type="text"
                  hide-details="auto"
                  :error-messages="serialNrErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Σειρά
                  </template>
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12" md="4" sm="6">
                <v-text-field
                  dense
                  outlined
                  v-model="daysDur"
                  type="text"
                  hide-details="auto"
                  class="mb-4"
                  :error-messages="daysDurErrors"
                >
                  <template v-slot:label>
                    <span class="error--text">*</span>
                    Διάρκεια σε ημέρες
                  </template>
                </v-text-field>
              </v-col> -->
            </v-row>
            <div
              v-if="semesterInfo != null && semesterInfo.lessonRepeatNr != 1"
            >
              <v-row
                v-for="(dur, i) in durationMulti"
                :key="i"
                style="border-bottom: 1px solid #00000030"
              >
                <v-col cols="12" md="3" sm="6">
                  <v-menu
                    ref="menu"
                    v-model="dur.menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="dur.startDateFormatted"
                        hide-details="auto"
                        class="mb-4"
                        @blur="
                          startDate = parseDate(
                            dur.startDateFormatted,
                            'startDate'
                          )
                        "
                        :error-messages="startDateErrorMessages(dur, i)"
                      >
                        <template v-slot:prepend-inner>
                          <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                        </template>
                        <template v-slot:label>
                          <span
                            class="error--text"
                            v-if="
                              durationMulti[i].startDate != null ||
                              durationMulti[i].endDate != null
                            "
                            >*</span
                          >
                          Ημερομηνία έναρξης
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="el"
                      v-model="dur.startDate"
                      hide-details="auto"
                      scrollable
                      @change="onChangeStartDate(i)"
                      @input="dur.menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3" sm="6">
                  <v-menu
                    ref="menu"
                    v-model="dur.menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="dur.endDateFormatted"
                        hide-details="auto"
                        prepend-inner-icon="mdi-calendar"
                        class="mb-4"
                        clearable
                        @blur="
                          endDate = parseDate(dur.endDateFormatted, 'endDate')
                        "
                      >
                        <template v-slot:label>
                          <span
                            class="error--text"
                            v-if="durationMulti[i].startDate != null"
                            >*</span
                          >
                          Ημερομηνία λήξης
                        </template>
                        <template v-slot:prepend-inner>
                          <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      locale="el"
                      v-model="dur.endDate"
                      scrollable
                      @input="dur.menu1 = false"
                      @change="onChangeEndDate(i)"
                      hide-details="auto"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3" sm="6" v-if="appId == 2">
                  <v-text-field
                    dense
                    outlined
                    v-model="durationMulti[i].serialNr"
                    type="text"
                    hide-details="auto"
                    :error-messages="serialNrErrorMessages(dur, i)"
                  >
                    <template v-slot:label>
                      <span
                        class="error--text"
                        v-if="
                          durationMulti[i].startDate != null ||
                          durationMulti[i].endDate != null
                        "
                        >*</span
                      >
                      Σειρά
                    </template>
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                  sm="6"
                  style="display: flex; justify-content: center"
                >
                  <v-btn depressed color="error" @click="removeDuration(i)">
                    <v-icon>mdi-minus-box</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>

        <div class="d-flex justify-center">
          <v-btn depressed color="error" @click="$emit('close')">Ακύρωση</v-btn>
          <v-btn
            depressed
            color="success"
            class="ml-4"
            type="submit"
            :loading="loader"
            >Δημιουργία</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import axios from "axios";

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  props: ["data"],
  async created() {
    // try {
    //   await this.getSemesters({
    //     schoolId:
    //       this.$route.path.split("/")[1] == "faculties"
    //         ? this.currentSchool.id
    //         : this.genericFilter.schoolId,
    //     page: 0,
    //   });
    // } catch (e) {
    //   console.log(e);
    // }
    this.fetchSemesters();
  },

  data() {
    return {
      absFlag: "1",
      active: null,
      registerFlag: "1",
      serialNr: null,
      description: null,
      startDate: null,
      startDateFormatted: null,
      endDate: null,
      endDateFormatted: null,
      // daysDur: null,
      classHours: null,
      labHours: null,
      trainingPeriodId: null,
      semesterInfo: { lessonRepeatNr: 0 },

      durationMulti: [],
      startDateFormattedMulti: [],
      endDateFormattedMulti: [],

      loader: false,
      menu: false,
      menu1: false,
      semesterList: [],
    };
  },

  // validations() {
  //   if (this.semestrerInfo?.lessonRepeatNr == 1 && this.appId == 1) {
  //     return {
  //       description: {
  //         required,
  //       },
  //       startDate: {
  //         required,
  //       },
  //       endDate: {
  //         required,
  //       },
  //       classHours: {
  //         required,
  //         numeric,
  //       },
  //       labHours: {
  //         required,
  //         numeric,
  //       },
  //       trainingPeriodId: {
  //         required,
  //       },
  //     };
  //   } else if (this.semestrerInfo?.lessonRepeatNr > 1 && this.appId == 1) {
  //     return {
  //       durationMulti: {
  //         $each: {
  //           startDate: {
  //             required: requiredIf((el) => {
  //               return el.endDate != null;
  //             }),
  //           },
  //           endDate: {
  //             required: requiredIf((el) => {
  //               return el.startDate != null;
  //             }),
  //           },
  //         },
  //       },
  //       classHours: {
  //         required,
  //         numeric,
  //       },
  //       labHours: {
  //         required,
  //         numeric,
  //       },
  //       trainingPeriodId: {
  //         required,
  //       },
  //       description: {
  //         required,
  //       },
  //     };
  //   } else if (this.semestrerInfo?.lessonRepeatNr == 1 && this.appId == 2) {
  //     return {
  //       description: {
  //         required,
  //       },
  //       startDate: {
  //         required,
  //       },
  //       endDate: {
  //         required,
  //       },
  //       serialNr: {
  //         required,
  //         numeric,
  //       },
  //       classHours: {
  //         required,
  //         numeric,
  //       },
  //       labHours: {
  //         required,
  //         numeric,
  //       },
  //       trainingPeriodId: {
  //         required,
  //       },
  //     };
  //   } else if (this.semestrerInfo?.lessonRepeatNr > 1 && this.appId == 2) {
  //     console.log("edw");
  //     return {
  //       durationMulti: {
  //         $each: {
  //           startDate: {
  //             required: requiredIf((el) => {
  //               return el.endDate != null;
  //             }),
  //           },
  //           endDate: {
  //             required: requiredIf((el) => {
  //               return el.startDate != null;
  //             }),
  //           },
  //           serialNr: {
  //             required: requiredIf((el) => {
  //               console.log(el.startDate, el.endDate);
  //               return el.startDate != null || el.endDate != null;
  //             }),
  //           },
  //         },
  //       },
  //       classHours: {
  //         required,
  //         numeric,
  //       },
  //       labHours: {
  //         required,
  //         numeric,
  //       },
  //       trainingPeriodId: {
  //         required,
  //       },
  //       description: {
  //         required,
  //       },
  //     };
  //   } else {
  //     console.log("edw 2");

  //     return {
  //       description: {
  //         required,
  //       },
  //       classHours: {
  //         required,
  //         numeric,
  //       },
  //       labHours: {
  //         required,
  //         numeric,
  //       },
  //       trainingPeriodId: {
  //         required,
  //       },
  //     };
  //   }
  // },
  validations() {
    const self = this;
    return {
      description: {
        required,
      },
      classHours: {
        required: requiredIf((e) => {
          return self.semesterInfo.shipTrainingId.cd != "1";
        }),
        numeric,
      },
      labHours: {
        required: requiredIf((e) => {
          return self.semesterInfo.shipTrainingId.cd != "1";
        }),
        numeric,
      },
      trainingPeriodId: {
        required,
      },
      startDate: {
        required: requiredIf((e) => {
          return self.semesterInfo.lessonRepeatNr == 1;
        }),
      },
      endDate: {
        required: requiredIf((e) => {
          return self.semesterInfo.lessonRepeatNr == 1;
        }),
      },
      serialNr: {
        required: requiredIf((e) => {
          return self.appId == 2 && self.semesterInfo.lessonRepeatNr == 1;
        }),
        numeric,
      },
      durationMulti: {
        $each: {
          startDate: {
            required: requiredIf((el) => {
              return el.endDate != null;
            }),
          },
          endDate: {
            required: requiredIf((el) => {
              return el.startDate != null;
            }),
          },
          serialNr: {
            required: requiredIf((el) => {
              return (
                (el.startDate != null || el.endDate != null) && self.appId == 2
              );
            }),
          },
        },
      },
    };
  },

  watch: {
    startDate(val) {
      this.startDateFormatted = this.formatDate(this.startDate);
    },

    endDate(val) {
      this.endDateFormatted = this.formatDate(this.endDate);
    },
  },

  computed: {
    ...mapState({
      currentFaculty: (state) => state.faculties.currentFaculty,
      currentSchool: (state) => state.schools.currentSchool,
      currentAcademicYear: (state) => state.academic_years.currentAcademicYear,
      semesters: (state) => state.semesters.semesters,
      staticList: (state) => state.auth.userInfo.staticView,
      genericFilter: (state) => state.generic_filter,
      appId: (state) => state.auth.appId,
      selectedRole: (state) => state.auth.selectedRole,
    }),

    serialNrErrors() {
      const errors = [];
      if (!this.$v.serialNr.$dirty) return errors;
      if (!this.$v.serialNr.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.serialNr.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
      return errors;
    },

    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      if (!this.$v.description.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    startDateErrors() {
      const errors = [];
      if (!this.$v.startDate.$dirty) return errors;
      if (!this.$v.startDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    endDateErrors() {
      const errors = [];
      if (!this.$v.endDate.$dirty) return errors;
      if (!this.$v.endDate.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
    // daysDurErrors() {
    //   const errors = [];
    //   if (!this.$v.daysDur.$dirty) return errors;
    //   if (!this.$v.daysDur.required) errors.push("Υποχρεωτικό πεδίο");
    //   if (!this.$v.daysDur.numeric) errors.push("Επιτρέπονται μόνο ψηφία");
    //   return errors;
    // },
    classHoursErrors() {
      const errors = [];
      if (!this.$v.classHours.$dirty) return errors;
      if (!this.$v.classHours.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.classHours.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    labHoursErrors() {
      const errors = [];
      if (!this.$v.labHours.$dirty) return errors;
      if (!this.$v.labHours.required) errors.push("Υποχρεωτικό πεδίο");
      if (!this.$v.labHours.numeric) errors.push("Επιτρέπονται μόνο ψηφία");

      return errors;
    },
    trainingPeriodIdErrors() {
      const errors = [];
      if (!this.$v.trainingPeriodId.$dirty) return errors;
      if (!this.$v.trainingPeriodId.required) errors.push("Υποχρεωτικό πεδίο");
      return errors;
    },
  },

  methods: {
    ...mapActions(["addSchedulingSemester", "getSemesters"]),
    async fetchSemesters() {
      let body = {
        type: this.appId,
        parentCd: this.genericFilter.schoolId,
        roleId: this.selectedRole,
      };
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/value/call/2006`,
          body
        );
        this.semesterList = res.data;
      } catch (e) {
        throw e;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "startDate") {
          this.startDate = null;
          this.startDateFormatted = null;
        } else if (dateName == "endDate") {
          this.endDate = null;
          this.endDateFormatted = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    isRequired() {
      return true;
    },

    async onSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loader = true;
        var dateList = [];
        let content = {
          // classHours: this.classHours,
          // daysDur: this.daysDur,
          description: this.description,
          // endDate: this.endDate,
          // labHours: this.labHours,
          // serialNr: this.serialNr,
          // startDate: this.startDate,
          yearId:
            this.$route.path.split("/")[1] == "faculties"
              ? this.currentAcademicYear.id
              : this.genericFilter.academicYearId,
          trainPeriodId: this.trainingPeriodId,
        };

        console.log(this.semesterInfo);
        if (this.semesterInfo.shipTrainingId.cd != "1") {
          content.classHours = this.classHours;
          content.labHours = this.labHours;
          if (this.absFlag != null) content.absFlag = this.absFlag;
          if (this.active != null) content.active = this.active;
          if (this.registerFlag != null)
            content.registerFlag = this.registerFlag;
        }

        if (this.semesterInfo.lessonRepeatNr > 1) {
          dateList = this.durationMulti
            .filter((d) => {
              if (this.appId == 1 && d.startDate != null) {
                return { ...d, serialNr: null };
              } else if (this.appId == 2 && d.startDate != null) {
                return d;
              }
            })
            .map((e) => {
              return {
                startDate: e.startDate,
                endDate: e.endDate,
                serialNr: e.serialNr,
              };
            });
        } else {
          if (this.appId == 1) {
            dateList.push({
              startDate: this.startDate,
              endDate: this.endDate,
              serialNr: null,
            });
          } else {
            dateList.push({
              startDate: this.startDate,
              endDate: this.endDate,
              serialNr: this.serialNr,
            });
          }
        }

        content = { ...content, dateList };
        console.log(content);
        await this.addSchedulingSemester(content);

        this.loader = false;

        this.$emit("close");
      } catch (e) {
        this.loader = false;
      }
    },

    getDataStaticList(code) {
      return this.staticList.filter((element) => {
        if (element.lov_grp_id == code) {
          return element;
        }
      });
    },
    async onChangeSemester() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/train/period/${this.trainingPeriodId}`,
          {
            params: {
              page: 0,
            },
          }
        );
    
        this.semesterInfo = res.data;
        this.labHours = res.data.labHours;
        this.classHours = res.data.hours;
        this.description = res.data.description;
          
        if (this.appId != 1) {
          if (res.data.lessonRepeatNr == 1) {
            let fromDd = res.data.lessonFromDd;
            let fromMm = res.data.lessonFromMm;
            let toDd = res.data.lessonToDd;
            let toMm = res.data.lessonToMm;
            // fetch year
            const yearRes = await axios.get(
              `${process.env.VUE_APP_BASE_URL}/year/${Number(
                this.genericFilter.academicYearId
              )}`,
              {
                params: {
                  page: 0,
                },
              }
            );
            let year = yearRes.data.year;
            this.startDate =
              year +
              "-" +
              String(fromMm).padStart(2, "0") +
              "-" +
              String(fromDd).padStart(2, "0");
            if (fromMm > toMm) {
              year = year + 1;
            }
            this.endDate =
              year +
              "-" +
              String(toMm).padStart(2, "0") +
              "-" +
              String(toDd).padStart(2, "0");
          } else {
            for (let i = 0; i < this.semesterInfo.lessonRepeatNr; i++) {
              this.durationMulti.push({
                startDate: null,
                startDateFormatted: null,
                endDate: null,
                endDateFormatted: null,
                serialNr: null,
                menu: false,
                menu1: false,
              });
            }
          }
        }
      } catch (e) {
        throw e;
      }
    },

    async onChangeStartDate(i) {
      this.durationMulti[i].startDateFormatted = this.formatDate(
        this.durationMulti[i].startDate
      );
      const res = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/value/calculate/working/days`,
        {
          params: {
            dateFrom: this.durationMulti[i].startDate,
            daysAdd: this.semesterInfo.duration,
          },
        }
      );
      // console.log(res);
      // end.setDate(date.getDate() + this.semesterInfo.duration);
      this.durationMulti[i].endDate = res.data;

      // this.durationMulti[i].endDate;=
      this.onChangeEndDate(i);
    },
    onChangeEndDate(i) {
      this.durationMulti[i].endDateFormatted = this.formatDate(
        this.durationMulti[i].endDate
      );
    },
    removeDuration(i) {
      this.durationMulti = this.durationMulti.filter((r, j) => {
        if (j != i) {
          return r;
        }
      });
    },
    startDateErrorMessages(item, i) {
      if (item.startDate != null || item.endDate != null) {
        const errors = [];
        if (!this.$v.durationMulti.$each[i].startDate.$dirty) return errors;
        if (!this.$v.durationMulti.$each[i].startDate.required)
          errors.push("Υποχρεωτικό πεδίο");
        return errors;
      } else return;
    },
    endDateErrorMessages(item, i) {
      if (item.startDate != null || item.endDate != null) {
        const errors = [];
        if (!this.$v.durationMulti.$each[i].endDate.$dirty) return errors;
        if (!this.$v.durationMulti.$each[i].endDate.required)
          errors.push("Υποχρεωτικό πεδίο");
        return errors;
      } else return;
    },
    serialNrErrorMessages(item, i) {
      if (item.startDate != null || item.endDate != null) {
        const errors = [];
        if (!this.$v.durationMulti.$each[i].serialNr.$dirty) return errors;
        if (!this.$v.durationMulti.$each[i].serialNr.required)
          errors.push("Υποχρεωτικό πεδίο");
        return errors;
      } else return;
    },
    viewDetail() {
      if (this.semesterInfo?.shipTrainingId?.cd == "1") {
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped lang="scss">
</style>