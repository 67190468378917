var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrapper d-flex align-center justify-center"},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",attrs:{"max-width":"1400px","width":"100%","height":"100%","elevation":"0","color":"#EAF1F5"}},[_c('GenericFilter',{attrs:{"level":2,"search":true,"requiredFields":{
        masterSchool: true,
        school: true,
        year: false,
        progTrainPeriod: false,
        class: false,
        lesson: false,
      }},on:{"onSearchAction":_vm.onSearch}}),_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"no-data-text":"Δεν υπάρχουν διαθέσιμα δεδομένα","loading-text":"Φόρτωση...","footer-props":{
            'items-per-page-text': 'Σειρές ανά σελίδα',
            'items-per-page-all-text': 'Όλες',
            'items-per-page-options': [5, 10, 15, -1],
          },"headers":_vm.appId == 1 ? _vm.headers : _vm.headersKesen,"items":_vm.students.content,"options":_vm.options,"server-items-length":_vm.students.elements,"loading":_vm.tableLoader},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pen ")])],1)]}}],null,true)},[_c('span',[_vm._v("Επεξεργασία")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){return _vm.onStudentView(item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Προβολή")])])]}},{key:`item.enabled`,fn:function({ item }){return [(item.enabled)?_c('span',[_vm._v("Ναι")]):_c('span',[_vm._v("Οχι")])]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }